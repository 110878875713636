import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from '../../common/Loader';
import AddOnFeature from './AddOnFeature';
import PricingDetails from './PricingDetails';
import NoRecords from '../../common/NoRecords';
import AllFeatureDetailsCont from './AllFeatureDetailsCont';
import PricingDetailsEnterprise from './PricingDetailsEnterprise';
import PlanManagementStyle from '../../Theme/style/PlanManagementStyle';
import { WhiteCard, Button } from '../../common';
import { PATH_PLAN_MANAGEMENT } from '../../constants';
import { EditBackgroundImg } from '../../assets/images';
import {
    PlanMangementDetailsType,
    FeaturesMasterListType,
} from '../../types/type';

const PlanManagementDetails = ({
    planDetails,
    isLoading,
    planName,
    otherEntDetails,
    getPlanDetail,
    isEnterprise,
    planId,
    enterprisePlanDetails,
    loaderStatus,
    setLoaderStatus,
    handleChangeStatus,
}: PlanMangementDetailsType) => {
    const navigate = useNavigate();
    const [isAddNewType, setIsAddNewType] = useState(false);
    const [pricingDetails, setPricingDetails] = useState<
        FeaturesMasterListType[]
    >([]);
    const [otherDetails, setOtherDetails] = useState<FeaturesMasterListType[]>(
        []
    );
    const [isShowEditBtn, setIsShowEditBtn] = useState<any>({});
    const [addFeature, setAddFeature] = useState<any>({});

    const handleSetAddFeature = (name: string) => {
        setAddFeature({
            ...addFeature,
            [name]: false,
        });
    };
    const handleAddFeatureValue = (name: string) => {
        handleSetAddFeature(name);
        getPlanDetail(false);
    };
    const handleAddFeatureClose = (name: string) => {
        handleSetAddFeature(name);
    };
    const handleUpdateClose = (name: string, isUp: boolean) => {
        setIsShowEditBtn({
            ...isShowEditBtn,
            [name]: false,
        });
        if (isUp) {
            getPlanDetail(false);
        }
    };

    const setOtherFeatureDetails = useCallback(() => {
        if (planDetails.length) {
            setPricingDetails(
                planDetails.filter(
                    (item: FeaturesMasterListType) =>
                        item?.featuresName === 'Pricing'
                )
            );
            setOtherDetails(
                planDetails.filter(
                    (item: FeaturesMasterListType) =>
                        item?.featuresName !== 'Pricing'
                )
            );
        }
    }, [planDetails]);

    const handleFeaturesAPICall = () => {
        getPlanDetail(false);
    };

    useEffect(() => {
        setOtherFeatureDetails();
    }, [planDetails, setOtherFeatureDetails]);

    return isLoading ? (
        <Loader />
    ) : (
        <PlanManagementStyle>
            {pricingDetails && pricingDetails?.length ? (
                <div>
                    {isEnterprise ? (
                        <PricingDetailsEnterprise
                            pricingDetails={pricingDetails[0]}
                            otherEntDetails={otherEntDetails}
                            getPlanDetail={getPlanDetail}
                        />
                    ) : (
                        <PricingDetails
                            pricingDetails={pricingDetails[0]}
                            planName={planName}
                            getPlanDetail={getPlanDetail}
                        />
                    )}
                </div>
            ) : (
                ''
            )}

            {otherDetails &&
                otherDetails.map((plan: FeaturesMasterListType) => {
                    return (
                        <WhiteCard
                            className="mt-4"
                            key={plan?.featuresMasterId}
                        >
                            <div className="card-header d-flex justify-content-between">
                                {plan?.featuresName}
                                {!isShowEditBtn?.[plan?.featuresName] &&
                                !addFeature?.[plan?.featuresName] ? (
                                    <span>
                                        <img
                                            src={EditBackgroundImg}
                                            className="cursor"
                                            role="presentation"
                                            alt="edit"
                                            onClick={() => {
                                                setIsShowEditBtn({
                                                    ...isShowEditBtn,
                                                    [plan?.featuresName]: true,
                                                });
                                            }}
                                        />
                                    </span>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="horizontal-line mt-3" />

                            <div>
                                {plan?.featurChildlist.length ? (
                                    <AllFeatureDetailsCont
                                        plan={plan?.featurChildlist}
                                        feature={plan?.featuresName}
                                        isEdit={
                                            isShowEditBtn?.[plan?.featuresName]
                                        }
                                        handleUpdateClose={handleUpdateClose}
                                        planName={planName}
                                        fmTitleId={plan?.featuresMasterId}
                                        isEnterprise={isEnterprise}
                                        setOtherDetails={setOtherDetails}
                                        otherDetails={otherDetails}
                                        enterprisePlanDetails={
                                            enterprisePlanDetails
                                        }
                                        setOtherFeatureDetails={
                                            setOtherFeatureDetails
                                        }
                                        isShowToggleSwitch={
                                            plan?.featuresName !== 'Features'
                                        }
                                        handleFeaturesAPICall={
                                            handleFeaturesAPICall
                                        }
                                    />
                                ) : (
                                    <NoRecords />
                                )}
                            </div>
                            <div className="row">
                                {!isShowEditBtn?.[plan?.featuresName] &&
                                !addFeature?.[plan?.featuresName] &&
                                plan?.featuresName !== 'Features' ? (
                                    <div className="col-12 mt-4">
                                        <Button
                                            className="btn-border"
                                            onClick={() => {
                                                setAddFeature({
                                                    ...addFeature,
                                                    [plan?.featuresName]: true,
                                                });
                                            }}
                                        >
                                            Add add-on
                                        </Button>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            {addFeature?.[plan?.featuresName] && (
                                <AddOnFeature
                                    fmTitleId={plan?.featuresMasterId}
                                    fmTitleName={plan?.featuresName}
                                    planName={planName}
                                    isAddOn
                                    setAddFeature={handleAddFeatureValue}
                                    planId={planId}
                                    handleAddFeatureClose={
                                        handleAddFeatureClose
                                    }
                                    getPlanDetail={getPlanDetail}
                                />
                            )}
                        </WhiteCard>
                    );
                })}
            <div className="row mt-3">
                {!isAddNewType && (
                    <div className="col-12 py-3">
                        <Button
                            onClick={() => {
                                setIsAddNewType(true);
                            }}
                        >
                            + Add new type
                        </Button>
                    </div>
                )}
            </div>
            {isAddNewType && (
                <WhiteCard className="mt-4">
                    <AddOnFeature
                        fmTitleId={0}
                        fmTitleName=""
                        planName={planName}
                        setAddFeature={handleAddFeatureValue}
                        setIsAddNewType={setIsAddNewType}
                        planId={planId}
                        handleAddFeatureClose={handleAddFeatureClose}
                        getPlanDetail={getPlanDetail}
                    />
                </WhiteCard>
            )}
            {otherEntDetails?.status === 'Draft' ? (
                <div className="col-12 text-right py-3">
                    <Button
                        className="btn-border"
                        onClick={() => {
                            navigate(PATH_PLAN_MANAGEMENT);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setLoaderStatus({
                                ...loaderStatus,
                                saveButton: true,
                            });
                            handleChangeStatus();
                        }}
                        className="ml-2"
                    >
                        {loaderStatus.saveButton ? (
                            <Loader type="button" />
                        ) : (
                            'Create'
                        )}
                    </Button>
                </div>
            ) : null}
        </PlanManagementStyle>
    );
};

export default PlanManagementDetails;
