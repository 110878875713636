import removeLocalStorageData from '../common/LocalData';
import { removeAuthDetails } from '../features/authSlice';
import { useAppDispatch } from '../store/hook';

const useLogout = () => {
    const dispatch = useAppDispatch();
    const logout = () => {
        removeLocalStorageData();
        dispatch(removeAuthDetails());
    };
    return logout;
};

export default useLogout;
