import { FC, useState, useEffect } from 'react';

import { toast } from 'react-toastify';

import AllFeatureDetails from './AllFeatureDetails';
import { FeaturChildlistType } from '../../types/type';
import { createEnterPrisePlan, setFeatureValues } from '../../api';
import { Button } from '../../common';

interface AllFeaturePropType {
    plan: any;
    feature: string;
    isEdit: boolean;
    handleUpdateClose: (val: string, isUpdate: boolean) => void;
    planName: string;
    fmTitleId: number;
    isEnterprise: boolean;
    setOtherDetails: any;
    otherDetails: any;
    enterprisePlanDetails: any;
    setOtherFeatureDetails: () => void;
    isShowToggleSwitch: boolean;
    handleFeaturesAPICall: () => void;
}

const AllFeatureDetailsCont: FC<AllFeaturePropType> = ({
    plan,
    feature,
    isEdit,
    planName,
    fmTitleId,
    handleUpdateClose,
    isEnterprise,
    setOtherDetails,
    otherDetails,
    enterprisePlanDetails,
    setOtherFeatureDetails,
    isShowToggleSwitch,
    handleFeaturesAPICall,
}) => {
    const [featureDetails, setFeatureDetails] = useState<any>();

    const handleUpdate = async () => {
        if (isEnterprise) {
            const payload = {
                ...enterprisePlanDetails,
                status: 'Save',
                featuresComponentModelList:
                    featureDetails.featuresComponentModelList.map(
                        (details: any) => {
                            return {
                                ...details,
                                featuresMasterId: fmTitleId,
                            };
                        }
                    ),
            };
            const response = await createEnterPrisePlan(payload);
            if (response?.status === 200) {
                toast.success(response?.data?.split('~')[0]);
                handleUpdateClose(feature, true);
            } else {
                toast.error(response?.data);
            }
        } else {
            const response = await setFeatureValues(featureDetails);
            if (response?.status === 200) {
                toast.success(response?.data);
                handleUpdateClose(feature, true);
            } else {
                toast.error(response?.data);
            }
        }
    };

    const getValue = (value: any, obj: any) => {
        if (value && obj.inputId === 2 && value === '1') {
            return 'Applicable';
        }
        if (value && obj.inputId === 2 && value === '2') {
            return 'NA';
        }
        return value;
    };

    const handleFeatureDetails = (obj: any) => {
        let temp: any = featureDetails?.featuresComponentModelList;

        if (temp.length) {
            const isFind = temp.find(
                (item: any) => item?.componentId === obj.componentId
            );
            if (isFind) {
                const newTemp = temp.map((ele: any) => {
                    if (ele.componentId === obj.componentId) {
                        return { ...ele, ...obj };
                    }
                    return { ...ele };
                });
                temp = [...newTemp];
            } else {
                temp = [...temp, { ...obj }];
            }
        } else {
            temp = [{ ...obj }];
        }
        const newOtherDetails = otherDetails.map((planDetails: any) => {
            if (isEdit) {
                return {
                    ...planDetails,
                    featurChildlist: planDetails.featurChildlist.map(
                        (subPlanDetails: any) => {
                            if (
                                subPlanDetails.featuresComponentId ===
                                obj.componentId
                            ) {
                                const { value1, value2, value3 } = obj;
                                return {
                                    ...subPlanDetails,
                                    value1: getValue(value1, obj),
                                    value2: value2 || '',
                                    value3: value3 || '',
                                };
                            }
                            return { ...subPlanDetails };
                        }
                    ),
                };
            }
            return { ...planDetails };
        });
        setOtherDetails(newOtherDetails);
        setFeatureDetails({
            ...featureDetails,
            featuresComponentModelList: [...temp],
        });
    };

    useEffect(() => {
        setFeatureDetails({
            planName,
            fmTitleId,
            featuresComponentModelList: [],
        });
    }, [planName, fmTitleId]);

    return (
        <>
            <div className="row">
                {plan.map((child: FeaturChildlistType) => {
                    return (
                        <AllFeatureDetails
                            key={`${child?.paymentPlanDetailId}${child?.featuresComponentId}`}
                            {...child}
                            isEdit={isEdit}
                            handleFeatureDetails={handleFeatureDetails}
                            isShowToggleSwitch={isShowToggleSwitch}
                            planName={planName}
                            handleFeaturesAPICall={handleFeaturesAPICall}
                        />
                    );
                })}
            </div>
            {isEdit ? (
                <div className="row">
                    <div className="col-12 text-right mt-4">
                        <Button
                            className="btn-border mr-3"
                            onClick={() => {
                                handleUpdateClose(feature, false);
                                setFeatureDetails({
                                    planName,
                                    fmTitleId,
                                    featuresComponentModelList: [],
                                });
                                setOtherFeatureDetails();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type="button" onClick={handleUpdate}>
                            Update
                        </Button>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default AllFeatureDetailsCont;
