import { FC, useState, useCallback } from 'react';

import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';

import { ErrorMsg } from '../../common';
import { TickMarkImg } from '../../assets/images';

type PhoneInputs = {
    phone: string;
};

type PhoneInputsProps = {
    handleChange: (txt: string, val: string) => void;
};

const PhoneField: FC<PhoneInputsProps> = ({ handleChange }) => {
    const [isPhoneError, setIsPhoneError] = useState<string | null>(null);
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<PhoneInputs>({
        mode: 'onChange',
        delayError: 500,
    });

    const handleValidate = useCallback((phoneNumber: any) => {
        const updatedPhone = phoneNumber.replace(/\D/g, '');

        if (updatedPhone && updatedPhone.toString().length < 10) {
            setIsPhoneError('Invalid phone number');
        } else {
            setIsPhoneError(null);
        }
        return updatedPhone.toString().length;
    }, []);

    const onSubmit: SubmitHandler<PhoneInputs> = (data) => {
        const onlyNum = data.phone?.replace(/\D/g, '');
        handleChange('phone', onlyNum);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-12">
                <Controller
                    control={control}
                    name="phone"
                    rules={{
                        validate: (value) => handleValidate(value),
                    }}
                    render={({ field: { ref, value, ...rest } }) => (
                        <PatternFormat
                            className={
                                errors?.phone
                                    ? 'error-border form-control'
                                    : 'form-control'
                            }
                            format="(###) ###-####"
                            {...rest}
                        />
                    )}
                />
                <ErrorMsg>{isPhoneError}</ErrorMsg>
                <div className="">
                    <input
                        type="image"
                        src={TickMarkImg}
                        alt="add"
                        style={{ width: '15px', top: '-20px', right: '30px' }}
                        className="position-absolute"
                    />
                </div>
            </div>
        </form>
    );
};

export default PhoneField;
