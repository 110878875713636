import { FC, useCallback, useEffect, useState, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import RcPagination from '../../common/RcPagination';
import { TableBody, getIntWithTwoDecimal } from '../../common';
import { SortType, SubscriberListType } from '../../types/type';
import { getSubscriberListAPI } from '../../api';
import { UserAvatar } from '../dashboard/DashboardStyles';
import { getInitialName } from '../../common/CommonMethods';
import {
    subsciberTableHeadersOptions,
    PATH_CANDIDATE_DETAIL,
} from '../../constants';
import Loader from '../../common/Loader';
import TableHeader from './TableHeader';
import NoRecords from '../../common/NoRecords';

const PlanSubscribeList: FC<{ PlanName: string }> = ({ PlanName }) => {
    // this component displays the subscibers list
    const navigate = useNavigate();
    const [subscriberList, setSubscriberList] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState<any>({
        PlanName,
        PageNumber: 1,
        PageSize: 10,
    });
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [isAscending, setIsAscending] = useState<SortType>({
        value: false,
        name: '',
    });

    const [isLoading, setIsLoading] = useState(false);
    const isFirstTimeLoading = useRef(false);

    const handleSorting = (order: string, field: string) => {
        setIsAscending({ value: order === 'ASC', name: field });
        setSelectedFilters({
            ...selectedFilters,
            SortType: order,
            Sort: field,
        });
    };

    const getSortColor = (isVal: boolean, name: string, cName: string) => {
        return isVal && name === cName ? '#414042' : '#bcbec0';
    };

    const getNameArr = (name: string) => {
        let str = '-';
        const nameArr = name.split(' ');
        if (nameArr && nameArr.length) {
            str = getInitialName(nameArr[0], nameArr[nameArr.length - 1]);
        }
        return str;
    };

    const handlePageChange = (PageNumber: number) => {
        setSelectedFilters({ ...selectedFilters, PageNumber });
    };

    const getSubscriberList = useCallback(async () => {
        if (!isFirstTimeLoading.current) {
            setIsLoading(true);
            isFirstTimeLoading.current = true;
        }

        let tempList = [];
        let tempTotalRecord = 0;
        const response = await getSubscriberListAPI({ ...selectedFilters });
        if (response?.status === 200) {
            if (
                response?.data?.subscribersList &&
                response?.data?.subscribersList?.length
            ) {
                tempList = response?.data?.subscribersList;
            }
            if (response?.data?.subscribersCount) {
                tempTotalRecord = response?.data?.subscribersCount;
            }
        }
        setSubscriberList(tempList);
        setTotalRecords(tempTotalRecord);
        setIsLoading(false);
    }, [selectedFilters]);

    useEffect(() => {
        getSubscriberList();
    }, [getSubscriberList]);

    return isLoading ? (
        <Loader />
    ) : (
        <div className="table-responsive mt-2">
            <TableBody className="mb-3">
                <thead className="grey-header">
                    <tr>
                        {subsciberTableHeadersOptions.map((header) => (
                            <TableHeader
                                key={header.title}
                                label={header.title}
                                keyVal="demo"
                                noSort={['']}
                                accessor={header?.accessor}
                                upColor={getSortColor(
                                    isAscending?.value,
                                    isAscending?.name,
                                    header?.accessor
                                )}
                                downColor={getSortColor(
                                    !isAscending?.value,
                                    isAscending?.name,
                                    header?.accessor
                                )}
                                handleSorting={handleSorting}
                                isAscending={isAscending}
                                cssStyle={{}}
                            />
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {subscriberList?.length ? (
                        subscriberList.map(
                            ({
                                id,
                                sName,
                                status,
                                amount,
                                frequency,
                                raceType,
                                location,
                                lastBilling,
                                nextBilling,
                            }: SubscriberListType) => (
                                <tr
                                    key={id}
                                    className="cursor"
                                    role="presentation"
                                    onClick={() =>
                                        navigate(
                                            PATH_CANDIDATE_DETAIL.replace(
                                                ':id',
                                                `${id}`
                                            )
                                        )
                                    }
                                >
                                    <td>
                                        <UserAvatar>
                                            <div className="user-profile">
                                                {getNameArr(sName)}
                                            </div>
                                            <div className="user-details ml-2 pl-1 capitalize">
                                                {sName || '-'}
                                                <div
                                                    className={`capsule-status mt-1 ${
                                                        status &&
                                                        status.toLowerCase()
                                                    }`}
                                                >
                                                    {status || '-'}
                                                </div>
                                            </div>
                                        </UserAvatar>
                                    </td>
                                    <td>
                                        {frequency} -{' '}
                                        <strong>
                                            {getIntWithTwoDecimal(amount)}
                                        </strong>{' '}
                                        / mo
                                    </td>

                                    <td>{raceType || '-'}</td>
                                    <td>{location || '-'}</td>
                                    <td>
                                        {dayjs(lastBilling).format(
                                            'MMM DD, YYYY'
                                        ) || '-'}
                                    </td>
                                    <td>
                                        {dayjs(nextBilling).format(
                                            'MMM DD, YYYY'
                                        ) || '-'}
                                    </td>
                                </tr>
                            )
                        )
                    ) : (
                        <NoRecords />
                    )}
                </tbody>
            </TableBody>
            <RcPagination
                totalRecords={totalRecords}
                currentPage={selectedFilters?.PageNumber}
                recordsPerPage={selectedFilters?.PageSize}
                handlePageChange={handlePageChange}
            />
        </div>
    );
};

export default PlanSubscribeList;
