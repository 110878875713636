export const tableHeader = [
    {
        label: 'First Name',
        value: 'firstName',
        isChecked: true,
    },
    {
        label: 'Last Name',
        value: 'lastName',
        isChecked: true,
    },
    {
        label: 'Address',
        value: 'addressLine1',
        isChecked: true,
    },

    {
        label: 'Email',
        value: 'primaryEmail',
        isChecked: false,
    },

    {
        label: 'Phone',
        value: 'phoneNumber',
        isChecked: false,
    },

    {
        label: 'Ballot Status',
        value: 'ballotStatus',
        isChecked: true,
    },
    {
        label: 'Affiliation',
        value: 'affiliation',
        isChecked: true,
    },
    {
        label: 'Voter Status',
        value: 'voterStatus',
        isChecked: true,
    },
    {
        label: 'Precinct',
        value: 'precinct',
        isChecked: false,
    },
    { label: 'County', value: 'county', isChecked: false },
    {
        label: 'State House District',
        value: 'stateHouseDistrict',
        isChecked: false,
    },
    {
        label: 'State Senate District',
        value: 'stateSenateDistrict',
        isChecked: false,
    },
    {
        label: 'Local School Board',
        value: 'localSchoolBoard',
        isChecked: false,
    },
    {
        label: 'State School Board',
        value: 'stateSchoolBoard',
        isChecked: false,
    },
    {
        label: 'Congressional House District',
        value: 'congressionalHouseDistrict',
        isChecked: false,
    },

    {
        label: 'Vote Method',
        value: 'votemethod',
        isChecked: false,
    },
    {
        label: 'Year of Birth',
        value: 'yearofbirth',
        isChecked: false,
    },
    {
        label: 'Supporter',
        value: 'supporter',
        isChecked: false,
    },
];

export const viewAs = [
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
];

export const SYSTEM_COLUMNS = [
    'First Name',
    'Middle Name',
    'Last Name',
    'Email',
    'Phone',
    'Address1',
    'Address2',
    'City',
    'State',
    'Zip',
    'Mailing Address1',
    'Mailing Address2',
    'Mailing City',
    'Mailing State',
    'Mailing Zip',
    'Employer',
    'Occupation',
    'Voter ID',
    'Registration Date',
    'Affiliation',
    'Voter Status',
    'Precinct',
    'County',
    'State House District',
    'State Senate District',
    'State School Board',
    'Local School Board',
    'Congressional House District',
    'Tags',
    'Note',
];

export const NAME_PATTERN_LABELS = [
    {
        value: 'first',
        label: 'First',
    },
    {
        value: 'first,middle',
        label: 'First + Middle',
    },
    {
        value: 'first,last',
        label: 'First + Last',
    },
    {
        value: 'middle,first',
        label: 'Middle + First',
    },
    {
        value: 'last,first',
        label: 'Last + First',
    },
    {
        value: 'first,middle,last',
        label: 'First + Middle + Last',
    },
    {
        value: 'first,last,middle',
        label: 'First + Last + Middle',
    },

    {
        value: 'middle,first,last',
        label: 'Middle + First + Last',
    },
    {
        value: 'last,first,middle',
        label: 'Last + First + Middle',
    },

    {
        value: 'middle,last,first',
        label: 'Middle + Last + First',
    },
    {
        value: 'last,middle,first',
        label: 'Last + Middle + First',
    },
];

export const SHOW_MORE_INITIAL = {
    'First Name': {
        numberOfFieldsToShow: 13,
    },
    'Middle Name': {
        numberOfFieldsToShow: 13,
    },
    'Last Name': {
        numberOfFieldsToShow: 13,
    },
    Email: {
        numberOfFieldsToShow: 13,
    },
    Phone: {
        numberOfFieldsToShow: 13,
    },
    Address1: {
        numberOfFieldsToShow: 13,
    },
    Address2: {
        numberOfFieldsToShow: 13,
    },
    City: {
        numberOfFieldsToShow: 13,
    },
    State: {
        numberOfFieldsToShow: 13,
    },
    Zip: {
        numberOfFieldsToShow: 13,
    },
    'Mailing Address1': {
        numberOfFieldsToShow: 13,
    },
    'Mailing Address2': {
        numberOfFieldsToShow: 13,
    },
    'Mailing City': {
        numberOfFieldsToShow: 13,
    },
    'Mailing State': {
        numberOfFieldsToShow: 13,
    },
    'Mailing Zip': {
        numberOfFieldsToShow: 13,
    },
    Employer: {
        numberOfFieldsToShow: 13,
    },
    Occupation: {
        numberOfFieldsToShow: 13,
    },
    'Voter ID': {
        numberOfFieldsToShow: 13,
    },
    'Registration Date': {
        numberOfFieldsToShow: 13,
    },
    Affiliation: {
        numberOfFieldsToShow: 13,
    },
    'Voter Status': {
        numberOfFieldsToShow: 13,
    },
    Precinct: {
        numberOfFieldsToShow: 13,
    },
    County: {
        numberOfFieldsToShow: 13,
    },
    'State House District': {
        numberOfFieldsToShow: 13,
    },
    'State Senate District': {
        numberOfFieldsToShow: 13,
    },
    'State School Board': {
        numberOfFieldsToShow: 13,
    },
    'Local School Board': {
        numberOfFieldsToShow: 13,
    },

    'Congressional House District': {
        numberOfFieldsToShow: 13,
    },
    Tags: {
        numberOfFieldsToShow: 13,
    },
    Note: {
        numberOfFieldsToShow: 13,
    },
};

export const firstRowArr = ['primaryEmail', 'primaryPhone', 'primaryAddress'];
export const secondRowArr = ['employer', 'occupation', 'affiliation'];
export const voterInfoArr = [
    'voterStatus',
    'registrationDate',
    'voterId',
    'ballotStatus',
    'preCinct',
    'county',
    'stateHouseDistrict',
    'stateSenateDistrict',
    'stateSchoolBoard',
    'congressionalHouseDistrict',
    'localSchoolBoard',
];

export const genaralContactDetails = [
    {
        id: 1,
        label: 'Email',
        accessor: 'primaryEmail',
    },
    {
        id: 2,
        label: 'Phone',
        accessor: 'primaryPhone',
    },
    {
        id: 3,
        label: 'Address',
        accessor: 'primaryAddress',
    },
    {
        id: 4,
        label: 'Employer',
        accessor: 'employer',
    },
    {
        id: 5,
        label: 'Occupation',
        accessor: 'occupation',
    },
    {
        id: 6,
        label: 'Affiliation',
        accessor: 'affiliation',
    },
    {
        id: 8,
        label: 'Voter Status',
        accessor: 'voterStatus',
    },

    {
        id: 9,
        label: 'Registration Date',
        accessor: 'registrationDate',
    },
    {
        id: 10,
        label: 'Voter ID',
        accessor: 'voterId',
    },
    {
        id: 11,
        label: 'Ballot Status',
        accessor: 'ballotStatus',
    },
    {
        id: 12,
        label: 'Precinct',
        accessor: 'preCinct',
    },
    {
        id: 13,
        label: 'County',
        accessor: 'county',
    },
    {
        id: 14,
        label: 'State House District',
        accessor: 'stateHouseDistrict',
    },
    {
        id: 15,
        label: 'State Senate District',
        accessor: 'stateSenateDistrict',
    },
    {
        id: 16,
        label: 'State School Board',
        accessor: 'stateSchoolBoard',
    },
    {
        id: 17,
        label: 'Congressional House District',
        accessor: 'congressionalHouseDistrict',
    },

    {
        id: 19,
        label: 'Local School Board',
        accessor: 'localSchoolBoard',
    },
];

export const AddressFieldConstant = [
    {
        id: 1,
        label: 'Address',
        accessor: 'address',
        value: '',
        nameOfClass: 'col-lg-6 py-2 mb-2',
        isDisabled: true,
    },
    {
        id: 2,
        label: 'Suit/Apt/Unit',
        accessor: 'suit',
        value: '',
        nameOfClass: 'col-lg-6 py-2 mb-2',
        isDisabled: false,
    },
    {
        id: 3,
        label: 'City',
        accessor: 'city',
        value: '',
        nameOfClass: 'col-lg-4 py-2 mb-2',
        isDisabled: true,
    },
    {
        id: 4,
        label: 'State',
        accessor: 'state',
        value: '',
        nameOfClass: 'col-lg-4 py-2 mb-2',
        isDisabled: true,
    },
    {
        id: 5,
        label: 'Zip',
        accessor: 'zip',
        value: '',
        nameOfClass: 'col-lg-4 py-2 mb-2',
        isDisabled: true,
    },
    {
        id: 6,
        label: '',
        accessor: 'gpdcoordinates',
        value: '',
        nameOfClass: '',
        isDisabled: true,
    },
];

export const voterStatusForContact = [
    { value: 1, label: 'Active' },
    { value: 2, label: 'Inactive' },
    { value: 3, label: 'Unknown' },
];

export const ballotStatusOptions = [
    { value: 1, label: 'Voted' },
    { value: 2, label: 'Not Voted' },
];

export const contactFiltersConst = [
    {
        name: 'Affiliation',
        choices: 'affiliations',
        key: 'affiliation',
    },
    {
        name: 'Ballot Status',
        choices: 'ballotStatuses',
        key: 'bollotStatus',
    },
    {
        name: 'Congressional House District',
        choices: 'congressionalHouseDistricts',
        key: 'congressionalHouseDistrict',
    },

    {
        name: 'County',
        choices: 'counties',
        key: 'county',
    },
    {
        name: 'Local School Board',
        choices: 'localSchoolBoards',
        key: 'localSchoolBoard',
    },
    {
        name: 'Precinct',
        choices: 'precincts',
        key: 'precinct',
    },
    {
        name: 'State House District',
        choices: 'stateHouseDistricts',
        key: 'stateHouseDistrict',
    },
    {
        name: 'State School Board',
        choices: 'stateSchoolBoards',
        key: 'stateSchoolBoard',
    },
    {
        name: 'State Senate District',
        choices: 'stateSenateDistricts',
        key: 'stateSenateDistrict',
    },
    {
        name: 'Vote Method',
        choices: 'voteMethods',
        key: 'voteMethod',
    },
    {
        name: 'Voter Status',
        choices: 'voterStatusLists',
        key: 'voterStatus',
    },
    {
        name: 'City',
        choices: 'city',
        key: 'city',
    },
    {
        name: 'Area',
        choices: 'areas',
        key: 'areas',
    },
];
