export const dashboardTableHeadersOptions = [
    { title: 'Name' },
    { title: 'Plan', width: '145px' },
    { title: 'Subscription' },
    { title: 'Race type', width: '150px' },
    { title: 'City' },
    { title: 'State', width: '150px' },
    { title: 'Contacts', width: '160px' },
];

export const dashboardFilters = [
    {
        name: 'Plan',
        choices: 'superPlanListDropDownModels',
        type: 'dropdown',
        key: 'plan',
    },
    {
        name: 'Status',
        choices: 'superStatusListDropDownModels',
        type: 'dropdown',
        key: 'status',
    },
    {
        name: 'Race type',
        choices: 'superRaceTypeListDropDownModels',
        type: 'dropdown',
        key: 'raceType',
    },
    {
        name: 'City',
        choices: 'superCityListDropDownModels',
        type: 'dropdown',
        key: 'city',
    },
    {
        name: 'State',
        choices: 'superStateListDropDownModels',
        type: 'dropdown',
        key: 'state',
    },
    {
        name: 'Contacts',
        choices: 'contactAndDonationRaisedLimitModel',
        type: 'slider',
        key: 'contacts',
    },
    {
        name: 'Donation raised',
        choices: 'contactAndDonationRaisedLimitModel',
        type: 'slider',
        key: 'donationraised',
    },
];

export const subscriptionHeaderOptions = [
    { label: 'Plan/Add-ons', width: '131px' },
    { label: 'Date', width: '120px' },
    { label: 'Amount', width: '120px' },
    { label: 'Receipt', width: '120px' },
];

export const resetMsg = `Please click the "Send Reset Link" button below to send the password reset link to the user's registered email address. Alternatively, you can choose to send the link to a different email ID.`;

export const terminateMsg = `Ensure you have confirmed the need to terminate the user's subscription and have exhausted all alternative solutions. If you are unsure, we recommend contacting the user to address any concerns or issues before taking this step.`;

export const graceOptions = (periodType: string) => [
    { name: 'Transaction date -', key: 'startDate' },
    { name: `${periodType} period start date -`, key: 'dates' },
    { name: `${periodType} period end date -`, key: 'endDate' },
    { name: `Duration of the ${periodType} period (in days) -`, key: 'grace' },
];

export const candidateBasicData = [
    { name: 'Contributions - ', key: 'contributions', fee: '$', property: '' },
    { name: 'Race Type - ', key: 'racetype', fee: '', property: 'red-type' },
    {
        name: 'Contacts - ',
        key: 'totalContacts',
        fee: '',
        property: 'purple-type',
    },
    { name: 'Donors - ', key: 'donors', fee: '', property: 'donor-type' },
];
