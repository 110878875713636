// Auth
export const PATH_ROOT = '/';
export const PATH_LOGIN = '/login';
export const PATH_FORGOT_PASSWORD = '/forgot-password';
export const PATH_RESET_PASSWORD = '/resetpassword';

// Contacts
export const PATH_CONTACTS = '/contacts';
export const PATH_UPLOAD_CONTACTS = '/contacts/upload';

// Dashboard
export const PATH_DASHBOARD = '/dashboard';
export const PATH_CANDIDATE_DETAIL = `${PATH_DASHBOARD}/:id`;

// Contact Change Log
export const PATH_CONTACT_CHANGE_LOG = '/contact-change-log';

// Plan Management
export const PATH_PLAN_MANAGEMENT = '/plan-management/';
export const PATH_PLAN_MANAGEMENT_DETAILS = `${PATH_PLAN_MANAGEMENT}:id/`;
export const PATH_CREATE_PLAN_MANAGEMENT = `${PATH_PLAN_MANAGEMENT}create`;
export const PATH_EDIT_PLAN_MANAGEMENT = `${PATH_PLAN_MANAGEMENT}edit`;
export const PATH_CLONE_PLAN = `${PATH_PLAN_MANAGEMENT}clone`;
