import styled from 'styled-components';

export const ModalContainer = styled.div`
    h1 {
        font-size: calc(1.375rem + 1.5vw);
    }
    @media (min-width: 1200px) {
        h1 {
            font-size: 2.5rem;
        }
    }
`;

export const ModalOverlay = styled.div`
    z-index: 99;
    .modal-heading {
        color: ${(props: any) => props.theme.colors.black};
        font-size: ${(props: any) => props.theme.fontSizes.larger};
        font-family: ${(props: any) => props.theme.fonts.semiBoldFontFamily};

        .modal-title {
            font-size: ${(props: any) => props.theme.fontSizes.highlightText};
        }
        .cross-icon {
            position: absolute;
            top: 0;
            right: 7px;
            width: 30px;
            cursor: pointer;
        }

        .check-icon {
            position: absolute;
            top: 24px;
            right: 21px;
            width: 35px;
            cursor: pointer;
            border: none;
            outline: none;
        }
    }

    .modal-body {
        max-height: 600px;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .modal-body-container {
        min-height: 450px;
    }

    .grace-modal {
        font-family: ${(props: any) => props.theme.fonts.FontFamily};
        color: ${(props: any) => props.theme.colors.grey85};
        span {
            font-family: ${(props: any) => props.theme.fonts.interMedium};
            font-size: ${(props: any) => props.theme.fontSizes.medium};
            color: ${(props: any) => props.theme.colors.grey85};
            padding-left: 10px;
        }
    }
`;

export const customStyle = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.6)',
        zIndex: 99,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        maxWidth: '463px ',
        minHeight: '170px',
        width: '90% ',
        overlay: ' {zIndex: 1000}',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
