import { styled } from 'styled-components';

const PlanManagementStyle = styled.div`
    .toggle-btn {
        display: flex;
        justify-content: end;
        align-items: center;
        @media only screen and (max-width: 768px) {
            justify-content: start;
            margin-top: 10px;
        }

        @media only screen and (max-width: 339px) {
            display: block;

            button {
                margin-top: 10px;
            }
        }
    }

    .toggle-switch {
        background-color: ${(props: any) => props.theme.colors.appleWhite};
        box-shadow: 0px 1px 6px
            ${(props: any) => props.theme.shadows.commonShadow};
        border-radius: 18px;
        max-width: 243px;
        width: fit-content;

        .data {
            list-style-type: none;
            padding: 0px;
            margin: 0px;

            li {
                padding: 7px 14px;
                font-size: 14px;
                display: inline-block;
                cursor: pointer;

                &.active {
                    background-color: ${(props: any) =>
                        props.theme.colors.activeBg};
                    border: 1px solid
                        ${(props: any) => props.theme.colors.activeBg};
                    border-radius: 18px;
                    color: ${(props: any) => props.theme.colors.white};
                }
            }
        }
    }

    table {
        table-layout: fixed;
        border-collapse: collapse;
        border-spacing: 0;

        @media only screen and (max-width: 726px) {
            width: 100%;
        }

        th {
            width: 130px;
            text-align: left;
            padding: 10px 0px;

            &.dash-line-width {
                width: 50px;
            }
        }

        td {
            .date {
                color: ${(props: any) => props.theme.colors.grey12};
                font-size: ${(props: any) => props.theme.fontSizes.medium};
                font-family: ${(props: any) => props.theme.fonts.interMedium};
            }

            .dash-line {
                height: 1px;
                width: 25px;
                background-color: ${(props: any) => props.theme.colors.grey13};
            }

            .old-new-amount {
                font-size: ${(props: any) => props.theme.fontSizes.medium};
                font-family: ${(props: any) => props.theme.fonts.interregular};
                color: ${(props: any) => props.theme.colors.grey9};
                padding: 4px 0px;
            }
        }
    }
    .drop-down-table {
        padding: 10px 10px;
    }

    .title-input {
        max-width: 350px;

        @media only screen and (max-width: 689px) {
            max-width: 100%;
        }
    }

    .delete-img {
        padding-top: 15px;
    }

    .activity-subtitle {
        font-size: ${(props: any) => props.theme.fontSizes.medium};
        font-family: ${(props: any) => props.theme.fonts.semiBoldFontFamily};
    }

    .bullet-text {
        display: flex;
        align-items: center;
        font-size: ${(props: any) => props.theme.fontSizes.larger};
        font-family: ${(props: any) => props.theme.fonts.semiBoldFontFamily};

        &.plan-text {
            font-size: ${(props: any) => props.theme.fontSizes.smallText};
            color: ${(props: any) => props.theme.colors.darkGrey};
        }

        .bullet-points {
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: ${(props: any) => props.theme.colors.ButtonColor};
            margin-right: 8px;

            &.orange-dot {
                background-color: ${(props: any) =>
                    props.theme.colors.dropdownActiveBg};
            }
            &.teal-dot {
                background-color: ${(props: any) =>
                    props.theme.colors.primaryColor};
            }
            &.grey-dot {
                background-color: ${(props: any) => props.theme.colors.grey4};
            }
        }

        .dollar-amt {
            font-size: ${(props: any) => props.theme.fontSizes.medium};
            font-family: ${(props: any) => props.theme.fonts.intersemibold};
            padding: 0px 2px;
        }
    }

    .amt-title {
        font-family: ${(props: any) => props.theme.fonts.interregular};
        font-size: ${(props: any) => props.theme.fontSizes.medium};
    }

    .pricing-amt {
        li {
            list-style: none;
            display: inline-block;
            padding-right: 15px;
            max-width: 200px;
            @media only screen and (max-width: 476px) {
                display: block;
                padding: 8px 0px;
                width: 100%;
                max-width: 100%;
            }

            &.amount-per-date {
                max-width: 137px;
                width: 100%;
            }
        }
    }
`;

export default PlanManagementStyle;
