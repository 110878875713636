import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    planDetails: [],
};

export const planmanagementSlice = createSlice({
    name: 'planmanagement',
    initialState,
    reducers: {
        setPlanDetailsInStore: (state, action) => {
            return {
                ...state,
                planDetails: action.payload,
            };
        },

        removePlanDetailsFromStore: (state) => {
            return {
                ...state,
                planDetails: [],
            };
        },
    },
});

export const { setPlanDetailsInStore, removePlanDetailsFromStore } =
    planmanagementSlice.actions;

export default planmanagementSlice.reducer;
