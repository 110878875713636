import { ChangeEvent, FC } from 'react';

import Select, { components } from 'react-select';

import CheckBox from './CheckBox';
import { TableHeaderType } from '../types/type';
import { useAppDispatch, useAppSelector } from '../store/hook';
import { setColumns } from '../features/contactsSlice';
import DropDownStyle from './DropDownStyle';

const { Option, ValueContainer } = components;

interface DisBleMultiSelect {
    isDisabled: boolean;
}

const MultiSelect: FC<DisBleMultiSelect> = ({ isDisabled }) => {
    const { columns } = useAppSelector((state) => state.contacts);
    const columnsWithoutCheckbox = columns.filter(
        (item) => item.value !== 'checkbox'
    );

    const dispatch = useAppDispatch();

    const handlecheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        const updatedcolumns: TableHeaderType[] = columns;
        const result: TableHeaderType[] = updatedcolumns.map((item) => {
            if (item.value! === value) {
                return { ...item, isChecked: checked };
            }
            return item;
        });

        dispatch(setColumns(result));
    };

    const selectOption = (item: any) => {
        return (
            <CheckBox>
                <Option {...item}>
                    <label
                        htmlFor={item.data.label}
                        className="check-container"
                    >
                        <input
                            type="checkbox"
                            id={item.data.label}
                            name={item.data.label}
                            value={item.data.value}
                            checked={item.data.isChecked}
                            onChange={handlecheckboxChange}
                            disabled={
                                item.data.value === 'firstName' ||
                                item.data.value === 'lastName'
                            }
                        />
                        <span
                            className={`checkmark ${
                                item.data.value === 'firstName' ||
                                item.data.value === 'lastName'
                                    ? 'disabled'
                                    : ''
                            }`}
                        />
                        <span
                            className={
                                item.data.label === 'First Name' ||
                                item.data.label === 'Last Name'
                                    ? 'disabled-text'
                                    : ''
                            }
                        >
                            {item.data.label}{' '}
                        </span>
                    </label>
                </Option>
            </CheckBox>
        );
    };

    const valueContainer = ({ getValue, ...props }: any) => {
        return (
            <ValueContainer {...props}>
                <span>
                    Columns&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </ValueContainer>
        );
    };

    const getAllComponents = () => {
        return {
            Option: selectOption,
            ValueContainer: valueContainer,
        };
    };

    return (
        <DropDownStyle>
            <Select
                classNamePrefix="react-select"
                isMulti
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: '#fff',
                        primary: '#fff',
                        primary50: '#fff',
                    },
                })}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isClearable={false}
                isSearchable={false}
                onChange={() => null}
                components={getAllComponents()}
                placeholder="Columns"
                options={columnsWithoutCheckbox}
                isDisabled={isDisabled}
                menuPlacement="auto"
            />
        </DropDownStyle>
    );
};

export default MultiSelect;
