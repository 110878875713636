import { FC, useState, useEffect, useCallback } from 'react';

import { toast } from 'react-toastify';

import MultiSelectDropdown from '../../common/MultiSelectDropdown';
import FilterStyle from '../../Theme/style/FilterStyle';
import { Button, DropDownStyle } from '../../common';
import { RemoveCross } from '../../assets/images';
import { getFiltersAPI, getZipAPI } from '../../api';
import { contactFiltersConst } from '../../constants';
import { ContactFilterConstType } from '../../types/type';
import Loader from '../../common/Loader';

interface ContactFilterProps {
    showFilterUI: boolean;
    selectedMultiFilters: any;
    setContactFilterOpt: (obj: any) => void;
    setShowFilterUI: (val: boolean) => void;
    setSelectedMultiFilters: (val: any) => void;
    handleClearAll: () => void;
}

const ContactFilter: FC<ContactFilterProps> = ({
    showFilterUI,
    selectedMultiFilters,
    setContactFilterOpt,
    setShowFilterUI,
    setSelectedMultiFilters,
    handleClearAll,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isZipLoading, setIsZipLoading] = useState<boolean>(false);
    const [filterOptions, setFilterOptions] = useState<any>({});

    const getAllFilters = useCallback(async () => {
        setIsLoading(true);
        const response = await getFiltersAPI();
        if (response?.status === 200) {
            const data = contactFiltersConst?.map(
                (item: ContactFilterConstType) => {
                    const up = response?.data?.[item?.choices]?.map(
                        (ele: any) => {
                            return ele && { value: ele?.id, label: ele?.name };
                        }
                    );
                    return { [item.choices]: up };
                }
            );

            const obj = Object.assign({}, ...data);
            setFilterOptions(obj);
        } else {
            toast.error(response?.data);
        }
        setIsLoading(false);
    }, []);

    const applyFilters = () => {
        const temp = { ...selectedMultiFilters };
        Object.keys(temp).forEach((key) => {
            if (temp[key]?.length === 0) {
                delete temp[key];
            }
        });
        const updatedArray: any = [];
        contactFiltersConst.map((op: any) => {
            if (temp?.[op.key]) {
                updatedArray.push({
                    [op.key]: temp[op.key]
                        ?.map((item: { label: string }) => item.label)
                        .join(','),
                });
            }
            return null;
        });
        const convertedObject = updatedArray.reduce(
            (result: any, currentObject: any) => {
                const key = Object.keys(currentObject)[0];
                return { ...result, [key]: currentObject[key] };
            },
            {}
        );
        setContactFilterOpt(convertedObject);
    };

    const getZipValues = async (val: string) => {
        setIsZipLoading(true);
        const response = await getZipAPI(val);
        if (response?.status === 200) {
            const up = response?.data.map((ele: any) => {
                return { value: ele.id, label: ele.name };
            });

            const updatedFilters = { ...filterOptions, areas: up };
            setFilterOptions(updatedFilters);
            setIsZipLoading(false);
        } else {
            toast.error(response?.data);
            setIsZipLoading(false);
        }
    };

    const handleMultiSelectChange = (value: any, filterName: string) => {
        const cityNameStr = value?.map((item: any) => item?.label).join(',');
        if (filterName === 'city') {
            getZipValues(cityNameStr);
        }
    };

    useEffect(() => {
        getAllFilters();
    }, [getAllFilters]);

    return (
        <div>
            {showFilterUI && (
                <div className="position-relative">
                    <FilterStyle>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <>
                                <div className="d-flex justify-content-end align-content-end pt-2">
                                    <img
                                        role="presentation"
                                        src={RemoveCross}
                                        alt="times"
                                        className="cross-icon-filter"
                                        onClick={() => setShowFilterUI(false)}
                                    />
                                </div>

                                <div className="row form-style">
                                    {contactFiltersConst.map((item: any) => (
                                        <div
                                            className="col-md-3 py-2"
                                            key={item?.name}
                                        >
                                            <p className="title">
                                                {item?.name}{' '}
                                            </p>
                                            <DropDownStyle>
                                                <MultiSelectDropdown
                                                    options={
                                                        filterOptions?.[
                                                            item?.choices
                                                        ]
                                                    }
                                                    label={item?.name}
                                                    value={item?.key}
                                                    multiSelectedValues={
                                                        selectedMultiFilters
                                                    }
                                                    setMultiSelectedValues={
                                                        setSelectedMultiFilters
                                                    }
                                                    handleMultiSelectChange={
                                                        handleMultiSelectChange
                                                    }
                                                    isDisabled={isZipLoading}
                                                />
                                            </DropDownStyle>
                                        </div>
                                    ))}
                                </div>

                                <div className="row mb-3">
                                    <div className="col-12 text-right pt-3">
                                        <Button
                                            className="mr-2 btn-border"
                                            onClick={handleClearAll}
                                        >
                                            Clear all
                                        </Button>
                                        <Button onClick={() => applyFilters()}>
                                            Apply
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                    </FilterStyle>
                </div>
            )}
        </div>
    );
};

export default ContactFilter;
