import { useState } from 'react';

function CustomInput({
    createData,
    feature,
    subFeature,
    subFeatureObj,
    setCreateData,
    featureId,
    componentValue = '',
}: any) {
    const getInititalInputValue = () => {
        if (componentValue) {
            return componentValue;
        }
        switch (subFeature) {
            case 'Additional Contacts':
                return '$ / contacts';
            case 'Donation Processing':
                return ' % + $ /donation';
            default:
                return '';
        }
    };
    const [inputValue, setInputValue] = useState(getInititalInputValue());

    const handleInputChange = (e: any) => {
        const newValue = e.target.value;

        setInputValue(newValue);
        setCreateData({
            ...createData,
            [feature]: {
                ...createData?.[feature],
                [subFeature]: {
                    value: newValue,
                    inputId: subFeatureObj.inputTypeId,
                    componentId: subFeatureObj.featureId,
                    featuresMasterId: featureId,
                },
            },
        });
    };

    return (
        <input
            type="text"
            className="form-control"
            value={
                componentValue
                    ? createData?.[feature]?.[subFeature]?.value
                    : inputValue
            }
            onChange={handleInputChange}
        />
    );
}

export default CustomInput;
