import { useEffect, useRef, useState } from 'react';

import Select from 'react-select';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import PageNav from '../../common/NavLink';
import DropDownSelect from '../../Theme/style/MapExcelFieldStyle';
import Loader from '../../common/Loader';
import CommonModal from '../../common/CommonModal';
import DuplicateContacts from './DuplicateContacts';
import { Button, ToggleSwitch } from '../../common';
import { mergeAllContacts, uploadExcelFileData } from '../../api';
import {
    ArrowBtn,
    ArrowRight,
    InfoImg,
    RemoveCross,
    RightArrow,
    TickMarkImg,
} from '../../assets/images';
import {
    NAME_PATTERN_LABELS,
    PATH_CONTACTS,
    SHOW_MORE_INITIAL,
    SYSTEM_COLUMNS,
    importTextConstant,
} from '../../constants';

const modalStyle = { maxWidth: '500px' };

const MapExcelFields = ({
    setSelectedFile,
    fileHeaders,
    fileRowData,
    fieldLoding,
}: any) => {
    const navigate = useNavigate();

    const [showConfirmationalModal, setShowConfirmationalModal] =
        useState(false);
    const [systemColumns, setSystemColumns] = useState(SYSTEM_COLUMNS);
    const [selectedField, setSelectedField] = useState<any>([]);
    const [namePatternValue, setnamePatternValue] = useState<any>({
        value: '',
        label: '',
    });
    const [showMore, setShowMore] = useState<any>(SHOW_MORE_INITIAL);
    const [voterHistoryStartingColumn, setVoterHistoryStartingColumn] =
        useState<any>({
            value: '',
            label: '',
        });
    const [voterHistoryEndingColumn, setVoterHistoryEndingColumn] =
        useState<any>({
            value: '',
            label: '',
        });
    const [isVoterHistory, setIsVoterHistory] = useState(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [totalContactCount, setTotalContactCount] = useState<any>({
        imported: 0,
        duplicate: 0,
        uploadId: null,
    });
    const [listData, setListData] = useState<any>({
        systemList: [],
        duplicateList: [],
    });
    const [viewContact, setViewContact] = useState(false);
    const [isLoading, setIsLoading] = useState({
        mergeButton: false,
        yesButton: false,
    });
    const pageBottom = useRef<any>(null);

    const handleSelection = (rowIndex: any, value: any, column: any) => {
        let tempSelectedField: any = [...selectedField];
        if (!['Tags', 'Note'].includes(column)) {
            if (selectedField.length) {
                tempSelectedField = selectedField.filter(
                    (field: any) => field.rowIndex !== rowIndex
                );
            }
        }
        const isSelected = selectedField.some(
            (item: any) => item.rowIndex === rowIndex && item.value === value
        );
        if (isSelected) {
            // Deselect the value
            setSelectedField(
                selectedField.filter(
                    (item: any) =>
                        !(item.rowIndex === rowIndex && item.value === value)
                )
            );
        } else {
            // Select the value
            setSelectedField([
                ...tempSelectedField,
                { rowIndex, value, column },
            ]);
        }
    };

    const renderExcelFields = (rowIndex: any, column: any) => {
        const rowValues = fileHeaders
            .filter((header: any) => header.split('_col')[0])
            .map((value: any) => {
                const isSelected = selectedField.some(
                    (item: any) =>
                        item.rowIndex === rowIndex && item.value === value
                );
                if (isSelected) {
                    return (
                        <p
                            key={value}
                            className="field-name-pills selected"
                            onClick={() =>
                                handleSelection(rowIndex, value, column)
                            }
                            role="presentation"
                        >
                            <img src={TickMarkImg} alt="" className="mr-1" />
                            {value.split('_col')[0]}
                        </p>
                    );
                }
                const isValueSelectedInOtherRow = selectedField.some(
                    (item: any) => item.value === value
                );

                if (!isValueSelectedInOtherRow) {
                    return (
                        <p
                            className="field-name-pills"
                            key={value}
                            onClick={() =>
                                handleSelection(rowIndex, value, column)
                            }
                            role="presentation"
                        >
                            {value.split('_col')[0]}
                        </p>
                    );
                }

                return null;
            })
            .filter((value: any) => value)
            .slice(0, showMore[column].numberOfFieldsToShow);

        return (
            <div>
                {rowValues}
                {fileHeaders.filter((header: any) => header.split('_col')[0])
                    .length > showMore[column].numberOfFieldsToShow && (
                    <>
                        <span className="show-dots">...</span>
                        <p
                            className="field-name-pills filed-name-pills-show"
                            onClick={() => {
                                setShowMore({
                                    ...showMore,
                                    [column]: {
                                        numberOfFieldsToShow:
                                            fileHeaders.filter(
                                                (header: any) =>
                                                    header.split('_col')[0]
                                            ).length,
                                    },
                                });
                            }}
                            role="presentation"
                        >
                            Show more
                        </p>
                    </>
                )}
                {showMore[column].numberOfFieldsToShow > 13 && (
                    <p
                        className="field-name-pills filed-name-pills-show"
                        onClick={() => {
                            setShowMore({
                                ...showMore,
                                [column]: {
                                    numberOfFieldsToShow: 13,
                                },
                            });
                        }}
                        role="presentation"
                    >
                        Show less
                    </p>
                )}
            </div>
        );
    };

    const createPayloadFields = (
        row: any,
        valueFields: any,
        fieldType: any = ''
    ) => {
        let str: any = '';
        if (valueFields && valueFields.length) {
            valueFields.forEach((field: any) => {
                if (!row?.[field]) {
                    str = null;
                } else if (str && !fieldType) {
                    str = `${str}, ${row[field]}`;
                } else if (fieldType === 'date' && row?.[field]) {
                    const dateObj = dayjs(row[field]);
                    if (dateObj.isValid()) {
                        str = new Date(
                            dateObj.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                        ).toISOString();
                    } else {
                        str = null;
                    }
                } else {
                    str += row[field];
                }
            });
        } else {
            str = null;
        }
        return str;
    };

    const mapVotingHistory = (row: any) => {
        const startingColumnNumber = parseInt(
            voterHistoryStartingColumn?.value.split('_col')[1],
            10
        );
        const endingColumnNumber = parseInt(
            voterHistoryEndingColumn?.value.split('_col')[1],
            10
        );
        const obj = Object.keys(row).filter(
            (value) =>
                startingColumnNumber <= parseInt(value.split('_col')[1], 10) &&
                endingColumnNumber >= parseInt(value.split('_col')[1], 10)
        );
        const pairs = obj.reduce((result: any, item, index) => {
            if (index % 2 === 0 && index + 1 < obj.length) {
                result.push([item, obj[index + 1]]);
            }
            return result;
        }, []);

        const finalArray = pairs.map((pair: any) => {
            return {
                votingDate: dayjs(row[pair[0]], [
                    'DD-MM-YYYY',
                    'MM-DD-YYYY',
                    'YYYY-MM-DD',
                    'MM/DD/YYYY',
                    'MM/DD/YY',
                ]).isValid()
                    ? new Date(
                          dayjs(row[pair[0]], [
                              'DD-MM-YYYY',
                              'MM-DD-YYYY',
                              'YYYY-MM-DD',
                              'MM/DD/YYYY',
                              'MM/DD/YY',
                          ]).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                      ).toISOString()
                    : null,
                votingMethod: row[pair[1]] || null,
            };
        });
        return finalArray;
    };

    const getName = (row: any, tempSelectedFields: any) => {
        switch (namePatternValue.value) {
            case 'first':
                return {
                    firstName: createPayloadFields(
                        row,
                        tempSelectedFields?.['First Name']?.value
                    ),
                    middleName: createPayloadFields(
                        row,
                        tempSelectedFields?.['Middle Name']?.value
                    ),
                    lastName: createPayloadFields(
                        row,
                        tempSelectedFields?.['Last Name']?.value
                    ),
                };
                break;
            case 'first,middle': {
                const value = createPayloadFields(
                    row,
                    tempSelectedFields?.['First Name']?.value
                );
                return {
                    firstName: value?.split(' ')?.[0] || null,
                    middleName: value?.split(' ')?.[1] || null,
                    lastName: createPayloadFields(
                        row,
                        tempSelectedFields?.['Last Name']?.value
                    ),
                };
            }
            case 'middle,first': {
                const value = createPayloadFields(
                    row,
                    tempSelectedFields?.['First Name']?.value
                );
                return {
                    firstName: value?.split(' ')?.[1] || null,
                    middleName: value?.split(' ')?.[0] || null,
                    lastName: createPayloadFields(
                        row,
                        tempSelectedFields?.['Last Name']?.value
                    ),
                };
            }
            case 'first,last': {
                const value = createPayloadFields(
                    row,
                    tempSelectedFields?.['First Name']?.value
                );
                return {
                    firstName: value?.split(' ')?.[0] || null,
                    lastName: value?.split(' ')?.[1] || null,
                    middleName: createPayloadFields(
                        row,
                        tempSelectedFields?.['Middle Name']?.value
                    ),
                };
            }
            case 'last,first': {
                const value = createPayloadFields(
                    row,
                    tempSelectedFields?.['First Name']?.value
                );
                return {
                    firstName: value?.split(' ')?.[1] || null,
                    lastName: value?.split(' ')?.[0] || null,
                    middleName: createPayloadFields(
                        row,
                        tempSelectedFields?.['Middle Name']?.value
                    ),
                };
            }
            case 'first,middle,last': {
                const value = createPayloadFields(
                    row,
                    tempSelectedFields?.['First Name']?.value
                );
                return {
                    firstName: value?.split(' ')?.[0] || null,
                    lastName: value?.split(' ')?.[2] || null,
                    middleName: value?.split(' ')?.[1] || null,
                };
            }
            case 'first,last,middle': {
                const value = createPayloadFields(
                    row,
                    tempSelectedFields?.['First Name']?.value
                );
                return {
                    firstName: value?.split(' ')?.[0] || null,
                    lastName: value?.split(' ')?.[1] || null,
                    middleName: value?.split(' ')?.[2] || null,
                };
            }
            case 'middle,first,last': {
                const value = createPayloadFields(
                    row,
                    tempSelectedFields?.['First Name']?.value
                );
                return {
                    firstName: value?.split(' ')?.[1] || null,
                    lastName: value?.split(' ')?.[2] || null,
                    middleName: value?.split(' ')?.[0] || null,
                };
            }
            case 'last,first,middle': {
                const value = createPayloadFields(
                    row,
                    tempSelectedFields?.['First Name']?.value
                );
                return {
                    firstName: value?.split(' ')?.[1] || null,
                    lastName: value?.split(' ')?.[0] || null,
                    middleName: value?.split(' ')?.[2] || null,
                };
            }
            case 'middle,last,first': {
                const value = createPayloadFields(
                    row,
                    tempSelectedFields?.['First Name']?.value
                );
                return {
                    firstName: value?.split(' ')?.[2] || null,
                    lastName: value?.split(' ')?.[1] || null,
                    middleName: value?.split(' ')?.[0] || null,
                };
            }
            case 'last,middle,first': {
                const value = createPayloadFields(
                    row,
                    tempSelectedFields?.['First Name']?.value
                );
                return {
                    firstName: value?.split(' ')?.[2] || null,
                    lastName: value?.split(' ')?.[0] || null,
                    middleName: value?.split(' ')?.[1] || null,
                };
            }
            default:
                return {
                    firstName: createPayloadFields(
                        row,
                        tempSelectedFields?.['First Name']?.value
                    ),
                    middleName: createPayloadFields(
                        row,
                        tempSelectedFields?.['Middle Name']?.value
                    ),
                    lastName: createPayloadFields(
                        row,
                        tempSelectedFields?.['Last Name']?.value
                    ),
                };
        }
    };

    const handleUploadFile = () => {
        let tempSelectedFields: any = {};
        selectedField.forEach((fieldDetails: any) => {
            tempSelectedFields = {
                ...tempSelectedFields,
                [fieldDetails.column]: {
                    value: tempSelectedFields?.[fieldDetails?.column]?.value
                        ? [
                              ...tempSelectedFields[fieldDetails?.column].value,
                              fieldDetails.value,
                          ]
                        : [fieldDetails.value],
                },
            };
        });
        const payload = fileRowData.map((row: any) => {
            const nameObj = getName(row, tempSelectedFields);
            return {
                ...nameObj,
                email: createPayloadFields(
                    row,
                    tempSelectedFields?.Email?.value
                ),
                phone: createPayloadFields(
                    row,
                    tempSelectedFields?.Phone?.value
                ),
                address1: createPayloadFields(
                    row,
                    tempSelectedFields?.Address1?.value
                ),
                address2: createPayloadFields(
                    row,
                    tempSelectedFields?.Address2?.value
                ),
                city: createPayloadFields(row, tempSelectedFields?.City?.value),
                state: createPayloadFields(
                    row,
                    tempSelectedFields?.State?.value
                ),
                zip: createPayloadFields(row, tempSelectedFields?.Zip?.value),
                mailingAddress1: createPayloadFields(
                    row,
                    tempSelectedFields?.['Mailing Address1']?.value
                ),
                mailingAddress2: createPayloadFields(
                    row,
                    tempSelectedFields?.['Mailing Address2']?.value
                ),
                mailingCity: createPayloadFields(
                    row,
                    tempSelectedFields?.['Mailing City']?.value
                ),
                mailingState: createPayloadFields(
                    row,
                    tempSelectedFields?.['Mailing State']?.value
                ),
                mailingZip: createPayloadFields(
                    row,
                    tempSelectedFields?.['Mailing Zip']?.value
                ),
                employer: createPayloadFields(
                    row,
                    tempSelectedFields?.Employer?.value
                ),
                occupation: createPayloadFields(
                    row,
                    tempSelectedFields?.Occupation?.value
                ),
                voterID: createPayloadFields(
                    row,
                    tempSelectedFields?.['Voter ID']?.value
                ),
                registrationDate: createPayloadFields(
                    row,
                    tempSelectedFields?.['Registration Date']?.value,
                    'date'
                ),
                affiliation: createPayloadFields(
                    row,
                    tempSelectedFields?.Affiliation?.value
                ),
                voteStatus: createPayloadFields(
                    row,
                    tempSelectedFields?.['Voter Status']?.value
                ),
                preCinct: createPayloadFields(
                    row,
                    tempSelectedFields?.Precinct?.value
                ),
                county: createPayloadFields(
                    row,
                    tempSelectedFields?.County?.value
                ),
                stateHouseDistrict: createPayloadFields(
                    row,
                    tempSelectedFields?.['State House District']?.value
                ),
                stateSenateDistrict: createPayloadFields(
                    row,
                    tempSelectedFields?.['State Senate District']?.value
                ),
                stateSchool: createPayloadFields(
                    row,
                    tempSelectedFields?.['State School Board']?.value
                ),
                localSchool: createPayloadFields(
                    row,
                    tempSelectedFields?.['Local School Board']?.value
                ),

                congressionalHouseDistrict: createPayloadFields(
                    row,
                    tempSelectedFields?.['Congressional House District']?.value
                ),
                tags: createPayloadFields(row, tempSelectedFields?.Tags?.value),
                note: createPayloadFields(row, tempSelectedFields?.Note?.value),
                votingHistoryList: mapVotingHistory(row).filter(
                    (votingDetails: any) =>
                        !(
                            !votingDetails?.votingDate ||
                            !votingDetails?.votingMethod
                        )
                ),
            };
        });
        // API will be called to upload data
        uploadExcelFileData(payload)
            .then((response) => {
                setShowConfirmationalModal(false);
                if (response?.data?.errorMessage) {
                    toast.error(response?.data?.errorMessage);
                } else {
                    setOpenModal(true);

                    setListData({
                        systemList: response?.data?.sysExistContact,
                        duplicateList: response?.data?.excelContactTemplates,
                    });

                    toast.success(response?.data?.successMessage);
                    setTotalContactCount({
                        imported: response?.data?.totalImported,
                        duplicate: response?.data?.totalDuplicate,
                        uploadId: response?.data?.uploadId,
                    });
                }
            })
            .catch((error) => toast.error(error?.data));
    };

    const mergeAllData = () => {
        setIsLoading({ mergeButton: true, yesButton: false });
        mergeAllContacts({
            excelContactTemplates: listData.duplicateList,
            uploadId: totalContactCount?.uploadId,
        })
            .then((res) => {
                toast.success(res?.data);
                setOpenModal(false);
                setSelectedFile([{}]);
                setViewContact(false);
                navigate(PATH_CONTACTS);
            })
            .catch((error) => {
                toast.error(error?.data);
                setOpenModal(false);
                setSelectedFile([{}]);
            })
            .finally(() =>
                setIsLoading({ mergeButton: false, yesButton: false })
            );
    };

    const handleSingleOperation = () => {
        setOpenModal(false);
        setSelectedFile([{}]);
        setViewContact(false);
    };

    useEffect(() => {
        switch (namePatternValue.value) {
            case 'first':
                setSystemColumns(SYSTEM_COLUMNS);
                break;
            case 'first,middle':
            case 'middle,first':
                setSystemColumns(
                    SYSTEM_COLUMNS.filter(
                        (column: any) => column !== 'Middle Name'
                    )
                );
                break;
            case 'first,last':
            case 'last,first':
                setSystemColumns(
                    SYSTEM_COLUMNS.filter(
                        (column: any) => column !== 'Last Name'
                    )
                );
                break;
            case 'first,middle,last':
            case 'first,last,middle':
            case 'middle,first,last':
            case 'last,first,middle':
            case 'middle,last,first':
            case 'last,middle,first':
                setSystemColumns(
                    SYSTEM_COLUMNS.filter(
                        (column: any) =>
                            column !== 'Last Name' && column !== 'Middle Name'
                    )
                );
                break;
            default:
                setSystemColumns(SYSTEM_COLUMNS);
                break;
        }
    }, [namePatternValue]);

    useEffect(() => {
        let tempSelected: any = [];

        systemColumns.forEach((column: any, index: any) => {
            if (
                fileHeaders
                    .map((header: any) => header.split('_col')[0])
                    .includes(column)
            ) {
                const value = fileHeaders.filter(
                    (fileHead: any) => fileHead.split('_col')[0] === column
                );
                tempSelected = [
                    ...tempSelected,
                    { rowIndex: index, value: value?.[0], column },
                ];
            }
        });

        setSelectedField(tempSelected);
    }, [fileHeaders, systemColumns]);
    useEffect(() => {
        if (isVoterHistory) {
            pageBottom.current.scrollIntoView();
        }
    }, [isVoterHistory]);

    const generateOptionsForDateVoted = () => {
        return fileHeaders
            .filter((header: any) => header.split('_col')[0])
            .map((value: any) => {
                const isValueSelectedInOtherRow = selectedField.some(
                    (item: any) => item.value === value
                );
                if (!isValueSelectedInOtherRow) {
                    const dropdownLabel = value.split('_col');

                    return {
                        value,
                        label: `${dropdownLabel[0]} (Column #${dropdownLabel[1]})`,
                    };
                }
                return null;
            })
            .filter((value: any) => value !== null);
    };

    const handleLinkYes = () => {
        setIsLoading({
            yesButton: true,
            mergeButton: false,
        });
        handleUploadFile();
    };

    const renderPageContent = () => {
        if (viewContact) {
            return (
                <DuplicateContacts
                    listData={listData}
                    mergeAllData={mergeAllData}
                    btnLoader={isLoading}
                    uploadId={totalContactCount.uploadId}
                    handleSingleOperations={handleSingleOperation}
                />
            );
        }
        if (!viewContact && fieldLoding) {
            return <Loader />;
        }

        return (
            <>
                <div className="row sticky-header">
                    <div className="col-6">
                        <div className="d-flex flex-row">
                            <h2 className="mt-4 mr-2 d-inline">
                                Link Column Headers
                            </h2>
                        </div>
                    </div>
                    <div className="col-6 text-right contact-header-btn mt-4">
                        <Button
                            className="btn-transparent mr-2 mb-1"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <img src={ArrowBtn} alt="back-button" />
                            <span className="capitalize"> Back</span>
                        </Button>
                        <Button
                            onClick={() => {
                                setShowConfirmationalModal(true);
                            }}
                            className="mb-1"
                        >
                            Link
                        </Button>
                    </div>
                    <div className="horizontal mt-2" />
                    <div className="col-12 py-2">
                        <img src={InfoImg} alt="" />
                        <span className="error-message error-align">
                            Associate the columns from your imported file with
                            the corresponding system columns.
                        </span>
                    </div>
                </div>

                <div className="row map-excel-background align-first-child p-2 mt-3">
                    <div className="col-md-10 py-1 map-excel-header">
                        File Columns
                    </div>
                    <div className="col-md-2 py-1 map-excel-header">
                        System Columns
                    </div>
                </div>
                {systemColumns.map((columns: any, index: any) => {
                    return (
                        <div
                            className="row map-excel-background p-3 my-4"
                            key={columns}
                        >
                            <div className="col-8">
                                <div className="row">
                                    <div className="col-12">
                                        {renderExcelFields(index, columns)}
                                    </div>

                                    {columns === 'First Name' ? (
                                        <div className="col-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-3" />
                                                <div className="col-md-3 mt-2 text-right">
                                                    <span>Name Pattern</span>
                                                </div>
                                                <div className="col-md-6">
                                                    <DropDownSelect>
                                                        <Select
                                                            placeholder="Select pattern"
                                                            value={
                                                                namePatternValue.value
                                                                    ? namePatternValue
                                                                    : namePatternValue.value
                                                            }
                                                            options={
                                                                NAME_PATTERN_LABELS
                                                            }
                                                            className="w-250"
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setnamePatternValue(
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </DropDownSelect>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-2 d-flex align-items-center justify-content-center">
                                <img src={RightArrow} alt="" />
                            </div>
                            <div className="col-md-2 d-flex align-items-center map-excel-header">
                                {columns}
                            </div>
                        </div>
                    );
                })}
                <div className="row map-excel-background mb-3">
                    <div className="col-12 py-3">
                        <p className="d-inline-block pr-4 pl-3">
                            Does your file include voter history ?
                        </p>
                        <ToggleSwitch className="my-1 ml-3 map-excel-field">
                            <input
                                type="checkbox"
                                checked={isVoterHistory}
                                onClick={() => {
                                    setIsVoterHistory(!isVoterHistory);
                                }}
                            />
                            <span className="slider round" />
                        </ToggleSwitch>
                    </div>
                    {isVoterHistory && (
                        <>
                            <div className="col-12">
                                <div className="pl-3">
                                    <img src={InfoImg} alt="" />
                                    <Span className="error-message error-align pl-2">
                                        {importTextConstant}
                                    </Span>
                                </div>
                            </div>
                            <div className="col-12 my-3">
                                <div className="row">
                                    <div className="col-8 pl-4">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <DropDownSelect>
                                                    <label
                                                        htmlFor="date-voted"
                                                        className="mb-1 d-inline-block"
                                                    >
                                                        Starting column (Date
                                                        voted)
                                                    </label>
                                                    <Select
                                                        id="date-voted"
                                                        value={
                                                            voterHistoryStartingColumn
                                                        }
                                                        options={generateOptionsForDateVoted()}
                                                        className="w-250"
                                                        onChange={(value) => {
                                                            setVoterHistoryStartingColumn(
                                                                value
                                                            );
                                                        }}
                                                    />
                                                </DropDownSelect>
                                            </div>
                                            <div className="col-md-4">
                                                <DropDownSelect>
                                                    <label
                                                        htmlFor="vote-method"
                                                        className="mb-1 d-inline-block"
                                                    >
                                                        Ending column (Vote
                                                        method)
                                                    </label>
                                                    <Select
                                                        id="vote-method"
                                                        value={
                                                            voterHistoryEndingColumn
                                                        }
                                                        options={fileHeaders
                                                            .filter(
                                                                (header: any) =>
                                                                    header.split(
                                                                        '_col'
                                                                    )[0]
                                                            )
                                                            .map(
                                                                (
                                                                    value: any
                                                                ) => {
                                                                    const isValueSelectedInOtherRow =
                                                                        selectedField.some(
                                                                            (
                                                                                item: any
                                                                            ) =>
                                                                                item.value ===
                                                                                value
                                                                        );
                                                                    if (
                                                                        !isValueSelectedInOtherRow
                                                                    ) {
                                                                        const dropdownLabel =
                                                                            value.split(
                                                                                '_col'
                                                                            );
                                                                        return {
                                                                            value,
                                                                            label: `${dropdownLabel[0]} (Column #${dropdownLabel[1]})`,
                                                                        };
                                                                    }
                                                                    return null;
                                                                }
                                                            )
                                                            .filter(
                                                                (value: any) =>
                                                                    value !==
                                                                    null
                                                            )}
                                                        className="w-250"
                                                        onChange={(value) => {
                                                            setVoterHistoryEndingColumn(
                                                                value
                                                            );
                                                        }}
                                                    />
                                                </DropDownSelect>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2 d-flex align-items-center justify-content-center">
                                        <img src={RightArrow} alt="" />
                                    </div>
                                    <FontWeightDiv
                                        className="col-2 d-flex align-items-center"
                                        ref={pageBottom}
                                    >
                                        Voter history & Method
                                    </FontWeightDiv>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {openModal && (
                    <CommonModal
                        modalBody={
                            <div className="modal-heading text-center">
                                {totalContactCount?.imported ? (
                                    <h3>
                                        {totalContactCount?.imported} contacts
                                        uploaded successfully!
                                    </h3>
                                ) : (
                                    ''
                                )}
                                {totalContactCount?.duplicate !== 0 ? (
                                    <img
                                        className="cross-icon mt-2"
                                        src={RemoveCross}
                                        onClick={() => {
                                            setOpenModal(false);
                                            setSelectedFile([{}]);
                                            navigate(PATH_CONTACTS);
                                        }}
                                        role="presentation"
                                        alt="cross"
                                    />
                                ) : (
                                    ''
                                )}
                                <div className="mt-4">
                                    <Heading>
                                        {totalContactCount?.duplicate === 0 ? (
                                            ''
                                        ) : (
                                            <div>
                                                <PrimaryColorSpan>
                                                    {
                                                        totalContactCount?.duplicate
                                                    }
                                                </PrimaryColorSpan>{' '}
                                                duplicate contacts found
                                            </div>
                                        )}
                                    </Heading>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        {totalContactCount?.duplicate === 0 ? (
                                            <Button
                                                className="uppercase"
                                                onClick={() => {
                                                    setOpenModal(false);
                                                    setSelectedFile([{}]);
                                                    navigate(PATH_CONTACTS);
                                                }}
                                                role="presentation"
                                            >
                                                {' '}
                                                OK
                                            </Button>
                                        ) : (
                                            <>
                                                <Button
                                                    className="btn-transparent mr-4"
                                                    type="button"
                                                    onClick={() => {
                                                        setOpenModal(false);
                                                        setViewContact(true);
                                                    }}
                                                    disabled={
                                                        isLoading?.mergeButton
                                                    }
                                                >
                                                    View duplicates
                                                </Button>
                                                <Button
                                                    type="button"
                                                    onClick={() =>
                                                        mergeAllData()
                                                    }
                                                    disabled={
                                                        isLoading?.mergeButton
                                                    }
                                                >
                                                    {isLoading?.mergeButton ? (
                                                        <Loader type="button" />
                                                    ) : (
                                                        'Merge all'
                                                    )}
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        }
                        isModalOpen={openModal}
                        handleCloseOperation={() => {
                            setOpenModal(false);
                        }}
                    />
                )}
                {showConfirmationalModal && (
                    <CommonModal
                        styles={modalStyle}
                        modalBody={
                            <>
                                <div className="modal-heading text-center">
                                    <h3>Confirm header linking and upload?</h3>
                                </div>
                                <div className="d-flex flex-col justify-content-center w-100 mt-4">
                                    <div className="d-flex">
                                        <Button
                                            className="btn-transparent mr-2"
                                            onClick={() => {
                                                setShowConfirmationalModal(
                                                    false
                                                );
                                            }}
                                            disabled={isLoading.yesButton}
                                        >
                                            No
                                        </Button>
                                        <Button
                                            onClick={handleLinkYes}
                                            disabled={isLoading.yesButton}
                                        >
                                            {isLoading.yesButton ? (
                                                <Loader type="button" />
                                            ) : (
                                                'Yes'
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </>
                        }
                        isModalOpen={showConfirmationalModal}
                        handleCloseOperation={() => {
                            setShowConfirmationalModal(false);
                        }}
                    />
                )}
            </>
        );
    };

    return (
        <div className="body-container">
            <PageNav className="sticky-header nav-sticky">
                <ul className="nav-list">
                    <li className="active-nav">
                        Contacts{' '}
                        <img className="ml-1" src={ArrowRight} alt="arrow" />
                    </li>
                    <li className="active-nav">
                        Upload{' '}
                        <img className="ml-1" src={ArrowRight} alt="arrow" />
                    </li>
                    <li className={`${viewContact ? 'active-nav' : ''}`}>
                        Link column headers
                        {viewContact ? (
                            <img
                                className="ml-1"
                                src={ArrowRight}
                                alt="arrow"
                            />
                        ) : null}
                    </li>
                    {viewContact ? <li>View duplicates</li> : ''}
                </ul>
            </PageNav>
            {renderPageContent()}
        </div>
    );
};

export default MapExcelFields;

const Span = styled.span`
    vertical-align: top;
`;

const PrimaryColorSpan = styled.span`
    color: ${(props: any) => props.theme.colors.primaryColor};
`;

const FontWeightDiv = styled.div`
    font-weight: 600;
`;

const Heading = styled.h4`
    font-size: ${(props: any) => props.theme.fontSizes.modalTitle};
    color: ${(props: any) => props.theme.colors.grey3};
`;
