import { FC, useState, useEffect, ChangeEvent } from 'react';
import { SearchIconImg } from '../assets/images';
import { InputSearch } from './InputSearch';

interface SearchInputType {
    setDebouncedInputValue: (val: string) => void;
}

const SearchInput: FC<SearchInputType> = ({ setDebouncedInputValue }) => {
    const [inputValue, setInputValue] = useState('');
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedInputValue(inputValue);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [inputValue, setDebouncedInputValue]);

    return (
        <InputSearch>
            <input
                type="text"
                placeholder="Search"
                className="form-control"
                aria-label="Search"
                aria-describedby="basic-addon2"
                value={inputValue}
                onChange={handleInputChange}
            />
            <img src={SearchIconImg} alt="Search" className="search-icon" />
        </InputSearch>
    );
};

export default SearchInput;
