import { useState, FC, useEffect } from 'react';

import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Loader from '../../common/Loader';
import duplicateContactStyle from '../../Theme/style/CustomStyles';

import { RemoveCross } from '../../assets/images';
import { LinkSearch } from '../../Theme/style/ContactStyle';
import { ModalOverlay } from '../../Theme/style/ModalStyle';
import { MergeModalField } from '../../Theme/style/MapExcelFieldStyle';
import { Button, phoneNoformat, TableBody } from '../../common';
import { keepBothContacts, mergeAContact } from '../../api';
import {
    PATH_CONTACTS,
    objBooleanFormat,
    objNumberFormat,
    tableHeader,
} from '../../constants';

const DuplicateContacts: FC<{
    listData: any;
    mergeAllData: any;
    btnLoader: any;
    uploadId: number;
    handleSingleOperations: () => void;
}> = ({
    listData,
    mergeAllData,
    btnLoader,
    uploadId,
    handleSingleOperations,
}) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState({
        pageLoader: true,
        linkLoader: false,
    });
    const [openMergeModal, setOpenMergeModal] = useState<any>({
        modal: false,
        item: null,
    });

    const [tableHeaders, setTableHeaders] = useState<any>([]);
    const [formattedList, setFormattedList] = useState<any>([]);
    const [selectedContactToMerge, setSelectedContactToMerge] = useState<any>(
        {}
    );
    const [hideKeepBothMergeLink, setHideKeepBothMergeLink] = useState<any>([]);
    const [keepBoothValues, setKeepBothValues] = useState<any>({});
    const [duplicateContactsCount, setDuplicateContactsCount] =
        useState<number>(
            listData?.duplicateList?.length
                ? Number(listData?.duplicateList?.length)
                : 0
        );

    const [bothDisabled, setBothDisabled] = useState<any>({
        keyName: '',
        value: false,
    });
    const [pageLoader, setPageLoader] = useState(true);

    useEffect(() => {
        const systemHeaders = Object.keys(listData?.systemList?.[0]);
        const duplicateHeaders = Object.keys(listData?.duplicateList?.[0]);
        const headerNames: any = [];
        const list: any = [];
        listData.duplicateList.forEach((duplicate: any) => {
            listData.systemList.forEach((sys: any) => {
                if (
                    sys.firstName === duplicate.firstName &&
                    sys.lastName === duplicate.lastName
                ) {
                    list.push({ sys, duplicate });
                }
            });
            setFormattedList(list);
        });
        tableHeader.forEach((op: any) => {
            systemHeaders.forEach((ele: any) => {
                duplicateHeaders.forEach((dup) => {
                    if (op.value === ele && op.value === dup) {
                        headerNames.push({ label: op.label, key: op.value });
                    }
                    if (
                        op.value === 'phoneNumber' &&
                        ele === 'primaryPhone' &&
                        dup === 'primaryPhone'
                    ) {
                        headerNames.push({ label: op.label, key: ele });
                    }
                });
            });
            setPageLoader(false);
        });
        setTableHeaders(headerNames);
    }, [listData]);

    useEffect(() => {
        if (tableHeaders?.length) {
            const checkFullNameExists = tableHeaders?.find((op: any) =>
                op.key.includes('lastName')
            );
            if (
                (openMergeModal.modal && !checkFullNameExists) ||
                (openMergeModal.modal &&
                    checkFullNameExists &&
                    !Object.keys(checkFullNameExists).length)
            ) {
                const addFullName = [...tableHeaders];
                addFullName.splice(1, 0, {
                    label: 'Middle Name',
                    key: 'middleName',
                });
                addFullName.splice(2, 0, {
                    label: 'Last Name',
                    key: 'lastName',
                });

                setTableHeaders(addFullName);
            }
        }
    }, [openMergeModal, setTableHeaders, tableHeaders]);

    useEffect(() => {
        // Set the initial value as a system value in marj modal
        if (openMergeModal?.modal === true && openMergeModal?.item?.sys) {
            const initialSelectedContact: any = {};
            Object.keys(openMergeModal?.item?.sys).forEach((item) => {
                if (
                    openMergeModal?.item?.sys[item] !== '' &&
                    openMergeModal?.item?.sys[item] !== null &&
                    openMergeModal?.item?.sys[item] !== undefined
                )
                    initialSelectedContact[
                        item
                    ] = `sys_${openMergeModal?.item?.sys[item]}`;
            });
            setSelectedContactToMerge(initialSelectedContact);
        }
    }, [openMergeModal]);

    // remove _sys and _csv while sending data to backend

    const selectedContactToMergeTemp: any = { ...selectedContactToMerge };

    const getSelectedContactToMerge = () => {
        const parseValues = (stringParam: string, valueParam: any) => {
            if (objNumberFormat.includes(valueParam)) {
                return Number(
                    selectedContactToMergeTemp[valueParam].replace(
                        stringParam,
                        ''
                    )
                );
            }
            if (objBooleanFormat.includes(valueParam)) {
                if (
                    selectedContactToMergeTemp[valueParam].toString ===
                    'true'.toString
                ) {
                    return true;
                }
                return false;
            }
            return selectedContactToMergeTemp[valueParam].replace(
                stringParam,
                ''
            );
        };
        Object.keys(selectedContactToMerge).forEach((item) => {
            if (selectedContactToMergeTemp[item].includes('sys_')) {
                selectedContactToMergeTemp[item] = parseValues('sys_', item);
            } else if (selectedContactToMergeTemp[item].includes('csv_')) {
                selectedContactToMergeTemp[item] = parseValues('csv_', item);
            }
        });

        return selectedContactToMergeTemp;
    };

    const keepBothOrMergeContact = (selectedContact: any, type: string) => {
        setIsLoading({ linkLoader: true, pageLoader: false });

        let details = {};
        if (type === 'both') {
            details = {
                excelContactId: selectedContact?.duplicate?.id,
                uploadId,
            };
        } else {
            const setSelectedContactToMergeTemp: any =
                getSelectedContactToMerge();
            const finalUpdatedValues = Object.keys(keepBoothValues)
                .filter(
                    (k) =>
                        keepBoothValues[k] !== '' &&
                        keepBoothValues[k] !== false
                )
                .reduce((a, k) => ({ ...a, [k]: keepBoothValues[k] }), {});
            details = {
                uploadId,
                ...setSelectedContactToMergeTemp,
                ...finalUpdatedValues,
            };
        }

        const api = type === 'both' ? keepBothContacts : mergeAContact;
        api(details)
            .then((res: any) => {
                toast.success(res?.data);
                setHideKeepBothMergeLink([
                    ...hideKeepBothMergeLink,
                    selectedContact?.duplicate?.id,
                ]);
                setOpenMergeModal({ moadl: false, item: null });
                setDuplicateContactsCount(duplicateContactsCount - 1);
                if (duplicateContactsCount - 1 === 0) {
                    navigate(PATH_CONTACTS);
                }
            })
            .catch((error) => toast.error(error?.data))
            .finally(() =>
                setIsLoading({ linkLoader: false, pageLoader: false })
            );
    };

    const keepBothRadioButtonConditions = (
        headerLabel: string,
        csvValue: any,
        duplicateValue: any
    ) => {
        let isShowKeepBothRadio = false;
        if (
            headerLabel === 'Email' ||
            headerLabel === 'Address' ||
            headerLabel === 'Phone'
        ) {
            if (csvValue && duplicateValue && csvValue !== duplicateValue) {
                isShowKeepBothRadio = true;
            }
        }
        return isShowKeepBothRadio;
    };

    // When we click radio button of keep both then put both the values of the key
    const keepBothValues = (
        e: any,
        key: any,
        sysValue: any,
        duplicateValue: any
    ) => {
        const returnKeyName = (keyParam: string) => {
            switch (keyParam) {
                case 'addressLine1':
                    return 'keepBothAddress';
                case 'primaryEmail':
                    return 'keepBothEmail';
                case 'primaryPhone':
                    return 'keepBothPhone';
                default:
                    return 'keepBoth';
            }
        };

        let objectBothKeyValues: any = {};
        if (returnKeyName(key) === 'keepBothAddress') {
            if (e.target.checked) {
                const updatedValues = keepBoothValues;
                objectBothKeyValues = {
                    addressLine1: sysValue,
                    addressLine2: openMergeModal.item?.sys?.addressLine2 || '',
                    city: openMergeModal.item?.sys?.city || '',
                    state: openMergeModal.item?.sys?.state || '',
                    gpsCoordinates:
                        openMergeModal.item?.sys?.gpsCoordinates || '',
                    zipCode: openMergeModal.item?.sys?.zipCode || '',
                    excelAddressLine1: duplicateValue,

                    excelAddressLine2:
                        openMergeModal.item?.duplicate?.addressLine2 || '',
                    excelCity: openMergeModal.item?.duplicate?.city || '',
                    excelState: openMergeModal.item?.duplicate?.state || '',
                    excelGPSCoordinates:
                        openMergeModal.item?.duplicate?.gpsCoordinates || '',
                    excelZipCode: openMergeModal.item?.duplicate?.zipCode || '',
                    keepBothAddress: true,
                };
                setKeepBothValues({ ...updatedValues, ...objectBothKeyValues });
                setBothDisabled({ keyName: key, value: true });
            } else {
                const updatedValues = keepBoothValues;
                objectBothKeyValues = {
                    excelAddressLine1: '',
                    excelAddressLine2: '',
                    excelCity: '',
                    excelState: '',
                    excelGPSCoordinates: '',
                    excelZipCode: '',
                    keepBothAddress: false,
                };

                setKeepBothValues({ ...updatedValues, ...objectBothKeyValues });
                setBothDisabled({ keyName: key, value: false });
            }
        }
        if (returnKeyName(key) === 'keepBothEmail') {
            if (e.target.checked) {
                const updatedValues = keepBoothValues;
                objectBothKeyValues = {
                    ...objectBothKeyValues,
                    primaryEmail: sysValue,
                    excelEmail: duplicateValue,
                    keepBothEmail: true,
                };

                setKeepBothValues({ ...updatedValues, ...objectBothKeyValues });
                setBothDisabled({ keyName: key, value: true });
            } else {
                const updatedValues = keepBoothValues;
                objectBothKeyValues = {
                    ...objectBothKeyValues,
                    excelEmail: '',
                    keepBothEmail: false,
                };

                setKeepBothValues({ ...updatedValues, ...objectBothKeyValues });
                setBothDisabled({ keyName: key, value: false });
            }
        }
        if (returnKeyName(key) === 'keepBothPhone') {
            if (e.target.checked) {
                const updatedValues = keepBoothValues;
                objectBothKeyValues = {
                    ...objectBothKeyValues,
                    primaryPhone: sysValue,
                    excelPhone: duplicateValue,
                    keepBothPhone: true,
                };
                setKeepBothValues({ ...updatedValues, ...objectBothKeyValues });
                setBothDisabled({ keyName: key, value: true });
            } else {
                const updatedValues = keepBoothValues;
                objectBothKeyValues = {
                    ...objectBothKeyValues,
                    excelPhone: '',
                    keepBothPhone: false,
                };
                setKeepBothValues({ ...updatedValues, ...objectBothKeyValues });
                setBothDisabled({ keyName: key, value: false });
            }
        }

        const keyName = returnKeyName(key);
        let selectedContactToMergeTempBoth: any = { ...selectedContactToMerge };
        delete selectedContactToMergeTempBoth?.[key];
        selectedContactToMergeTempBoth = {
            ...selectedContactToMergeTempBoth,
            [keyName]: true,
            ...objectBothKeyValues,
        };
    };

    const handleCloseOpenMergeModal = () => {
        setKeepBothValues({});
        setOpenMergeModal({ modal: false, item: null });
    };

    useEffect(() => {
        setKeepBothValues({});
        setBothDisabled({ keyName: '', value: false });
    }, []);

    useEffect(() => {
        if (duplicateContactsCount === 0) {
            handleSingleOperations();
        }
    }, [duplicateContactsCount, handleSingleOperations]);

    const handleMergeModalDisable = (header: any) => {
        return (
            openMergeModal?.item?.sys[header.key] === '' ||
            openMergeModal?.item?.sys[header.key] === null ||
            openMergeModal?.item?.sys[header.key] === undefined ||
            (header.key === bothDisabled.keyName && bothDisabled.value)
        );
    };

    const handleMergeModalOnClick = (event: any, header: any) => {
        setSelectedContactToMerge({
            ...selectedContactToMerge,
            [header.key]: event.target.id,
        });
    };

    const handleKeepBoth = (e: any, header: any) =>
        keepBothValues(
            e,
            header.key,
            openMergeModal.item?.sys?.[header.key],
            openMergeModal.item?.duplicate?.[header.key]
        );

    const getPhoneNumber = (num: any) => {
        return num && num?.toString().length === 10 ? phoneNoformat(num) : '-';
    };

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <div className="d-flex flex-row">
                        <h2 className="mt-4 mr-2 d-inline">View Duplicates</h2>
                    </div>
                </div>
                <div className="col-6 text-right contact-header-btn mt-4">
                    <Button
                        className="btn-transparent mr-2 mb-1"
                        onClick={() => {
                            navigate(PATH_CONTACTS);
                        }}
                    >
                        <span className="capitalize"> Cancel</span>
                    </Button>
                    <Button
                        onClick={() => {
                            mergeAllData();
                        }}
                        className="mb-1"
                        disabled={btnLoader?.mergeButton}
                    >
                        {btnLoader?.mergeButton ? (
                            <Loader type="button" />
                        ) : (
                            'Merge All'
                        )}
                    </Button>
                </div>
            </div>
            {pageLoader ? (
                <Loader />
            ) : (
                <div className="table-responsive duplicates-table table-horizontal-scroll import-contacts">
                    <TableBody className="duplicate-table">
                        <thead className="grey-header">
                            <tr>
                                {tableHeaders?.map((header: any) => (
                                    <th key={header.label}>{header.label}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="data-border">
                            {formattedList.map((item: any) =>
                                hideKeepBothMergeLink.includes(
                                    item.duplicate.id
                                ) ? null : (
                                    <>
                                        <tr className="normal-table duplicate-table-hover">
                                            {tableHeaders.map((header: any) => {
                                                let tData = '-';
                                                if (
                                                    header.key ===
                                                    'primaryPhone'
                                                ) {
                                                    tData = getPhoneNumber(
                                                        item?.sys?.primaryPhone
                                                    );
                                                } else if (
                                                    header.key ===
                                                    'ballotStatus'
                                                ) {
                                                    tData =
                                                        item?.sys
                                                            ?.strBallotStatus;
                                                } else {
                                                    tData =
                                                        item?.sys?.[header.key];
                                                }
                                                return (
                                                    <td
                                                        className="normal-data"
                                                        key={tData}
                                                    >
                                                        {tData}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                        <tr
                                            className={`
                                                ${
                                                    hideKeepBothMergeLink.includes(
                                                        item.duplicate.id
                                                    )
                                                        ? 'link-table-row'
                                                        : ''
                                                } normal-table duplicate-table-hover`}
                                        >
                                            {tableHeaders.map((header: any) => (
                                                <td
                                                    key={header.key}
                                                    className="normal-data"
                                                >
                                                    {header.key ===
                                                    'primaryPhone'
                                                        ? getPhoneNumber(
                                                              item?.duplicate
                                                                  ?.primaryPhone
                                                          )
                                                        : item?.duplicate?.[
                                                              header.key
                                                          ] || '-'}
                                                </td>
                                            ))}
                                        </tr>

                                        <tr className="link-table-row normal-table duplicate-table-hover">
                                            {' '}
                                            <td
                                                className="table-link link-table-row normal-data"
                                                colSpan={14}
                                            >
                                                {' '}
                                                <LinkSearch
                                                    className={
                                                        isLoading.linkLoader
                                                            ? 'disabled pr-3'
                                                            : 'pr-3'
                                                    }
                                                    onClick={() =>
                                                        keepBothOrMergeContact(
                                                            item,
                                                            'both'
                                                        )
                                                    }
                                                >
                                                    Keep Both{' '}
                                                </LinkSearch>
                                                <LinkSearch
                                                    onClick={() =>
                                                        setOpenMergeModal({
                                                            modal: true,
                                                            item,
                                                        })
                                                    }
                                                >
                                                    Merge
                                                </LinkSearch>{' '}
                                            </td>{' '}
                                        </tr>
                                    </>
                                )
                            )}
                        </tbody>
                    </TableBody>
                    <Modal
                        isOpen={openMergeModal.modal}
                        style={duplicateContactStyle}
                        ariaHideApp={false}
                    >
                        <ModalOverlay>
                            <div className="modal-heading text-left modal-title">
                                Merge Contact
                                <div className="text-grey ">
                                    Merge contact to get you all activity of
                                    same person across the channel.
                                </div>
                                <img
                                    className="cross-icon"
                                    src={RemoveCross}
                                    onClick={() => {
                                        handleCloseOpenMergeModal();
                                    }}
                                    role="presentation"
                                    alt="cross"
                                />
                                <div className="horizontal-line lighter-line my-3" />
                            </div>
                            <div className="modal-body modal-body-container mt-3 pt-1">
                                <div className="row mt-4">
                                    <div className="col-2" />
                                    <div className="col-5">
                                        <div className="text-bold text-center">
                                            System Info{' '}
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <div className="text-bold text-center">
                                            File Info{' '}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3 ">
                                    {tableHeaders.map((header: any) => {
                                        return (
                                            <>
                                                <div className="small-label mt-2 pr-0 col-2 mb-4">
                                                    {header.label}
                                                </div>
                                                <div className="col-lg-5 col-5 pl-4 mb-4 ">
                                                    <MergeModalField>
                                                        <label
                                                            className="container"
                                                            htmlFor={`sys_${openMergeModal
                                                                .item?.sys?.[
                                                                header.key
                                                            ]}`}
                                                        >
                                                            <input
                                                                type="radio"
                                                                checked={
                                                                    selectedContactToMerge[
                                                                        header
                                                                            .key
                                                                    ] ===
                                                                    `sys_${openMergeModal
                                                                        .item
                                                                        ?.sys?.[
                                                                        header
                                                                            .key
                                                                    ]}`
                                                                }
                                                                disabled={handleMergeModalDisable(
                                                                    header
                                                                )}
                                                                id={`sys_${openMergeModal
                                                                    .item
                                                                    ?.sys?.[
                                                                    header.key
                                                                ]}`}
                                                                name={
                                                                    header.key
                                                                }
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleMergeModalOnClick(
                                                                        event,
                                                                        header
                                                                    )
                                                                }
                                                            />

                                                            <span className="checkmark" />
                                                            <span className="input-text text-black">
                                                                {header.key ===
                                                                'ballotStatus'
                                                                    ? openMergeModal
                                                                          .item
                                                                          ?.sys
                                                                          ?.strBallotStatus
                                                                    : openMergeModal
                                                                          .item
                                                                          ?.sys?.[
                                                                          header
                                                                              .key
                                                                      ]}
                                                            </span>
                                                        </label>
                                                    </MergeModalField>
                                                </div>
                                                <div className="col-lg-5 col-5 pr-5 mb-4">
                                                    <MergeModalField>
                                                        <label
                                                            className="container"
                                                            htmlFor={`csv_${openMergeModal
                                                                .item
                                                                ?.duplicate?.[
                                                                header.key
                                                            ]}`}
                                                        >
                                                            <input
                                                                type="radio"
                                                                disabled={handleMergeModalDisable(
                                                                    header
                                                                )}
                                                                id={`csv_${openMergeModal
                                                                    .item
                                                                    ?.duplicate?.[
                                                                    header.key
                                                                ]}`}
                                                                name={
                                                                    header.key
                                                                }
                                                                onClick={(
                                                                    event: any
                                                                ) =>
                                                                    handleMergeModalOnClick(
                                                                        event,
                                                                        header
                                                                    )
                                                                }
                                                            />
                                                            <span className="checkmark" />
                                                            <span className="input-text text-black">
                                                                {
                                                                    openMergeModal
                                                                        .item
                                                                        ?.duplicate?.[
                                                                        header
                                                                            .key
                                                                    ]
                                                                }
                                                            </span>
                                                        </label>
                                                    </MergeModalField>
                                                </div>
                                                {keepBothRadioButtonConditions(
                                                    header?.label,
                                                    openMergeModal.item?.sys?.[
                                                        header.key
                                                    ],
                                                    openMergeModal.item
                                                        ?.duplicate?.[
                                                        header.key
                                                    ]
                                                ) ? (
                                                    <div className="col-lg-12 pr-5 mb-4 text-right">
                                                        <input
                                                            id={`keep_both_${header?.label}`}
                                                            type="checkbox"
                                                            className="mr-2"
                                                            onClick={(e: any) =>
                                                                handleKeepBoth(
                                                                    e,
                                                                    header
                                                                )
                                                            }
                                                        />
                                                        <span>Keep both</span>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="horizontal-line lighter-line my-3" />
                                <div className="row text-right">
                                    <div className="col-12">
                                        <Button
                                            className="btn-transparent mr-2 "
                                            onClick={() =>
                                                keepBothOrMergeContact(
                                                    openMergeModal.item,
                                                    'both'
                                                )
                                            }
                                        >
                                            Keep both
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                keepBothOrMergeContact(
                                                    openMergeModal.item,
                                                    'single'
                                                )
                                            }
                                            disabled={isLoading?.linkLoader}
                                        >
                                            {isLoading?.linkLoader ? (
                                                <Loader type="button" />
                                            ) : (
                                                'Merge'
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </ModalOverlay>
                    </Modal>
                </div>
            )}
        </>
    );
};

export default DuplicateContacts;
