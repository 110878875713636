import { FC, useState } from 'react';

import Select from 'react-select';

import { SelectOption, OptionsArrType } from '../types/type';
import DropDownStyle from './DropDownStyle';

const SelectDropdown: FC<OptionsArrType> = ({
    options,
    selected,
    isDisabled,
    handlePageChange,
}) => {
    const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
        selected!
    );

    const handleOnchange = (option: SelectOption | null) => {
        setSelectedOption(option!);
        handlePageChange(option!);
    };
    return (
        <DropDownStyle>
            <Select
                defaultValue={selectedOption}
                options={options}
                onChange={handleOnchange}
                isDisabled={isDisabled}
                isSearchable={false}
            />
        </DropDownStyle>
    );
};

export default SelectDropdown;
