import styled from 'styled-components';

const CheckBox = styled.div`
    .check-container {
        display: block;
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        color: ${(props: any) => props.theme.colors.grey6};
        font-size: ${(props: any) => props.theme.fontSizes.normal};

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .check-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 4px;
        width: 20px;
        background-color: ${(props: any) => props.theme.colors.white};
        height: 20px;
        border: 1px solid ${(props: any) => props.theme.colors.grey5};
    }

    .check-container:hover input ~ .checkmark {
        background-color: ${(props: any) => props.theme.colors.white};
    }

    .check-container input:checked ~ .checkmark {
        background-color: ${(props: any) =>
            props.theme.colors.dropdownActiveBg};
        color: white;
        border: none;
    }

    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    .check-container input:checked ~ .checkmark:after {
        display: block;
    }

    .check-container .checkmark:after {
        left: 7px;
        top: 3px;
        width: 6px;
        height: 11px;
        border: solid ${(props: any) => props.theme.colors.white};
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

export default CheckBox;
