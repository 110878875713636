import {
    DashboardImg,
    ContactImg,
    PlanManagementImg,
    ContactLogImg,
} from '../assets/images';
import {
    PATH_DASHBOARD,
    PATH_CONTACTS,
    PATH_PLAN_MANAGEMENT,
    PATH_CONTACT_CHANGE_LOG,
} from './RouteConstants';

export const menu = [
    { id: 1, label: 'Dashboard', img: DashboardImg, link: PATH_DASHBOARD },
    { id: 2, label: 'Contacts', img: ContactImg, link: PATH_CONTACTS },
    {
        id: 3,
        label: 'Plan Management',
        img: PlanManagementImg,
        link: PATH_PLAN_MANAGEMENT,
    },
    {
        id: 4,
        label: 'Contact Change Log',
        img: ContactLogImg,
        link: PATH_CONTACT_CHANGE_LOG,
    },
];

export const subMenu = [];
