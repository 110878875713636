import { styled } from 'styled-components';

const FilterTag = styled.div`
    width: fit-content;
    padding: 6px 2px 5px 8px;
    border-radius: 12px;
    background-color: ${(props: any) => props.theme.colors.primaryColor};
    color: ${(props: any) => props.theme.colors.white};
    font-size: ${(props: any) => props.theme.fontSizes.extrasmall};
    margin-right: 4px;

    .cross-icon {
        vertical-align: middle;
        margin-left: 3px;
        cursor: pointer;
    }

    &.status-block {
        padding: 5px 10px;
        font-size: ${(props: any) => props.theme.fontSizes.smallText};
    }
    &.list-status {
        margin-left: 3px;
        padding: 5px 10px;
        background-color: ${(props: any) => props.theme.colors.ButtonColor};
    }
`;

export default FilterTag;
