import { styled } from 'styled-components';

const TextArea = styled.textarea`
    border: 1px solid ${(props: any) => props.theme.colors.grey2};
    min-height: 75px;
    border-radius: 5px;
    margin-top: 8px;
    outline: none;
    padding: 10px 12px;
    resize: none;
    width: 100%;

    &:focus {
        background: none;
        outline: none;
    }
`;

export default TextArea;
