import { styled } from 'styled-components';

const DatePickerCalendar = styled.div`
    position: relative;

    .react-date-picker,
    .react-time-picker {
        position: relative;
        display: inline-block;
        width: 100%;

        .react-date-picker__wrapper,
        .react-time-picker__wrapper {
            min-height: 40px;
            font-size: ${(props: any) => props.theme.fontSizes.larger};
            background-color: ${(props: any) => props.theme.colors.white};
            color: ${(props: any) => props.theme.colors.grey12};
            border: 1px solid ${(props: any) => props.theme.colors.grey2};
            border-radius: 4px;
            font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
            width: 100%;
            cursor: pointer;
            .react-datetime-picker__calendar-button {
                picker__button:enabled:hover
                    .react-datetime-picker__button__icon,
                .react-datetime-picker__button:enabled:focus
                    .react-datetime-picker__button__icon {
                    stroke: ${(props: any) =>
                        props.theme.colors.grey85}!important;
                }
            }
            .react-date-picker__inputGroup,
            .react-time-picker__inputGroup {
                padding-left: 8px;
                padding-top: 3px;

                input {
                    color: ${(props: any) => props.theme.colors.grey12};
                }
                .react-date-picker__inputGroup__divider,
                .react-time-picker__inputGroup__divide {
                    padding: 0 1px;
                }
                .react-date-picker__inputGroup__input {
                    color: ${(props: any) =>
                        props.theme.colors.grey12} !important;
                    min-width: 0 !important;
                    height: 30px !important;
                    &:focus {
                        outline: none !important;
                        border: none;
                        background-color: none;
                        background: none;
                    }
                    &:hover {
                        outline: none !important;
                        border: none;
                        background-color: none;
                        background: none;
                    }
                }
            }
        }
    }

    .react-date-picker__inputGroup__month {
    }

    .input,
    input[type='password'],
    input[type='text'] {
        outline: none;
    }

    .react-date-picker__inputGroup__input {
        -webkit-appearance: textfield;
        appearance: textfield;
        background: none;
        border: 0;
        box-sizing: initial;
        font: inherit;
        height: 100%;
        min-width: 0.54em;
        padding: 0 1px;
        position: relative;
    }

    .react-date-picker__inputGroup {
        box-sizing: initial;
        flex-grow: 1;
        min-width: calc(12px + 4.754em);
        padding: 0 2px;
    }

    .react-date-picker__inputGroup__leadingZero {
        display: inline-block;
        padding-right: 9px;
    }

    .react-date-picker--disabled {
        opacity: 0.6;
        cursor: not-allowed !important;
        pointer-events: none !important;
        background-color: transparent;
    }

    .react-date-picker__button svg {
        stroke: ${(props: any) => props.theme.colors.white} !important;
        width: 17px;
        margin-top: 4px;
    }

    .react-date-picker__calendar-button {
        background-color: ${(props: any) => props.theme.colors.primaryColor};
        padding: 0 7px;
        position: absolute;
        min-height: 39px;
        right: 0px;
        top: 0px;
        cursor: default;
        border: none;
    }

    .react-date-picker__clear-button {
        .react-date-picker__clear-button__icon {
            stroke: ${(props: any) => props.theme.colors.grey5} !important;
            display: none !important;
        }
    }

    .react-date-picker__clear-button,
    .react-time-picker__clear-button {
        position: absolute;
        right: 31px;
        min-height: 0px;
        display: none !important;

        svg {
            display: none !important;
        }
    }

    .react-date-picker__clear-button svg {
        display: none !important;
    }

    .react-date-picker__calendar {
        z-index: 99 !important;
        inset: inherit !important;
        right: 0px !important;

        .react-calendar {
            background: ${(props: any) => props.theme.colors.white};
            border: 1px solid ${(props: any) => props.theme.colors.grey5};
            line-height: 1.125em;

            .react-calendar__navigation {
                display: flex;
                height: 44px;
                margin-bottom: 1em;

                .react-calendar__navigation button {
                    background: none;
                    min-width: 44px;
                }
            }
        }

        .react-calendar button {
            border: 0;
            margin: 0;
            outline: none;
            background-color: ${(props: any) => props.theme.colors.white};
            cursor: pointer;
        }

        .react-calendar__viewContainer {
            padding: 3px 5px;

            .react-calendar__month-view {
                .react-calendar__month-view__weekdays {
                    font-size: 0.75em;
                    font-weight: 700;
                    text-align: center;
                    text-transform: uppercase;

                    .react-calendar__month-view__weekdays__weekday {
                        padding: 0.5em;
                    }

                    .react-calendar__month-view__days {
                        .react-calendar__month-view__days__day--neighboringMonth {
                            color: ${(props: any) =>
                                props.theme.colors.secondaryColor};
                        }
                    }
                }

                .react-calendar__tile {
                    background: none;
                    line-height: 16px;
                    max-width: 100%;
                    padding: 10px 6.6667px;
                    text-align: center;
                }

                .react-calendar__month-view__days__day--weekend {
                    color: ${(props: any) => props.theme.colors.primaryColor};
                }

                .react-calendar__tile--now {
                    background-color: ${(props: any) =>
                        props.theme.colors.ButtonColor};
                    color: ${(props: any) => props.theme.colors.white};
                }
            }
        }
    }

    .react-calendar__tile--active {
        background-color: ${(props: any) =>
            props.theme.colors.dropdownActiveBg} !important;
        color: ${(props: any) => props.theme.colors.white};
    }
    .react-calendar__navigation button {
        background: none;
        min-width: 44px;
    }
    &.grace-date {
        .react-calendar {
            line-height: 0.1rem;
        }
        .react-calendar__navigation {
            margin-bottom: 0px !important;
            height: 38px !important;
        }
        .react-calendar__viewContainer
            .react-calendar__month-view
            .react-calendar__tile {
            padding: 5px 6.6667px !important;
        }
    }
`;

export default DatePickerCalendar;
