import styled from 'styled-components';

const Button = styled.button`
    background-color: ${(props: any) => props.theme.colors.ButtonColor};
    color: ${(props: any) => props.theme.colors.white};
    border: none;
    padding: 8px 21px;
    border-radius: 4px;
    text-align: center;
    font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
    font-size: ${(props: any) => props.theme.fontSizes.normal};
    cursor: pointer;
    min-height: 40px;
    min-width: 100px;
    vertical-align: top;

    &.btn-border {
        background-color: ${(props: any) => props.theme.colors.white};
        color: ${(props: any) => props.theme.colors.ButtonColor};
        border: 1px solid ${(props: any) => props.theme.colors.ButtonColor};
    }

    &:disabled {
        background-color: ${(props: any) => props.theme.colors.smokeWhite};
        color: ${(props: any) => props.theme.colors.grey4};
        cursor: not-allowed;
    }

    &.terminate-sub-btn {
        width: 232px;
    }

    &.reset-link-btn {
        width: 162px;
    }

    &.brown-color {
        background-color: ${(props: any) => props.theme.colors.userTextColor};

        &:disabled {
            color: ${(props: any) => props.theme.colors.white} !important;
        }
    }
    &.btn-transparent {
        border: 1px solid ${(props: any) => props.theme.colors.ButtonColor};
        background: transparent;
        color: ${(props: any) => props.theme.colors.ButtonColor};
        @media screen and (max-width: 991px) {
            padding: 0 28px;
        }
    }
    &.disable-btn {
        background-color: ${(props: any) => props.theme.colors.grey10};
        color: ${(props: any) => props.theme.colors.grey82};
        cursor: not-allowed !important;
        pointer-events: none;
    }

    &.save-draft-btn {
        max-width: 145px;
        width: 100%;
    }
`;

export default Button;
