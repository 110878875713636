import styled from 'styled-components';

const HeaderStyle = styled.div`
    position: fixed;
    width: -webkit-fill-available;
    width: 100%;
    top: 0px;
    z-index: 9 !important;
    background-color: white;

    .img-arrow {
        width: 15px;
        transition: transform 0.3s ease;
    }

    .img-rotate {
        transform: rotate(180deg);
    }

    .header-style {
        display: flex;
        align-items: center;
        box-shadow: 2px 4px 20px
            ${(props: any) => props.theme.shadows.commonShadow};
        padding: 10px 33px;
        min-height: 70px;
        @media screen and (max-width: 991px) {
            padding: 10px 73px 10px 20px;
        }

        @media screen and (max-width: 477px) {
            padding: 10px 61px 10px 9px;
        }

        .header-right {
            display: flex;
            justify-content: end;
            align-items: center;
            width: 100%;
        }

        .proximity-logo {
            background-color: ${(props: any) => props.theme.colors.white};
            padding: 5px 10px;

            @media screen and (max-width: 378px) {
                max-width: 150px;
                img {
                    width: 135px;
                }
            }

            @media screen and (max-width: 361px) {
                padding: 10px 5px;
                max-width: 114px;
                img {
                    width: 100px;
                }
            }
        }

        .user-avatar {
            display: inline-block;
            width: 32px;
            height: 32px;
            background-color: ${(props: any) => props.theme.colors.iconBg};
            border-radius: 50%;
            text-align: center;
            vertical-align: middle;
            font-size: ${(props: any) => props.theme.fontSizes.extrasmall};
            font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
            color: ${(props: any) => props.theme.colors.userTextColor};
            padding-top: 9px;
            margin: 0px 10px 0 15px;
        }

        .user-name {
            font-size: ${(props: any) => props.theme.fontSizes.medium};
            color: ${(props: any) => props.theme.colors.secondaryColor};
        }
        .sub-admin {
            font-size: ${(props: any) => props.theme.fontSizes.small};
            color: ${(props: any) => props.theme.colors.grey3};
        }

        .logout {
            &.show-on-hover {
                position: absolute;
                right: 26px;
                top: 90px;
                transform: translate3d(0, -50%, 0);
                box-shadow: 0px 3px 6px
                    ${(props: any) => props.theme.colors.grey2};
                background-color: ${(props: any) => props.theme.colors.white};
                width: 155px;
                border: 1px solid #e6e7e8;
                z-index: 1;

                @media screen and (max-width: 991px) {
                    right: 78px;
                    top: 104px;
                    z-index: 99;
                }

                @media screen and (max-width: 768px) {
                    z-index: 99;
                }

                &:hover {
                    display: block;
                }

                &::before {
                    content: '';
                    position: absolute;
                    right: 10%;
                    top: -33px;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-bottom: 17px solid
                        ${(props: any) => props.theme.colors.white};
                    border-top: 17px solid transparent;
                    border-right: 10px solid transparent;
                    filter: drop-shadow(
                        0 -2px 1px ${(props: any) => props.theme.colors.grey2}
                    );
                    @media screen and (max-width: 768px) {
                        right: 9%;
                    }
                }
            }

            ul {
                list-style: none;
                padding: 5px 0px;

                li {
                    padding: 9px 25px;
                    margin: 5px 0px;
                    border-left: 4px solid transparent;
                    position: relative;
                    text-align: center;
                    color: ${(props: any) => props.theme.colors.secondaryColor};

                    &:hover {
                        background-color: ${(props: any) =>
                            props.theme.colors.primaryColor};
                        color: ${(props: any) =>
                            props.theme.colors.secondaryColor};

                        &::before {
                            content: '';
                            height: 30px;
                            width: 3px;
                            display: inline-block;
                            position: absolute;
                            left: -3px;
                            top: 7px;
                            border-radius: 20%;
                            border-left: 4px solid
                                ${(props: any) =>
                                    props.theme.colors.statusColor};
                        }
                    }

                    .logout-img {
                        vertical-align: middle;
                        padding-right: 10px;
                    }
                }
            }
        }
    }
`;

export default HeaderStyle;
