import { FC } from 'react';

import { ContactDetailOption } from '../../types/type';

const InputWithoutAdd: FC<ContactDetailOption> = ({ label, value }) => {
    return (
        <div className="col-lg-4 py-2">
            <p className="details-title">{label}</p>
            <p className="details-info">{value}</p>
        </div>
    );
};

export default InputWithoutAdd;
