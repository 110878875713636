import React from 'react';

const NoRecords = () => {
    return (
        <div className="d-flex flex-row justify-content-center mt-2">
            <h4>No Records found</h4>
        </div>
    );
};

export default NoRecords;
