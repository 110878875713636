import { styled } from 'styled-components';

const ContactStyle = styled.div`
    .contact-filter-left {
        float: left;
        width: 43%;

        @media screen and (max-width: 1273px) {
            width: 35%;
        }

        @media screen and (max-width: 1190px) {
            width: 100%;
        }

        .contact-filter {
            li {
                list-style: none;
                display: inline-block;
                padding-right: 20px;

                @media screen and (max-width: 436px) {
                    display: block;
                    padding: 2px 0px;
                }

                &.input-search {
                    width: 100%;
                    max-width: 300px;

                    @media screen and (max-width: 1273px) {
                        max-width: 200px;
                    }

                    @media screen and (max-width: 1190px) {
                        max-width: 300px;
                    }
                }
            }
        }

        .filter-link {
            color: ${(props: any) => props.theme.colors.primaryColor};
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .contact-btn-right {
        float: right;
        width: 57%;
        display: flex;
        justify-content: end;

        @media screen and (max-width: 1273px) {
            width: 64%;
        }

        @media screen and (max-width: 1190px) {
            width: 100%;
            float: left;
            width: 100%;
            justify-content: start;
            padding: 20px 0px;
        }

        @media screen and (max-width: 573px) {
            display: block;

            .multiple-select {
                margin: 10px 0px;
            }
        }
    }

    .contact-details-user {
        display: flex;

        .edit-icon {
            padding-top: 10px;
            padding-left: 20px;
            @media screen and (max-width: 783px) {
                display: flex;
            }
        }
    }

    .cross-icon-black {
        display: flex;
        justify-content: end;
        align-items: center;
        margin-top: 16px;
    }

    .contact-details-header {
        font-size: ${(props: any) => props.theme.fontSizes.larger};
        font-family: ${(props: any) => props.theme.fonts.FontFamily};
    }

    .primary-horizontal-line {
        background-color: ${(props: any) => props.theme.colors.primaryColor};
        height: 1px;
        width: 100%;
    }

    .details-title {
        font-family: ${(props: any) => props.theme.fonts.FontFamily};
        font-size: ${(props: any) => props.theme.fontSizes.medium};
    }

    .details-info {
        font-family: ${(props: any) => props.theme.fonts.interregular};
        font-size: ${(props: any) => props.theme.fontSizes.medium};
        width: 220px;
    }

    .show-more {
        color: ${(props: any) => props.theme.colors.primaryColor};
        font-size: ${(props: any) => props.theme.fontSizes.extrasmall};
        cursor: pointer;
    }

    .show-more-info {
        display: flex;
    }

    .add {
        color: ${(props: any) => props.theme.colors.primaryColor};
        font-size: ${(props: any) => props.theme.fontSizes.small};
        cursor: pointer;
    }

    .option-brackets {
        color: ${(props: any) => props.theme.colors.grey3};
        font-size: ${(props: any) => props.theme.fontSizes.small};
        font-family: ${(props: any) => props.theme.fonts.interregular};
    }
`;

export const LinkSearch = styled.a`
    color: ${(props: any) => props.theme.colors.primaryColor};
    font-size: ${(props: any) => props.theme.fontSizes.normal};
    padding-top: 0px;
    text-decoration: underline;
    cursor: pointer;
`;

export const UndoFile = styled.div`
    cursor: pointer;
    margin-top: 10px;
    span {
        vertical-align: top;
        font-size: ${(props: any) => props.theme.fontSizes.medium};
        &.grey-text {
            color: ${(props: any) => props.theme.colors.grey3};
        }
    }
`;

export default ContactStyle;
