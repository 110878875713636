import { FC } from 'react';

export const UpArrow: FC<{ color: string }> = ({ color }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5.075"
            height="8.119"
            viewBox="0 0 5.075 8.119"
        >
            <path
                id="Path_731"
                data-name="Path 731"
                d="M218.261,2.18,216.231.15h0q-.018-.018-.037-.033L216.176.1l-.022-.016-.022-.013-.02-.012L216.088.05l-.022-.01-.023-.008-.024-.008L216,.017l-.025-.006-.027,0-.022,0a.511.511,0,0,0-.1,0l-.022,0-.027,0-.025.006-.023.006L215.7.032l-.023.008-.022.01-.023.011-.02.012-.022.013L215.569.1l-.018.014c-.013.011-.025.022-.037.033h0l-2.03,2.03a.507.507,0,0,0,.718.718l1.164-1.164V7.613a.507.507,0,0,0,1.015,0V1.734L217.543,2.9a.507.507,0,1,0,.718-.718Z"
                transform="translate(-213.335 -0.001)"
                fill={color}
            />
        </svg>
    );
};

export const DownArrow: FC<{ color: string }> = ({ color }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5.075"
            height="8.119"
            viewBox="0 0 5.075 8.119"
        >
            <path
                id="Path_732"
                data-name="Path 732"
                d="M89.542,175.89l-1.164,1.164v-5.879a.507.507,0,1,0-1.015,0v5.879L86.2,175.89a.507.507,0,1,0-.718.718l2.03,2.03h0c.012.012.024.023.037.033l.018.014.022.016.022.013.02.012.023.011.022.01.023.008.024.008.023.006.025.006.027,0,.022,0a.509.509,0,0,0,.1,0l.022,0,.027,0L88,178.77l.023-.006.024-.008.023-.008.022-.01.023-.011.02-.012.022-.013.022-.016.018-.014q.019-.016.037-.033h0l2.03-2.03a.507.507,0,1,0-.718-.718Z"
                transform="translate(-85.334 -170.667)"
                fill={color}
            />
        </svg>
    );
};
