import { FC, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import SideBarStyle from './SidebarStyle';
import { menu } from '../constants';

const SideBar: FC = () => {
    const { pathname } = useLocation();

    const [isShowCross, setIsShowCross] = useState<boolean>(false);

    const handleHamburgerChange = () => {
        setIsShowCross(!isShowCross);
    };

    const ThemeData =
        localStorage.getItem('theme') &&
        JSON.parse(localStorage.getItem('theme') || '');

    return (
        <SideBarStyle>
            <div className="main-side-bar">
                <div
                    className={
                        isShowCross ? 'mainmenuicon openmenud' : 'mainmenuicon'
                    }
                    role="presentation"
                    onClick={handleHamburgerChange}
                >
                    <div className="bar1">&nbsp;</div>
                    <div className="bar2">&nbsp;</div>
                    <div className="bar3">&nbsp;</div>
                </div>

                <div className={isShowCross ? 'sidemenu d-block ' : 'sidemenu'}>
                    <div className="proximity-logo">
                        <img
                            src={ThemeData?.headerLogo}
                            alt="logo"
                            width="120px"
                        />
                    </div>
                    <ul className="sidebar-menu">
                        {menu.map(({ id, label, img, link }) => {
                            return (
                                <li className="sidebar-list" key={id}>
                                    <Link
                                        to={link}
                                        onClick={handleHamburgerChange}
                                    >
                                        <div
                                            className={
                                                pathname.includes(link)
                                                    ? 'list-link active'
                                                    : 'list-link'
                                            }
                                        >
                                            <img
                                                src={img}
                                                alt={label}
                                                className="sidebar-img"
                                            />
                                            <span>{label}</span>
                                        </div>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </SideBarStyle>
    );
};

export default SideBar;
