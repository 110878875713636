import {
    useState,
    useEffect,
    useCallback,
    Dispatch,
    SetStateAction,
} from 'react';

import dayjs from 'dayjs';
import Modal from 'react-modal';
import copy from 'copy-to-clipboard';
import DatePicker from 'react-date-picker';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '../../common/Loader';
import PageNav from '../../common/NavLink';
import WhiteCard from '../../common/WhiteCard';
import ToggleSwitch from '../../common/ToggleSwitch';
import { ModalOverlay } from '../../Theme/style/ModalStyle';
import {
    DashboardCandidateHistoryType,
    DashboardCandidatePermissionType,
} from '../../types/type';
import {
    copyFormLink,
    displayNumberFormat,
    getInitialName,
    phoneNoformat,
} from '../../common/CommonMethods';
import {
    PATH_DASHBOARD,
    PATH_EDIT_PLAN_MANAGEMENT,
    candidateBasicData,
    graceOptions,
    resetMsg,
    subscriptionHeaderOptions,
    terminateMsg,
} from '../../constants';
import {
    Button,
    DatePickerCalendar,
    InputSearch,
    TableBody,
    TextArea,
    handleError,
    validateEmail,
} from '../../common';
import {
    getCandidateDetails,
    terminateCandidate,
    updateCandidateStatus,
    resetCandidatePassword,
    updateCandidatePermission,
    saveGracePeriod,
    saveComplementaryPeriod,
    aliasLogin,
    setCancelSubscriptionAPI,
} from '../../api';
import {
    ArrowRight,
    EmailAction,
    HierarchyOrganization,
    HomeLocation,
    PhoneMobile,
    SearchEdit,
    ReportImg,
    DasboardInfoImg,
} from '../../assets/images';

const customStylesContent = {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '400px ',
    width: '100% ',
    overlay: ' {zIndex: 1000}',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '30px',
    height: '100%',
};

const zeroToNine = [
    'Digit1',
    'Digit2',
    'Digit3',
    'Digit4',
    'Digit5',
    'Digit6',
    'Digit7',
    'Digit8',
    'Digit9',
    'Digit0',
];

const zeroToNineNumPad = [
    'Numpad1',
    'Numpad2',
    'Numpad3',
    'Numpad4',
    'Numpad5',
    'Numpad6',
    'Numpad7',
    'Numpad8',
    'Numpad9',
    'Numpad0',
];

const preventedCharCode = [
    'Minus',
    'NumpadSubtract',
    'Period',
    'NumpadDecimal',
    'NumpadAdd',
    'Equal',
];

const CandidateDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState<any>({ pageLoader: true });
    const [candidateDetails, setCandidateDetails] = useState<any>({});
    const [selectedEmail, setSelectedEmail] = useState<any>('');
    const [editEmail, setEditEmail] = useState(false);
    const [terminateReason, setTerminateReason] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [graceDays, setGraceDays] = useState<any>({});
    const [showComplementaryModal, setShowComplementaryModal] = useState(false);
    const [confirmProxy, setConfirmProxy] = useState<boolean>(false);
    const [isCanceled, setIsCanceled] = useState<boolean>(false);

    const getModalHeight = () => {
        if (showModal) return '360px';
        if (confirmProxy) return '158px';
        return '325px';
    };
    const customStyles = {
        overlay: {
            background: 'rgba(0, 0, 0, 0.6)',
            zIndex: 99,
        },
        content: {
            ...customStylesContent,
            maxHeight: getModalHeight(),
        },
    };

    const getcandidateData = useCallback(() => {
        if (id) {
            setIsLoading({ pageLoader: true });
            getCandidateDetails(id)
                .then((res) => {
                    setCandidateDetails({
                        details: res?.data?.superCandidateDetailsList?.[0],
                        historyDetails:
                            res?.data?.superSubscriptionHistoryDetailsList,
                        permissionDetails: res?.data?.superUserPermissionsList,
                        trialDetails: res?.data?.superusertrialdetails,
                    });
                    setSelectedEmail(
                        res?.data?.superCandidateDetailsList?.[0]?.emailId
                    );
                })
                .catch((err) => handleError(err))
                .finally(() => setIsLoading({ pageLoader: false }));
        }
    }, [id]);

    useEffect(() => {
        if (candidateDetails?.historyDetails?.length) {
            const canceledData = candidateDetails?.historyDetails?.filter(
                (item: any) => item.planAddOns === 'Cancellation Request'
            );
            if (canceledData?.length) {
                setIsCanceled(
                    new Date(canceledData?.[0]?.startDate) <= new Date()
                );
            }
        }
    }, [candidateDetails?.historyDetails]);

    useEffect(() => {
        getcandidateData();
    }, [getcandidateData]);

    const updateStatus = (status: string) => {
        setIsLoading({ statusLoader: true });
        updateCandidateStatus({
            candidateId: Number(id),
            status,
        })
            .then((res: any) => {
                toast.success(res?.data);
                setCandidateDetails({
                    ...candidateDetails,
                    details: {
                        ...candidateDetails?.details,
                        isActive: status === 'Active',
                    },
                });
                getcandidateData();
            })
            .catch((err) => handleError(err))
            .finally(() => setIsLoading({ statusLoader: false }));
    };

    const resetPasswordLink = () => {
        setIsLoading({ resetBtn: true });
        resetCandidatePassword({
            candidateEmail: candidateDetails?.details?.emailId,
            receivingEmail: selectedEmail,
        })
            .then((res: any) => {
                toast.success(res?.data);
            })
            .catch((err) => handleError(err))
            .finally(() => setIsLoading({ resetBtn: false }));
    };

    const candidateTermination = () => {
        setIsLoading({ terminateBtn: true });
        terminateCandidate({
            candidateId: Number(id),
            status: terminateReason,
        })
            .then((res: any) => {
                toast.success(res?.data);
                getcandidateData();
            })
            .catch((err) => handleError(err))
            .finally(() => setIsLoading({ terminateBtn: false }));
    };

    const handleUserPermission = (item: DashboardCandidatePermissionType) => {
        setIsLoading({ permissionToggle: true });
        updateCandidatePermission({
            candidateId: Number(id),
            permissionsId: item?.id,
            isPermissions: !item?.isactive,
        })
            .then((res: any) => {
                toast.success(res?.data);
                // update the toggle data too if successful
                const initialPermission = candidateDetails?.permissionDetails;
                const updatedArray = initialPermission?.map(
                    (op: DashboardCandidatePermissionType) => {
                        if (op?.id === item?.id && op?.name === item?.name) {
                            return { ...item, isactive: !item?.isactive };
                        }
                        return op;
                    }
                );
                setCandidateDetails({
                    ...candidateDetails,
                    permissionDetails: updatedArray,
                });
            })
            .catch((err) => handleError(err))
            .finally(() => setIsLoading({ permissionToggle: false }));
    };

    const getStatusToggle = (status: boolean) => {
        if (isLoading.statusLoader) return 'disabled-btn';
        if (status) return 'active disabled-btn';
        return '';
    };

    const getBgColor = (color: string) => {
        switch (color) {
            case 'Enterprise':
                return 'enterprise-bg';
            case 'Advanced':
                return 'advanced-bg';
            default:
                return '';
        }
    };

    const getCleanDate = (val: Date | string) => {
        const dateStr = new Date(val);
        const offset = dateStr?.getTimezoneOffset();
        const updatedDate = new Date(dateStr.getTime() - offset * 60 * 1000);
        const cleanDate = updatedDate?.toISOString().split('T')[0];
        return dayjs(cleanDate).format('MM-DD-YYYY');
    };

    const saveGraceDays = () => {
        const apiCallName: any = showModal
            ? saveGracePeriod
            : saveComplementaryPeriod;
        const details = showModal
            ? {
                  candidateId: Number(id),
                  noofDays: graceDays?.day,
                  startDate:
                      graceDays?.startDate &&
                      getCleanDate(graceDays?.startDate),
              }
            : {
                  candidateId: Number(id),
                  updatedDate:
                      graceDays?.endDate && getCleanDate(graceDays?.endDate),
                  email: candidateDetails?.details?.emailId,
              };
        setIsLoading({ graceLoader: true });
        apiCallName(details)
            .then((res: any) => {
                toast.success(res?.data);
                setShowModal(false);
                setShowComplementaryModal(false);
                getcandidateData();
                setGraceDays({});
            })
            .catch((error: any) => handleError(error))
            .finally(() => setIsLoading({ graceLoader: false }));
    };

    const getGracevalues = (key: string, item: any) => {
        switch (key) {
            case 'startDate':
                return dayjs(item?.[key]).format('MMM DD, YYYY');
            case 'endDate': {
                const startDate = new Date(item?.dates);
                const numberOfDaysToAdd = Number(
                    item?.planAddOns?.split(' ')?.[0]
                );
                return dayjs(startDate)
                    .add(numberOfDaysToAdd, 'day')
                    .format('MMM DD, YYYY');
            }
            case 'grace':
                return item?.planAddOns?.split(' ')?.[0];
            default:
                return item?.[key];
        }
    };

    const getTrialData = (type: string, value: any) => {
        return (
            <div className="col-12">
                <p className="grace-period trial">
                    This candidate is under {type} period until{' '}
                    {dayjs(value).format('MMMM DD, YYYY')}.
                </p>
            </div>
        );
    };

    const disableSave = () => {
        if (showModal) {
            if (!graceDays?.startDate || !graceDays?.day) return true;
        }
        if (showComplementaryModal && !graceDays?.endDate) return true;
        return false;
    };

    const handleEffectiveDate = (dateString: any) => {
        const dateObject = new Date(dateString);
        const options = {
            year: 'numeric' as const,
            month: 'long' as const,
            day: 'numeric' as const,
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
            dateObject
        );

        return formattedDate;
    };

    const getReceiptHTML = (item: any) => {
        if (
            item?.planAddOns?.toLowerCase()?.includes('grace') ||
            item?.planAddOns?.toLowerCase()?.includes('complementary')
        ) {
            return (
                <div className="report-img">
                    <img
                        src={DasboardInfoImg}
                        alt="info icon"
                        data-for="grace-info"
                        data-tip=""
                        className="cursor position-relative"
                        role="presentation"
                    />
                    <div className="show-on-info">
                        <div className="row">
                            {graceOptions(
                                item?.planAddOns
                                    ?.toLowerCase()
                                    ?.includes('grace')
                                    ? 'Grace'
                                    : 'Complementary'
                            ).map((option: any) => (
                                <>
                                    <div
                                        className="col-md-7"
                                        key={option?.name}
                                    >
                                        <p className="transaction-date">
                                            {option?.name}
                                        </p>
                                    </div>
                                    <div className="col-md-5">
                                        <p className="transaction-date-rightside">
                                            {getGracevalues(option?.key, item)}
                                        </p>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            );
        }

        if (item?.planAddOns?.toLowerCase()?.includes('cancellation')) {
            return (
                <div className="report-img">
                    <img
                        src={DasboardInfoImg}
                        alt="info icon"
                        data-for="cancel-info"
                        data-tip=""
                        className="cursor"
                        role="presentation"
                    />
                    <div className="show-on-info">
                        <div className="row">
                            <div className="col-md-7">
                                <p className="transaction-date">
                                    Cancellation Date:
                                </p>
                            </div>
                            <div className="col-md-5">
                                <p className="transaction-date-rightside">
                                    {item?.dates}
                                </p>
                            </div>
                            <div className="col-md-7">
                                <p className="transaction-date">
                                    Effective Cancellation Date:
                                </p>
                            </div>
                            <div className="col-md-5">
                                <p className="transaction-date-rightside">
                                    {handleEffectiveDate(item?.startDate)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (item?.planAddOns?.toLowerCase()?.includes('trial')) return '-';
        return (
            <img
                src={ReportImg}
                alt="report-img"
                className=" cursor"
                onClick={() => window.open(item?.receiptLink, '_blank')}
                role="presentation"
            />
        );
    };

    const handleCopyButtons = (path: string) => {
        let isContentCopied: boolean = false;
        const { firstName, lastName, userId } = candidateDetails.details;
        isContentCopied = copy(
            copyFormLink(userId, path, `${firstName}-${lastName}`)
        );
        if (isContentCopied) {
            toast.success('Link copied!');
        } else {
            toast.error('Error while copy the text!');
        }
    };

    const handleGraceText = (
        type: string,
        funCustom: Dispatch<SetStateAction<boolean>>
    ) => {
        return (
            <div className="col-12">
                <p
                    className="grace-period"
                    onClick={() => {
                        funCustom(true);
                    }}
                    role="presentation"
                >
                    {type === 'grace'
                        ? 'Increase Grace Period'
                        : ' Increase Complementary Period'}
                </p>
            </div>
        );
    };

    const handleGracePeriodDetails = () => {
        return (
            candidateDetails?.details?.isActive &&
            !candidateDetails?.details?.isTerminated &&
            !candidateDetails?.trialDetails?.[0]?.status &&
            candidateDetails?.details?.isGracePeriodVisible &&
            handleGraceText('grace', setShowModal)
        );
    };

    const handleComplementaryGracePeriodDetails = () => {
        return (
            candidateDetails?.details?.isActive &&
            !candidateDetails?.details?.isTerminated &&
            candidateDetails?.details?.nextBilling &&
            !candidateDetails?.trialDetails?.[0]?.complimentoryStatus &&
            candidateDetails?.details?.isSubscriptionActive &&
            handleGraceText('complementary', setShowComplementaryModal)
        );
    };

    const renderPageNav = () => {
        return (
            <PageNav className="mb-3">
                <ul className="nav-list">
                    <li
                        className="active-nav cursor"
                        onClick={() => navigate(PATH_DASHBOARD)}
                        role="presentation"
                    >
                        Dashboard
                        <img className="ml-1" src={ArrowRight} alt="arrow" />
                    </li>
                    <li>Candidate details</li>
                </ul>
            </PageNav>
        );
    };

    const renderUserDetails = () => {
        return (
            <div className="row mt-5">
                <div className="col-md-4">
                    <div className="user-profile-details">
                        <div className="user-avatar mb-3 ml-2">
                            {getInitialName(
                                candidateDetails?.details?.firstName,
                                candidateDetails?.details?.lastName
                            )}
                        </div>
                        <div className=" card-header small-header ml-3">
                            {candidateDetails?.details?.firstName}{' '}
                            {candidateDetails?.details?.lastName}
                        </div>
                        <p className="grey-text mt-2">
                            {' '}
                            <img
                                className="middle-align"
                                src={HomeLocation}
                                alt="location"
                            />{' '}
                            {candidateDetails?.details?.city},{' '}
                            {candidateDetails?.details?.state}
                        </p>
                    </div>
                </div>
                <div className="col-md-8">
                    <ul className="contacts-details">
                        <li>
                            {' '}
                            <img src={EmailAction} alt="mail" />{' '}
                            {candidateDetails?.details?.emailId}
                        </li>
                        <li>
                            {' '}
                            <img src={PhoneMobile} alt="call" />
                            {phoneNoformat(
                                candidateDetails?.details?.contactNo
                            )}
                        </li>
                        <li>
                            {' '}
                            <img
                                className="organization-icon"
                                src={HierarchyOrganization}
                                alt="organization"
                            />
                            Multiusers -
                            <span>
                                {' '}
                                {candidateDetails?.details?.multiUsers.includes(
                                    '1 / -1'
                                )
                                    ? 'Unlimited'
                                    : candidateDetails?.details?.multiUsers}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    const renderToggleSwitch = () => {
        return (
            <ul className="data">
                {candidateDetails?.details?.isTerminated ? (
                    <li role="presentation" className="terminate">
                        Terminated
                    </li>
                ) : (
                    <>
                        <li
                            className={getStatusToggle(
                                candidateDetails?.details?.isActive
                            )}
                            onClick={() =>
                                isLoading.statusLoader ||
                                candidateDetails?.details?.isActive
                                    ? null
                                    : updateStatus('Active')
                            }
                            role="presentation"
                        >
                            Active
                        </li>
                        <li
                            className={getStatusToggle(
                                !candidateDetails?.details?.isActive
                            )}
                            onClick={() =>
                                isLoading.statusLoader ||
                                !candidateDetails?.details?.isActive
                                    ? null
                                    : updateStatus('Hold')
                            }
                            role="presentation"
                        >
                            Data Hold
                        </li>
                    </>
                )}
            </ul>
        );
    };

    const renderSubscriptionDetails = () => {
        return (
            <div className="additional-contacts">
                <div className="row">
                    <div className="col-5 px-0">
                        <div
                            className={`${getBgColor(
                                candidateDetails?.details?.plan
                            )} subscription-details fix-height`}
                        >
                            Subscription Details
                            <div className="plan-list">
                                {' '}
                                <span /> Plan: {candidateDetails?.details?.plan}
                            </div>
                        </div>
                    </div>
                    <div className="col-5 px-0">
                        {' '}
                        <div
                            className={`${getBgColor(
                                candidateDetails?.details?.plan
                            )} next-billing fix-height`}
                        >
                            Next Billing
                            <div className="mt-2 mb-1 pt-1">
                                {candidateDetails?.details?.nextBilling ? (
                                    <>
                                        {displayNumberFormat(
                                            Number(
                                                candidateDetails?.details
                                                    ?.planAmount
                                            ) +
                                                Number(
                                                    candidateDetails?.details
                                                        ?.addOnAmount
                                                ),
                                            '$',
                                            ''
                                        )}{' '}
                                        @{' '}
                                        {candidateDetails?.details?.nextBilling}
                                    </>
                                ) : (
                                    '-'
                                )}
                            </div>
                            {candidateDetails?.details?.addOnAmount !== '0' ? (
                                <div className="amount-details">
                                    {' '}
                                    <span className="amount">
                                        {' '}
                                        {displayNumberFormat(
                                            candidateDetails?.details
                                                ?.planAmount,
                                            '$',
                                            ''
                                        )}
                                        +
                                        {displayNumberFormat(
                                            candidateDetails?.details
                                                ?.addOnAmount,
                                            '$',
                                            ''
                                        )}{' '}
                                    </span>
                                    (Additional Contacts){' '}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderStatusCodeList = () => {
        return (
            <ul className="status-color-block">
                {candidateBasicData?.map((item) => {
                    const value = candidateDetails?.details?.[item.key];
                    return (
                        <li className={item.property} key={item.key}>
                            {item.name}{' '}
                            {item.key === 'racetype'
                                ? value
                                : displayNumberFormat(value, item.fee, '')}
                        </li>
                    );
                })}
            </ul>
        );
    };

    const renderSubscriptionLink = () => {
        const canceledHistoryArray = candidateDetails?.historyDetails?.filter(
            (item: any) => item?.planAddOns === 'Cancellation Request'
        );

        return (
            <div className="row">
                <div className="col-lg-6 py-2 mt-4">
                    <InputSearch>
                        <input
                            type="text"
                            className="form-control"
                            defaultValue={selectedEmail}
                            readOnly={!editEmail}
                            disabled={
                                candidateDetails?.details?.isTerminated ||
                                !candidateDetails?.details?.nextBilling ||
                                (canceledHistoryArray?.length &&
                                    !(
                                        new Date(
                                            canceledHistoryArray[0]?.startDate
                                        ) >= new Date()
                                    ))
                            }
                            onChange={(event) =>
                                setSelectedEmail(event?.target.value)
                            }
                        />
                        {(!candidateDetails?.details?.isTerminated ||
                            candidateDetails?.details?.nextBilling) && (
                            <img
                                onClick={() => setEditEmail(true)}
                                role="presentation"
                                src={SearchEdit}
                                alt="search-img"
                                className="search-icon cursor"
                            />
                        )}
                    </InputSearch>
                    {validateEmail(selectedEmail) ? (
                        ''
                    ) : (
                        <span className="pt-2 error-message">
                            Invalid Email
                        </span>
                    )}
                </div>
                <div className="col-lg-6 py-2 mt-4">
                    <Button
                        onClick={() => resetPasswordLink()}
                        disabled={
                            !validateEmail(selectedEmail) ||
                            candidateDetails?.details?.isTerminated ||
                            !candidateDetails?.details?.nextBilling ||
                            (canceledHistoryArray?.length &&
                                !(
                                    new Date(
                                        canceledHistoryArray[0]?.startDate
                                    ) >= new Date()
                                ))
                        }
                        className={
                            !validateEmail(selectedEmail)
                                ? 'disabled-btn'
                                : 'reset-link-btn'
                        }
                    >
                        {isLoading.resetBtn ? (
                            <Loader type="button" />
                        ) : (
                            'Send reset link'
                        )}
                    </Button>
                </div>

                {handleGracePeriodDetails()}
                {handleComplementaryGracePeriodDetails()}
                <div className="col-lg-12 copy-btn mt-4">
                    <div>
                        <Button
                            onClick={() => {
                                handleCopyButtons('web-volunteer-form');
                            }}
                            disabled={
                                candidateDetails?.details?.isTerminated ||
                                (canceledHistoryArray?.length &&
                                    !(
                                        new Date(
                                            canceledHistoryArray[0]?.startDate
                                        ) >= new Date()
                                    ))
                            }
                        >
                            Copy Web Volunteer Form Link
                        </Button>
                    </div>
                    <div className="copy-link-btn mb-2">
                        <Button
                            onClick={() => {
                                handleCopyButtons('payment-donation/link');
                            }}
                            disabled={
                                !candidateDetails?.details?.isDonationAccept ||
                                candidateDetails?.details?.isTerminated ||
                                (canceledHistoryArray?.length &&
                                    !(
                                        new Date(
                                            canceledHistoryArray[0]?.startDate
                                        ) >= new Date()
                                    ))
                            }
                        >
                            Copy Contribution Page Link
                        </Button>
                    </div>
                    {candidateDetails?.details?.nextBilling?.length && (
                        <div className="btn-bown-sub mb-2">
                            <Button
                                className={`${
                                    !candidateDetails?.details
                                        ?.isSubscriptionActive ||
                                    candidateDetails?.details?.isTerminated
                                        ? 'disabled-btn'
                                        : 'terminate-sub-btn'
                                }`}
                                onClick={handleCancelSubscription}
                                disabled={
                                    !candidateDetails?.details
                                        ?.isSubscriptionActive ||
                                    candidateDetails?.details?.isTerminated
                                }
                            >
                                {isLoading?.cancelSubscription ? (
                                    <Loader type="button" />
                                ) : (
                                    'Cancel subscription'
                                )}
                            </Button>
                        </div>
                    )}
                </div>

                {candidateDetails?.trialDetails?.[0]?.status &&
                    getTrialData(
                        'trial',
                        candidateDetails?.trialDetails?.[0]?.endDate
                    )}
                {candidateDetails?.trialDetails?.[0]?.complimentoryStatus &&
                    getTrialData(
                        'complementary',
                        candidateDetails?.trialDetails?.[0]
                            ?.complimentoryEndDate
                    )}
            </div>
        );
    };

    const getTerminateCTAClass = () => {
        return !terminateReason ||
            terminateReason === '' ||
            candidateDetails?.details?.isTerminated ||
            !candidateDetails?.details?.nextBilling
            ? 'disabled-btn'
            : 'terminate-sub-btn';
    };

    const renderTerminateTextArea = () => {
        return (
            <div className="row">
                <div className="col-lg-6 py-2 mt-2">
                    <div className="character-count">
                        <TextArea
                            disabled={
                                candidateDetails?.details?.isTerminated ||
                                !candidateDetails?.details?.nextBilling
                            }
                            placeholder="Enter reason"
                            maxLength={250}
                            onChange={(event) =>
                                setTerminateReason(event.target.value)
                            }
                        />
                        <span>
                            {terminateReason?.length}
                            /250
                        </span>
                    </div>
                </div>
                <div className="col-lg-6 py-2 terminate-btn">
                    <Button
                        className={`brown-color ${getTerminateCTAClass()}`}
                        onClick={() => candidateTermination()}
                        disabled={!terminateReason || terminateReason === ''}
                    >
                        {isLoading?.terminateBtn ? (
                            <Loader type="button" />
                        ) : (
                            'Terminate subscription'
                        )}
                    </Button>
                </div>
            </div>
        );
    };

    const renderTableBody = () => {
        return (
            <TableBody className="mt-3">
                <thead className="grey-header">
                    <tr>
                        {subscriptionHeaderOptions?.map((header) => (
                            <th
                                key={header.label}
                                style={{
                                    width: header.width,
                                }}
                            >
                                {header.label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {candidateDetails?.historyDetails?.map(
                        (item: DashboardCandidateHistoryType) => (
                            <tr key={item?.id}>
                                <td>{item?.planAddOns}</td>
                                <td>{item?.dates}</td>
                                <td>
                                    {item?.planAddOns
                                        ?.toLowerCase()
                                        ?.includes('grace') ||
                                    item?.planAddOns
                                        ?.toLowerCase()
                                        ?.includes('trial') ||
                                    item?.planAddOns
                                        ?.toLowerCase()
                                        ?.includes('complementary') ||
                                    item?.planAddOns
                                        ?.toLowerCase()
                                        ?.includes('cancellation')
                                        ? '-'
                                        : displayNumberFormat(
                                              item?.amount,
                                              '$',
                                              ''
                                          )}
                                </td>
                                <td className="text-center">
                                    {getReceiptHTML(item)}
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </TableBody>
        );
    };

    const renderPermissionDetails = () => {
        return (
            <div className="min-height-card">
                {candidateDetails?.permissionDetails?.map(
                    (item: DashboardCandidatePermissionType) => (
                        <div
                            className="d-flex justify-content-between mb-3 pb-1"
                            key={item?.id}
                        >
                            <div className="card-header font-regular">
                                {item?.name}
                            </div>{' '}
                            <ToggleSwitch
                                className={
                                    isLoading?.permissionToggle ||
                                    candidateDetails?.details?.isTerminated ||
                                    !candidateDetails?.details?.nextBilling
                                        ? 'disabled-toggle'
                                        : ''
                                }
                            >
                                <input
                                    type="checkbox"
                                    name={item?.name}
                                    onChange={() => handleUserPermission(item)}
                                    checked={item?.isactive}
                                    disabled={isLoading?.permissionToggle}
                                />
                                <span className="slider round" />
                            </ToggleSwitch>
                        </div>
                    )
                )}
            </div>
        );
    };

    const getAliasData = () => {
        setIsLoading({ aliasLoader: true });
        setConfirmProxy(false);
        aliasLogin(
            candidateDetails?.details?.emailId,
            localStorage.getItem('token') || ''
        )
            .then((res: any) => {
                window.open(
                    `${
                        process.env.REACT_APP_PROXIMITY_BASE_URL
                    }/login?key=${localStorage.getItem('token')}&user=${res
                        ?.data?.email}`,
                    '_blank'
                );
            })
            .catch((error) => handleError(error))
            .finally(() => setIsLoading({ aliasLoader: false }));
    };

    const generateAliasHTML = () => {
        const { firstName, lastName } = candidateDetails.details;
        return (
            <div className="text-center">
                <p className="mb-3">
                    Are you sure you want to adopt the persona of {firstName}{' '}
                    {lastName}&apos;s profile?
                </p>
                <Button
                    onClick={() => getAliasData()}
                    disabled={isLoading.aliasLoader}
                >
                    {isLoading.aliasLoader ? <Loader type="button" /> : 'Yes'}
                </Button>
                <Button
                    className="btn-transparent ml-5"
                    onClick={() => setConfirmProxy(false)}
                >
                    No
                </Button>
            </div>
        );
    };

    const getDateValue = () => {
        return showComplementaryModal
            ? graceDays?.endDate ||
                  candidateDetails?.trialDetails?.[0]?.complimentoryEndDate
            : graceDays?.startDate;
    };

    const handleCancelButton = () => {
        setShowModal(false);
        setGraceDays({});
        setShowComplementaryModal(false);
    };

    const minDateValidation = () => {
        if (
            showModal &&
            new Date(candidateDetails?.details?.nextBilling) < new Date()
        )
            return new Date();
        return new Date(candidateDetails?.details?.nextBilling);
    };

    const handleKeyDownEvent = (e: any) => {
        if (
            preventedCharCode.includes(e.code) ||
            (e.target.value.toString().length > 3 &&
                (zeroToNine.includes(e.code) ||
                    zeroToNineNumPad.includes(e.code)))
        )
            e.preventDefault();
    };

    const renderDetailsModal = () => {
        return (
            <Modal
                isOpen={showModal || showComplementaryModal || confirmProxy}
                style={customStyles}
            >
                <ModalOverlay>
                    {confirmProxy ? (
                        generateAliasHTML()
                    ) : (
                        <>
                            <div className="grace-modal">
                                Next Billing :{' '}
                                <span>
                                    {' '}
                                    {candidateDetails?.details?.nextBilling}
                                </span>
                            </div>

                            <DatePickerCalendar className="grace-date">
                                <p className="pb-1 mt-3">
                                    {showModal ? 'Start Date' : 'End Date'}
                                </p>
                                <DatePicker
                                    value={getDateValue()}
                                    className="mb-2"
                                    minDate={minDateValidation()}
                                    calendarType="US"
                                    format="MM/dd/y"
                                    dayPlaceholder="DD"
                                    monthPlaceholder="MM"
                                    yearPlaceholder="YYYY"
                                    onRateChange={(event) =>
                                        setGraceDays({
                                            ...graceDays,
                                            startDate: showModal ? event : null,
                                            endDate: showComplementaryModal
                                                ? event
                                                : null,
                                        })
                                    }
                                    clearIcon={null}
                                    onChange={(event) =>
                                        setGraceDays({
                                            ...graceDays,
                                            startDate: showModal ? event : null,
                                            endDate: showComplementaryModal
                                                ? event
                                                : null,
                                        })
                                    }
                                />
                            </DatePickerCalendar>
                            {showModal && (
                                <InputSearch>
                                    <p className="pb-1 mt-3">No. of days</p>
                                    <input
                                        type="number"
                                        className="form-control w-100"
                                        min={1}
                                        step={1}
                                        max={9999}
                                        maxLength={4}
                                        onKeyDown={handleKeyDownEvent}
                                        placeholder="Enter number"
                                        onChange={(event) => {
                                            setGraceDays({
                                                ...graceDays,
                                                day: Number(event.target.value),
                                            });
                                        }}
                                    />
                                </InputSearch>
                            )}
                            <div
                                className={`text-center pt-4 ${
                                    showModal ? 'mt-4' : 'mt-5'
                                }`}
                            >
                                <Button
                                    className="btn-border mr-2"
                                    onClick={handleCancelButton}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    disabled={disableSave()}
                                    onClick={() => saveGraceDays()}
                                >
                                    {isLoading?.graceLoader ? (
                                        <Loader type="button" />
                                    ) : (
                                        'Save'
                                    )}
                                </Button>
                            </div>
                        </>
                    )}
                </ModalOverlay>
            </Modal>
        );
    };

    const handleCancelSubscription = async () => {
        try {
            setIsLoading({ cancelSubscription: true });
            const res = await setCancelSubscriptionAPI({
                candidateId: Number(id),
                cancelDate: getCleanDate(new Date()),
                status: candidateDetails?.details?.isActive ? 'Active' : 'Hold',
            });
            toast.success(res?.data);
            getcandidateData();
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading({ cancelSubscription: false });
        }
    };

    return (
        <div className="body-container">
            {renderPageNav()}
            {isLoading.pageLoader ? (
                <Loader />
            ) : (
                <>
                    <WhiteCard>
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="card-header">User Details</div>
                                {renderUserDetails()}
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="row text-right">
                                    <div className="col-12 impersonate-btn">
                                        {candidateDetails?.details?.plan ===
                                            'Enterprise' && (
                                            <div className="mr-2 mb-2">
                                                <Button
                                                    className="btn-transparent text-left"
                                                    onClick={() =>
                                                        window.open(
                                                            `${PATH_EDIT_PLAN_MANAGEMENT}/${candidateDetails
                                                                ?.details
                                                                ?.planId}/${encodeURIComponent(
                                                                candidateDetails
                                                                    ?.details
                                                                    ?.planName
                                                            )}`,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    Go to plan
                                                </Button>
                                            </div>
                                        )}
                                        {!isCanceled && (
                                            <div className="mr-2 mb-2">
                                                <Button
                                                    className="text-left"
                                                    onClick={() =>
                                                        setConfirmProxy(true)
                                                    }
                                                    disabled={
                                                        !candidateDetails
                                                            ?.details
                                                            ?.isActive ||
                                                        candidateDetails
                                                            ?.details
                                                            ?.isTerminated
                                                    }
                                                >
                                                    Impersonate Profile
                                                </Button>
                                            </div>
                                        )}
                                        {!candidateDetails?.details
                                            ?.isSubscriptionActive && (
                                            <div className="mr-2">
                                                <Button className="canceled-subscription">
                                                    Cancelled
                                                </Button>
                                            </div>
                                        )}
                                        {candidateDetails?.details
                                            ?.isSubscriptionActive && (
                                            <div className="toggle-switch mb-5">
                                                {candidateDetails?.details
                                                    ?.nextBilling &&
                                                    renderToggleSwitch()}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {renderSubscriptionDetails()}
                            </div>
                        </div>
                        {renderStatusCodeList()}
                        <div className="row mt-3">
                            <div className="col-md-6 col-12 mb-3 ">
                                <div className="card-header">
                                    Reset Password
                                </div>
                                <p className="grey-long-text mt-2">
                                    {resetMsg}
                                </p>
                                {renderSubscriptionLink()}
                            </div>
                            <div className="col-md-6 col-12 ">
                                {' '}
                                <div className="card-header">
                                    Do you want to terminate the subscription?
                                </div>
                                <p className="grey-long-text mt-2">
                                    {terminateMsg}
                                </p>
                                {renderTerminateTextArea()}
                            </div>
                        </div>
                    </WhiteCard>
                    <div className="row mt-3">
                        <div className="col-md-6 col-12">
                            <WhiteCard className="mb-4">
                                <div className="card-header">
                                    Subscription History
                                </div>
                                <div className="table-responsive subscription-history">
                                    {renderTableBody()}
                                </div>
                            </WhiteCard>
                        </div>
                        <div className="col-md-6 col-12">
                            <WhiteCard className="mb-4">
                                {' '}
                                <div className="card-header mb-3">
                                    User Permissions
                                </div>
                                {renderPermissionDetails()}
                            </WhiteCard>
                        </div>
                    </div>
                </>
            )}
            {renderDetailsModal()}
        </div>
    );
};

export default CandidateDetails;
