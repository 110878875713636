import { Navigate, Outlet } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import Header from './Header';
import SideBar from './SideBar';
import removeLocalStorageData from './LocalData';
import { useAppDispatch, useAppSelector } from '../store/hook';
import { PATH_LOGIN } from '../constants';
import { removeAuthDetails } from '../features/authSlice';

const ProtectedRoutes = () => {
    const userDetails = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    useIdleTimer({
        crossTab: true,
        leaderElection: true,
        timeout: 10 * 60 * 1000,
        onIdle: () => {
            removeLocalStorageData();
            dispatch(removeAuthDetails());
        },
    });
    return userDetails.accessToken ? (
        <>
            <Header />
            <SideBar />
            <Outlet />
        </>
    ) : (
        <Navigate to={PATH_LOGIN} replace />
    );
};

export default ProtectedRoutes;
