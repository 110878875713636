import { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

import TableHeader from '../planManagement/TableHeader';
import Loader from '../../common/Loader';
import NoRecords from '../../common/NoRecords';
import SearchInput from '../../common/SearchInput';
import RcPagination from '../../common/RcPagination';
import MultiSelectDropdown from '../../common/MultiSelectDropdown';
import ContactChangeStyle from '../../Theme/style/ContactChangeStyle';
import { ModalOverlay } from '../../Theme/style/ModalStyle';
import { ContactLogType, SortType } from '../../types/type';
import {
    contactLogHeader,
    confirmOptions,
} from '../../constants/ContactConstants';
import {
    getContactLog,
    getContactLogOptions,
    updateContactLog,
} from '../../api';
import { Button, DropDownStyle, InputSearch, TableBody } from '../../common';

const ContactChangeLog = () => {
    const [isLoading, setIsLoading] = useState<any>({ pageLoader: true });
    const [searchInput, setSearchInput] = useState('');
    const [contactLogData, setContactLogData] = useState([]);
    const [contactNameOptions, setContactNameOptions] = useState([]);
    const [selectedMultiFilters, setSelectedMultiFilters] = useState<any>([]);
    const [logCount, setLogCount] = useState(0);
    const [showConfirmModal, setShowConfirmModal] = useState({
        modal: false,
        type: '',
        id: '',
    });
    const [selectedFilters, setSelectedFilters] = useState<
        ContactLogType | any
    >({ pageNumber: 1, pageSize: 20 });
    const [isAscending, setIsAscending] = useState<SortType>({
        value: false,
        name: '',
    });

    const customStyles = {
        overlay: {
            background: 'rgba(0, 0, 0, 0.6)',
            zIndex: 99,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '30px',
        },
    };

    useEffect(() => {
        setIsLoading({ pageLoader: true });
        getContactLog(selectedFilters, searchInput).then((res: any) => {
            if (res?.status === 200) {
                setContactLogData(res?.data?.changeLog_List);
                setLogCount(res?.data?.count);
            } else {
                setContactLogData([]);
                setLogCount(0);
            }
            setIsLoading({ pageLoader: false });
        });
    }, [searchInput, selectedFilters]);

    useEffect(() => {
        let formatMultiSelectedValue = '';
        selectedMultiFilters?.changedBy?.forEach((op: any) => {
            if (formatMultiSelectedValue !== '') {
                formatMultiSelectedValue += ',';
            }
            formatMultiSelectedValue += op.value;
        });
        setSelectedFilters({
            pageNumber: 1,
            pageSize: 20,
            changedBy: formatMultiSelectedValue,
        });
    }, [selectedMultiFilters]);

    useEffect(() => {
        setIsLoading({ pageLoader: true });
        getContactLogOptions().then((op) => {
            if (op?.status === 200) setContactNameOptions(op?.data);
            setIsLoading({ pageLoader: false });
        });
    }, []);

    const handlePageChange = (pageNumber: number) => {
        setSelectedFilters({ ...selectedFilters, pageNumber });
    };

    const handleInputchange = (searchText: string) => {
        setSearchInput(searchText);
    };

    const getSortColor = (isVal: boolean, name: string, cName: string) => {
        return isVal && name === cName ? '#414042' : '#bcbec0';
    };

    const handleSorting = (order: string, field: string) => {
        setIsAscending({ value: order === 'ASC', name: field });
        setSelectedFilters({
            ...selectedFilters,
            sortType: order,
            sort: field,
        });
    };

    const confirmLog = () => {
        setIsLoading({ btnLoader: true });
        updateContactLog({
            id: showConfirmModal.id,
            type: showConfirmModal.type,
        }).then((res: any) => {
            if (res?.status === 200) {
                setShowConfirmModal({ modal: false, type: '', id: '' });
                setSelectedFilters({
                    ...selectedFilters,
                    pageSize: 20,
                });
            } else toast.error(res?.data);
            setIsLoading({ btnLoader: false });
        });
    };

    const getConfirmOptions = (count: number) => {
        const originalOptions = [...confirmOptions];
        const updatedOptions = confirmOptions.slice(
            0,
            confirmOptions.length - 1
        );
        if (count === 2) return updatedOptions;
        return originalOptions;
    };

    return (
        <div className="body-container">
            <ContactChangeStyle>
                <div className="row">
                    <div className="col-12">
                        <h2>
                            Contact Change Log{' '}
                            <span className="header-number">({logCount})</span>
                        </h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 py-3">
                        <InputSearch>
                            <SearchInput
                                setDebouncedInputValue={handleInputchange}
                            />
                        </InputSearch>
                    </div>
                    {contactNameOptions?.length > 1 && (
                        <div className="col-lg-8 py-3 text-md-left text-right">
                            <div className="drop-down">
                                <DropDownStyle>
                                    <MultiSelectDropdown
                                        options={contactNameOptions}
                                        label="Changed By"
                                        value="changedBy"
                                        multiSelectedValues={
                                            selectedMultiFilters
                                        }
                                        setMultiSelectedValues={
                                            setSelectedMultiFilters
                                        }
                                    />
                                </DropDownStyle>
                            </div>
                        </div>
                    )}
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="table-responsive">
                            <TableBody className="data-border">
                                <thead className="grey-header">
                                    <tr>
                                        {contactLogHeader.map((header) => (
                                            <TableHeader
                                                key={header.title}
                                                label={header.title}
                                                keyVal="demo"
                                                noSort={['']}
                                                accessor={header?.title}
                                                upColor={getSortColor(
                                                    isAscending?.value,
                                                    isAscending?.name,
                                                    header?.title
                                                )}
                                                downColor={getSortColor(
                                                    !isAscending?.value,
                                                    isAscending?.name,
                                                    header?.title
                                                )}
                                                handleSorting={handleSorting}
                                                isAscending={isAscending}
                                                cssStyle={{
                                                    width: header.width,
                                                }}
                                            />
                                        ))}
                                    </tr>
                                </thead>

                                {isLoading.pageLoader ? (
                                    <div className="contactChangeLog">
                                        <Loader />
                                    </div>
                                ) : (
                                    <tbody>
                                        {contactLogData?.length ? (
                                            contactLogData?.map((item: any) => {
                                                return (
                                                    <>
                                                        <tr
                                                            className="link-table-row"
                                                            key={
                                                                item?.contactId
                                                            }
                                                        >
                                                            <td>
                                                                {
                                                                    item?.contactName
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    item?.changedBy
                                                                }
                                                            </td>
                                                            <td>
                                                                {item?.createdDate
                                                                    ? dayjs(
                                                                          item?.createdDate
                                                                      ).format(
                                                                          'MMM DD, YYYY'
                                                                      )
                                                                    : '-'}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item?.oldRecord
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    item?.changeinrecord
                                                                }
                                                            </td>
                                                        </tr>

                                                        <tr className="link-table-row">
                                                            <td
                                                                colSpan={5}
                                                                className="table-link link-table-row"
                                                            >
                                                                {getConfirmOptions(
                                                                    item.btnCount
                                                                ).map((op) => (
                                                                    <span
                                                                        key={
                                                                            op.key
                                                                        }
                                                                        className="table-link-opt"
                                                                        role="presentation"
                                                                        onClick={() =>
                                                                            setShowConfirmModal(
                                                                                {
                                                                                    modal: true,
                                                                                    type: op.key,
                                                                                    id: item?.id,
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            op.label
                                                                        }
                                                                    </span>
                                                                ))}
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={5}>
                                                    <NoRecords />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                )}
                            </TableBody>
                            <RcPagination
                                totalRecords={logCount}
                                currentPage={selectedFilters?.pageNumber}
                                recordsPerPage={20}
                                handlePageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </ContactChangeStyle>

            <Modal
                isOpen={showConfirmModal.modal}
                contentLabel="Confirm Modal"
                style={customStyles}
                onRequestClose={() =>
                    setShowConfirmModal({ modal: false, type: '', id: '' })
                }
            >
                <ModalOverlay>
                    <div className="row ">
                        <div className="col-12 py-2">
                            <h3 className="mb-4 text-center">Confirm ?</h3>
                            <div className="d-flex flex-row align-content-center justify-content-center">
                                <Button
                                    type="button"
                                    className="btn-transparent mr-2"
                                    onClick={() =>
                                        setShowConfirmModal({
                                            modal: false,
                                            type: '',
                                            id: '',
                                        })
                                    }
                                >
                                    No
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => confirmLog()}
                                >
                                    {isLoading.btnLoader ? (
                                        <Loader type="button" />
                                    ) : (
                                        'Yes'
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalOverlay>
            </Modal>
        </div>
    );
};

export default ContactChangeLog;
