import styled from 'styled-components';

const ToggleSwitch = styled.label`
    position: relative;
    display: inline-block;
    width: 35px;
    height: 18px;
    align-self: center;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${(props: any) => props.theme.colors.white};
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 20px;
    }

    .slider::before {
        position: absolute;
        content: '';
        height: 13px;
        width: 13px;
        left: 4px;
        bottom: 2px;
        background-color: ${(props: any) => props.theme.colors.primaryColor};
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 25px;
        border: 1px solid ${(props: any) => props.theme.colors.primaryColor};
    }

    input:checked + .slider {
        background-color: ${(props: any) => props.theme.colors.primaryColor};
    }

    input:focus + .slider {
        box-shadow: 0 0 1px ${(props: any) => props.theme.colors.primaryColor};
    }

    input:checked + .slider::before {
        -webkit-transform: translateX(12px);
        -ms-transform: translateX(12px);
        transform: translateX(12px);
        background-color: ${(props: any) => props.theme.colors.white};
        border: 1px solid ${(props: any) => props.theme.colors.primaryColor};
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 9px;
        color: ${(props: any) => props.theme.colors.white};
        font-size: 9px;
        font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
        padding-top: 3px;
        padding-right: 1px;
        padding-left: 1px;
        border: 1px solid ${(props: any) => props.theme.colors.primaryColor};
    }
    .slider.round:before {
        border-radius: 9px;
    }
    &.map-excel-field {
        vertical-align: middle;
    }
`;
export default ToggleSwitch;
