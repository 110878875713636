import { styled } from 'styled-components';

const WhiteCard = styled.div`
    box-shadow: 0px 3px 6px ${(props: any) => props.theme.shadows.commonShadow};
    padding: 25px 20px;

    .user-profile-details {
        .user-avatar {
            width: 100px;
            height: 100px;
            background-color: ${(props: any) => props.theme.colors.iconBg};
            font-size: ${(props: any) => props.theme.fontSizes.titleLarge};
            border-radius: 100%;
            text-align: center;
            padding-top: 34px;
            color: ${(props: any) => props.theme.colors.userTextColor};
        }
    }
    .min-height-card {
        max-height: 200px;
        overflow: scroll;
    }
    .contacts-details {
        list-style-type: none;
        padding: 0;
        margin: 0 25px 0 0;

        li {
            font-size: ${(props: any) => props.theme.fontSizes.normal};
            color: ${(props: any) => props.theme.colors.secondaryColor};
            margin-bottom: 15px;
            word-break: break-all;
            display: flex;
            img {
                width: 18px;
                vertical-align: middle;
                margin-right: 12px;
                &.organization-icon {
                    width: 15px;
                    margin-right: 18px;
                }
            }
            span {
                color: ${(props: any) => props.theme.colors.primaryColor};
            }
        }
    }
    .toggle-switch {
        background-color: ${(props: any) => props.theme.colors.appleWhite};
        box-shadow: 0px 1px 6px
            ${(props: any) => props.theme.shadows.commonShadow};
        border-radius: 18px;
        max-width: 243px;
        width: fit-content;

        .data {
            list-style-type: none;
            padding: 0px;
            margin: 0px;

            li {
                padding: 7px 18px;
                font-size: ${(props: any) => props.theme.fontSizes.medium};
                display: inline-block;
                cursor: pointer;

                &.active {
                    background-color: ${(props: any) =>
                        props.theme.colors.activeBg};
                    border: 1px solid
                        ${(props: any) => props.theme.colors.activeBg};
                    border-radius: 18px;
                    color: ${(props: any) => props.theme.colors.white};
                }
                &.terminate {
                    background-color: ${(props: any) =>
                        props.theme.colors.userTextColor};
                    border: 1px solid
                        ${(props: any) => props.theme.colors.userTextColor};
                    border-radius: 18px;
                    color: ${(props: any) => props.theme.colors.white};
                    cursor: default;
                }
            }
        }
    }
    .additional-contacts {
        .subscription-details {
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
            padding: 15px;
            background-color: ${(props: any) =>
                props.theme.colors.dropdownActiveBg};
            color: ${(props: any) => props.theme.colors.white};
            font-size: ${(props: any) => props.theme.fontSizes.normal};
            box-shadow: 0px 3px 6px
                ${(props: any) => props.theme.shadows.commonShadow};
            &.advanced-bg {
                background-color: ${(props: any) =>
                    props.theme.colors.ButtonColor};
            }
            &.enterprise-bg {
                background-color: ${(props: any) =>
                    props.theme.colors.statusColor};
            }
        }
        .next-billing {
            background-color: ${(props: any) => props.theme.colors.iconBg};
            font-family: ${(props: any) => props.theme.fonts.FontFamily};
            border-radius: 4px;
            padding: 15px;
            box-shadow: 0px 3px 6px
                ${(props: any) => props.theme.shadows.commonShadow};
            &.advanced-bg {
                background-color: ${(props: any) =>
                    props.theme.colors.lightcardBg};
            }
            &.enterprise-bg {
                background-color: ${(props: any) =>
                    props.theme.colors.statusBg};
            }

            .amount-details {
                font-size: ${(props: any) => props.theme.fontSizes.small};

                .amount {
                    font-size: ${(props: any) => props.theme.fontSizes.medium};
                }
            }
        }
        .plan-list {
            padding-top: 25px;
            margin: 0;

            span {
                width: 10px;
                background: ${(props: any) => props.theme.colors.white};
                height: 10px;
                margin-right: 5px;
                display: inline-block;
                border-radius: 25px;
            }
        }
    }

    .status-color-block {
        list-style-type: none;
        padding: 0;
        margin: 35px 0 0 0;
        li {
            display: inline-block;
            padding: 10px 18px;
            border-radius: 4px;
            background-color: ${(props: any) => props.theme.colors.statusBg};
            color: ${(props: any) => props.theme.colors.statusColor};
            font-family: ${(props: any) => props.theme.fonts.FontFamily};
            margin-right: 20px;
            min-width: 246px;
            margin-bottom: 8px;

            &:last-child {
                margin-right: 0;
            }
            &.red-type {
                background-color: ${(props: any) => props.theme.colors.iconBg};
                color: ${(props: any) => props.theme.colors.dropdownActiveBg};
            }
            &.purple-type {
                background-color: ${(props: any) =>
                    props.theme.colors.lightcardBg};
                color: ${(props: any) => props.theme.colors.whiteCardLightText};
            }
            &.donor-type {
                background-color: ${(props: any) => props.theme.colors.grey2};
                color: ${(props: any) => props.theme.colors.darkGrey};
            }
        }
    }

    .character-count {
        position: relative;

        span {
            position: absolute;
            bottom: 9px;
            right: 7px;
        }
    }

    .terminate-btn {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

    .plan-title {
        color: ${(props: any) => props.theme.colors.grey12};
        font-size: ${(props: any) => props.theme.fontSizes.medium};
        font-family: ${(props: any) => props.theme.fonts.intersemibold};
    }

    .small-text-bold {
        color: ${(props: any) => props.theme.colors.grey12};
        font-size: ${(props: any) => props.theme.fontSizes.medium};
        font-family: ${(props: any) => props.theme.fonts.interregular};
    }

    .plan-management-list {
        li {
            list-style: none;
            display: inline-block;
            padding-right: 20px;
            margin: 10px 0px;
            max-width: 200px;
            width: 100%;
            @media only screen and (max-width: 1023px) {
                padding-right: 10px;
            }
            @media only screen and (max-width: 991px) {
                padding-right: 20px;
            }
            @media only screen and (max-width: 729px) {
                padding-right: 10px;
            }

            @media only screen and (max-width: 494px) {
                display: block;
                padding-right: 0px;
            }

            &.lable {
                @media only screen and (max-width: 689px) {
                    display: block;
                }
            }
        }
    }

    .pricing-input {
        li {
            list-style: none;
            display: inline-block;
            &:nth-of-type(1) {
                width: 202px;
            }
        }
    }

    .grace-period {
        color: ${(props: any) => props.theme.colors.statusColor};
        font-family: ${(props: any) => props.theme.fonts.FontFamily};
        width: fit-content;
        text-decoration: underline;
        cursor: pointer;
        padding-top: 20px;

        &.trial {
            cursor: default;
            text-decoration: none;
        }
    }

    .copy-btn {
        display: flex;
        @media only screen and (max-width: 1516px) {
            flex-direction: column;
        }
        .copy-link-btn {
            margin-left: 10px;
            @media only screen and (max-width: 1516px) {
                margin-left: 0px;
                margin-top: 10px;
            }
        }
    }

    .impersonate-btn {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    .subscription-history {
        max-height: 350px;
        min-height: 329px;
        overflow: auto;
    }
`;

export default WhiteCard;
