import { useCallback, useEffect, useState } from 'react';

import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import './App.css';
import Toaster from './common/Toaster';
import Login from './components/Auth/Login';
import GlobalStyle from './Theme/style/GlobalStyle';
import Contacts from './components/contacts/Contacts';
import ProtectedRoutes from './common/ProtectedRoutes';
import Dashboard from './components/dashboard/Dashboard';
import ResetPassword from './components/Auth/ResetPassword';
import ForgotPassword from './components/Auth/ForgotPassword';
import UploadContacts from './components/contacts/UploadContacts';
import CandidateDetails from './components/dashboard/CandidateDetails';
import ContactChangeLog from './components/ContactChange/ContactChangeLog';
import PlanManagementList from './components/planManagement/PlanManagementList';
import { handleError } from './common';
import axiosInstance from './axiosInstance';
import PlanManagementDetailContainer from './components/planManagement/PlanManagementDetailContainer';
import { store, persistor } from './store/store';
import {
    PATH_LOGIN,
    PATH_CONTACTS,
    PATH_CANDIDATE_DETAIL,
    PATH_FORGOT_PASSWORD,
    PATH_RESET_PASSWORD,
    PATH_ROOT,
    PATH_DASHBOARD,
    PATH_UPLOAD_CONTACTS,
    PATH_CONTACT_CHANGE_LOG,
    PATH_PLAN_MANAGEMENT,
    PATH_CREATE_PLAN_MANAGEMENT,
    PATH_EDIT_PLAN_MANAGEMENT,
    PATH_CLONE_PLAN,
    API_DYNAMIC_THEMES,
} from './constants';
import CreatePlanContainer from './components/planManagement/CreatePlanContainer';

const App = () => {
    const [themeData, setThemeData] = useState<any>();
    const [loading, setLoading] = useState(true);

    const getManifestData = (name: string) => {
        return {
            short_name: name,
            name,
            start_url: '.',
            display: 'standalone',
            theme_color: '#000000',
            background_color: '#ffffff',
        };
    };

    const fetchData = useCallback(async () => {
        try {
            const response = await axiosInstance.get(API_DYNAMIC_THEMES);
            const theme = response?.data?.[0];
            localStorage.setItem('theme', JSON.stringify(theme));

            if (!theme) {
                throw new Error('Theme not found');
            }

            const themeResponse = await axios.get(theme.adminThemeFile, {
                responseType: 'json',
            });

            // change the browser tab name based on the type
            document.title = `${theme?.type} Admin` || '';

            setThemeData(themeResponse?.data);
            // show manifest file
            const manifest = document.getElementById('manifest') as
                | HTMLLinkElement
                | any;

            if (manifest) {
                const manifestData = getManifestData(theme?.type || '');
                const manifestJson = JSON.stringify(manifestData);
                const blob = new Blob([manifestJson], {
                    type: 'application/json',
                });
                manifest.href = URL.createObjectURL(blob);
            }
        } catch (error) {
            handleError('');
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <BrowserRouter>
            {!loading ? (
                <Provider store={store}>
                    <ThemeProvider theme={themeData}>
                        <PersistGate loading={null} persistor={persistor}>
                            <Toaster />

                            <GlobalStyle />
                            <Routes>
                                <Route path={PATH_LOGIN} element={<Login />} />

                                <Route
                                    path={PATH_FORGOT_PASSWORD}
                                    element={<ForgotPassword />}
                                />

                                <Route
                                    path={PATH_RESET_PASSWORD}
                                    element={<ResetPassword />}
                                />
                                <Route
                                    path="*"
                                    element={<Navigate to={PATH_LOGIN} />}
                                />
                                <Route element={<ProtectedRoutes />}>
                                    <Route
                                        path={PATH_ROOT}
                                        element={
                                            <Navigate to={PATH_DASHBOARD} />
                                        }
                                    />
                                    <Route
                                        path={PATH_DASHBOARD}
                                        element={<Dashboard />}
                                    />
                                    <Route
                                        path={PATH_CONTACTS}
                                        element={<Contacts />}
                                    />
                                    <Route
                                        path={PATH_CANDIDATE_DETAIL}
                                        element={<CandidateDetails />}
                                    />

                                    <Route
                                        path={PATH_PLAN_MANAGEMENT}
                                        element={<PlanManagementList />}
                                    />
                                    <Route
                                        path={PATH_UPLOAD_CONTACTS}
                                        element={<UploadContacts />}
                                    />

                                    <Route
                                        path={PATH_CONTACT_CHANGE_LOG}
                                        element={<ContactChangeLog />}
                                    />
                                    <Route
                                        path={PATH_CREATE_PLAN_MANAGEMENT}
                                        element={<CreatePlanContainer />}
                                    />
                                    <Route
                                        path={PATH_CLONE_PLAN}
                                        element={<CreatePlanContainer />}
                                    />
                                    <Route
                                        path={`${PATH_EDIT_PLAN_MANAGEMENT}/:id/:name`}
                                        element={
                                            <PlanManagementDetailContainer />
                                        }
                                    />
                                </Route>
                            </Routes>
                        </PersistGate>
                    </ThemeProvider>
                </Provider>
            ) : (
                <p className="align-items-center d-flex justify-content-center h-100vh">
                    {' '}
                    Loading...
                </p>
            )}
        </BrowserRouter>
    );
};

export default App;
