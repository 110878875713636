import { styled } from 'styled-components';

const FilterStyle = styled.div`
    .dashboard-filter {
        padding: 0;
        margin: 0;
        li {
            display: inline-block;
            padding-right: 15px;
            vertical-align: bottom;

            &.input-search {
                width: 100%;
                max-width: 300px;
                @media screen and (max-width: 391px) {
                    margin-bottom: 10px;
                }
            }

            &.fagtag {
                @media screen and (max-width: 522px) {
                    margin-top: 10px;
                }
            }

            &.drop-down-list {
                vertical-align: top;
                padding-top: 9px;
            }

            .filter-img {
                border: 1px solid ${(props: any) => props.theme.colors.grey2};
                border-radius: 4px;
                height: 40px;
                padding-top: 10px;
                width: 40px;
                text-align: center;
            }
        }
    }

    .dashboard-filter-click {
        position: relative;
        .filter-box {
            box-shadow: 0px 1px 6px
                ${(props: any) => props.theme.shadows.commonShadow};
            width: 100%;
            max-width: 800px;
            padding: 30px 20px;
            margin-top: 30px;
            background-color: ${(props: any) => props.theme.colors.white};
            position: absolute;
            z-index: 9;

            @media screen and (max-width: 522px) {
                margin-top: 4px;
            }

            @media screen and (max-width: 391px) {
                margin-top: 32px;
            }

            &::before {
                content: '';
                position: absolute;
                left: 306px;
                top: -42px;
                width: 0;
                height: 0;
                border-left: 15px solid transparent;
                border-bottom: 26px solid
                    ${(props: any) => props.theme.colors.white};
                border-top: 17px solid transparent;
                border-right: 15px solid transparent;
                filter: drop-shadow(
                    0 -1px 0px ${(props: any) => props.theme.shadows.commonShadow}
                );

                @media screen and (max-width: 391px) {
                    left: 8px;
                }
            }

            .title {
                font-family: ${(props: any) => props.theme.fonts.interregular};
                font-size: ${(props: any) => props.theme.fontSizes.medium};
                padding-bottom: 3px;
            }

            .slidecontainer {
                width: 100%;
                padding-top: 18px;

                .slider {
                    -webkit-appearance: none;
                    width: 100%;
                    height: 5px;
                    background: ${(props: any) => props.theme.colors.darkGrey};
                    box-shadow: 0px 3px 6px
                        ${(props: any) => props.theme.shadows.commonShadow};
                    border-radius: 10px;
                    outline: none;
                    opacity: 0.7;
                    -webkit-transition: 0.2s;
                    transition: opacity 0.2s;
                    position: relative;
                }

                .slider:hover {
                    opacity: 1;
                }

                .slider::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 16px;
                    height: 16px;
                    border: 2px solid
                        ${(props: any) => props.theme.colors.darkGrey};
                    border-radius: 50%;
                    cursor: pointer;
                    position: relative;
                    z-index: 9999;
                    background-color: ${(props: any) =>
                        props.theme.colors.white};
                }
            }
        }
    }
`;

export default FilterStyle;
