import storage from 'redux-persist/lib/storage';

const removeLocalStorageData = () => {
    storage.removeItem('persist:root');
    if (!localStorage.isRemember) {
        localStorage.removeItem('user');
    }
    localStorage.removeItem('token');
};

export default removeLocalStorageData;
