import { styled } from 'styled-components';

const ContactChangeStyle = styled.div`
    .header-number {
        font-size: ${(props: any) => props.theme.fontSizes.larger};
    }

    .drop-down {
        max-width: 200px;
        text-align: left;
        @media screen and (max-width: 991px) {
            width: 100%;
            max-width: 100%;
        }
    }
`;

export default ContactChangeStyle;
