export const planTabs = [
    { key: 'feature', label: 'Pricing & features' },
    { key: 'subscribe', label: 'Subscribers' },
    { key: 'activity', label: 'Activity' },
];

export const planTableHeaders = [
    { key: 'plan', label: 'Plan', width: '' },
    { key: 'subscribers', label: 'Subscribers', width: '200px' },
    { key: 'pricing', label: 'Pricing', width: '200px' },
    { key: 'lastUpdated', label: 'Last updated', width: '' },
    { key: 'details', label: 'Details', width: '140px' },
];

export const planDetailsDropdownOptions = [
    { value: '1', label: 'Applicable' },
    { value: '2', label: 'Not Applicable(NA)' },
];

export const frequencyDropdownOptions = [
    { value: 'mo', label: 'Monthly' },
    { value: 'yr', label: 'Yearly' },
];

export const subsciberTableHeadersOptions = [
    { title: 'Name', accessor: 'Name' },
    { title: 'Pricing', accessor: 'Pricing' },
    { title: 'Race Type', accessor: 'RaceType' },
    { title: 'Location', accessor: 'Location' },
    { title: 'Last Billing', accessor: 'LastBilling' },
    { title: 'Next Billing', accessor: 'NextBilling' },
];

export const subLabelArr = [5, 6];
export const enterprisePlanType = [
    {
        value: '1',
        label: 'Enterprise plan 1',
    },
    {
        value: '2',
        label: 'Enterprise plan 2',
    },
    {
        value: '3',
        label: 'Enterprise plan 3',
    },
    {
        value: '4',
        label: 'Enterprise plan 4',
    },
];

export const FEATURE_FIELDS_UNLIMITED = [
    'Contacts',
    'Emails',
    'Multiusers',
    'Contact Lists',
];
