import axiosInstance from '../axiosInstance';
import {
    DashboardCandidateFiltersType,
    DashboardCandidateRestPasswordType,
    DashboardCandidateStatusType,
    DashboardCandidateAPIPermissionType,
    GracePeriodType,
    ComplementaryPeriodType,
} from '../types/type';
import {
    API_ALIAS_LOGIN,
    API_CANDIDATE_DETAIL,
    API_CANDIDATE_RESET_PASSWORD,
    API_CANDIDATE_TERMINATE,
    API_COMPLEMENTARY_PERIOD,
    API_DASHBOARD_CANDIDATE_LIST,
    API_DASHBOARD_CARDS,
    API_DASHBOARD_FILTER_OPTIONS,
    API_DASHBOARD_STATUS_UPDATE,
    API_SAVE_GRACE_PERIOD,
    API_UPDATE_CANDIDATE_PERMISSIONS,
    API_CANCEL_SUBSCRIPTION,
} from '../constants';

// get api for dashboard card details
export const getDashboardCard = () => {
    return axiosInstance
        .get(API_DASHBOARD_CARDS)
        .then((res) => res)
        .catch((error) => Promise.reject(error));
};

// get api for dashboard candidate list with its filters
export const getDashboardCandidateList = async (
    filters: DashboardCandidateFiltersType,
    searchText: string
) => {
    return axiosInstance
        .post(API_DASHBOARD_CANDIDATE_LIST, {
            ...filters,
            searchText,
        })
        .then((response) => response)
        .catch((error) => Promise.reject(error));
};

// get filter options for dashboard candidate table
export const getDashboardFilterOptions = () => {
    return axiosInstance
        .get(API_DASHBOARD_FILTER_OPTIONS)
        .then((res) => res)
        .catch((error) => Promise.reject(error));
};

// get individual candidate details
export const getCandidateDetails = (id: number | string | undefined) => {
    const params = { candidateId: id };
    return axiosInstance
        .get(API_CANDIDATE_DETAIL, { params })
        .then((res) => res)
        .catch((error) => Promise.reject(error));
};

// update the status of each candidate
export const updateCandidateStatus = (data: DashboardCandidateStatusType) => {
    return axiosInstance
        .post(API_DASHBOARD_STATUS_UPDATE, data)
        .then((response) => response)
        .catch((error) => Promise.reject(error));
};

// terminate the candidate
export const terminateCandidate = (data: DashboardCandidateStatusType) => {
    return axiosInstance
        .post(API_CANDIDATE_TERMINATE, data)
        .then((response) => response)
        .catch((error) => Promise.reject(error));
};

// send reset password to the candidate
export const resetCandidatePassword = (
    data: DashboardCandidateRestPasswordType
) => {
    return axiosInstance
        .post(API_CANDIDATE_RESET_PASSWORD, data)
        .then((response) => response)
        .catch((error) => Promise.reject(error));
};

// update candidate's permissions
export const updateCandidatePermission = (
    data: DashboardCandidateAPIPermissionType
) => {
    return axiosInstance
        .post(API_UPDATE_CANDIDATE_PERMISSIONS, data)
        .then((response) => response)
        .catch((error) => Promise.reject(error));
};

// save grace period
export const saveGracePeriod = (data: GracePeriodType) => {
    return axiosInstance
        .post(API_SAVE_GRACE_PERIOD, data)
        .then((response) => response)
        .catch((error) => Promise.reject(error));
};

// save complementary period
export const saveComplementaryPeriod = (data: ComplementaryPeriodType) => {
    return axiosInstance
        .post(API_COMPLEMENTARY_PERIOD, data)
        .then((response) => response)
        .catch((error) => Promise.reject(error));
};

// API call for getting alias
export const aliasLogin = (userName: string, token: string) => {
    return axiosInstance
        .post(API_ALIAS_LOGIN, { userName }, { params: { token } })
        .then((response) => response)
        .catch((error) => Promise.reject(error));
};

// API call for SET CANCEL SUBSCRIPTION
export const setCancelSubscriptionAPI = (data: any) => {
    return axiosInstance
        .post(API_CANCEL_SUBSCRIPTION, data)
        .then((response) => response)
        .catch((error) => Promise.reject(error));
};
