import { FC } from 'react';

import Pagination from 'rc-pagination';
import styled from 'styled-components';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import 'rc-pagination/assets/index.css';

import { PaginationType } from '../types/type';

const RcPagination: FC<PaginationType> = ({
    totalRecords,
    currentPage,
    recordsPerPage,
    handlePageChange,
}) => {
    return (
        <PaginationStyled>
            <Pagination
                className="p-2 mt-2"
                current={currentPage}
                total={totalRecords}
                pageSize={recordsPerPage}
                showTotal={(total, range) =>
                    `${range[0]} - ${range[1]} of ${total} items`
                }
                hideOnSinglePage
                onChange={(event) => handlePageChange(event)}
                locale={localeInfo}
            />
        </PaginationStyled>
    );
};

export default RcPagination;

const PaginationStyled = styled.div`
    ul.rc-pagination {
        text-align: end;
        .rc-pagination-total-text {
            float: left;
            color: ${(props: any) => props.theme.colors.grey6};
        }
        li {
            margin-bottom: 10px;

            &.rc-pagination-item {
                display: inline-block;
                border: none;
                a {
                    color: ${(props: any) => props.theme.colors.black};
                }

                &.rc-pagination-item-active {
                    font-weight: 500;
                    border: none;
                    a {
                        color: ${(props: any) =>
                            props.theme.colors.primaryColor};
                    }
                }

                &:hover {
                    font-weight: 500;
                    a {
                        color: ${(props: any) =>
                            props.theme.colors.primaryColor};
                    }
                }
            }
        }
        .rc-pagination-prev button,
        .rc-pagination-next button {
            font-size: 18px;
            .rc-pagination-next button:after {
                font-size: 15px !important;
                color: ${(props: any) => props.theme.colors.black};
            }
            .rc-pagination-prev button:after {
                content: '‹';
                display: block;
                color: ${(props: any) => props.theme.colors.black};
                font-size: 15px !important;
            }

            &:hover {
                border: 1px solid #d9d9d9;
                color: ${(props: any) => props.theme.colors.black};
            }
        }
        .rc-pagination-next:focus .rc-pagination-item-link,
        .rc-pagination-next:hover .rc-pagination-item-link,
        .rc-pagination-prev:focus .rc-pagination-item-link,
        .rc-pagination-prev:hover .rc-pagination-item-link {
            border: 1px solid #d9d9d9;
            font-size: 18px;
            color: black;
        }
    }

    @media only screen and (max-width: 500px) {
        ul.rc-pagination {
            text-align: center !important;
        }
        li.rc-pagination-total-text {
            width: 100%;
        }
    }
    .rc-pagination-options {
        margin-left: 0;
    }
`;
