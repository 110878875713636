import { styled } from 'styled-components';

const ToasterStyle = styled.div`
    .Toastify__toast-container--top-right {
        left: 86% !important;
        top: 11px !important;
        transform: translateX(-48%) !important;

        .Toastify__toast-theme--colored.Toastify__toast--error {
            background-color: ${(props: any) =>
                props.theme.colors.toasterError} !important;
        }

        .Toastify__toast {
            background-color: ${(props: any) =>
                props.theme.colors.primaryColor};
            padding: 0 !important;
            box-shadow: 3px 3px 6px
                ${(props: any) => props.theme.shadows.commonShadow} !important;
            border-radius: 6px;

            .Toastify__toast-body {
                font-family: ${(props: any) =>
                    props.theme.fonts.semiBoldFontFamily};
                padding-left: 15px;

                .Toastify__zoom-enter {
                    display: none;
                }
            }

            .Toastify__close-button {
                padding: 15px !important;
                max-width: 22px !important;
                margin-right: 22px;
                margin-top: 10px;
                color: ${(props: any) => props.theme.colors.white};
            }
        }
    }
`;

export default ToasterStyle;
