import { FC } from 'react';
import Modal from 'react-modal';

import { RemoveCross } from '../../assets/images';
import { Button } from '../../common';

interface ConfirmModalProps {
    isConfirmModalOpen: true;
    sendDecision: (val: boolean) => void;
}

const customStyles = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.6)',
        zIndex: 99,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const ConfirmModal: FC<ConfirmModalProps> = ({
    isConfirmModalOpen,
    sendDecision,
}) => {
    const closeModal = () => {
        sendDecision(false);
    };

    return (
        <Modal
            isOpen={isConfirmModalOpen}
            onRequestClose={() => sendDecision(false)}
            onAfterClose={() => sendDecision(false)}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
            contentLabel="Confirm Modal"
            style={customStyles}
        >
            <div className="position-relative p-4">
                <img
                    role="presentation"
                    src={RemoveCross}
                    alt="times"
                    className="position-absolute cursor"
                    style={{
                        height: '20px',
                        width: '20px',
                        top: '0',
                        right: '0',
                    }}
                    onClick={closeModal}
                />

                <div className="row ">
                    <div className="col-12 py-2">
                        <p className="mb-4">
                            Do you want to delete the selected record(s)?
                        </p>
                        <div className="d-flex flex-row align-content-center justify-content-center">
                            <Button
                                type="button"
                                onClick={() => sendDecision(true)}
                            >
                                Yes
                            </Button>
                            <Button
                                type="button"
                                className="ml-2"
                                onClick={() => sendDecision(false)}
                            >
                                No
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
