import { FC } from 'react';

import styled, { useTheme } from 'styled-components';
import { Bars } from 'react-loader-spinner';

import { LoaderPropsType } from '../types/type';

const Loader: FC<LoaderPropsType> = ({ type, text }) => {
    const theme: any = useTheme();

    let color = theme?.colors?.primaryColor || '#0000';
    let height = '80';
    let width = '80';
    let wrapperStyle = {};
    let wrapperClass = 'page-with-side-panel-and-top';
    let parentClass =
        'd-flex flex-row justify-content-center align-content-center';
    let wrapperHeight = '50vh';

    if (type === 'button') {
        height = '20';
        width = '20';
        color = theme?.colors?.white;
        wrapperStyle = { margin: 'auto' };
        wrapperClass = 'flex-row justify-content-center';
        parentClass = '';
        wrapperHeight = '1';
    }

    if (type === 'sideModal') {
        wrapperHeight = '90vh';
    }

    return (
        <LoaderWrapper
            className={parentClass}
            style={{ height: wrapperHeight }}
        >
            <Bars
                height={height}
                width={width}
                color={color}
                ariaLabel="Loading..."
                wrapperStyle={wrapperStyle}
                wrapperClass={wrapperClass}
                visible
            />
            {text ? <div className="loader-text">{text}</div> : ''}
        </LoaderWrapper>
    );
};

export default Loader;

const LoaderWrapper = styled.div`
    position: relative;
    width: auto;
    overflow: hidden;
    .page-with-side-panel-and-top {
        position: absolute;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        transform: translate(-50%, -50%);
    }
    .loader-text {
        position: absolute;
        top: 65%;
        left: 50%;
        right: auto;
        bottom: auto;
        transform: translate(-50%, -50%);
    }
`;
