import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import Loader from '../../common/Loader';
import CommonModal from '../../common/CommonModal';
import PlanSubscribeList from './PlanSubscribeList';
import PlanManagementDetails from './PlanManagementDetails';
import PlanManagementActivity from './PlanManagementActivity';
import PlanManagementStyle from '../../Theme/style/PlanManagementStyle';
import { useAppDispatch } from '../../store/hook';
import { validateEmail } from '../../common/CommonMethods';
import { ArrowBtn, ArrowRight } from '../../assets/images';
import { setPlanDetailsInStore } from '../../features/planmanagementSlice';
import { PlanTabsType, OtherEntDetailsType } from '../../types/type';
import {
    PATH_CLONE_PLAN,
    PATH_PLAN_MANAGEMENT,
    planTabs,
} from '../../constants';
import {
    getPlanDetailAPI,
    getEnterPrisePlanDetailAPI,
    updateEnterprisePlanStatus,
    sendSignupEmail,
} from '../../api';
import {
    PageNav,
    NavTab,
    Button,
    InputSearch,
    FilterTag,
    ErrorMsg,
} from '../../common';

const PlanManagementDetailContainer = () => {
    // This component redirecrts from plan list screen after click on view button with paln id and name in params.
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { id, name } = useParams();
    const disptach = useAppDispatch();

    const [selectedTab, setSelectedTab] = useState('feature');
    const [planDetails, setPlanDetails] = useState<any>([]);
    const [updatedPlanTabs, setUpdatedPlanTabs] =
        useState<PlanTabsType[]>(planTabs);
    const [otherEntDetails, setOtherEntDetails] =
        useState<OtherEntDetailsType>();
    const [enterprisePlanDetails, setEnterprisePlanDetails] = useState<any>({});
    const [isSignupEmailButton, setIsSignupEmailButton] = useState(false);
    const [isSignupEmailModal, setIsSignupEmailModal] = useState(false);
    const [signupEmail, setSignupEmail] = useState('');
    const [loaderStatus, setLoaderStatus] = useState({
        sendEmail: false,
        saveButton: false,
        pageLoader: false,
    });

    const mode: any = pathname
        ?.split('/')
        ?.find((element: any) => element === 'create' || element === 'edit');

    // display pricing $ feature, subscribers and activity tab screens
    const getTabHTML = () => {
        switch (selectedTab) {
            case 'feature':
                return (
                    <PlanManagementDetails
                        planDetails={planDetails}
                        isLoading={loaderStatus.pageLoader}
                        planName={name!}
                        otherEntDetails={otherEntDetails!}
                        getPlanDetail={getPlanDetail}
                        isEnterprise={
                            (name as string) !== 'Starter' &&
                            (name as string) !== 'Advanced'
                        }
                        planId={id!}
                        enterprisePlanDetails={enterprisePlanDetails}
                        loaderStatus={loaderStatus}
                        setLoaderStatus={setLoaderStatus}
                        handleChangeStatus={handleChangeStatus}
                    />
                );
                break;
            case 'subscribe':
                return <PlanSubscribeList PlanName={name!} />;
                break;
            default:
                return <PlanManagementActivity planName={name!} />;
                break;
        }
    };

    const getNavBarPath = (navLoc: string) => {
        switch (navLoc) {
            case 'create':
                return 'Create new';
            case 'edit':
                return 'Plan details';
            default:
                return '';
        }
    };

    const handleChangeStatus = async () => {
        try {
            const response = await updateEnterprisePlanStatus(
                otherEntDetails?.eentrpriseplanId
            );
            if (response?.status === 200) {
                toast.success(response?.data);
                getPlanDetail(false);
                setIsSignupEmailButton(true);
            }
        } catch (error: any) {
            toast.error(error?.data);
        } finally {
            setLoaderStatus({ ...loaderStatus, saveButton: false });
        }
    };

    const handleSendEmail = async () => {
        const payload = {
            eentrpriseplanId: Number(otherEntDetails?.eentrpriseplanId),
            email: signupEmail,
        };
        try {
            const response = await sendSignupEmail(payload);
            if (response.status === 200) {
                toast.success(response.data);
                navigate(PATH_PLAN_MANAGEMENT);
            }
        } catch (error: any) {
            toast.error(error.data);
        } finally {
            setLoaderStatus({
                ...loaderStatus,
                saveButton: false,
                sendEmail: false,
            });
        }
    };

    const handleSendEmailValidation = () => {
        if (!signupEmail || (signupEmail && !validateEmail(signupEmail))) {
            return true;
        }
        return false;
    };
    // using plan id and name fetch details for starter, advanced and enterprise plans.
    const getPlanDetail = useCallback(
        async (isNeedLoader: boolean) => {
            if (isNeedLoader) {
                setLoaderStatus({
                    saveButton: false,
                    sendEmail: false,
                    pageLoader: true,
                });
            }

            if (name && id) {
                try {
                    const response =
                        name !== 'Starter' && name !== 'Advanced'
                            ? await getEnterPrisePlanDetailAPI(Number(id), name)
                            : await getPlanDetailAPI(name);
                    if (name !== 'Starter' && name !== 'Advanced') {
                        setOtherEntDetails({
                            eentrpriseplanId: response?.data?.eentrpriseplanId,
                            planTypeId: response?.data?.planTypeId,
                            status: response?.data?.status,
                            subTitle: response?.data?.subTitle,
                            isSignupLinkSent: response?.data?.signuplinksent,
                        });
                    }

                    let tempPlanDetails = [];
                    let tempSubscriberscount = 0;
                    if (response?.status === 200) {
                        if (response?.data?.featuresMasterList?.length) {
                            tempPlanDetails =
                                response?.data?.featuresMasterList;
                        }
                        if (response?.data?.eentrpriseplanId) {
                            setEnterprisePlanDetails({
                                eentrpriseplanId:
                                    response?.data?.eentrpriseplanId,
                                planTypeId: response?.data?.planTypeId,
                                subTitle: response?.data?.subTitle,
                            });
                        }
                        tempSubscriberscount =
                            response?.data?.subscribersCount || 0;
                    }
                    let tempPlanTabs = [
                        { key: 'feature', label: 'Pricing & features' },
                    ];
                    if (name === 'Starter' || name === 'Advanced') {
                        tempPlanTabs = planTabs.map((item: PlanTabsType) => {
                            if (item.key === 'subscribe') {
                                return {
                                    key: item.key,
                                    label: `Subscribers(${tempSubscriberscount})`,
                                };
                            }
                            return item;
                        });
                    }

                    setUpdatedPlanTabs(tempPlanTabs);
                    setPlanDetails(tempPlanDetails);
                } catch (error: any) {
                    toast.error(error.data);
                }
            }
            setLoaderStatus({
                saveButton: false,
                sendEmail: false,
                pageLoader: false,
            });
        },
        [name, id]
    );

    useEffect(() => {
        getPlanDetail(true);
    }, [getPlanDetail]);

    const getBulletPointClassname = (planName: string) => {
        switch (planName) {
            case 'Starter':
                return 'orange-dot';
            case 'Advanced':
                return '';
            default:
                return 'teal-dot';
        }
    };

    const handleModalClose = () => {
        setSignupEmail('');
        setIsSignupEmailModal(false);
    };

    const sendButton = {
        maxWidth: '132px',
        width: '100%',
    };

    return (
        <div className="body-container">
            <PlanManagementStyle>
                <PageNav className="mb-4">
                    <ul className="nav-list">
                        <li
                            className="active-nav"
                            role="presentation"
                            onClick={() => navigate(PATH_PLAN_MANAGEMENT)}
                        >
                            Plan Management
                            <img
                                className="ml-1"
                                src={ArrowRight}
                                alt="arrow"
                            />{' '}
                        </li>
                        <li>{getNavBarPath(mode)}</li>
                    </ul>
                </PageNav>
                <div className="row">
                    <div className="col-md-4">
                        <div className="bullet-text">
                            <div
                                className={`bullet-points ${
                                    mode === 'create'
                                        ? 'teal-dot'
                                        : getBulletPointClassname(name!)
                                }`}
                            />

                            <span className="pl-1">
                                {mode === 'edit' ? name : 'Starter'}
                            </span>
                            {otherEntDetails?.status &&
                            otherEntDetails?.status === 'Draft' ? (
                                <FilterTag className="list-status">
                                    Draft
                                </FilterTag>
                            ) : null}
                        </div>
                        {mode === 'create' && (
                            <InputSearch className="mt-3">
                                <p className="amt-title mb-1">Subtitle</p>
                                <input type="text" className="form-control" />
                            </InputSearch>
                        )}
                    </div>
                    <div className="col-md-8">
                        <div className="toggle-btn">
                            {(name as string) !== 'Starter' &&
                                (name as string) !== 'Advanced' && (
                                    <Button
                                        className="mr-2"
                                        type="button"
                                        onClick={() => {
                                            disptach(
                                                setPlanDetailsInStore(
                                                    planDetails
                                                )
                                            );
                                            navigate(PATH_CLONE_PLAN);
                                        }}
                                    >
                                        Clone
                                    </Button>
                                )}
                            {(isSignupEmailButton ||
                                (otherEntDetails?.status !== 'Draft' &&
                                    !otherEntDetails?.isSignupLinkSent &&
                                    (name as string) !== 'Starter' &&
                                    (name as string) !== 'Advanced')) && (
                                <Button
                                    className="mr-2"
                                    type="button"
                                    onClick={() => {
                                        setIsSignupEmailModal(true);
                                    }}
                                >
                                    Send sign up link
                                </Button>
                            )}
                            <Button
                                className="btn-border"
                                type="button"
                                onClick={() => navigate(PATH_PLAN_MANAGEMENT)}
                            >
                                <img
                                    role="presentation"
                                    src={ArrowBtn}
                                    alt="back"
                                />{' '}
                                Back
                            </Button>
                        </div>
                    </div>
                </div>
                {mode === 'edit' ? (
                    <NavTab className="mt-4">
                        <ul className="tabs">
                            {updatedPlanTabs?.map((item: PlanTabsType) => (
                                <li
                                    key={item.key}
                                    onClick={() => setSelectedTab(item.key)}
                                    role="presentation"
                                    className={
                                        selectedTab === item.key ? 'active' : ''
                                    }
                                >
                                    {item.label}
                                </li>
                            ))}
                        </ul>
                    </NavTab>
                ) : null}
                {getTabHTML()}

                {isSignupEmailModal && (
                    <CommonModal
                        isModalOpen={isSignupEmailModal}
                        modalBody={
                            <>
                                <h3 className="text-center mb-3">
                                    Please enter candidate&apos;s email below to
                                    send the registration link for the
                                    application
                                </h3>
                                <div className="row my-2">
                                    <div className="col-12 text-center">
                                        <InputSearch>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={signupEmail}
                                                onChange={(e) => {
                                                    setSignupEmail(
                                                        e.target.value
                                                    );
                                                }}
                                                placeholder="Enter email"
                                            />
                                            {signupEmail &&
                                            handleSendEmailValidation() ? (
                                                <ErrorMsg className="text-left email-error">
                                                    Invalid email
                                                </ErrorMsg>
                                            ) : (
                                                ''
                                            )}
                                        </InputSearch>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 text-center">
                                        <Button
                                            className="btn-border"
                                            onClick={() => {
                                                handleModalClose();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="ml-2"
                                            style={sendButton}
                                            disabled={
                                                loaderStatus.sendEmail ||
                                                handleSendEmailValidation()
                                            }
                                            onClick={() => {
                                                setLoaderStatus({
                                                    ...loaderStatus,
                                                    sendEmail: true,
                                                });
                                                handleSendEmail();
                                            }}
                                        >
                                            {loaderStatus.sendEmail ? (
                                                <Loader type="button" />
                                            ) : (
                                                'Send email'
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </>
                        }
                        handleCloseOperation={() => {
                            handleModalClose();
                        }}
                    />
                )}
            </PlanManagementStyle>
        </div>
    );
};

export default PlanManagementDetailContainer;
