import { FC } from 'react';

import { useLoadScript } from '@react-google-maps/api';

import { InputSearch } from '../../common';
import { AddressComps } from '../../types/type';

const AutoSearch: FC<{
    label: string;
    setAddressComponents: (obj: AddressComps) => void;
}> = ({ label, setAddressComponents }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
        libraries: ['places'],
    });

    if (isLoaded) {
        const options = {
            componentRestrictions: { country: 'us' },
            fields: ['address_components', 'geometry'],
        };
        const inputTextBox = document.getElementById(
            'autoAddressInput'
        ) as HTMLInputElement;

        const searchBox = new google.maps.places.Autocomplete(
            inputTextBox,
            options
        );

        searchBox.addListener('place_changed', () => {
            const place = searchBox.getPlace();
            if (place) {
                const addressComponentsArray: any =
                    place.address_components?.map((item: any) => {
                        return { [item.types[0]]: item.long_name };
                    });

                const gpdcoordinates = `${place?.geometry?.location?.toJSON()
                    .lat}, ${place?.geometry?.location?.toJSON().lng}`;

                const addressComps = Object.assign(
                    { gpdcoordinates },
                    ...addressComponentsArray
                );

                setAddressComponents(addressComps);
            }
        });
    }

    return (
        <div className="row mb-2">
            <div className="col-12">
                <p className="details-title mb-1">{label}</p>
                <InputSearch>
                    <input
                        type="text"
                        className="form-control"
                        maxLength={200}
                        placeholder="Search with location"
                        id="autoAddressInput"
                    />
                </InputSearch>
            </div>
        </div>
    );
};

export default AutoSearch;
