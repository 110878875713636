// Auth
export const API_LOGIN = '/superauth/superLogin';
export const API_FORGOT_PASSWORD = '/user/forgotSuperUserPassword';
export const API_RESET_PASSWORD = '/user/resetpassword';
export const API_ALIAS_LOGIN = 'auth/aliasLogin';
export const API_DYNAMIC_THEMES = 'user/getThemeDetails';

// Contacts
export const API_GET_CONTACTS = './supercontact/canvassinglistdetails';
export const API_SELECTED_CONTACT_DETAILS =
    'supercontact/selectedcontactdetails';
export const API_SET_EMAIL = 'supercontact/savenewcontactemail';
export const API_SET_PHONE = 'supercontact/savenewcontactphone';
export const API_SET_ADDRESS = 'supercontact/savenewcontactaddress';
export const API_SET_EMAIL_PRIMARY = 'supercontact/setprimaryemail';
export const API_SET_PHONE_PRIMARY = 'supercontact/setprimaryphone';
export const API_SET_ADDRESS_PRIMARY = 'supercontact/setprimaryaddress';
export const API_DELETE_EMAIL = 'supercontact/contactemail';
export const API_DELETE_PHONE = 'supercontact/contactphone';
export const API_DELETE_ADDRESS = 'supercontact/contactaddress';
export const API_DELETE_CONTACT = 'supercontact/DeleteContactById';

export const API_UPLOAD_EXCEL = 'superimportcontact/uploadExcelFileData';
export const API_MERGE_ALL_CONTACTS =
    'superimportcontact/mergeAllexcelContacts';
export const API_KEEP_BOTH_CONTACTS = 'superimportcontact/keepBothExcelContact';
export const API_MERGE_A_CONTACT = 'superimportcontact/mergeExcelContacts';
export const API_DELETE_BULK_CONTACT =
    'superimportcontact/deleteSelectedContact';
export const API_FILE_UPLOAD_LOG = 'superimportcontact/latestfileuploadlog';
export const API_DELETE_LAST_UPLOADED_FILE =
    'superimportcontact/deleteLastUploadedFile';
export const API_GET_CAMPAIGN_AFFILIATION =
    'contact/getaffiliationnamedropdown';
export const API_UPDATE_CONTACT = 'supercontact/updateContact';
export const API_GET_CONTACTS_FILTERS =
    'supercontact/getadvancesearchdropdownlist';
export const API_GET_ZIP_FILTERS = 'supercontact/getAreasDropDown';

// Dashboard
export const API_DASHBOARD = 'superdashboard/';
export const API_DASHBOARD_CARDS = `${API_DASHBOARD}getSuperDashboardCardDetailsView`;
export const API_DASHBOARD_CANDIDATE_LIST = `${API_DASHBOARD}getSuperDashboardCandidatesDetailsView`;
export const API_DASHBOARD_FILTER_OPTIONS = `${API_DASHBOARD}getSuperDashboardDropDowns`;
export const API_CANDIDATE_DETAIL = `${API_DASHBOARD}getSuperCandidateDetailsView`;
export const API_DASHBOARD_STATUS_UPDATE = `${API_DASHBOARD}setSuperCandidateStatus`;
export const API_CANDIDATE_TERMINATE = `${API_DASHBOARD}setCandidateTermination`;
export const API_UPDATE_CANDIDATE_PERMISSIONS = `${API_DASHBOARD}getupdatesuperuserpermissions`;
export const API_CANDIDATE_RESET_PASSWORD =
    'user/forgotPasswordThroughCandidate';
export const API_SAVE_GRACE_PERIOD =
    'superdashboard/setSuperCandidateGracePeriod';
export const API_COMPLEMENTARY_PERIOD =
    'superdashboard/saveAndUpdateComplementaryPeriodDate';
export const API_CANCEL_SUBSCRIPTION =
    'superdashboard/setCandidateCancelSubscription';

// Plan Management
export const API_PLAN_MANAGEMENT = 'superplanmanagement/';
export const API_PLAN_MANAGEMENT_LIST = `${API_PLAN_MANAGEMENT}getPlanManagementList`;
export const API_GET_PLAN_DETAIL = 'superplanmanagement/getselectedplandetails';
export const API_GET_PLAN_ACTIVITY = 'superplanmanagement/getPlanActivity';
export const API_GET_SUBSCRIBER_LIST =
    'superplanmanagement/getAllSubscribersList';
export const API_GET_PLAN_MGMT_DATA_TYPE =
    'superplanmanagement/getPlanInputTypeDropDown';
export const API_SET_ADD_ON = 'superplanmanagement/AddNewTypeAddOnInPlan';
export const API_UPDATE_PRICING = 'superplanmanagement/UpdatePlanPricing';
export const API_GET_ERP_PLAN_DETAIL =
    'superplanmanagement/getSelectedEnterpriseplanDetails';
export const API_UPDATE_FEATURE_VALUES =
    'superplanmanagement/UpdatePlanFeatures';
export const API_UPDATE_PRICING_ENT =
    'superplanmanagement/CreateUpdateEnterprisePlan';
export const API_ADD_NEW_TYPE = 'superplanmanagement/AddNewTypeInPlan';
export const API_GET_DROPDOWN_TYPES = `${API_PLAN_MANAGEMENT}getPlanInputTypeDropDown`;
export const API_CREATE_PLAN = `${API_PLAN_MANAGEMENT}CreateUpdateEnterPrisePlan`;
export const API_SIGNUP_EMAIL = `${API_PLAN_MANAGEMENT}EnterprisePlanSignUpSendMail`;
export const API_GET_ENTERPRISE_PLAN_DETAILS_ON_SELECT = `${API_PLAN_MANAGEMENT}getEnterpriseplanDetailsonSelecteType`;
export const API_UPDATE_ENTERPRISE_PLAN_STATUS = `${API_PLAN_MANAGEMENT}UpdateEnterprisePlanStatus`;
export const API_UPDATE_NOT_APPLICABLE =
    'superplanmanagement/getdeletePlanComponent';

// Contact change log
export const API_CONTACT_LOG = 'contact/selectContactChangeLogList';
export const API_UPDATE_CONTACT_LOG = 'contact/updatecontactchangelog';
export const API_CONTACT_LOG_NAME_OPTIONS = 'contact/getContactLogDropDownt';
export const API_PLAN_FEATURES_DETAILS = `${API_PLAN_MANAGEMENT}getPricingFeaturesNameDetails`;
