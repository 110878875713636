import { FC, useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

import NoRecords from '../../common/NoRecords';
import { updatePricingFeatureAPI } from '../../api';
import { frequencyDropdownOptions } from '../../constants';
import { checkIsOnlyNumber } from '../../common/CommonMethods';
import { EditBackgroundImg, DollarImg } from '../../assets/images';
import {
    WhiteCard,
    FilterTag,
    InputSearch,
    Button,
    getIntWithTwoDecimal,
    DropDownStyle,
    getPlanLabelInPricing,
    getNumberUptoTwoDecimal,
} from '../../common';

const PricingDetailsEnterprise: FC<any> = ({
    pricingDetails,
    otherEntDetails,
    getPlanDetail,
}) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [pricingValues, setPricingValues] = useState<{
        value1: string;
        value2: string;
    }>({
        value1: '',
        value2: '',
    });

    const handleUpdate = async () => {
        try {
            const response = await updatePricingFeatureAPI({
                eentrpriseplanId: otherEntDetails?.eentrpriseplanId,
                subTitle: otherEntDetails?.subTitle,
                status: otherEntDetails?.status,
                planTypeId: otherEntDetails?.planTypeId,
                featuresComponentModelList: [
                    {
                        componentId: otherEntDetails?.eentrpriseplanId,
                        componentValue: '',
                        inputId: 3,
                        value1: pricingValues.value1,
                        value2: pricingValues.value2,
                        value3: '',
                        featuresMasterId: pricingDetails?.featuresMasterId,
                    },
                ],
            });
            if (response?.status === 200) {
                toast.success(response?.data?.split('~')[0]);
                setIsEdit(false);
                getPlanDetail(false);
            }
        } catch (error: any) {
            toast.error(error?.data);
        }
    };

    const handleChange = (val: any, type: string, isNum: boolean) => {
        if (isNum) {
            if (checkIsOnlyNumber(val)) {
                setPricingValues({ ...pricingValues, [type]: val });
            }
        } else {
            setPricingValues({ ...pricingValues, [type]: val });
        }
    };

    const handlePricingEntValues = useCallback(() => {
        setPricingValues({
            value1: getNumberUptoTwoDecimal(
                pricingDetails?.featurChildlist[0].value1
            ),
            value2: pricingDetails?.featurChildlist[0].lable,
        });
    }, [pricingDetails?.featurChildlist]);

    useEffect(() => {
        handlePricingEntValues();
    }, [handlePricingEntValues]);

    return (
        <WhiteCard className="mt-4">
            <div className="card-header d-flex justify-content-between">
                Pricing
                {!isEdit ? (
                    <span>
                        <img
                            src={EditBackgroundImg}
                            className="cursor"
                            role="presentation"
                            alt="edit"
                            onClick={() => {
                                setIsEdit(true);
                            }}
                        />
                    </span>
                ) : (
                    ''
                )}
            </div>
            <div className="horizontal-line mt-3" />

            <div className="row">
                {pricingDetails?.featurChildlist[0].value1 &&
                pricingDetails?.featurChildlist[0].lable ? (
                    <div className="col mt-2">
                        <FilterTag className="status-block mt-4 ">
                            {getPlanLabelInPricing(pricingValues?.value2)}
                        </FilterTag>
                        {isEdit ? (
                            <form className="row mt-2">
                                <div className="col-12">
                                    <p className=" py-2">Amount</p>
                                    <ul className="pricing-amt">
                                        <li>
                                            <InputSearch>
                                                <input
                                                    type="text"
                                                    className="form-control dollar-input"
                                                    onChange={(e) =>
                                                        handleChange(
                                                            e.target.value,
                                                            'value1',
                                                            true
                                                        )
                                                    }
                                                    value={
                                                        pricingValues?.value1
                                                    }
                                                />
                                                <img
                                                    src={DollarImg}
                                                    alt="dollar-img"
                                                    className="dollar-img"
                                                />
                                            </InputSearch>
                                        </li>
                                        <li className="amount-per-date">
                                            <DropDownStyle>
                                                <Select
                                                    value={frequencyDropdownOptions.find(
                                                        (ele: any) =>
                                                            ele.value ===
                                                            pricingValues?.value2
                                                    )}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    options={
                                                        frequencyDropdownOptions
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            e?.value,
                                                            'value2',
                                                            false
                                                        )
                                                    }
                                                />
                                            </DropDownStyle>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        ) : (
                            <div>
                                <div className="page-title mt-4 ">
                                    {getIntWithTwoDecimal(
                                        pricingValues?.value1
                                    )}
                                    <span className="ml-2 pre-pay">
                                        {`/${pricingValues?.value2}`}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="col">
                        <NoRecords />
                    </div>
                )}
            </div>
            {isEdit ? (
                <div className="row">
                    <div className="col-12 text-right mt-4">
                        <Button
                            className="btn-border mr-3"
                            onClick={() => {
                                handlePricingEntValues();
                                setIsEdit(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            onClick={handleUpdate}
                            disabled={!pricingValues.value1}
                        >
                            Update
                        </Button>
                    </div>
                </div>
            ) : (
                ''
            )}
        </WhiteCard>
    );
};

export default PricingDetailsEnterprise;
