import styled from 'styled-components';

const DropDownSelect = styled.div`
    .input-aff {
        min-height: 40px !important;
    }

    .label {
        margin-bottom: 3px !important;
        color: ${(props: any) => props.theme.colors.grey85};
        font-size: ${(props: any) => props.theme.fontSizes.normal};
        display: inline-block;
    }

    &.w-250 {
        width: 250px;
        float: right;
        @media screen and (max-width: 991px) {
            float: left;
        }
    }

    &.w-250-multi-event {
        width: 250px;
        float: right;
        @media screen and (max-width: 991px) {
            float: left;
            margin-top: 10px;
        }
    }

    &.w-100 {
        width: 100%;
        .css-b62m3t-container {
            max-width: 350px !important;
        }
    }

    .css-b62m3t-container {
        min-height: 40px;
        cursor: pointer;
        font-size: ${(props: any) => props.theme.fontSizes.normal};
        color: ${(props: any) => props.theme.colors.grey3};
        border-color: ${(props: any) => props.theme.colors.grey2};
        box-shadow: none !important;
        outline: none !important;
        svg {
            color: ${(props: any) => props.theme.colors.black} !important;
            stroke: black !important;
        }
        .css-13cymwt-control {
            min-height: 40px;

            .css-1xc3v61-indicatorContainer {
                svg {
                    color: ${(props: any) =>
                        props.theme.colors.black} !important;

                    path {
                        color: ${(props: any) =>
                            props.theme.colors.black} !important;
                    }
                }
            }
        }

        .css-1hb7zxy-IndicatorsContainer {
            background-color: ${(props: any) => props.theme.colors.white};
            .css-1xc3v61-indicatorContainer {
                color: ${(props: any) => props.theme.colors.black};
            }
        }

        .css-tj5bde-Svg {
            color: ${(props: any) => props.theme.colors.black};
        }

        .css-t3ipsp-control {
            min-height: 40px;
            border-color: ${(props: any) => props.theme.colors.grey2};
            box-shadow: none !important;
            outline: none !important;
            &:hover,
            &:focus {
                border-color: ${(props: any) => props.theme.colors.grey2};
                box-shadow: none !important;
                outline: none !important;
            }

            .css-1wy0on6 {
                .css-151sz6c-indicatorContainer {
                    color: ${(props: any) =>
                        props.theme.colors.white} !important;
                    stroke: white !important;

                    .css-8mmkcg {
                        color: ${(props: any) =>
                            props.theme.colors.white} !important;
                        stroke: white !important;
                    }

                    svg {
                        color: ${(props: any) =>
                            props.theme.colors.white} !important;
                        stroke: white !important;

                        path {
                            color: ${(props: any) =>
                                props.theme.colors.white} !important;
                            stroke: white !important;
                        }
                    }
                }

                .css-7hmgah-indicatorContainer {
                    color: ${(props: any) =>
                        props.theme.colors.white} !important;
                    stroke: white !important;

                    .css-8mmkcg {
                        color: ${(props: any) =>
                            props.theme.colors.white} !important;
                        stroke: white !important;
                    }

                    svg {
                        color: ${(props: any) =>
                            props.theme.colors.white} !important;
                        stroke: white !important;

                        path {
                            color: ${(props: any) =>
                                props.theme.colors.white} !important;
                            stroke: white !important;
                        }
                    }
                }

                .css-mnviuz-indicatorContianer {
                    color: ${(props: any) =>
                        props.theme.colors.white} !important;
                    stroke: white !important;

                    .css-8mmkcg {
                        color: ${(props: any) =>
                            props.theme.colors.white} !important;
                        stroke: white !important;
                    }

                    svg {
                        color: ${(props: any) =>
                            props.theme.colors.white} !important;
                        stroke: white !important;

                        path {
                            color: ${(props: any) =>
                                props.theme.colors.white} !important;
                            stroke: white !important;
                        }
                    }
                }
            }
        }
        .css-1hb7zxy-IndicatorsContainer {
            .css-1u9des2-indicatorSeparator {
                display: none !important;
            }
            .css-1xc3v61-indicatorContainer {
                color: ${(props: any) => props.theme.colors.black};
            }
        }

        .css-1nmdiq5-menu {
            z-index: 3;
        }
    }

    &.sorting-list {
        .css-b62m3t-container {
            .css-13cymwt-control {
                max-width: 102px !important;
                width: 100% !important;
            }

            .css-1hb7zxy-IndicatorsContainer {
                .css-1xc3v61-indicatorContainer {
                    color: ${(props: any) => props.theme.colors.white};
                }
            }

            .css-tj5bde-Svg {
                color: ${(props: any) => props.theme.colors.white};
            }

            .css-t3ipsp-control {
                .css-151sz6c-indicatorContainer {
                    svg {
                        color: ${(props: any) => props.theme.colors.white};
                        path {
                            color: ${(props: any) => props.theme.colors.white};
                        }
                    }
                }
            }
        }
    }
    &.dropdown-disabled {
        .react-select__control {
            opacity: 0.5;
            cursor: not-allowed !important;
            pointer-events: none !important;
        }
    }
`;

export const MergeModalField = styled.div`
    position: relative;
    .container {
        display: block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .input-text {
            color: white;
            margin-left: 20px;
            z-index: 2;
            z-index: 99;
            position: absolute;
            padding-top: 8px;
            font-size: ${(props: any) => props.theme.fontSizes.normal};
            &.text-black {
                color: ${(props: any) => props.theme.colors.black};
            }
        }
    }

    /* Hide the browser's default radio button */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        width: 100%;
        border: 1px solid ${(props: any) => props.theme.colors.grey3};
        border-radius: 4px;
        font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
        font-size: ${(props: any) => props.theme.fontSizes.normal};
        padding: 7px 11px;
        min-height: 40px;
    }

    .container input:checked ~ .checkmark {
        background-color: ${(props: any) => props.theme.colors.primaryColor};
    }

    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    .container input:checked ~ .checkmark:after {
        display: block;
    }
`;

export default DropDownSelect;
