import { useState } from 'react';

import jwt from 'jwt-decode';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import Loader from '../../common/Loader';
import { InputType } from '../../types';
import { validateUser } from '../../api';
import { useAppDispatch } from '../../store/hook';
import { setAuthDetails } from '../../features/authSlice';
import { LoginStyle } from './AuthStyles';
import { Button, CheckBox, ErrorMsg, handleError } from '../../common';
import { PATH_DASHBOARD, PATH_FORGOT_PASSWORD } from '../../constants';
import {
    LoginLeftImg,
    LoginLock,
    LoginUserProfile,
    AdminLoginImg,
    OpenEyeIcon,
    CloseEyeIcon,
} from '../../assets/images';

const Login = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = useForm<InputType>({
        mode: 'onChange',
        delayError: 500,
    });
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleLogin: SubmitHandler<InputType> = async (data: InputType) => {
        try {
            setIsLoading(true);
            const response = await validateUser(data);
            const token = response?.data?.token;
            const user: any = jwt(token);
            if (data.isRemember) {
                localStorage.setItem('isRemember', 'true');
            } else {
                localStorage.removeItem('isRemember');
            }
            localStorage.setItem('user', data.user!);
            localStorage.setItem('token', token);
            const userDetails: any = {
                userEmail: data.user!,
                givenName:
                    user[
                        `http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname`
                    ],
                surName:
                    user[
                        `http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname`
                    ],
                role: user[
                    `http://schemas.microsoft.com/ws/2008/06/identity/claims/role`
                ],
                accessToken: token,
                userId: user?.Id,
            };
            dispatch(setAuthDetails(userDetails));
            if (isSubmitSuccessful) {
                reset();
            }
            navigate(PATH_DASHBOARD, { replace: true });
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const ThemeData =
        localStorage.getItem('theme') &&
        JSON.parse(localStorage.getItem('theme') || '');

    return (
        <LoginStyle>
            <div className="container-fluid">
                <div className="row h-100">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-6 login-left">
                                <div className="logo">
                                    <img
                                        src={ThemeData?.adminLogo}
                                        alt="Logo"
                                        width="165px"
                                    />
                                </div>
                                <div className="login-left-style">
                                    <img
                                        width="310px"
                                        src={LoginLeftImg}
                                        alt="logo"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="login-right-style">
                                    <div className="logo-rigt-img">
                                        <img
                                            src={ThemeData?.adminLogo}
                                            alt="logo"
                                            width="165px"
                                        />
                                    </div>

                                    <h1 className="heading text-center mb-4">
                                        Welcome To {ThemeData?.type}!
                                    </h1>
                                    <div className="admin-login">
                                        <div className="adminlogin-img">
                                            <img src={AdminLoginImg} alt="" />
                                        </div>
                                        <p className="admin-title">
                                            Admin Login
                                        </p>
                                        <form
                                            onSubmit={handleSubmit(handleLogin)}
                                            id="loginform"
                                        >
                                            <div className="fromfield py-2">
                                                <input
                                                    type="text"
                                                    id="user"
                                                    {...register('user', {
                                                        required: {
                                                            value: true,
                                                            message:
                                                                'This field is required.',
                                                        },
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                            message:
                                                                'Invalid email address.',
                                                        },
                                                    })}
                                                />
                                                <div className="login-icon">
                                                    <img
                                                        src={LoginUserProfile}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <ErrorMsg>
                                                {errors?.user?.message}
                                            </ErrorMsg>

                                            <div className="fromfield py-2">
                                                <input
                                                    type={
                                                        showPassword
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    id="password"
                                                    {...register('password', {
                                                        required: {
                                                            value: true,
                                                            message:
                                                                'This field is required.',
                                                        },
                                                    })}
                                                />
                                                <div className="login-icon">
                                                    <img
                                                        src={LoginLock}
                                                        alt=""
                                                    />
                                                </div>
                                                <img
                                                    src={
                                                        showPassword
                                                            ? OpenEyeIcon
                                                            : CloseEyeIcon
                                                    }
                                                    role="presentation"
                                                    className="show-password-icon"
                                                    alt=""
                                                    onClick={() =>
                                                        setShowPassword(
                                                            !showPassword
                                                        )
                                                    }
                                                />
                                            </div>
                                            <ErrorMsg>
                                                {errors?.password?.message}
                                            </ErrorMsg>

                                            <div className="row">
                                                <div className="col-6 py-4">
                                                    <CheckBox>
                                                        <label
                                                            className="check-container customer-pannel ml-2"
                                                            htmlFor="remembermecheck"
                                                        >
                                                            <input
                                                                className="checkboxes"
                                                                type="checkbox"
                                                                id="remembermecheck"
                                                                {...register(
                                                                    'isRemember'
                                                                )}
                                                            />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </CheckBox>
                                                    <p className="password-text pl-4 ml-2">
                                                        Remember me
                                                    </p>
                                                </div>

                                                <div className="col-6 text-right py-4">
                                                    <Link
                                                        to={
                                                            PATH_FORGOT_PASSWORD
                                                        }
                                                        className="password-text"
                                                    >
                                                        Forgot password?
                                                    </Link>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="text-center pt-1">
                                        <Button
                                            type="submit"
                                            form="loginform"
                                            disabled={isLoading}
                                        >
                                            {isLoading ? (
                                                <Loader type="button" />
                                            ) : (
                                                'Sign in'
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoginStyle>
    );
};

export default Login;
