import axiosInstance from '../axiosInstance';

import {
    SearchOptionsType,
    EmailAPIOptions,
    PhoneAPIOptions,
    AddressAPIOptions,
    PhonePrimaryAPIOptions,
    AddressPrimaryAPIOptions,
    DeleteEmailType,
    DeletePhoneType,
    ContactLogType,
    UpdateContactLogType,
} from '../types/type';

import {
    API_GET_CONTACTS,
    API_SELECTED_CONTACT_DETAILS,
    API_SET_EMAIL,
    API_SET_PHONE,
    API_SET_ADDRESS,
    API_SET_EMAIL_PRIMARY,
    API_SET_PHONE_PRIMARY,
    API_SET_ADDRESS_PRIMARY,
    API_DELETE_EMAIL,
    API_DELETE_PHONE,
    API_DELETE_ADDRESS,
    API_DELETE_CONTACT,
    API_UPLOAD_EXCEL,
    API_MERGE_ALL_CONTACTS,
    API_KEEP_BOTH_CONTACTS,
    API_MERGE_A_CONTACT,
    API_DELETE_BULK_CONTACT,
    API_FILE_UPLOAD_LOG,
    API_DELETE_LAST_UPLOADED_FILE,
    API_GET_CAMPAIGN_AFFILIATION,
    API_UPDATE_CONTACT,
    API_GET_CONTACTS_FILTERS,
    API_GET_ZIP_FILTERS,
    API_CONTACT_LOG,
    API_UPDATE_CONTACT_LOG,
    API_CONTACT_LOG_NAME_OPTIONS,
} from '../constants';

export const getContactsRecordsAPI = async (params: SearchOptionsType) => {
    const param = JSON.stringify({ ...params });
    const result = await axiosInstance
        .post(API_GET_CONTACTS, param)
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};

export const getContactDetailsAPI = async (ContactId: number) => {
    const result = await axiosInstance
        .get(API_SELECTED_CONTACT_DETAILS, {
            params: {
                ContactId,
            },
        })
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};

export const uploadExcelFileData = async (body: any) => {
    const response = await axiosInstance.post(API_UPLOAD_EXCEL, body);
    return response;
};

export const mergeAllContacts = async (data: any) => {
    const result = await axiosInstance
        .post(API_MERGE_ALL_CONTACTS, data)
        .then((response: any) => {
            return response;
        })
        .catch((error: any) => {
            return error.response;
        });
    return result;
};

export const keepBothContacts = async (data: any) => {
    const result = await axiosInstance
        .post(API_KEEP_BOTH_CONTACTS, data)
        .then((response: any) => {
            return response;
        })
        .catch((error: any) => {
            return error.response;
        });
    return result;
};

export const mergeAContact = async (data: any) => {
    const result = await axiosInstance
        .post(API_MERGE_A_CONTACT, data)
        .then((response: any) => {
            return response;
        })
        .catch((error: any) => {
            return error.response;
        });
    return result;
};

export const setEmailAPI = async (params: EmailAPIOptions) => {
    const param = JSON.stringify({ ...params });
    const result = await axiosInstance
        .post(API_SET_EMAIL, param)
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};

export const setPhoneAPI = async (params: PhoneAPIOptions) => {
    const param = JSON.stringify({ ...params });
    const result = await axiosInstance
        .post(API_SET_PHONE, param)
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};

export const setAddressAPI = async (params: AddressAPIOptions) => {
    const param = JSON.stringify({ ...params });
    const result = await axiosInstance
        .post(API_SET_ADDRESS, param)
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};

export const setPrimaryEmailAPI = async (params: EmailAPIOptions) => {
    const param = JSON.stringify({ ...params });
    const result = await axiosInstance
        .post(API_SET_EMAIL_PRIMARY, param)
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};

export const setPrimaryPhoneAPI = async (params: PhonePrimaryAPIOptions) => {
    const param = JSON.stringify({ ...params });
    const result = await axiosInstance
        .post(API_SET_PHONE_PRIMARY, param)
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};

export const setPrimaryAddressAPI = async (
    params: AddressPrimaryAPIOptions
) => {
    const param = JSON.stringify({ ...params });
    const result = await axiosInstance
        .post(API_SET_ADDRESS_PRIMARY, param)
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};

export const deleteEmailAPI = async (params: DeleteEmailType) => {
    const param = JSON.stringify({ ...params });
    const result = await axiosInstance
        .post(API_DELETE_EMAIL, param)
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};

export const deletePhoneAPI = async (params: DeletePhoneType) => {
    const param = JSON.stringify({ ...params });
    const result = await axiosInstance
        .post(API_DELETE_PHONE, param)
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};

export const deleteAddressAPI = async (params: AddressPrimaryAPIOptions) => {
    const param = JSON.stringify({ ...params });
    const result = await axiosInstance
        .post(API_DELETE_ADDRESS, param)
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};

export const deleteContactAPI = async (ContactId: number) => {
    const params = { ContactId };
    const result = await axiosInstance
        .delete(API_DELETE_CONTACT, { params })
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};

export const deleteBulkContactAPI = async (contactids: string) => {
    const param = JSON.stringify({ contactids });
    const result = await axiosInstance
        .post(API_DELETE_BULK_CONTACT, param)
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};
export const getLatestFileUploadLog = async () => {
    const result = await axiosInstance
        .get(API_FILE_UPLOAD_LOG)
        .then((response) => response)
        .catch((error) => error);
    return result;
};

export const deleteLastUploadedFile = async () => {
    const result = await axiosInstance
        .delete(API_DELETE_LAST_UPLOADED_FILE)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getAffiliationDetails = async () => {
    const result = await axiosInstance
        .get(API_GET_CAMPAIGN_AFFILIATION)
        .then((res) => res)
        .catch((error) => error.response);
    return result;
};

export const updateContact = async (details: any) => {
    const updatedDetails = JSON.stringify(details);
    const result = await axiosInstance
        .put(API_UPDATE_CONTACT, updatedDetails)
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};

export const getFiltersAPI = async () => {
    const result = await axiosInstance
        .get(API_GET_CONTACTS_FILTERS)
        .then((res) => res)
        .catch((error) => error.response);
    return result;
};

export const getZipAPI = async (Name: string) => {
    const result = await axiosInstance
        .get(API_GET_ZIP_FILTERS, {
            params: {
                Name,
            },
        })
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};

// get list of contact change logs
export const getContactLog = async (
    filters: ContactLogType,
    searchText: string
): Promise<ContactLogType> => {
    const result = await axiosInstance
        .post<ContactLogType>(API_CONTACT_LOG, {
            ...filters,
            searchText,
        })
        .then((response: any) => {
            return response;
        })
        .catch((error: any) => {
            return error.response;
        });
    return result;
};

// update the contact change logs
export const updateContactLog = async (
    data: UpdateContactLogType
): Promise<UpdateContactLogType> => {
    const result = await axiosInstance
        .post<UpdateContactLogType>(API_UPDATE_CONTACT_LOG, data)
        .then((response: any) => {
            return response;
        })
        .catch((error: any) => {
            return error.response;
        });
    return result;
};

// get dropdown options for who changed the contacts
export const getContactLogOptions = async () => {
    const result = await axiosInstance
        .get(API_CONTACT_LOG_NAME_OPTIONS)
        .then((response) => response)
        .catch((error) => error.response);
    return result;
};
