import { createGlobalStyle } from 'styled-components';

import WorkSansRegular from '../font/static/WorkSans-Regular.ttf';
import WorkSansSemiBold from '../font/static/WorkSans-SemiBold.ttf';
import WorkSansMedium from '../font/static/WorkSans-Medium.ttf';
import InterRegular from '../font/static/Inter-Regular.ttf';
import InterSemiBold from '../font/static/Inter-SemiBold.ttf';
import InterMedium from '../font/static/Inter-Medium.ttf';

const BodyStyles = createGlobalStyle`

@font-face {
  font-family: 'WorkSansProRegular';
  src: url(${WorkSansRegular});
}

@font-face {
  font-family: 'WorkSansSemiBold';
  src: url(${WorkSansSemiBold});
}

@font-face {
  font-family: 'WorkSansMedium';
  src: url(${WorkSansMedium});
}

@font-face {
  font-family: 'InterRegular';
  src: url(${InterRegular});
}

@font-face {
  font-family: 'InterSemiBold';
  src: url(${InterSemiBold});
}

@font-face {
  font-family: 'InterMedium';
  src: url(${InterMedium});
}


mediunFontFamily
#root {
  height: 100%;
}

html,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: ${(props: any) => props.theme.colors.white};
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
  color: ${(props: any) => props.theme.colors.secondaryColor};
}

.container-fluid {
  width: 100%;
  height: 100%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
  text-align: -webkit-right;
}

.text-left {
  text-align: left !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-100 {
  height: 100% !important;
}

.h-100vh {
  height: 100vh !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.clear-fix {
  clear: both;
}

.cursor {
  cursor: pointer !important;
}

.fix-height {
  flex-wrap: wrap;
  flex: initial;
  height: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize !important;
}

.card-header {
  font-size: ${(props: any) => props.theme.fontSizes.normal};
  color: ${(props: any) => props.theme.colors.secondaryColor};
  font-family: ${(props: any) => props.theme.fonts.FontFamily};
  padding-right: 2px;

  &.small-header {
    font-size: ${(props: any) => props.theme.fontSizes.medium};
  }
}

.horizontal-line {
  border-bottom: 1px solid ${(props: any) => props.theme.colors.grey2}
}

.grey-text {
  color: ${(props: any) => props.theme.colors.grey3};
  font-size: ${(props: any) => props.theme.fontSizes.smallText};
}

.page-title {
  font-size: ${(props: any) => props.theme.fontSizes.larger};
  font-family: ${(props: any) => props.theme.fonts.semiBoldFontFamily};

  .grey-option {
    font-size: ${(props: any) => props.theme.fontSizes.medium};
    color: ${(props: any) => props.theme.colors.grey4};
    font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
    padding-left: 5px;
  }

  .refresh-icon {
    vertical-align: middle;
    padding-left: 8px;
  }
}

.pre-pay {
  color: ${(props: any) => props.theme.colors.grey9};
  font-size: ${(props: any) => props.theme.fontSizes.medium};
  font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
  span {
    text-decoration: line-through;
  }
}

.grey-long-text {
  color: ${(props: any) => props.theme.colors.grey4};
  font-size: ${(props: any) => props.theme.fontSizes.medium};
}

.font-regular {
  font-family: ${(props: any) =>
      props.theme.fonts.regularFontFamily} !important;
}

.table-responsive{
 width: 100%;
 overflow-x: scroll;
 overflow-y: scroll;
 height: fit-content;
 &.duplicates-table{
  height: calc(100vh - 213px);
 }
 &.show-voter-table{
  height: calc(100vh - 560px);
 }
 
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  position: absolute;
  user-select: none;
  opacity: 0;
  transition: opacity 300ms ease-in-out 0s;
}
::-webkit-scrollbar-thumb {
  position: absolute;
  user-select: none;
  opacity: 0;
  transition: opacity 300ms ease-in-out 0s;
  background-color: ${(props: any) => props.theme.colors.grey4};
  border-radius: 20px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.text-break {
  word-break: break-all;
}

/* form-control for input */
.form-control {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: ${(props: any) => props.theme.colors.white};
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition:
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
    }

    @media (prefers-reduced-motion: reduce) {
        .form-control {
            transition: none;
        }
    }

    .form-control::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    .form-control:focus {
        color: #495057;
        background-color: ${(props: any) => props.theme.colors.white};
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    .form-control::-webkit-input-placeholder {
        color: ${(props: any) => props.theme.colors.grey14};
        opacity: 1;
    }

    .form-control::-moz-placeholder {
        color: ${(props: any) => props.theme.colors.grey14};
        opacity: 1;
    }

    .form-control:-ms-input-placeholder {
        color: ${(props: any) => props.theme.colors.grey14};
        opacity: 1;
    }

    .form-control::-ms-input-placeholder {
        color:  ${(props: any) => props.theme.colors.grey14};
        opacity: 1;
    }

    .form-control::placeholder {
        color: ${(props: any) => props.theme.colors.grey14};
        opacity: 1;
    }

    .form-control:disabled,
    .form-control[readonly] {
        background-color: #e9ecef;
        opacity: 1;
    }

    input[type='date'].form-control,
    input[type='time'].form-control,
    input[type='datetime-local'].form-control,
    input[type='month'].form-control {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    textarea.form-control {
  height: auto;
}

/*  utility classes */
 h1, h2, h3, h4, h5, h6{
  font-family: ${(props: any) => props.theme.fonts.semiBoldFontFamily};
  color: ${(props: any) => props.theme.colors.secondaryColor};
  font-weight: 600;
}
/* general CSS */

.body-container{
  position: relative;
  z-index: 8;
  padding: 86px 28px 0px 302px;

  @media screen and (max-width: 991px) {
    margin: 86px auto 0px auto;
    padding: 0px 18px;
  }
}

.disabled-text{
  color: ${(props: any) => props.theme.colors.grey3};
}

.position-relative {
  position: relative
}
.disabled-btn{
  opacity: 0.6;
  cursor: not-allowed !important;
  pointer-events: auto !important;
}
.disabled-toggle{
   opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}
input[type="range"] {
  accent-color: ${(props: any) => props.theme.colors.black};
  cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
  cursor: pointer;
}
.box-shadow-small {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.txt-right{
  text-align: right
}
.error-border{
  border:1px solid ${(props: any) => props.theme.colors.red} !important;
}
.error-message {
  color: ${(props: any) => props.theme.colors.red}
}

.text-md-left {
   @media only screen and (max-width: 991px)  {
     text-align: left !important;
     text-align: -webkit-left;
   } 
  }
.map-excel-background{
  position: relative;
  margin-left:0px;
  margin-right:0px ;
  background-color: ${(props: any) => props.theme.colors.white70};
  &.align-first-child{
    margin-top:136px !important;
  }
}

.map-excel-header {
  font-family: ${(props: any) => props.theme.fonts.semiBoldFontFamily};
  color: ${(props: any) => props.theme.colors.grey85};
  font-size: ${(props: any) => props.theme.fontSizes.larger};
}

.field-name-pills{
  display:inline-block;
  border-radius: 16px;
  background: ${(props: any) => props.theme.colors.white};
  padding: 5px 12px;
  margin:4px ;
  cursor: pointer;
  &.selected{
    background:${(props: any) => props.theme.colors.primaryColor};
    color:${(props: any) => props.theme.colors.white}
  }
  &.filed-name-pills-show{
    border: 1px solid ${(props: any) => props.theme.colors.primaryColor};
    color: ${(props: any) => props.theme.colors.primaryColor};
    padding:7px 14px ;
  }
}
.text-grey {
  color: ${(props: any) => props.theme.colors.grey85};
  font-size: ${(props: any) => props.theme.fontSizes.normal};
  font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
}
.error-align {
    vertical-align: top;
    padding-left: 10px;
  }
.sticky-header{
  position: fixed;
  z-index: 2;
  top: 95px;
  left: 301px;
  right: 33px;
  width: -webkit-fill-available;
  width: initial;
  padding-top: 18px;
  background-color: ${(props: any) => props.theme.colors.white};
  margin-bottom: 15px;
  &.nav-sticky{
    top:61px;
    margin-bottom:0;
  }
}

.position-absolute{
  position: absolute;
}

.show-dots {
  color:${(props: any) => props.theme.colors.primaryColor};
  font-size: ${(props: any) => props.theme.fontSizes.extarLarger};
}
.disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
  pointer-events: none !important;
  user-select: none !important;
  -webkit-user-select: none !important;
}
.link-name:hover{
  color: ${(props: any) => props.theme.colors.primaryColor};
  text-decoration: underline;
}

.success-password-reset{
  height:90vh;
}

.middle-align {
  vertical-align: middle;
}

.vertical-align-middle {
  vertical-align: middle;
}
.canceled-subscription{
  background-color: ${(props: any) => props.theme.colors.userTextColor};
  cursor: auto;
  border-radius: 20px;
}
.btn-bown-sub{
  display: flex;
  margin-left: 10px;

   @media only screen and (max-width: 1516px)  {
    margin-left: 0px;
   } 
}
`;

export default BodyStyles;
