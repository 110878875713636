import axiosInstance from '../axiosInstance';
import {
    API_PLAN_FEATURES_DETAILS,
    API_PLAN_MANAGEMENT_LIST,
    API_GET_PLAN_DETAIL,
    API_GET_PLAN_ACTIVITY,
    API_GET_SUBSCRIBER_LIST,
    API_GET_PLAN_MGMT_DATA_TYPE,
    API_SET_ADD_ON,
    API_UPDATE_PRICING,
    API_GET_ERP_PLAN_DETAIL,
    API_UPDATE_FEATURE_VALUES,
    API_UPDATE_PRICING_ENT,
    API_ADD_NEW_TYPE,
    API_GET_DROPDOWN_TYPES,
    API_CREATE_PLAN,
    API_SIGNUP_EMAIL,
    API_GET_ENTERPRISE_PLAN_DETAILS_ON_SELECT,
    API_UPDATE_ENTERPRISE_PLAN_STATUS,
    API_UPDATE_NOT_APPLICABLE,
} from '../constants';
import { PlanManagementListType } from '../types/type';

// api for applying search and sorting of the plan management list
export const getPlanManagementList = (
    searchText: string
): Promise<PlanManagementListType> => {
    return axiosInstance
        .post<PlanManagementListType>(API_PLAN_MANAGEMENT_LIST, {
            searchText,
        })
        .then((response) => response)
        .catch((error) => error.response);
};

export const getPlanDetailsFeatuers = (isEnterprise: boolean) => {
    return axiosInstance
        .get(`${API_PLAN_FEATURES_DETAILS}?IsEnterprise=${isEnterprise}`)
        .then((response: any) => response)
        .catch((error: any) => error.response);
};

// get Starter and Advanced plan details
export const getPlanDetailAPI = (planName: string) => {
    return axiosInstance
        .get(API_GET_PLAN_DETAIL, {
            params: {
                planName,
            },
        })
        .then((response) => response)
        .catch((error) => Promise.reject(error.response));
};

// get Enterprise plan details
export const getEnterPrisePlanDetailAPI = (
    EentrpriseplanId: number,
    SubTitle: string
) => {
    return axiosInstance
        .get(API_GET_ERP_PLAN_DETAIL, {
            params: {
                EentrpriseplanId,
                SubTitle,
            },
        })
        .then((response) => response)
        .catch((error) => Promise.reject(error.response));
};

export const getPlanActivityAPI = (PlanName: string) => {
    return axiosInstance
        .get(API_GET_PLAN_ACTIVITY, {
            params: {
                PlanName,
            },
        })
        .then((response) => response)
        .catch((error) => error.response);
};

export const getSubscriberListAPI = (obj: any) => {
    return axiosInstance
        .get(API_GET_SUBSCRIBER_LIST, {
            params: {
                ...obj,
            },
        })
        .then((response) => response)
        .catch((error) => error.response);
};

export const getDataTypeAPI = () => {
    return axiosInstance
        .get(API_GET_PLAN_MGMT_DATA_TYPE)
        .then((response) => response)
        .catch((error) => Promise.reject(error));
};

export const setAddOnFeatureAPI = (obj: any) => {
    return axiosInstance
        .post(API_SET_ADD_ON, {
            ...obj,
        })
        .then((response) => response)
        .catch((error) => Promise.reject(error));
};

export const updatePricingAPI = (obj: any) => {
    return axiosInstance
        .put(API_UPDATE_PRICING, {
            ...obj,
        })
        .then((response) => response)
        .catch((error) => error.response);
};

// UPDATE STARTER AND ADVANCED VALUES, NOT PRICING
export const setFeatureValues = (obj: any) => {
    return axiosInstance
        .put(API_UPDATE_FEATURE_VALUES, {
            ...obj,
        })
        .then((response) => response)
        .catch((error) => error.response);
};

// UPDATE ENTERPRISE PRICING
export const updatePricingFeatureAPI = (obj: any) => {
    return axiosInstance
        .post(API_UPDATE_PRICING_ENT, {
            ...obj,
        })
        .then((response) => response)
        .catch((error) => Promise.reject(error.response));
};

export const addNewTypeAPI = (obj: any) => {
    return axiosInstance
        .post(API_ADD_NEW_TYPE, {
            ...obj,
        })
        .then((response) => response)
        .catch((error) => Promise.reject(error));
};
export const getTypesDropdown = () => {
    return axiosInstance
        .get(API_GET_DROPDOWN_TYPES)
        .then((response) => response)
        .catch((error) => error.response);
};

export const createEnterPrisePlan = (body: any) => {
    return axiosInstance
        .post(API_CREATE_PLAN, body)
        .then((response) => response)
        .catch((error) => error.response);
};

export const sendSignupEmail = (body: any) => {
    return axiosInstance
        .post(API_SIGNUP_EMAIL, body)
        .then((response) => response)
        .catch((error) => Promise.reject(error.response));
};

export const getPlanDetailsOnSelect = (id: number) => {
    return axiosInstance
        .get(`${API_GET_ENTERPRISE_PLAN_DETAILS_ON_SELECT}?TypeId=${id}`)
        .then((response) => response)
        .catch((error) => error.response);
};

export const updateEnterprisePlanStatus = (id: any) => {
    return axiosInstance
        .post(API_UPDATE_ENTERPRISE_PLAN_STATUS, { eentrpriseplanId: id })
        .then((response) => response)
        .catch((error) => error.response);
};

// UPDATE APPLICABLE / NOT - APPLICABLE VALUES
export const updateNotApplicable = (
    IsmainType: boolean,
    F_id: number,
    PlanName: string,
    Isactive: boolean
) => {
    const params = { IsmainType, F_id, PlanName, Isactive };
    return axiosInstance
        .delete(API_UPDATE_NOT_APPLICABLE, { params })
        .then((response) => response)
        .catch((error) => error.response);
};
