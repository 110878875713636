import styled from 'styled-components';

const MultiDropdownStyle = styled.div`
    width: 100%;
    cursor: pointer;

    .css-185uon1-control {
        border-radius: 6px;
        border: 1px solid ${(props: any) => props.theme.colors.grey12};
        min-height: 40px;
        font-size: ${(props: any) => props.theme.fontSizes.normal};
        color: ${(props: any) => props.theme.colors.grey9};
        .css-1gtu0rj-indicatorContainer {
            .fa-angle-down {
                color: ${(props: any) => props.theme.colors.grey9} !important;
            }
        }
        .css-uz51yo-control {
            min-height: 40px;
            font-size: ${(props: any) => props.theme.fontSizes.normal};
        }
    }

    .css-1pahdxg-control {
        background-color: ${(props: any) => props.theme.colors.grey1};
        border: 1px solid ${(props: any) => props.theme.colors.grey10};
        box-shadow: none;
        color: ${(props: any) => props.theme.colors.grey9};
        outline: none;
        border-radius: 6px;
        min-height: 40px;
        font-size: ${(props: any) => props.theme.fontSizes.normal};
        .css-b8ldur-Input,
        .css-1g6gooi {
            color: ${(props: any) => props.theme.colors.black};
            font-size: ${(props: any) => props.theme.fontSizes.normal};
        }

        .css-1gtu0rj-indicatorContainer {
            .fa-angle-down {
                color: ${(props: any) => props.theme.colors.grey9} !important;
            }
        }
    }

    .css-1okebmr-indicatorSeparator {
        display: none;
    }

    .react-select__value-container--is-multi {
        span {
            max-width: calc(100% - 8px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .css-26l3qy-menu {
        background: ${(props: any) => props.theme.colors.white};
        border-radius: 8px;
        color: ${(props: any) => props.theme.colors.grey11} !important;
        border: 1px solid ${(props: any) => props.theme.colors.grey12};
        min-height: 40px;
        box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.2);
        .css-4ljt47-MenuList {
            padding-bottom: 4px;
            padding-top: 0;
            border-radius: 8px;
            color: ${(props: any) => props.theme.colors.grey10} !important;
        }

        .react-select__option {
            color: ${(props: any) => props.theme.colors.black} !important ;
            cursor: pointer;
            text-align: left;
        }

        .react-select__option--is-selected {
            color: ${(props: any) => props.theme.colors.white};
        }
    }

    &.multi-select-searchable-dropdown {
        .css-b8ldur-Input,
        .css-1g6gooi {
            padding: 0;
            margin: 0;
        }
        .react-select__input {
            position: absolute;
            top: 0px;
        }
        .react-select__menu-list {
            background: ${(props: any) => props.theme.colors.white};
        }
    }
    &.dropdown-disabled {
        .react-select__control {
            opacity: 0.5;
            cursor: not-allowed !important;
            pointer-events: none !important;
        }
    }
`;
export default MultiDropdownStyle;
