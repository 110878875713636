import { FC } from 'react';

import { useForm, SubmitHandler } from 'react-hook-form';

import { InputSearch, ErrorMsg } from '../../common';
import { TickMarkImg } from '../../assets/images';

type EmailInputs = {
    email: string;
};

type EmailInputsProps = {
    handleChange: (txt: string, val: string) => void;
};

const EmailField: FC<EmailInputsProps> = ({ handleChange }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<EmailInputs>({
        mode: 'onChange',
        delayError: 500,
    });

    const onSubmit: SubmitHandler<EmailInputs> = (data) => {
        handleChange('email', data.email);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-12">
                <InputSearch>
                    <input
                        type="text"
                        className={
                            errors?.email
                                ? 'error-border form-control'
                                : 'form-control'
                        }
                        {...register('email', {
                            maxLength: {
                                value: 100,
                                message: 'Name should be max 100 characters',
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: 'Invalid email address.',
                            },
                        })}
                    />
                </InputSearch>
                <ErrorMsg>{errors?.email?.message}</ErrorMsg>
                <div>
                    <input
                        type="image"
                        src={TickMarkImg}
                        alt="add"
                        style={{ width: '15px', top: '-20px', right: '30px' }}
                        className="position-absolute"
                    />
                </div>
            </div>
        </form>
    );
};

export default EmailField;
