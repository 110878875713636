import { useState } from 'react';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import Loader from '../../common/Loader';
import Button from '../../common/Button';
import ErrorMsg from '../../common/ErrorMessage';
import { forgotPassword } from '../../api/AuthApi';
import { EmailInputs } from '../../types/index';
import { PATH_LOGIN } from '../../constants';
import { LoginStyle } from './AuthStyles';
import {
    ForgetLockImg,
    ForgetPasswordImg,
    LoginUserProfile,
} from '../../assets/images';
import { handleError } from '../../common';

export default function ForgotPassword() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<EmailInputs>({
        mode: 'onChange',
        delayError: 500,
    });

    const [isLoading, setIsLoading] = useState(false);
    const handleFormSubmit: SubmitHandler<EmailInputs> = async (
        userEmail: EmailInputs
    ) => {
        try {
            setIsLoading(true);
            const response = await forgotPassword(userEmail);
            toast.success(response.data);
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const ThemeData =
        localStorage.getItem('theme') &&
        JSON.parse(localStorage.getItem('theme') || '');

    return (
        <LoginStyle>
            <div className="container-fluid">
                <div className="row h-100">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-6 login-left">
                                <div className="logo cursor">
                                    <img
                                        src={ThemeData?.adminLogo}
                                        alt="logo"
                                        width="165px"
                                        onClick={() => navigate(PATH_LOGIN)}
                                        role="presentation"
                                    />
                                </div>
                                <div className="login-left-style">
                                    <img
                                        width="310px"
                                        src={ForgetPasswordImg}
                                        alt="logo"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 right-side">
                                <div className="login-right-style">
                                    <div className="logo-rigt-img">
                                        <img
                                            src={ThemeData?.adminLogo}
                                            alt="logo"
                                        />
                                    </div>
                                    <h1 className="heading text-center mb-4">
                                        Forgot Password
                                    </h1>
                                    <form
                                        onSubmit={handleSubmit(
                                            handleFormSubmit
                                        )}
                                        className="w-100 pt-3"
                                    >
                                        <div className="admin-login">
                                            <div className="adminlogin-img">
                                                <img
                                                    src={ForgetLockImg}
                                                    alt=""
                                                />
                                            </div>
                                            <p className="admin-title text-left">
                                                To reset your password, please
                                                enter your email address
                                            </p>

                                            <div className="fromfield py-2">
                                                <input
                                                    type="text"
                                                    id="email"
                                                    placeholder="Email"
                                                    className={
                                                        errors?.email
                                                            ? 'error-border'
                                                            : ''
                                                    }
                                                    {...register('email', {
                                                        required: {
                                                            value: true,
                                                            message:
                                                                'This field is required.',
                                                        },
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                            message:
                                                                'Invalid email address.',
                                                        },
                                                    })}
                                                />
                                                <div className="login-icon">
                                                    <img
                                                        src={LoginUserProfile}
                                                        alt="email"
                                                    />
                                                </div>

                                                <ErrorMsg>
                                                    {errors?.email?.message}
                                                </ErrorMsg>
                                            </div>
                                        </div>

                                        <div className="mb-3 d-flex justify-content-center">
                                            <Button
                                                className="mt-2"
                                                type="submit"
                                                disabled={isLoading}
                                            >
                                                {isLoading ? (
                                                    <Loader type="button" />
                                                ) : (
                                                    'Submit'
                                                )}
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoginStyle>
    );
}
