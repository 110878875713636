import { ToastContainer } from 'react-toastify';
import ToasterStyle from '../Theme/style/ToasterStyle';

const Toaster = () => {
    return (
        <ToasterStyle>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                pauseOnFocusLoss={false}
                theme="colored"
            />
        </ToasterStyle>
    );
};

export default Toaster;
