import axiosInstance from '../axiosInstance';
import {
    API_DYNAMIC_THEMES,
    API_FORGOT_PASSWORD,
    API_LOGIN,
    API_RESET_PASSWORD,
} from '../constants';
import { EmailInputs, InputType } from '../types';
import { ResetPassword } from '../types/type';

export const validateUser = (data: InputType) => {
    return axiosInstance
        .post(API_LOGIN, {
            userName: data.user,
            password: data.password,
        })
        .then((res) => res)
        .catch((error) => Promise.reject(error));
};

export const forgotPassword = (userEmail: EmailInputs) => {
    return axiosInstance
        .post(API_FORGOT_PASSWORD, userEmail)
        .then((response) => response)
        .catch((error) => Promise.reject(error));
};

export const resetPassword = (data: ResetPassword, token: string | null) => {
    return axiosInstance
        .post(API_RESET_PASSWORD, data, {
            params: {
                token,
            },
        })
        .then((response) => response)
        .catch((error) => Promise.reject(error));
};

export const dynamicTheme = () => {
    return axiosInstance
        .get(API_DYNAMIC_THEMES)
        .then((response) => response)
        .catch((error) => Promise.reject(error));
};
