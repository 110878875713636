import styled from 'styled-components';

const LoadingSpinner = styled.div`
    @-webkit-keyframes spinner-border {
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes spinner-border {
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .spinner-border {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        vertical-align: -0.125em;
        border: 0.25em solid currentcolor;
        border-right-color: transparent;
        border-radius: 50%;
        -webkit-animation: 0.75s linear infinite spinner-border;
        animation: 0.75s linear infinite spinner-border;
    }

    @media (prefers-reduced-motion: reduce) {
        .spinner-border {
            -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
        }
    }
`;

export default LoadingSpinner;
