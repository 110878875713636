import { useState, useEffect, useRef, useCallback } from 'react';
import { InputSearch, ListElement } from '../../common';

const ThreeInput = ({
    createData,
    feature,
    subFeature,
    subFeatureObj,
    setCreateData,
    featureId,
}: any) => {
    const [valArr, setValArr] = useState<any>([]);

    // we combine 2 and 3 input boxes into 1 as per design.
    const getBorderValue = (len: number, indexNum: number, heading: string) => {
        switch (len) {
            case 1:
                return {};
                break;
            case 2:
                if (indexNum === 0) {
                    return {
                        borderRight: 'none',
                        width: '35%',
                    };
                }
                if (indexNum === 1) {
                    return {
                        borderLeft: 'none',
                    };
                }
                break;

            default:
                if (indexNum === 0) {
                    return {
                        borderRight: 'none',
                        width:
                            heading === 'Donation Processing' ? '40%' : '35%',
                    };
                }
                if (indexNum === 1) {
                    return {
                        borderLeft: 'none',
                        borderRight: 'none',
                        width:
                            heading === 'Donation Processing' ? '40%' : '35%',
                    };
                }
                if (indexNum === 2) {
                    return {
                        borderLeft: 'none',
                    };
                }
        }
        return '';
    };

    const getPlace = (val1: any, head: string, indexNumber: number) => {
        if (head === 'Additional Contacts') {
            if (indexNumber === 1) {
                return `/${val1}`;
            }
            if (indexNumber === 0) {
                return `$${val1}`;
            }
        }
        if (head === 'Donation Processing') {
            if (indexNumber === 0) {
                return `${val1}%`;
            }
            if (indexNumber === 1) {
                return `+ $${val1}`;
            }
            if (indexNumber === 2) {
                return `/${val1}`;
            }
        }
        return val1;
    };

    const handleReadOnlyFields = (val: string, name: string) => {
        return (
            val === 'value3' &&
            [
                'Additional Contacts',
                'Donation Processing',
                'Campaign Logistics AI Manager (CLAIM)',
                'Emails',
            ].includes(name)
        );
    };

    const getThirdVal = (title: string) => {
        switch (title) {
            case 'Additional Contacts':
                return 'contacts';
            case 'Donation Processing':
                return 'donation';
            case 'Emails':
                return '/mo';
            default:
                return 'queries';
        }
    };

    const fVal = useRef<any>();
    const sVal = useRef<any>();

    const handleInputchange = (
        val: any,
        access: string,
        title: string,
        type: string
    ) => {
        const regex = /^\d*\.?\d*$/;
        if (type === 'number') {
            if (val === '' || regex.test(val)) {
                if (access === 'value1') {
                    fVal.current = val;
                }
                if (access === 'value2') {
                    sVal.current = val;
                }
                setCreateData({
                    ...createData,
                    [feature]: {
                        ...createData?.[feature],
                        [subFeature]: {
                            value: `${fVal.current || ''} ${
                                sVal.current || ''
                            } ${getThirdVal(title)}`,
                            inputId: subFeatureObj.inputTypeId,
                            componentId: subFeatureObj.featureId,
                            featuresMasterId: featureId,
                            value1: fVal.current || '',
                            value2: sVal.current || '',
                            value3: getThirdVal(title),
                        },
                    },
                });
            }
        }
    };

    const getDefaultValues = useCallback((val: string) => {
        if (val) {
            return Number(parseInt(val, 10));
        }
        return '';
    }, []);

    useEffect(() => {
        switch (subFeature) {
            case 'Additional Contacts':
                setValArr([
                    {
                        accessor: 'value1',
                        value:
                            createData?.Features?.['Additional Contacts']
                                ?.value1 || '',
                        headingVal: 'Additional Contacts',
                        type: 'number',
                    },
                    {
                        accessor: 'value2',
                        value:
                            createData?.Features?.['Additional Contacts']
                                ?.value2 || '',
                        headingVal: 'Additional Contacts',
                        type: 'number',
                    },
                    {
                        accessor: 'value3',
                        value: 'contacts',
                        headingVal: 'Additional Contacts',
                        type: 'text',
                    },
                ]);
                break;

            case 'Donation Processing':
                setValArr([
                    {
                        accessor: 'value1',
                        value:
                            createData?.Features?.['Donation Processing']
                                ?.value1 || '',
                        headingVal: 'Donation Processing',
                        type: 'number',
                    },
                    {
                        accessor: 'value2',
                        value:
                            createData?.Features?.['Donation Processing']
                                ?.value2 || '',
                        headingVal: 'Donation Processing',
                        type: 'number',
                    },
                    {
                        accessor: 'value3',
                        value: 'donation',
                        headingVal: 'Donation Processing',
                        type: 'text',
                    },
                ]);
                break;

            case 'Campaign Logistics AI Manager (CLAIM)':
                setValArr([
                    {
                        accessor: 'value1',
                        value:
                            createData?.Features?.[
                                'Campaign Logistics AI Manager (CLAIM)'
                            ]?.value1 || '',
                        headingVal: 'Campaign Logistics AI Manager (CLAIM)',
                        type: 'number',
                    },
                    {
                        accessor: 'value3',
                        value: 'queries',
                        headingVal: 'Campaign Logistics AI Manager (CLAIM)',
                        type: 'text',
                    },
                ]);
                break;

            case 'Emails':
                setValArr([
                    {
                        accessor: 'value1',
                        value: getDefaultValues(
                            createData?.Features?.Emails?.value1
                        ),
                        headingVal: 'Emails',
                        type: 'number',
                    },
                    {
                        accessor: 'value3',
                        value: '/mo',
                        headingVal: 'Emails',
                        type: 'text',
                    },
                ]);
                break;

            default:
                break;
        }
    }, [subFeature, getDefaultValues, createData?.Features]);

    return (
        valArr.length && (
            <ListElement className="mx-1">
                <InputSearch className="d-flex flex-row">
                    {valArr.map(
                        (
                            { type, accessor, value, headingVal }: any,
                            index: number
                        ) => {
                            return (
                                <input
                                    key={accessor}
                                    className="form-control inputSearchID"
                                    type={type}
                                    name={accessor}
                                    style={{
                                        ...getBorderValue(
                                            valArr.length,
                                            index,
                                            headingVal
                                        ),
                                    }}
                                    placeholder={getPlace(
                                        value,
                                        headingVal,
                                        index
                                    )}
                                    onChange={(e) =>
                                        handleInputchange(
                                            e.target.value,
                                            accessor,
                                            headingVal,
                                            type
                                        )
                                    }
                                    onKeyDownCapture={(e) => {
                                        if (
                                            type === 'number' &&
                                            (e.code === 'Minus' ||
                                                e.code === 'NumpadSubtract')
                                        ) {
                                            e.preventDefault();
                                        }
                                    }}
                                    min={0}
                                    readOnly={handleReadOnlyFields(
                                        accessor,
                                        headingVal
                                    )}
                                    disabled={handleReadOnlyFields(
                                        accessor,
                                        headingVal
                                    )}
                                />
                            );
                        }
                    )}
                </InputSearch>
            </ListElement>
        )
    );
};

export default ThreeInput;
