export const contactLogHeader = [
    { title: 'Contact Name', width: '250px' },
    { title: 'Changed By' },
    { title: 'Date', width: '130px' },
    { title: 'Old record', width: '265px' },
    { title: ' Change in record', width: '265px' },
];

export const confirmOptions = [
    { key: 'Ignore', label: 'Ignore' },
    { key: 'Replace', label: 'Replace' },
    { key: 'KeepBoth', label: 'Keep both' },
];

export const importTextConstant = `Please specify the starting and ending columns for the voter history data from the file, ensuring that the column "Vote method" comes immediately after the "Date voted" column.`;

export const objNumberFormat: any = [
    'ballotStatus',
    'voteStatus',
    'voteMethod',
    'uploadId',
    'id',
    'partyId',
    'scale',
];

export const objBooleanFormat: any = [
    'active',
    'keepBothEmail',
    'keepBothPhone',
    'keepBothAddress',
    'isAmbassador',
    'isVolunteer',
];
