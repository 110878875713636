import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { LoginBackground } from '../../assets/images';

export const LoginStyle = styled.div`
    background: url(${LoginBackground}) no-repeat;
    width: 100%;
    height: 100vh;
    background-size: cover !important;
    background-position: bottom center;

    .login-left {
        .logo {
            width: 100%;
            margin-left: 20px;
            margin-top: 25px;
        }
        @media only screen and (max-width: 991px) {
            display: none;
        }
    }

    .login-left-style {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding-left: 110px;
    }

    .login-right-style {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;

        .logo-rigt-img {
            display: none;
            @media only screen and (max-width: 991px) {
                display: block;
                padding: 10px 8px;
            }
        }

        .heading {
            font-family: ${(props: any) =>
                props.theme.fonts.semiBoldFontFamily};
            color: ${(props: any) => props.theme.colors.white};
            padding-top: 70px;
            font-size: ${(props: any) => props.theme.fontSizes.extarLarger};
            @media only screen and (max-width: 991px) {
                padding-top: 40px;
            }

            @media only screen and (max-width: 388px) {
                padding-top: 10px;
            }
        }

        .admin-login {
            width: 100%;
            max-width: 573px;
            margin: 45px auto;
            border: 1px solid white;
            padding: 35px 75px;
            border-radius: 8px;
            position: relative;
            @media only screen and (max-width: 991px) {
                padding: 20px 25px;
            }

            @media only screen and (max-width: 388px) {
                padding: 15px 25px;
            }

            .adminlogin-img {
                position: absolute;
                top: -28px;
                left: 45%;
                padding: 8px 14px;
                background-color: ${(props: any) =>
                    props.theme.colors.primaryColor};
                border-radius: 50%;
            }

            .admin-title {
                font-size: ${(props: any) =>
                    props.theme.fontSizes.larger} !important;
                color: ${(props: any) => props.theme.colors.white};
                text-align: center;
                padding: 28px 0px;
                @media only screen and (max-width: 388px) {
                    padding: 30px 0px;
                }
            }

            .fromfield {
                width: 100%;
                position: relative;

                input {
                    width: 100%;
                    height: 48px;
                    border: 1px solid
                        ${(props: any) => props.theme.colors.white};
                    border-radius: 5px;
                    padding-left: 65px;
                    margin-top: 5px;
                    background-color: transparent;
                    color: ${(props: any) => props.theme.colors.white};

                    &:focus {
                        background: none;
                        outline: none;
                    }

                    &:hover {
                        border: 1px solid
                            ${(props: any) => props.theme.colors.white};
                    }

                    &::placeholder {
                        color: ${(props: any) => props.theme.colors.white};
                    }
                }

                .login-icon {
                    position: absolute;
                    top: 13px;
                    border-right: 1px solid
                        ${(props: any) => props.theme.colors.white};
                    padding: 11px 15px;

                    img {
                        width: 20px;
                        height: 20px;
                        margin-top: 2px;
                    }
                }
                .show-password-icon {
                    right: 10px;
                    position: absolute;
                    top: 26px;
                    cursor: pointer;
                    width: 25px;
                }
            }
        }

        .password-text {
            color: ${(props: any) => props.theme.colors.white};
        }
    }
`;

export const FormContainer = styled.div`
    .login-background-img {
        background-image: url(${LoginBackground});
        background-repeat: no-repeat;
        height: 100vh;
        background-position: center center;
        background-size: cover;

        @media screen and (max-width: 996px) {
            max-width: 400px;
        }
        @media screen and (max-width: 769px) {
            display: none;
        }
    }
    .relative-position {
        position: relative;
    }
    .form-container {
        display: flex;
        max-width: 481px;
        width: 100%;
        margin: auto;
        height: auto;
        align-items: center;
    }
    .fromfield {
        width: 100%;
        label {
            font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
            color: ${(props: any) => props.theme.colors.white};
            font-size: ${(props: any) => props.theme.fontSizes.normal};
        }
        input {
            width: 100%;
            height: 48px;
            border: 1px solid ${(props: any) => props.theme.colors.grey5};
            border-radius: 5px;
            font-size: ${(props: any) => props.theme.fontSizes.normal};
            padding-left: 42px;
            margin-top: 5px;
            background-color: transparent;
            &:focus {
                border-color: ${(props: any) =>
                    props.theme.colors.primaryColor};
            }
        }
    }
    .svg-icon {
        position: absolute;
        top: 0.9rem;
        left: 0.5rem;
        border-right: 1px solid ${(props: any) => props.theme.colors.white};
        padding: 5px 5px;
    }
`;

export const FormHeading = styled.div`
    h2 {
        color: ${(props: any) => props.theme.colors.white};
        font-size: ${(props: any) => props.theme.fontSizes.larger};
        opacity: 1;
    }
    .horizontalLine {
        width: 100%;
        height: 2px;
        margin-top: 8px;
        padding-left: 0;
        margin-left: 0;
    }
    .forget-para {
        color: ${(props: any) => props.theme.colors.white};
        font-size: ${(props: any) => props.theme.fontSizes.normal};
        font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
    }
`;

export const ForgotPasswordLink = styled(Link)`
    text-decoration: underline;
    color: ${(props: any) => props.theme.colors.statusColor};
    font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
    cursor: pointer;
`;

export const SignUpSuccessMSG = styled.div`
    max-width: 550px;
    margin: 0 auto;
    background-color: ${(props: any) => props.theme.colors.white};
    opacity: 0.6;
    border-radius: 8px;
    padding: 80px 50px;
    h2 {
        font-size: 28px;
        margin: 75px 0;
        font-family: ${(props: any) => props.theme.fonts.semiBoldFontFamily};
    }
    p {
        font-size: 1.2rem;
        color: ${(props: any) => props.theme.colors.grey9};
        font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
    }
    .success-image {
        text-align: center;
        margin: 15px 0;
    }
`;
export default LoginStyle;
