import { styled } from 'styled-components';

const SideBarStyle = styled.div`
    .main-side-bar {
        .mainmenuicon {
            display: none;

            @media screen and (max-width: 991px) {
                display: block;
                position: fixed;
                top: 18px;
                right: 17px;
                z-index: 9;

                .bar1,
                .bar2,
                .bar3 {
                    width: 35px;
                    height: 3px;
                    background-color: ${(props: any) =>
                        props.theme.colors.black};
                    margin: 6px 0;
                    transition: 0.4s;
                }

                &.openmenud .bar1 {
                    transform: translate(0, 11px) rotate(-45deg);
                }

                &.openmenud .bar2 {
                    opacity: 0;
                }

                &.openmenud .bar3 {
                    transform: translate(0, -8px) rotate(45deg);
                }
            }
        }

        .sidemenu {
            background-color: ${(props: any) =>
                props.theme.colors.primaryColor};
            width: 100%;
            max-width: 262px;
            height: 100%;
            position: fixed;
            z-index: 10;
            top: 0px;
            left: -1px;

            @media screen and (max-width: 991px) {
                top: 71px;
                max-width: 100%;
                display: none;
                &.d-block {
                    display: block;
                }
            }

            .proximity-logo {
                padding: 17px 34px;
                margin-bottom: 10px;
                background-color: ${(props: any) => props.theme.colors.white};

                @media screen and (max-width: 991px) {
                    display: none;
                }
            }

            .sidebar-menu {
                .sidebar-list {
                    list-style: none;
                    color: ${(props: any) => props.theme.colors.white};

                    a {
                        text-decoration: none;
                        color: ${(props: any) => props.theme.colors.white};
                    }
                    .active {
                        background-color: ${(props: any) =>
                            props.theme.colors.grey7};
                    }

                    .list-link {
                        padding: 15px 20px;
                        margin: 0px 12px;
                        display: flex;

                        &:hover {
                            background-color: ${(props: any) =>
                                props.theme.colors.grey7};
                        }
                        .sidebar-img {
                            width: 18px;
                            height: 18px;
                        }

                        span {
                            font-family: ${(props: any) =>
                                props.theme.fonts.regularFontFamily};
                            padding-left: 17px;
                            vertical-align: top;
                        }
                    }
                }
            }
        }
    }
`;

export default SideBarStyle;
