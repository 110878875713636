import NUMBERWITHOUTLEADINGZERO from './Regex';

export const displayNumberFormat = (
    value: string | number,
    prefix: string,
    suffix: string
) => {
    return (
        prefix +
        (Number(value)?.toLocaleString(undefined, {
            minimumFractionDigits: prefix === '$' || suffix === '%' ? 2 : 0,
            maximumFractionDigits: prefix === '$' || suffix === '%' ? 2 : 0,
        }) || value) +
        suffix
    );
};

export const phoneNoformat = (phoneNum: string) => {
    let num = '';
    if (phoneNum) {
        const onlyNum = phoneNum?.replace(/\D/g, '');
        if (onlyNum.length === 10) {
            const numWithOne = ['1', ...onlyNum.split('')].join('');
            const match = numWithOne.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                num = ['(', match[2], ') ', match[3], '-', match[4]].join('');
            }
        }
    }
    return num;
};

export const getInitialName = (firstName: string, lastName: string) => {
    const name = `${firstName || ''} ${lastName || ''}`;
    return name
        .toUpperCase()
        ?.split(' ')
        ?.map((n) => n[0])
        ?.join('');
};

export const getIntWithTwoDecimal = (val: any) => {
    const sign = '$';
    let num = val;
    if (typeof num === 'string') {
        num = num.replace(/[^\d.]/g, '').trim();
    }
    if (Number(num)) {
        return Number.isInteger(Number(num))
            ? `${sign}${Number(num)}`
            : `${sign}${Number(num).toFixed(2)}`;
    }
    return num;
};

export const getPlanLabelInPricing = (val: string) => {
    switch (val) {
        case 'qtr':
            return 'Quarterly';
        case 'yr':
            return 'Yearly';
        case 'mo':
        default:
            return 'Monthly';
    }
};

export const convertEnterpriseData = (planDetails: any[]) => {
    const outputData: any = {};

    planDetails.forEach((data: any) => {
        const { featuresName } = data;
        if (featuresName) {
            outputData[featuresName] = {};

            data.featurChildlist.forEach((child: any) => {
                const {
                    lable,
                    value1,
                    value2,
                    value3,
                    inputTypeId,
                    featuresComponentId,
                    isApplicable,
                } = child;

                let value: any = '';
                if (lable) {
                    switch (inputTypeId) {
                        case 1:
                        case 3:
                            if (value1 && value1 === '-1') {
                                value = 'Unlimited';
                            } else if (value1) {
                                value = `${value1}`;
                            }
                            break;
                        case 2:
                            if (value1) {
                                value = {
                                    value: value1 === 'Applicable' ? '1' : '2',
                                    label:
                                        value1 === 'NA'
                                            ? 'Not Applicable(NA)'
                                            : value1,
                                };
                            }
                            break;
                        case 4:
                            if (value1 && value2) {
                                value = `${value1} ${value2}`;
                            }
                            break;
                        case 5:
                            if (
                                value1 &&
                                value2 &&
                                value3 &&
                                lable !== 'Donation Processing'
                            ) {
                                value = `${value1}/${value2} ${value3}`;
                            } else if (
                                value1 &&
                                value2 &&
                                value3 &&
                                lable === 'Donation Processing'
                            ) {
                                value = `${value1}% + $ ${value2}/${value3}`;
                            }
                            break;
                        case 6:
                            if (value1 && value2) {
                                value = `${value1}/${value2}`;
                            }
                            break;
                        default:
                            value = '';
                    }

                    outputData[featuresName][lable] = {
                        value,
                        inputId: inputTypeId,
                        componentId: featuresComponentId,
                        featuresMasterId: data.featuresMasterId || 0,
                        isApplicable,
                        value1,
                        value2,
                        value3,
                    };
                }
            });
        }
    });

    return outputData;
};

export const extractEnterprisePlanDetails = (plan: string) => {
    switch (plan) {
        case '1':
            return {
                contacts: 'Unlimited',
                lists: 'Unlimited',
                users: '5',
            };
        case '2':
            return {
                contacts: 'Unlimited',
                lists: 'Unlimited',
                users: '7',
            };
        case '3':
            return {
                contacts: 'Unlimited',
                lists: 'Unlimited',
                users: '9',
            };
        case '4':
            return {
                contacts: 'Unlimited',
                lists: 'Unlimited',
                users: 'Unlimited',
            };
        default:
            return {};
    }
};

export const getNumberUptoTwoDecimal = (num: string | number) => {
    return num.toString().indexOf('.') !== -1
        ? Number(num).toFixed(2).toString()
        : num.toString();
};

export const checkIsOnlyNumber = (val: any) => {
    return val === '' || NUMBERWITHOUTLEADINGZERO.test(val);
};

export const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
};

export const copyFormLink = (
    candidateId: any,
    path: string,
    candidateName: string
) => {
    return `${process.env.REACT_APP_PROXIMITY_BASE_URL}/${path}/${candidateId}?name=${candidateName}`;
};
