import { FC } from 'react';

import AutoSearch from './AutoSearch';
import { AddressComps, AddressFilteredComps } from '../../types/type';

interface AddressCompProp {
    setCompleteAddress: (obj: AddressFilteredComps) => void;
}

const AddressComp: FC<AddressCompProp> = ({ setCompleteAddress }) => {
    const getAddressComponents = (addressComp: AddressComps) => {
        let city = '';
        let countyName = '';
        let state = '';
        let zip = '';
        let gpdcoordinates = '';
        if (addressComp.locality) {
            city = addressComp.locality;
        } else if (addressComp.administrative_area_level_2) {
            if (!addressComp.locality) {
                city = addressComp.administrative_area_level_2;
            } else {
                countyName = addressComp.administrative_area_level_2;
            }
        }
        const address = `${addressComp?.street_number || ''} ${
            addressComp?.route || ''
        } ${addressComp?.neighborhood || ''} ${
            addressComp?.sublocality_level_1 || ''
        } ${countyName}`;

        if (addressComp?.administrative_area_level_1) {
            state = addressComp?.administrative_area_level_1;
        }
        if (addressComp?.postal_code) {
            zip = addressComp?.postal_code;
        }
        if (addressComp?.gpdcoordinates) {
            gpdcoordinates = addressComp?.gpdcoordinates;
        }

        setCompleteAddress({
            address: address.trim(),
            city,
            state,
            zip,
            gpdcoordinates,
        });
    };

    return (
        <AutoSearch
            label="Address"
            setAddressComponents={getAddressComponents}
        />
    );
};

export default AddressComp;
