import { FC } from 'react';

import { DownArrow, UpArrow } from '../../common/SvgIcon';

interface TableHeaderProps {
    label: string;
    keyVal: string;
    noSort: string[];
    accessor: string;
    upColor: string;
    downColor: string;
    handleSorting: (sortType: string, field: string) => void;
    isAscending: { value: boolean; name: string };
    cssStyle: any;
}

const TableHeader: FC<TableHeaderProps> = ({
    label,
    keyVal,
    noSort,
    accessor,
    upColor,
    downColor,
    handleSorting,
    isAscending,
    cssStyle,
}) => {
    return (
        <th
            scope="col"
            className={noSort.includes(keyVal) ? '' : 'cursor'}
            onClick={() =>
                noSort.includes(keyVal)
                    ? null
                    : handleSorting(
                          isAscending.name === accessor &&
                              isAscending.name !== '' &&
                              isAscending.value
                              ? 'DESC'
                              : 'ASC',
                          accessor
                      )
            }
            style={cssStyle}
        >
            {label}
            {noSort.includes(keyVal) ? null : (
                <span>
                    <span role="presentation" className="ml-2">
                        <UpArrow color={upColor} />
                    </span>{' '}
                    <span role="presentation">
                        <DownArrow color={downColor} />
                    </span>
                </span>
            )}
        </th>
    );
};
export default TableHeader;
