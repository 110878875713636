import { FC, useCallback, useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import NoRecords from '../../common/NoRecords';
import { updatePricingAPI } from '../../api';
import { EditBackgroundImg } from '../../assets/images';
import {
    WhiteCard,
    FilterTag,
    InputSearch,
    Button,
    getIntWithTwoDecimal,
    getPlanLabelInPricing,
    getNumberUptoTwoDecimal,
} from '../../common';

const PricingDetails: FC<any> = ({
    pricingDetails,
    planName,
    getPlanDetail,
}) => {
    const [list, setList] = useState<any>();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [updatePricing, setUpdatePricing] = useState<any>();

    const updatedPricingChildList = useCallback(() => {
        const getFirstVal = (id: number) => {
            const { value1, value2 } = pricingDetails.featurChildlist.find(
                (ele2: any) =>
                    ele2.featuresComponentId === id && ele2?.lable === 'mo'
            );
            return {
                moVal1: getNumberUptoTwoDecimal(value1),
                moVal2: getNumberUptoTwoDecimal(value2),
            };
        };
        const up = pricingDetails.featurChildlist
            .filter((item: any) => item.lable !== 'mo')
            .map((ele: any) => {
                return {
                    ...ele,
                    value1: getNumberUptoTwoDecimal(ele.value1),
                    value2: getNumberUptoTwoDecimal(ele.value2),
                    ...getFirstVal(ele.featuresComponentId),
                };
            });
        return up;
    }, [pricingDetails.featurChildlist]);

    const handleChange = (e: any) => {
        const regex = /^[1-9][0-9]*$/;
        if (e.target.value === '' || regex.test(e.target.value)) {
            setUpdatePricing({
                ...updatePricing,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleUpdate = async () => {
        const response = await updatePricingAPI(updatePricing);
        if (response?.status === 200) {
            toast.success(response?.data?.split('~')[0]);
            setIsEdit(false);
            getPlanDetail(false);
        } else {
            toast.error(response?.data);
        }
    };

    const handlePricingValues = useCallback(() => {
        const temp = updatedPricingChildList();
        const getVal = (val: string, type: string) => {
            let amount = 0;
            const update = temp.find((ele: any) => ele?.lable === val);

            if (type === 'offer') {
                amount = update?.moVal1;
            } else {
                amount = update?.moVal2;
            }
            return amount;
        };
        const toUpdatePricing = {
            planName,
            monthlyOfferAmt: '',
            monthlyRegularAmt: '',
            quarterlyOfferAmt: getVal('qtr', 'offer'),
            quarterlyRegularAmt: getVal('qtr', 'regular'),
            yearlyOfferAmt: getVal('yr', 'offer'),
            yearlyRegularAmt: getVal('yr', 'regular'),
        };
        setUpdatePricing(toUpdatePricing);
        setList(temp);
    }, [planName, updatedPricingChildList]);

    useEffect(() => {
        if (
            pricingDetails?.featurChildlist &&
            pricingDetails?.featurChildlist?.length
        ) {
            handlePricingValues();
        }
    }, [handlePricingValues, pricingDetails?.featurChildlist]);

    return (
        <WhiteCard className="mt-4">
            <div className="card-header d-flex justify-content-between">
                Pricing
                {!isEdit && list && list?.length ? (
                    <span>
                        <img
                            src={EditBackgroundImg}
                            className="cursor"
                            role="presentation"
                            alt="edit"
                            onClick={() => {
                                setIsEdit(true);
                            }}
                        />
                    </span>
                ) : (
                    ''
                )}
            </div>
            <div className="horizontal-line mt-3" />

            <div className="row">
                {list ? (
                    list.map((item: any) => {
                        return (
                            <div
                                className="col mt-2"
                                key={item?.paymentPlanDetailId}
                            >
                                <FilterTag className="status-block mt-4 ">
                                    {getPlanLabelInPricing(item?.lable)}
                                </FilterTag>
                                {isEdit ? (
                                    <form className="row mt-2">
                                        <div className="col-lg-6 py-2">
                                            <label htmlFor={item?.value1}>
                                                Offer amount
                                            </label>
                                            <ul className="pricing-input">
                                                <li>
                                                    <InputSearch>
                                                        <input
                                                            type="text"
                                                            className="form-control mt-2"
                                                            name={
                                                                item?.lable ===
                                                                'qtr'
                                                                    ? 'quarterlyOfferAmt'
                                                                    : 'yearlyOfferAmt'
                                                            }
                                                            value={
                                                                item?.lable ===
                                                                'qtr'
                                                                    ? updatePricing?.quarterlyOfferAmt
                                                                    : updatePricing?.yearlyOfferAmt
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </InputSearch>
                                                </li>
                                                <li>
                                                    <span className="pt-3 pl-1">{`${'/'}${'mo'}`}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6 py-2">
                                            <label htmlFor={item?.value2}>
                                                Regular amount
                                            </label>
                                            <ul className="pricing-input">
                                                <li>
                                                    <InputSearch>
                                                        <input
                                                            type="text"
                                                            className="form-control mt-2"
                                                            name={
                                                                item?.lable ===
                                                                'qtr'
                                                                    ? 'quarterlyRegularAmt'
                                                                    : 'yearlyRegularAmt'
                                                            }
                                                            value={
                                                                item?.lable ===
                                                                'qtr'
                                                                    ? updatePricing?.quarterlyRegularAmt
                                                                    : updatePricing?.yearlyRegularAmt
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </InputSearch>
                                                </li>
                                                <li>
                                                    <span className="pt-3 pl-1">{`${'/'}${'mo'}`}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </form>
                                ) : (
                                    <div>
                                        <div className="page-title mt-4 ">
                                            {item?.moVal1
                                                ? `${getIntWithTwoDecimal(
                                                      item?.moVal1
                                                  )}`
                                                : ''}
                                            <span className="ml-2 pre-pay">
                                                /mo
                                            </span>
                                        </div>

                                        {item?.value1 && item?.value2 ? (
                                            <div className="page-title mt-3 ">
                                                <span className="pre-pay">
                                                    {item?.lable === 'qtr'
                                                        ? 'Commitment of '
                                                        : 'Billed at '}
                                                    <strong className="page-title">
                                                        {getIntWithTwoDecimal(
                                                            item?.value1
                                                        )}
                                                    </strong>
                                                    /{item?.lable}
                                                </span>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <div className="col">
                        <NoRecords />
                    </div>
                )}
            </div>
            {isEdit ? (
                <div className="row">
                    <div className="col-12 text-right mt-4">
                        <Button
                            className="btn-border mr-3"
                            onClick={() => {
                                handlePricingValues();
                                setIsEdit(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            onClick={() => handleUpdate()}
                            disabled={
                                !updatePricing.quarterlyOfferAmt ||
                                !updatePricing.quarterlyRegularAmt ||
                                !updatePricing.yearlyOfferAmt ||
                                !updatePricing.yearlyRegularAmt
                            }
                        >
                            Update
                        </Button>
                    </div>
                </div>
            ) : (
                ''
            )}
        </WhiteCard>
    );
};

export default PricingDetails;
