const overlay = {
    background: 'rgba(00, 00, 00, 0.6)',
    zIndex: 999,
};

const commonProperties = {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '100% ',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
};

const duplicateContactStyle = {
    overlay,
    content: {
        ...commonProperties,
        maxWidth: '775px ',
        minHeight: '570px',
        overlay: ' {zIndex: 1000}',
    },
};

export default duplicateContactStyle;
