import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from '../types';

const initialState: AuthState = {
    userEmail: '',
    givenName: '',
    surName: '',
    accessToken: '',
    userId: '',
    role: '',
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthDetails: (state, action: PayloadAction<AuthState>) => {
            return {
                ...state,
                userEmail: action.payload.userEmail,
                givenName: action.payload.givenName,
                surName: action.payload.surName,
                accessToken: action.payload.accessToken,
                userId: action.payload.userId,
                role: action.payload.role,
            };
        },
        removeAuthDetails: (state) => {
            return {
                ...state,
                userEmail: '',
                givenName: '',
                surName: '',
                accessToken: '',
                userId: '',
                role: '',
            };
        },
    },
});

export const { setAuthDetails, removeAuthDetails } = authSlice.actions;

export default authSlice.reducer;
