import { styled } from 'styled-components';

const DropDownStyle = styled.div`
    &.plan-dropdown {
        max-width: 240px;
    }

    .css-b62m3t-container {
        .css-13cymwt-control {
            min-height: 40px;
            border-color: ${(props: any) => props.theme.colors.grey10};
            outline: none !important;

            .css-1hb7zxy-IndicatorsContainer {
                background-color: ${(props: any) =>
                    props.theme.colors.dropdownActiveBg};
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                cursor: pointer;

                .css-1u9des2-indicatorSeparator {
                    margin: 0px;
                }

                .css-1xc3v61-indicatorContainer {
                    .css-8mmkcg {
                        color: ${(props: any) =>
                            props.theme.colors.white} !important;
                        stroke: white !important;
                    }
                    svg {
                        path {
                            color: ${(props: any) => props.theme.colors.white};
                        }
                    }
                }
            }

            .css-1wy0on6 {
                background-color: ${(props: any) =>
                    props.theme.colors.dropdownActiveBg};
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;

                .css-1u9des2-indicatorSeparator {
                    margin: 0px;
                }

                .css-1xc3v61-indicatorContainer {
                    svg {
                        path {
                            color: ${(props: any) => props.theme.colors.white};
                        }
                    }
                }
            }
        }

        .css-t3ipsp-control {
            min-height: 40px;
            border-color: ${(props: any) => props.theme.colors.grey10};
            outline: none !important;
            box-shadow: none !important;

            &:hover {
                border-color: ${(props: any) => props.theme.colors.grey10};
                box-shadow: none !important;
            }

            .css-1hb7zxy-IndicatorsContainer {
                background-color: ${(props: any) =>
                    props.theme.colors.dropdownActiveBg} !important;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;

                .css-1xc3v61-indicatorContainer,
                .react-select__indicator {
                    color: ${(props: any) => props.theme.colors.white};
                }

                .css-1u9des2-indicatorSeparator {
                    margin: 0px;
                }
                svg {
                    color: ${(props: any) => props.theme.colors.white};
                    path {
                        color: ${(props: any) => props.theme.colors.white};
                    }
                }
            }

            .css-1wy0on6 {
                background-color: ${(props: any) =>
                    props.theme.colors.dropdownActiveBg} !important;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                cursor: pointer;

                .css-8mmkcg {
                    color: ${(props: any) =>
                        props.theme.colors.white} !important;
                    stroke: white !important;
                }

                svg {
                    color: ${(props: any) =>
                        props.theme.colors.white} !important;
                    stroke: white !important;

                    path {
                        color: ${(props: any) =>
                            props.theme.colors.white} !important;
                        stroke: white !important;
                    }
                }

                svg {
                    color: ${(props: any) => props.theme.colors.white};
                    path {
                        color: ${(props: any) => props.theme.colors.white};
                    }
                }
            }
        }

        .css-1n6sfyn-MenuList {
            .css-10wo9uf-option {
                .css-tr4s17-option {
                    background-color: ${(props: any) =>
                        props.theme.colors.white} !important;
                }
            }
        }

        .css-11ik7dj-control {
            border-color: ${(props: any) =>
                props.theme.colors.grey10} !important;
            min-height: 40px !important;
            .css-1wy0on6 {
                background-color: ${(props: any) =>
                    props.theme.colors.dropdownActiveBg} !important;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            .css-151sz6c-indicatorContainer {
                background-color: ${(props: any) =>
                    props.theme.colors.dropdownActiveBg} !important;
                cursor: pointer;
                svg {
                    color: ${(props: any) => props.theme.colors.white};
                    path {
                        color: ${(props: any) => props.theme.colors.white};
                    }
                }
            }
        }
    }

    .css-tj5bde-Svg {
        color: ${(props: any) => props.theme.colors.white};
    }

    .css-1nmdiq5-menu {
        z-index: 2;

        .css-d7l1ni-option {
            background-color: ${(props: any) => props.theme.colors.white};
            cursor: pointer;
        }

        .css-tr4s17-option {
            background-color: ${(props: any) => props.theme.colors.white};
            color: ${(props: any) => props.theme.colors.secondaryColor};
        }
    }

    .react-select__control--menu-is-open,
    .react-select__control--is-focused {
        .react-select__dropdown-indicator {
            background-color: ${(props: any) =>
                props.theme.colors.dropdownActiveBg} !important;
            padding: 9px 8px;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;

            .css-8mmkcg {
                color: ${(props: any) => props.theme.colors.white} !important;
                stroke: white !important;
            }

            svg {
                color: ${(props: any) => props.theme.colors.white} !important;
                stroke: white !important;

                path {
                    color: ${(props: any) =>
                        props.theme.colors.white} !important;
                    stroke: white !important;
                }
            }
        }
        .css-1u9des2-indicatorSeparator {
            margin-bottom: 0px;
            margin-top: 0px;
        }
    }
`;

export default DropDownStyle;
