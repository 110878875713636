import { useCallback, FC } from 'react';

import Select, { components } from 'react-select';

import CheckBox from './CheckBox';
import MultiDropdownStyle from '../Theme/style/MultiDropdownStyle';
import { MultiSelectProp } from '../types/type';

const MultiSelectDropdown: FC<MultiSelectProp> = ({
    options,
    label,
    value,
    multiSelectedValues,
    setMultiSelectedValues,
    handleMultiSelectChange,
    isDisabled,
}) => {
    const { Option, ValueContainer } = components;

    const selectOption = (dataProps: any) => {
        return (
            <div>
                <Option {...dataProps}>
                    <CheckBox>
                        <label
                            htmlFor={dataProps.value}
                            className="check-container customer-pannel"
                        >
                            <input
                                type="checkbox"
                                name={dataProps.value}
                                id={dataProps.value}
                                checked={dataProps.isSelected}
                                disabled={
                                    multiSelectedValues?.[value]?.length === 1
                                }
                            />
                            <span className="checkmark" />
                            {dataProps.label}
                        </label>
                    </CheckBox>
                </Option>
            </div>
        );
    };

    const valueContainer = useCallback(
        ({ children, getValue, ...dataProps }: any) => {
            const updatedValue = [...children];
            const { length } = getValue();
            switch (length) {
                case 0:
                    updatedValue[0] = `Select ${label}`;
                    break;
                case 1:
                    updatedValue[0] = dataProps?.selectProps?.value?.[0]?.label;
                    break;
                default:
                    updatedValue[0] = `${
                        dataProps?.selectProps?.value?.[0]?.label === 'All' ||
                        dataProps?.selectProps?.value?.[0]?.value === 'all'
                            ? `All ${label}`
                            : `Multiple ${label}`
                    } `;
                    break;
            }
            return (
                <ValueContainer {...dataProps}>
                    <span>{updatedValue}</span>
                </ValueContainer>
            );
        },
        [ValueContainer, label]
    );

    const getAllComponents = () => {
        return {
            Option: selectOption,
            ValueContainer: valueContainer,
        };
    };

    const handleChange = (event: any, values: any) => {
        if (values.action === 'select-option') {
            if (values.option?.value === 'all')
                setMultiSelectedValues({
                    ...multiSelectedValues,
                    [value]: [{ label: 'All', value: 'all' }, ...options],
                });
            if (values.option?.value !== 'all')
                setMultiSelectedValues((prevState: any) => ({
                    ...prevState,
                    [value]:
                        event?.length === options?.length
                            ? [{ label: 'All', value: 'all' }, ...event]
                            : event,
                }));
        }
        if (values.action === 'deselect-option') {
            if (values.option?.value === 'all')
                setMultiSelectedValues({
                    ...multiSelectedValues,
                    [value]: [],
                });
            if (values.option?.value !== 'all')
                setMultiSelectedValues({
                    ...multiSelectedValues,
                    [value]: event.filter((op: any) => op.value !== 'all'),
                });
        }
    };

    const getOptions = () => {
        if (options?.length > 1)
            return [{ label: 'All', value: 'all' }, ...options];
        if (options?.length === 1) return options;
        return [];
    };

    return (
        <MultiDropdownStyle>
            <Select
                classNamePrefix="react-select"
                options={getOptions()}
                isMulti
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: '#fff',
                        primary: '#fff',
                        primary50: '#fff',
                    },
                })}
                value={multiSelectedValues?.[value]}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={getAllComponents()}
                isClearable={false}
                isSearchable={false}
                placeholder={`Select ${label}`}
                onChange={(event, values) => {
                    handleChange(event, values);
                    handleMultiSelectChange?.(event, value, values);
                }}
                name={value}
                isDisabled={isDisabled && value === 'areas'}
            />
        </MultiDropdownStyle>
    );
};
export default MultiSelectDropdown;
