import { styled } from 'styled-components';

const UserAvatar = styled.div`
    display: flex;
    align-items: center;
    .user-profile {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color: ${(props: any) => props.theme.colors.iconBg};
        color: ${(props: any) => props.theme.colors.userTextColor};
        text-align: center;
        padding-top: 16px;
    }

    .contac-details {
        font-family: ${(props: any) => props.theme.fonts.FontFamily};
        font-size: ${(props: any) => props.theme.fontSizes.extarLarger};
    }
`;

export default UserAvatar;
