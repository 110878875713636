import { FC } from 'react';

import { InputSearch } from '../../common';

interface AddressFieldType {
    label: string;
    accessor: string;
    value: string;
    nameOfClass: string;
    isDisabled: boolean;
    handleChange: (val1: string, val: string) => void;
}

const AddressField: FC<AddressFieldType> = ({
    label,
    accessor,
    value,
    nameOfClass,
    isDisabled,
    handleChange,
}) => {
    return (
        <div className={nameOfClass}>
            <p className="details-title mb-1">
                {label}{' '}
                {accessor === 'suit' && (
                    <span className="option-brackets">(Optional)</span>
                )}
            </p>

            <InputSearch>
                <input
                    type="text"
                    className="form-control"
                    maxLength={200}
                    name={accessor}
                    value={value}
                    onChange={(e) => handleChange(accessor, e.target.value)}
                    disabled={isDisabled}
                />
            </InputSearch>
        </div>
    );
};

export default AddressField;
