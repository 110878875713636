import styled from 'styled-components';

export const DashboardCardStyle = styled.div`
    .dashboard-card {
        box-shadow: 0px 3px 6px
            ${(props: any) => props.theme.shadows.commonShadow};
        padding: 15px 20px;

        .dashboard-style {
            position: relative;

            .teal-border {
                position: absolute;
                width: 2px;
                background-color: ${(props: any) =>
                    props.theme.colors.statusColor};
                right: 0px;
                height: 100%;
                top: 0px;
            }

            .purple-border {
                position: absolute;
                width: 2px;
                background-color: ${(props: any) =>
                    props.theme.colors.whiteCardLightText};
                right: 0px;
                height: 100%;
                top: 0px;
            }

            .brown-border {
                position: absolute;
                width: 2px;
                background-color: ${(props: any) =>
                    props.theme.colors.userTextColor};
                right: 0px;
                height: 100%;
                top: 0px;
            }
        }

        .candidate-traffic {
            font-size: 24px;
            font-family: ${(props: any) =>
                props.theme.fonts.semiBoldFontFamily};
        }

        .card-image {
            max-width: 85px;
            @media screen and (max-width: 1200px) and (min-width: 991px) {
                max-width: 65px;
            }
        }
        .small-text {
            font-size: ${(props: any) => props.theme.fontSizes.extrasmall};
            color: ${(props: any) => props.theme.colors.grey4};
        }
    }
`;

export const UserAvatar = styled.div`
    display: flex;
    align-items: center;
    .user-profile {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color: ${(props: any) => props.theme.colors.iconBg};
        color: ${(props: any) => props.theme.colors.userTextColor};
        text-align: center;
        padding-top: 16px;
    }
`;
