import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Select from 'react-select';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

import PlanManagementStyle from '../../Theme/style/PlanManagementStyle';
import CustomInput from '../../common/CustomInput';
import AddOnFeature from './AddOnFeature';
import Loader from '../../common/Loader';
import { useAppSelector } from '../../store/hook';
import {
    checkIsOnlyNumber,
    convertEnterpriseData,
    getPlanLabelInPricing,
} from '../../common/CommonMethods';
import {
    PATH_PLAN_MANAGEMENT,
    frequencyDropdownOptions,
    planDetailsDropdownOptions,
} from '../../constants';
import {
    ContactDeleteImg,
    DollarImg,
    EditBackgroundImg,
} from '../../assets/images';
import {
    WhiteCard,
    FilterTag,
    Button,
    InputSearch,
    DropDownStyle,
    ToggleSwitch,
    handleError,
} from '../../common';
import {
    createEnterPrisePlan,
    getEnterPrisePlanDetailAPI,
    getPlanDetailsFeatuers,
    getPlanDetailsOnSelect,
    updateNotApplicable,
} from '../../api';
import ThreeInput from './ThreeInput';

const CreatePlan = ({
    mode,
    subTitle,
    isPlanCreated,
    setIsPlanCreated,
    planType,
    createdPlanId,
    setCreatedPlanId,
    isPlanChanged,
    setIsPlanChanged,
}: any) => {
    const [featuresList, setFeaturesList] = useState([]);
    const [addFeature, setAddFeature] = useState<any>({});
    const [createData, setCreateData] = useState<any>([]);
    const [amountDetails, setAmountDetails] = useState<any>({});
    const [isAddNewType, setIsAddNewType] = useState(false);
    const [editModule, setEditModule] = useState<any>({});
    const [pricingObj, setPricingObj] = useState<any>({});
    const [isLoading, setIsLoading] = useState({
        isLoading: true,
        type: 'pageLoader',
    });
    const [isToggleDisabled, setIsToggleDisabled] = useState(false);

    const navigate = useNavigate();
    const planDetails = useAppSelector((state) => state.planmangement);

    const fillData = (planData: any) => {
        const filteredData = planData.filter(
            (planFeatures: any) => planFeatures.featuresName !== 'Pricing'
        );

        const tempCreateData = convertEnterpriseData(filteredData);
        setPricingObj(
            planData
                .filter(
                    (planFeatures: any) =>
                        planFeatures.featuresName === 'Pricing'
                )
                .map((planFeatures: any) => {
                    return {
                        inputId:
                            planFeatures?.featurChildlist?.[0]?.inputTypeId,
                        componentId:
                            planFeatures?.featurChildlist?.[0]
                                ?.featuresComponentId,
                        featuresMasterId: planFeatures?.featuresMasterId,
                        value1: planFeatures?.featurChildlist?.[0]?.value1,
                        value2: {
                            value: planFeatures?.featurChildlist?.[0]?.lable,
                            label: getPlanLabelInPricing(
                                planFeatures?.featurChildlist?.[0]?.lable
                            ),
                        },
                    };
                })[0]
        );
        setCreateData(tempCreateData);
    };

    useEffect(() => {
        getPlanDetailsFeatuers(mode === 'create' || mode === 'clone').then(
            (res: any) => {
                if (res.status === 200) {
                    setFeaturesList(res.data);
                    setAmountDetails(
                        res.data.filter(
                            (resDetails: any) => resDetails.fName === 'Pricing'
                        )[0]
                    );
                    setIsLoading({ isLoading: false, type: '' });
                }
            }
        );
        if (mode === 'clone') {
            fillData(planDetails.planDetails);
        }
    }, [mode, planDetails]);

    useEffect(() => {
        if (isPlanCreated) {
            setIsLoading({ isLoading: true, type: 'pageLoader' });
            getEnterPrisePlanDetailAPI(Number(createdPlanId), subTitle).then(
                (response) => {
                    if (response.status === 200) {
                        fillData(response.data.featuresMasterList);
                    }
                    setIsLoading({ isLoading: false, type: '' });
                }
            );
        }
    }, [isPlanCreated, subTitle, createdPlanId]);

    const getEnterpriseDetails = useCallback(async () => {
        setIsLoading({ isLoading: true, type: 'pageLoader' });
        const response = await getPlanDetailsOnSelect(Number(planType.value));
        if (response.status === 200) {
            fillData(response.data.featuresMasterList);
            setIsPlanChanged(false);
            setIsLoading({ isLoading: false, type: '' });
        }
    }, [planType, setIsPlanChanged]);

    useEffect(() => {
        if (isPlanChanged) {
            getEnterpriseDetails();
        }
    }, [getEnterpriseDetails, isPlanChanged]);

    function parseValue(input: any, subFeature: any, detailsObj: any) {
        let output: any = {};

        if (input === 'Unlimited') {
            output.value1 = '-1';
        } else {
            const match = input?.match(/([\d.]+)\s*(k)?\s*\/?\s*(\d*)\s*(\w*)/);

            if (
                subFeature !== 'Donation Processing' &&
                subFeature !== 'Campaign Logistics AI Manager (CLAIM)' &&
                subFeature !== 'Additional Contacts'
            ) {
                if (match) {
                    const [, value1, isK, value2, value3] = match;
                    output = { value1 };

                    if (isK === 'k') {
                        output.value2 = 'k';
                    }

                    if (value2) {
                        output.value2 = value2;
                    }

                    if (!output?.value2 && value3) {
                        output.value2 = value3;
                    } else if (value3) {
                        output.value3 = value3;
                    }
                } else {
                    // Handle the case where the input doesn't match the pattern
                    output = { value1: input };
                }
            } else if (subFeature === 'Donation Processing') {
                output = {
                    value1: detailsObj?.value1,
                    value2: detailsObj?.value2,
                    value3: 'donation',
                };
            } else if (subFeature === 'Campaign Logistics AI Manager (CLAIM)') {
                output = {
                    value1: detailsObj?.value1,
                    value2: 'queries',
                };
            } else if (subFeature === 'Additional Contacts') {
                output = {
                    value1: detailsObj?.value1,
                    value2: detailsObj?.value2,
                    value3: 'contacts',
                };
            } else if (subFeature === 'Emails') {
                output = {
                    value1: detailsObj?.value1,
                    value2: detailsObj?.value2,
                };
            } else {
                const matchProcessing = input?.match(
                    /([\d.]+)\s*%?\s*\+\s*\$?\s*([\d.]+)\s*\/\s*(\w+)/
                );
                if (matchProcessing) {
                    const [, value1, value2, value3] = matchProcessing;
                    return { value1, value2, value3 };
                }
            }
        }
        return output;
    }

    const handleSubmit = async (status: any) => {
        let tempObj: any = [];
        Object.keys(createData).forEach((dataDetails: any) => {
            Object.keys(createData[dataDetails]).forEach(
                (subDataDetails: any) => {
                    let valueObj: any = {};
                    if (
                        createData[dataDetails][subDataDetails].value ||
                        [
                            'Additional Contacts',
                            'Donation Processing',
                            'Emails',
                            'Campaign Logistics AI Manager (CLAIM)',
                        ].includes(subDataDetails)
                    ) {
                        if (
                            typeof createData[dataDetails][subDataDetails]
                                .value === 'string' ||
                            [
                                'Additional Contacts',
                                'Donation Processing',
                                'Emails',
                                'Campaign Logistics AI Manager (CLAIM)',
                            ].includes(subDataDetails)
                        ) {
                            valueObj = parseValue(
                                createData[dataDetails][subDataDetails].value,
                                subDataDetails,
                                createData[dataDetails][subDataDetails]
                            );
                        } else {
                            valueObj = {
                                value1: createData[dataDetails][subDataDetails]
                                    .value.value,
                            };
                        }
                        tempObj = [
                            ...tempObj,

                            {
                                componentId:
                                    createData[dataDetails][subDataDetails]
                                        .componentId,
                                inputId:
                                    createData[dataDetails][subDataDetails]
                                        .inputId,
                                componentValue:
                                    typeof createData[dataDetails][
                                        subDataDetails
                                    ].value === 'string'
                                        ? createData[dataDetails][
                                              subDataDetails
                                          ].value
                                        : createData[dataDetails][
                                              subDataDetails
                                          ].value.value,
                                featuresMasterId:
                                    createData[dataDetails][subDataDetails]
                                        .featuresMasterId,
                                ...valueObj,
                            },
                        ];
                    }
                }
            );
        });
        const payload = {
            eentrpriseplanId: 0,
            subTitle,
            status,
            planTypeId: planType.value ? Number(planType.value) : 0,
            featuresComponentModelList: [
                ...tempObj,
                { ...pricingObj, value2: pricingObj?.value2?.value },
            ],
        };
        const response = await createEnterPrisePlan(payload);
        if (response.status === 200) {
            setIsPlanCreated(true);
            setCreatedPlanId(response.data.split('~')[1]);
            toast.success(response.data.split('~')[0]);
            if (status === 'draft') {
                navigate(PATH_PLAN_MANAGEMENT);
            }
            setEditModule({});
            window.scrollTo(0, 0);
        } else {
            toast.error(response.data);
        }
        setIsLoading({ isLoading: false, type: '' });
    };

    const getEnterpriseAfterToggleChange = async (title: string) => {
        try {
            const res = await getEnterPrisePlanDetailAPI(
                Number(createdPlanId),
                title
            );
            fillData(res.data.featuresMasterList);
        } catch (error) {
            handleError(error);
        }
    };

    const handleToggleChange = async (
        isChecked: boolean,
        featuresComponentId: any,
        subFeature: any,
        feature: any,
        featureId: any,
        subFeatureObj: any
    ) => {
        try {
            setIsToggleDisabled(true);
            const response = await updateNotApplicable(
                false,
                featuresComponentId,
                subTitle!,
                isChecked
            );
            toast.success(response?.data);
            if (mode === 'clone' && !isPlanCreated) {
                // save temporary data
                setCreateData({
                    ...createData,
                    [feature]: {
                        ...createData?.[feature],
                        [subFeature]: {
                            value: '',
                            inputId: subFeatureObj.inputTypeId,
                            componentId: subFeatureObj.featureId,
                            featuresMasterId: featureId,
                            isApplicable: isChecked,
                        },
                    },
                });
            } else {
                getEnterpriseAfterToggleChange(subTitle);
            }
        } catch (error) {
            handleError(error);
        } finally {
            setIsToggleDisabled(false);
        }
    };
    const handleValueOfAddNewFeature = (valueObj: any) => {
        const { value1, value2, value3 } = valueObj;
        if (value1 && value2 && value3) {
            return `${value1}/${value2} ${value3}`;
        }
        if (value1 && value3) {
            return `${value1}/${value3}`;
        }
        if (value1) {
            return `${value1}`;
        }
        return '';
    };
    const handleAddNewFeature = async (
        name: any,
        label: any,
        valueObj: any,
        isAddOn: any
    ) => {
        setAddFeature({
            ...addFeature,
            [name]: false,
        });
        getPlanDetailsFeatuers(mode === 'create').then((res: any) => {
            if (res.status === 200) {
                setFeaturesList(res.data);
                setAmountDetails(
                    res.data.filter(
                        (resDetails: any) => resDetails.fName === 'Pricing'
                    )[0]
                );
                const featureAdded = res.data.filter(
                    (fieldDetails: any) => fieldDetails.fName === name
                )[0];
                if (isAddOn) {
                    const subFeature =
                        featureAdded.featuresComponentList.filter(
                            (subFeatureDetails: any) =>
                                subFeatureDetails.featureName === label
                        )[0];
                    setCreateData({
                        ...createData,
                        [featureAdded.fName]: {
                            ...createData?.[featureAdded.fName],
                            [subFeature.featureName]: {
                                value: handleValueOfAddNewFeature(valueObj),
                                inputId: subFeature.inputTypeId,
                                componentId: subFeature.featureId,
                                featuresMasterId: featureAdded.fId,
                            },
                        },
                    });
                } else {
                    let tempObj: any = {
                        ...createData,
                        [featureAdded.fName]: {},
                    };

                    featureAdded.featuresComponentList.forEach(
                        (featureDetails: any) => {
                            tempObj = {
                                ...tempObj,
                                [featureAdded.fName]: {
                                    ...tempObj[featureAdded.fName],
                                    [featureDetails.featureName]: {
                                        inputId: featureDetails.inputTypeId,
                                        componentId: featureDetails.featureId,
                                        featuresMasterId: featureAdded.fId,
                                        value: valueObj?.[featureAdded.fName]?.[
                                            featureDetails.featureName
                                        ]?.value,
                                    },
                                },
                            };
                        }
                    );

                    setCreateData(tempObj);
                }
            }
        });
    };

    const handleUpdateModule = async () => {
        const moduleToEdit = Object.keys(editModule).filter(
            (moduleKeys) => editModule[moduleKeys]
        );
        let tempObj: any = [];

        Object.keys(createData).forEach((dataDetails: any) => {
            Object.keys(createData[dataDetails]).forEach(
                (subDataDetails: any) => {
                    let valueObj: any = {};
                    if (
                        typeof createData[dataDetails][subDataDetails].value ===
                        'string'
                    ) {
                        valueObj = parseValue(
                            createData[dataDetails][subDataDetails].value,
                            subDataDetails,
                            createData[dataDetails][subDataDetails]
                        );
                    } else {
                        valueObj = {
                            value1: createData[dataDetails][subDataDetails]
                                .value.value,
                        };
                    }
                    tempObj = [
                        ...tempObj,

                        {
                            componentId:
                                createData[dataDetails][subDataDetails]
                                    .componentId,
                            inputId:
                                createData[dataDetails][subDataDetails].inputId,
                            componentValue:
                                typeof createData[dataDetails][subDataDetails]
                                    .value === 'string'
                                    ? createData[dataDetails][subDataDetails]
                                          .value
                                    : createData[dataDetails][subDataDetails]
                                          .value.value,
                            featuresMasterId:
                                createData[dataDetails][subDataDetails]
                                    .featuresMasterId,
                            ...valueObj,
                        },
                    ];
                }
            );
        });
        const payload = {
            eentrpriseplanId: createdPlanId,
            subTitle,
            status: 'Save',
            planTypeId: planType.value ? Number(planType.value) : 0,
            featuresComponentModelList: [
                ...tempObj,
                { ...pricingObj, value2: pricingObj?.value2?.value },
            ],
        };
        const response = await createEnterPrisePlan(payload);
        if (response.status === 200) {
            toast.success(response.data);
            setEditModule({
                ...editModule,
                [moduleToEdit[0]]: false,
            });
        } else {
            toast.error(response.data);
        }
        setIsLoading({ isLoading: false, type: '' });
    };

    const handleDropdownChange = (
        e: any,
        feature: any,
        subFeature: any,
        subFeatureObj: any,
        featureId: any,
        label: string
    ) => {
        let data = {
            ...createData,
            [feature]: {
                ...createData?.[feature],
                [subFeature]: {
                    value: e,
                    inputId: subFeatureObj.inputTypeId,
                    componentId: subFeatureObj.featureId,
                    featuresMasterId: featureId,
                },
            },
        };

        if (label === 'defaultDropdown') {
            data = {
                ...createData,
                [feature]: {
                    ...createData?.[feature],
                    [subFeature]: e.value,
                    featuresMasterId: featureId,
                },
            };
        }
        setCreateData(data);
    };

    const getFeatureFields = (
        type: string,
        subFeature: any,
        feature: any,
        featureId: any,
        subFeatureObj: any
    ) => {
        switch (type) {
            case 'Applicable/ Not Applicable':
                return (mode === 'create' && !isPlanCreated) ||
                    (mode === 'clone' && editModule?.[feature]) ||
                    (mode === 'create' &&
                        isPlanCreated &&
                        editModule?.[feature]) ? (
                    <DropDownStyle>
                        <Select
                            options={planDetailsDropdownOptions}
                            onChange={(e: any) =>
                                handleDropdownChange(
                                    e,
                                    feature,
                                    subFeature,
                                    subFeatureObj,
                                    featureId,
                                    ''
                                )
                            }
                            value={
                                createData?.[feature]?.[subFeature]?.value ||
                                null
                            }
                        />
                    </DropDownStyle>
                ) : (
                    <div>
                        {createData?.[feature]?.[subFeature]?.value?.label}
                    </div>
                );

            case 'Text':
            case 'Numeric':
            case 'Numeric + Prefix':
            case 'Numeric + Frequency':
                return (mode === 'create' && !isPlanCreated) ||
                    (mode === 'clone' && editModule?.[feature]) ||
                    (mode === 'create' &&
                        isPlanCreated &&
                        editModule?.[feature]) ? (
                    <div>
                        {[
                            'Campaign Logistics AI Manager (CLAIM)',
                            'Emails',
                        ].includes(subFeature) ? (
                            <ThreeInput
                                createData={createData}
                                feature={feature}
                                subFeature={subFeature}
                                subFeatureObj={subFeatureObj}
                                setCreateData={setCreateData}
                                featureId={featureId}
                            />
                        ) : (
                            <InputSearch>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={
                                        createData?.[feature]?.[subFeature]
                                            ?.value
                                            ? createData?.[feature]?.[
                                                  subFeature
                                              ]?.value
                                            : ''
                                    }
                                    onChange={(e) => {
                                        handleDropdownChange(
                                            e.target.value,
                                            feature,
                                            subFeature,
                                            subFeatureObj,
                                            featureId,
                                            ''
                                        );
                                    }}
                                />
                            </InputSearch>
                        )}
                    </div>
                ) : (
                    <div> {createData?.[feature]?.[subFeature]?.value}</div>
                );
            case 'Numeric + Numeric + Prefix':
                return (mode === 'create' && !isPlanCreated) ||
                    (mode === 'clone' && editModule?.[feature]) ||
                    (mode === 'create' &&
                        isPlanCreated &&
                        editModule?.[feature]) ? (
                    <div>
                        {[
                            'Additional Contacts',
                            'Donation Processing',
                        ].includes(subFeature) ? (
                            <ThreeInput
                                createData={createData}
                                feature={feature}
                                subFeature={subFeature}
                                subFeatureObj={subFeatureObj}
                                setCreateData={setCreateData}
                                featureId={featureId}
                            />
                        ) : (
                            <InputSearch>
                                <CustomInput
                                    createData={createData}
                                    feature={feature}
                                    subFeature={subFeature}
                                    subFeatureObj={subFeatureObj}
                                    setCreateData={setCreateData}
                                    featureId={featureId}
                                    componentValue={
                                        createData?.[feature]?.[subFeature]
                                            ?.value
                                    }
                                />
                            </InputSearch>
                        )}
                    </div>
                ) : (
                    <div> {createData?.[feature]?.[subFeature]?.value}</div>
                );
            default:
                return (
                    <DropDownStyle>
                        <Select
                            options={planDetailsDropdownOptions}
                            onChange={(e: any) =>
                                handleDropdownChange(
                                    e.target.value,
                                    feature,
                                    subFeature,
                                    subFeatureObj,
                                    featureId,
                                    'defaultDropdown'
                                )
                            }
                        />
                    </DropDownStyle>
                );
        }
    };

    const handleAmountChange = (val: any, access: string) => {
        setPricingObj({
            ...pricingObj,
            inputId: amountDetails.featuresComponentList.filter(
                (amountDetailsObj: any) =>
                    amountDetailsObj.featureName === 'Amount'
            )[0].inputTypeId,
            componentId: amountDetails.featuresComponentList.filter(
                (amountDetailsObj: any) =>
                    amountDetailsObj.featureName === 'Amount'
            )[0].featureId,
            [access]: val,
            featuresMasterId: amountDetails.fId,
        });
    };

    return isLoading.isLoading && isLoading.type === 'pageLoader' ? (
        <Loader />
    ) : (
        <PlanManagementStyle>
            <WhiteCard className="mt-4">
                <div className="card-header d-flex justify-content-between">
                    Pricing
                    {(mode === 'create' && isPlanCreated) ||
                    mode === 'clone' ? (
                        <span>
                            <img
                                src={EditBackgroundImg}
                                className="cursor"
                                role="presentation"
                                alt="edit"
                                onClick={() => {
                                    setEditModule({
                                        ...editModule,
                                        Pricing: true,
                                    });
                                }}
                            />
                        </span>
                    ) : null}
                </div>
                <div className="horizontal-line mt-3" />
                {(mode === 'create' && !isPlanCreated) ||
                (mode === 'create' && isPlanCreated && editModule.Pricing) ||
                (mode === 'clone' && editModule.Pricing) ? (
                    <div className="row">
                        <div className="col-12">
                            <p className=" py-2">Amount</p>
                            <ul className="pricing-amt">
                                <li>
                                    <InputSearch>
                                        <input
                                            type="text"
                                            className="form-control dollar-input"
                                            onChange={(e: any) => {
                                                if (
                                                    checkIsOnlyNumber(
                                                        e.target.value
                                                    )
                                                ) {
                                                    handleAmountChange(
                                                        e.target.value,
                                                        'value1'
                                                    );
                                                }
                                            }}
                                            value={pricingObj?.value1 || ''}
                                        />
                                        <img
                                            src={DollarImg}
                                            alt="dollar-img"
                                            className="dollar-img"
                                        />
                                    </InputSearch>
                                </li>
                                <li className="amount-per-date">
                                    <DropDownStyle>
                                        <Select
                                            options={frequencyDropdownOptions}
                                            onChange={(e: any) => {
                                                handleAmountChange(e, 'value2');
                                            }}
                                            value={pricingObj?.value2 || null}
                                        />
                                    </DropDownStyle>
                                </li>
                            </ul>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-6 mt-2">
                            <FilterTag className="status-block mt-4 ">
                                {getPlanLabelInPricing(
                                    pricingObj?.value2?.value
                                )}
                            </FilterTag>
                            <div className="page-title mt-4 ">
                                ${pricingObj?.value1} /
                                {pricingObj?.value2?.value}
                            </div>
                        </div>
                    </div>
                )}
            </WhiteCard>
            {featuresList
                .filter(
                    (featureDetails: any) => featureDetails.fName !== 'Pricing'
                )
                .map((featureDetails: any) => {
                    return (
                        <WhiteCard className="mt-4" key={featureDetails.fId}>
                            <div className="card-header d-flex justify-content-between">
                                {featureDetails.fName}{' '}
                                {(mode === 'create' && isPlanCreated) ||
                                mode === 'clone' ? (
                                    <span>
                                        <img
                                            src={EditBackgroundImg}
                                            className="cursor"
                                            role="presentation"
                                            alt="edit"
                                            onClick={() => {
                                                setEditModule({
                                                    ...editModule,
                                                    [featureDetails.fName]:
                                                        true,
                                                });
                                            }}
                                        />
                                    </span>
                                ) : null}
                            </div>
                            <div className="horizontal-line mt-3" />
                            <div className="row">
                                {featureDetails.featuresComponentList.map(
                                    (featureCompDetails: any) => {
                                        return featureDetails.fName ===
                                            'Pricing' ? (
                                            <div className="col-12">
                                                <p className=" py-2">Amount</p>
                                                <ul className="pricing-amt">
                                                    <li>
                                                        <InputSearch>
                                                            <input
                                                                type="text"
                                                                className="form-control dollar-input"
                                                            />
                                                            <img
                                                                src={DollarImg}
                                                                alt="dollar-img"
                                                                className="dollar-img"
                                                            />
                                                        </InputSearch>
                                                    </li>
                                                    <li>
                                                        <DropDownStyle>
                                                            <Select
                                                                options={
                                                                    planDetailsDropdownOptions
                                                                }
                                                            />
                                                        </DropDownStyle>
                                                    </li>
                                                </ul>
                                            </div>
                                        ) : (
                                            <div className="col-lg-3 py-2">
                                                <div className="plan-title mb-2">
                                                    {
                                                        featureCompDetails.featureName
                                                    }
                                                    {(mode === 'clone' &&
                                                        !editModule?.[
                                                            featureDetails.fName
                                                        ] &&
                                                        featureDetails.fName !==
                                                            'Features') ||
                                                    (mode === 'create' &&
                                                        isPlanCreated &&
                                                        !editModule?.[
                                                            featureDetails.fName
                                                        ] &&
                                                        featureDetails.fName !==
                                                            'Features') ? (
                                                        <>
                                                            <ToggleSwitch
                                                                className="ml-2"
                                                                data-tooltip-id="app"
                                                                data-tooltip-content="Applicable/Not applicable"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        createData?.[
                                                                            featureDetails
                                                                                .fName
                                                                        ]?.[
                                                                            featureCompDetails
                                                                                .featureName
                                                                        ]
                                                                            ?.isApplicable
                                                                    }
                                                                    disabled={
                                                                        isToggleDisabled
                                                                    }
                                                                    onChange={(
                                                                        e: ChangeEvent<HTMLInputElement>
                                                                    ) =>
                                                                        handleToggleChange(
                                                                            e
                                                                                .target
                                                                                .checked,
                                                                            createData?.[
                                                                                featureDetails
                                                                                    .fName
                                                                            ]?.[
                                                                                featureCompDetails
                                                                                    .featureName
                                                                            ]
                                                                                ?.componentId,
                                                                            featureCompDetails.featureName,
                                                                            featureDetails.fName,
                                                                            featureDetails.fId,
                                                                            featureCompDetails
                                                                        )
                                                                    }
                                                                />
                                                                <span className="slider round" />
                                                            </ToggleSwitch>
                                                            <Tooltip id="app" />
                                                        </>
                                                    ) : null}
                                                </div>
                                                <div className="row">
                                                    <div className="col-10 pr-0">
                                                        {getFeatureFields(
                                                            featureCompDetails.inputTypeValue,
                                                            featureCompDetails.featureName,
                                                            featureDetails.fName,
                                                            featureDetails.fId,
                                                            featureCompDetails
                                                        )}
                                                    </div>
                                                    {mode === 'edit' &&
                                                    editModule?.[
                                                        featureDetails?.fName
                                                    ] ? (
                                                        <div className="col-2">
                                                            <img
                                                                src={
                                                                    ContactDeleteImg
                                                                }
                                                                role="presentation"
                                                                alt="delete-img"
                                                                className="delete-img cursor"
                                                                onClick={() => {
                                                                    const tempData =
                                                                        {
                                                                            ...createData,
                                                                        };
                                                                    delete tempData?.[
                                                                        featureDetails?.fName
                                                                    ]?.[
                                                                        featureCompDetails?.featureName
                                                                    ];
                                                                    setCreateData(
                                                                        tempData
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                            {featureDetails?.fName !== 'Features' &&
                                mode === 'create' &&
                                !isPlanCreated && (
                                    <div className="row">
                                        {!addFeature?.[
                                            featureDetails.fName
                                        ] && (
                                            <div className="col-12 mt-4">
                                                <Button
                                                    className="btn-border"
                                                    onClick={() => {
                                                        setAddFeature({
                                                            ...addFeature,
                                                            [featureDetails.fName]:
                                                                true,
                                                        });
                                                    }}
                                                >
                                                    Add add-on
                                                </Button>
                                            </div>
                                        )}
                                        {addFeature?.[featureDetails.fName] && (
                                            <AddOnFeature
                                                fmTitleId={featureDetails.fId}
                                                fmTitleName={
                                                    featureDetails.fName
                                                }
                                                planName="Enterprise"
                                                isAddOn
                                                setAddFeature={
                                                    handleAddNewFeature
                                                }
                                                planId="0"
                                                flag={mode}
                                            />
                                        )}
                                    </div>
                                )}
                            {(mode === 'create' &&
                                editModule?.[featureDetails?.fName]) ||
                            (mode === 'clone' &&
                                editModule?.[featureDetails?.fName] &&
                                isPlanCreated) ? (
                                <div className="row">
                                    <div className="col-12 text-right mt-4">
                                        <Button
                                            className="btn-border mr-3"
                                            onClick={() => {
                                                setEditModule({
                                                    ...editModule,
                                                    [featureDetails?.fName]:
                                                        false,
                                                });
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setIsLoading({
                                                    isLoading: true,
                                                    type: 'Update',
                                                });
                                                handleUpdateModule();
                                            }}
                                        >
                                            {isLoading.isLoading &&
                                            isLoading.type === 'Update' ? (
                                                <Loader type="button" />
                                            ) : (
                                                'Update'
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            ) : null}
                        </WhiteCard>
                    );
                })}
            <div className="row mt-3">
                {!isAddNewType && (
                    <div className="col-12 py-4 mt-2">
                        <Button
                            onClick={() => {
                                setIsAddNewType(true);
                            }}
                        >
                            + Add new type
                        </Button>
                    </div>
                )}
            </div>
            {isAddNewType && (
                <WhiteCard>
                    <AddOnFeature
                        fmTitleId={0}
                        fmTitleName=""
                        planName={subTitle}
                        setAddFeature={handleAddNewFeature}
                        setIsAddNewType={setIsAddNewType}
                        planId={createdPlanId}
                        flag={mode}
                    />
                </WhiteCard>
            )}
            {(mode === 'create' && !isPlanCreated) ||
            (mode === 'clone' && !isPlanCreated) ? (
                <div className="col-12 text-right py-5">
                    <Button
                        className="btn-border"
                        onClick={() => {
                            navigate(PATH_PLAN_MANAGEMENT);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="mx-2 save-draft-btn"
                        onClick={() => {
                            setIsLoading({ isLoading: true, type: 'Draft' });
                            handleSubmit('draft');
                        }}
                        disabled={
                            (!subTitle && !subTitle?.trim()) ||
                            !pricingObj?.value1
                        }
                    >
                        {isLoading.isLoading && isLoading.type === 'Draft' ? (
                            <Loader type="button" />
                        ) : (
                            'Save as draft'
                        )}
                    </Button>
                    <Button
                        onClick={() => {
                            setIsLoading({ isLoading: true, type: 'Save' });
                            handleSubmit('Save');
                        }}
                        disabled={
                            (!subTitle && !subTitle?.trim()) ||
                            !pricingObj?.value1
                        }
                    >
                        {isLoading.isLoading && isLoading.type === 'Save' ? (
                            <Loader type="button" />
                        ) : (
                            'Create'
                        )}
                    </Button>
                </div>
            ) : null}
        </PlanManagementStyle>
    );
};

export default CreatePlan;
