import { FC, useState, useCallback, useEffect } from 'react';

import DatePicker from 'react-date-picker';
import { toast } from 'react-toastify';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';

import AddressComp from '../Address/AddressComp';
import SelectDropdown from '../../common/SelectDropdown';
import AddressField from '../Address/AddressField';
import ContactStyle from '../../Theme/style/ContactStyle';
import { updateContact } from '../../api';
import {
    AddressFieldConstant,
    voterStatusForContact,
    ballotStatusOptions,
} from '../../constants';
import {
    ContactEditProp,
    AddressFilteredComps,
    AddressFieldsType,
    SelectOption,
} from '../../types/type';
import {
    Button,
    InputSearch,
    TextArea,
    ErrorMsg,
    DatePickerCalendar,
} from '../../common';

import 'react-date-picker/dist/DatePicker.css';

const ContactDetailsEdit: FC<ContactEditProp> = ({
    firstName,
    middleName,
    lastName,
    primaryEmail,
    primaryPhone,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    employer,
    occupation,
    affiliation,
    note,
    voterStatus,
    ballotStatus,
    registrationDate,
    voterId,
    preCinct,
    county,
    congressionalHouseDistrict,
    localSchoolBoard,
    stateHouseDistrict,
    stateSenateDistrict,
    stateSchoolBoard,
    contactId,
    gpsCoordinates,
    addressId,
    affiliactionDropdown,
    handleEdit,
    handleDetailAPICall,
    closeDetailModal,
}) => {
    const [isPhoneError, setIsPhoneError] = useState<string | null>(null);
    const [completeAddress, setCompleteAddress] =
        useState<AddressFieldsType[]>();

    const getInitVoterStatus = () => {
        return voterStatusForContact.find(
            (item: any) =>
                item?.label?.toLowerCase() === voterStatus?.toLowerCase()
        );
    };

    const getInitBallotStatus = () => {
        return ballotStatusOptions.find(
            (item: any) =>
                item?.label?.toLowerCase() === ballotStatus?.toLowerCase()
        );
    };

    const getInitAffiliation = () => {
        return affiliactionDropdown?.find(
            (item: any) => item?.label === affiliation
        );
    };
    const [selectedVoterStatus, setSelectedVoterStatus] = useState<
        SelectOption | undefined
    >(getInitVoterStatus());
    const [selectedBallotStatus, setSelectedBallotStatus] = useState<
        SelectOption | undefined
    >(getInitBallotStatus());

    const [selectedAffiliation, setSelectedAffiliation] = useState<
        SelectOption | undefined
    >(getInitAffiliation());

    const [addressObj, setAddToApi] = useState<any>();

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<ContactEditProp>({
        mode: 'onChange',
        delayError: 500,
        defaultValues: {
            firstName,
            middleName,
            lastName,
            primaryEmail,
            primaryPhone,
            employer,
            occupation,
            affiliation,
            note,
            registrationDate,
            voterId,
            preCinct,
            county,
            congressionalHouseDistrict,
            localSchoolBoard,
            stateHouseDistrict,
            stateSenateDistrict,
            stateSchoolBoard,
        },
    });

    const getCleanDate = (val: Date | string) => {
        const dateStr = new Date(val);
        const offset = dateStr.getTimezoneOffset();
        const updatedDate = new Date(dateStr.getTime() - offset * 60 * 1000);
        return updatedDate.toISOString();
    };

    const onSubmit: SubmitHandler<ContactEditProp> = async (data) => {
        const address = [
            {
                contactId,
                primaryAddressId: addressId,
                addressLine1: addressObj?.address,
                addressLine2: addressObj?.suit,
                city: addressObj?.city,
                state: addressObj?.state,
                zipCode: addressObj?.zip,
                gpsCoordinates: addressObj?.gpdcoordinates
                    ? addressObj?.gpdcoordinates.replace(/[()]/g, '')
                    : '',
            },
        ];

        const updatedData = {
            ...data,
            contactId,
            address,
            affiliation:
                selectedAffiliation?.label === 'Other'
                    ? data.affiliation
                    : selectedAffiliation?.label,
            voteStatus: Number(selectedVoterStatus?.value),
            ballotStatus: Number(selectedBallotStatus?.value),
            registrationDate:
                data.registrationDate && getCleanDate(data.registrationDate),
            primaryPhone: Number(data.primaryPhone?.replace(/\D/g, '')),
        };

        const response = await updateContact(updatedData);
        if (response?.status === 200) {
            toast.success(response?.data);
            handleEdit();
            handleDetailAPICall();
        } else {
            toast.error(response?.data);
        }
    };

    const handleValidate = useCallback((phoneNumber: any) => {
        const updatedPhone = phoneNumber.replace(/\D/g, '');

        if (updatedPhone && updatedPhone.toString().length < 10) {
            setIsPhoneError('Invalid phone number');
        } else {
            setIsPhoneError(null);
        }
        return updatedPhone.toString().length;
    }, []);

    const handleCompleteAddress = (obj: AddressFilteredComps) => {
        setAddToApi(obj);
        const completeAdd = AddressFieldConstant.map(
            (item: AddressFieldsType) => {
                return {
                    ...item,
                    value:
                        obj[item.accessor as keyof AddressFilteredComps]! || '',
                };
            }
        );
        setCompleteAddress(completeAdd);
    };

    const handleAddressChange = (type: string, val: string) => {
        if (type === 'suit') {
            const allAdd = { ...addressObj, suit: val };
            handleCompleteAddress(allAdd);
        }
    };

    const handleVoterStatusChange = (option: SelectOption) => {
        setSelectedVoterStatus(option);
    };

    const handleBallotStatusChange = (option: SelectOption) => {
        setSelectedBallotStatus(option);
    };

    const handleAffiliationChange = (option: SelectOption) => {
        setSelectedAffiliation(option);
    };

    const handleCancel = () => {
        closeDetailModal();
    };

    const setInitialAddress = useCallback(() => {
        handleCompleteAddress({
            address: addressLine1,
            suit: addressLine2,
            city,
            state,
            zip: zipCode,
            gpdcoordinates: gpsCoordinates,
        });
    }, [addressLine1, addressLine2, city, state, zipCode, gpsCoordinates]);

    useEffect(() => {
        setInitialAddress();
    }, [setInitialAddress]);

    return (
        <ContactStyle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">First name</p>
                        <InputSearch>
                            <input
                                type="text"
                                className={
                                    errors?.firstName
                                        ? 'error-border form-control'
                                        : 'form-control'
                                }
                                {...register('firstName', {
                                    required: {
                                        value: true,
                                        message: 'First Name is required.',
                                    },
                                    maxLength: {
                                        value: 50,
                                        message:
                                            'First Name should be max 50 characters',
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z]([\sa-zA-Z -]*[a-zA-Z])?$/,
                                        message:
                                            "Numbers are not allowed and need characters after '-' and space.",
                                    },
                                })}
                            />
                        </InputSearch>
                        <ErrorMsg>{errors?.firstName?.message}</ErrorMsg>
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">Middle name</p>
                        <InputSearch>
                            <input
                                type="text"
                                className={
                                    errors?.middleName
                                        ? 'error-border form-control'
                                        : 'form-control'
                                }
                                {...register('middleName', {
                                    maxLength: {
                                        value: 50,
                                        message:
                                            'Middle name should be max 50 characters',
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z]([\sa-zA-Z -]*[a-zA-Z])?$/,
                                        message:
                                            "Numbers are not allowed and need characters after '-' and space.",
                                    },
                                })}
                            />
                        </InputSearch>
                        <ErrorMsg>{errors?.middleName?.message}</ErrorMsg>
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">Last name</p>
                        <InputSearch>
                            <input
                                type="text"
                                className={
                                    errors?.lastName
                                        ? 'error-border form-control'
                                        : 'form-control'
                                }
                                {...register('lastName', {
                                    required: {
                                        value: true,
                                        message: 'Last name is required.',
                                    },
                                    maxLength: {
                                        value: 50,
                                        message:
                                            'Last name should be max 50 characters',
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z]([\sa-zA-Z -]*[a-zA-Z])?$/,
                                        message:
                                            "Numbers are not allowed and need characters after '-' and space.",
                                    },
                                })}
                            />
                        </InputSearch>
                        <ErrorMsg>{errors?.lastName?.message}</ErrorMsg>
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">Email</p>
                        <InputSearch>
                            <input
                                type="text"
                                className={
                                    errors?.primaryEmail
                                        ? 'error-border form-control'
                                        : 'form-control'
                                }
                                {...register('primaryEmail', {
                                    maxLength: {
                                        value: 100,
                                        message:
                                            'Email should be max 100 characters',
                                    },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Invalid email address.',
                                    },
                                })}
                            />
                        </InputSearch>
                        <ErrorMsg>{errors?.primaryEmail?.message}</ErrorMsg>
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">Phone</p>
                        <Controller
                            control={control}
                            name="primaryPhone"
                            rules={{
                                validate: (value) => handleValidate(value),
                            }}
                            render={({ field: { ref, value, ...rest } }) => (
                                <PatternFormat
                                    className={
                                        errors?.primaryPhone
                                            ? 'error-border form-control'
                                            : 'form-control'
                                    }
                                    value={primaryPhone}
                                    format="(###) ###-####"
                                    {...rest}
                                />
                            )}
                        />
                        <ErrorMsg>{isPhoneError}</ErrorMsg>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-8">
                        <AddressComp
                            setCompleteAddress={handleCompleteAddress}
                        />

                        <div className="row">
                            {completeAddress &&
                                completeAddress.length &&
                                completeAddress.map(
                                    (item: AddressFieldsType) =>
                                        item.accessor !== 'gpdcoordinates' && (
                                            <AddressField
                                                key={item.id}
                                                handleChange={
                                                    handleAddressChange
                                                }
                                                {...item}
                                            />
                                        )
                                )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">Employer</p>
                        <InputSearch>
                            <input
                                type="text"
                                className={
                                    errors?.employer
                                        ? 'error-border form-control'
                                        : 'form-control'
                                }
                                {...register('employer', {
                                    maxLength: {
                                        value: 100,
                                        message:
                                            'Employer should be max 100 characters',
                                    },
                                })}
                            />
                        </InputSearch>
                        <ErrorMsg>{errors?.employer?.message}</ErrorMsg>
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">Occupation</p>
                        <InputSearch>
                            <input
                                type="text"
                                className={
                                    errors?.occupation
                                        ? 'error-border form-control'
                                        : 'form-control'
                                }
                                {...register('occupation', {
                                    maxLength: {
                                        value: 100,
                                        message:
                                            'Occupation should be max 100 characters',
                                    },
                                })}
                            />
                        </InputSearch>
                        <ErrorMsg>{errors?.occupation?.message}</ErrorMsg>
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">Affiliation</p>
                        <SelectDropdown
                            options={affiliactionDropdown!}
                            selected={selectedAffiliation}
                            handlePageChange={handleAffiliationChange}
                        />

                        {selectedAffiliation?.label === 'Other' && (
                            <div className="mt-2">
                                <InputSearch>
                                    <input
                                        type="text"
                                        className={
                                            errors?.affiliation
                                                ? 'error-border form-control'
                                                : 'form-control'
                                        }
                                        {...register('affiliation', {
                                            required: {
                                                value: true,
                                                message:
                                                    'Affiliation is required.',
                                            },
                                            maxLength: {
                                                value: 100,
                                                message:
                                                    'Affiliation should be max 100 characters',
                                            },
                                        })}
                                    />
                                </InputSearch>
                                <ErrorMsg>
                                    {errors?.affiliation?.message}
                                </ErrorMsg>
                            </div>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 py-2">
                        <p className="details-title">Note</p>
                        <TextArea
                            {...register('note', {
                                maxLength: {
                                    value: 500,
                                    message:
                                        'Note should be max 500 characters',
                                },
                            })}
                        />
                        <ErrorMsg>{errors?.note?.message}</ErrorMsg>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">Voter status</p>
                        <SelectDropdown
                            options={voterStatusForContact}
                            selected={selectedVoterStatus}
                            handlePageChange={handleVoterStatusChange}
                        />
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">Registration date</p>
                        <DatePickerCalendar>
                            <Controller
                                control={control}
                                name="registrationDate"
                                render={({
                                    field: { ref, value, ...rest },
                                }) => (
                                    <DatePicker
                                        value={value!}
                                        calendarType="US"
                                        format="MM/dd/y"
                                        dayPlaceholder="DD"
                                        monthPlaceholder="MM"
                                        yearPlaceholder="YYYY"
                                        clearIcon={null}
                                        {...rest}
                                    />
                                )}
                            />
                        </DatePickerCalendar>
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">Voter ID</p>
                        <InputSearch>
                            <input
                                type="text"
                                className={
                                    errors?.voterId
                                        ? 'error-border form-control'
                                        : 'form-control'
                                }
                                {...register('voterId', {
                                    maxLength: {
                                        value: 50,
                                        message:
                                            'Voter ID should be max 50 characters',
                                    },
                                })}
                            />
                        </InputSearch>
                        <ErrorMsg>{errors?.voterId?.message}</ErrorMsg>
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">Ballot status</p>
                        <SelectDropdown
                            options={ballotStatusOptions}
                            selected={selectedBallotStatus}
                            handlePageChange={handleBallotStatusChange}
                        />
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">Precinct</p>
                        <InputSearch>
                            <input
                                type="text"
                                className={
                                    errors?.preCinct
                                        ? 'error-border form-control'
                                        : 'form-control'
                                }
                                {...register('preCinct', {
                                    maxLength: {
                                        value: 100,
                                        message:
                                            'Precinct should be max 100 characters',
                                    },
                                })}
                            />
                        </InputSearch>
                        <ErrorMsg>{errors?.preCinct?.message}</ErrorMsg>
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">County</p>
                        <InputSearch>
                            <input
                                type="text"
                                className={
                                    errors?.county
                                        ? 'error-border form-control'
                                        : 'form-control'
                                }
                                {...register('county', {
                                    maxLength: {
                                        value: 100,
                                        message:
                                            'county should be max 100 characters',
                                    },
                                })}
                            />
                        </InputSearch>
                        <ErrorMsg>{errors?.county?.message}</ErrorMsg>
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">
                            Congrssional House District
                        </p>
                        <InputSearch>
                            <input
                                type="text"
                                className={
                                    errors?.congressionalHouseDistrict
                                        ? 'error-border form-control'
                                        : 'form-control'
                                }
                                {...register('congressionalHouseDistrict', {
                                    maxLength: {
                                        value: 100,
                                        message:
                                            'Congrssional House District should be max 100 characters',
                                    },
                                })}
                            />
                        </InputSearch>
                        <ErrorMsg>
                            {errors?.congressionalHouseDistrict?.message}
                        </ErrorMsg>
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">Local School Board</p>
                        <InputSearch>
                            <input
                                type="text"
                                className={
                                    errors?.localSchoolBoard
                                        ? 'error-border form-control'
                                        : 'form-control'
                                }
                                {...register('localSchoolBoard', {
                                    maxLength: {
                                        value: 100,
                                        message:
                                            'Local School Board should be max 100 characters',
                                    },
                                })}
                            />
                        </InputSearch>
                        <ErrorMsg>{errors?.localSchoolBoard?.message}</ErrorMsg>
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">
                            State House District
                        </p>
                        <InputSearch>
                            <input
                                type="text"
                                className={
                                    errors?.stateHouseDistrict
                                        ? 'error-border form-control'
                                        : 'form-control'
                                }
                                {...register('stateHouseDistrict', {
                                    maxLength: {
                                        value: 100,
                                        message:
                                            'State House District should be max 100 characters',
                                    },
                                })}
                            />
                        </InputSearch>
                        <ErrorMsg>
                            {errors?.stateHouseDistrict?.message}
                        </ErrorMsg>
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">
                            State Senate District
                        </p>
                        <InputSearch>
                            <input
                                type="text"
                                className={
                                    errors?.stateSenateDistrict
                                        ? 'error-border form-control'
                                        : 'form-control'
                                }
                                {...register('stateSenateDistrict', {
                                    maxLength: {
                                        value: 100,
                                        message:
                                            'State Senate District should be max 100 characters',
                                    },
                                })}
                            />
                        </InputSearch>
                        <ErrorMsg>
                            {errors?.stateSenateDistrict?.message}
                        </ErrorMsg>
                    </div>

                    <div className="col-lg-4 py-2">
                        <p className="details-title mb-1">State School Board</p>
                        <InputSearch>
                            <input
                                type="text"
                                className={
                                    errors?.stateSchoolBoard
                                        ? 'error-border form-control'
                                        : 'form-control'
                                }
                                {...register('stateSchoolBoard', {
                                    maxLength: {
                                        value: 100,
                                        message:
                                            'State School Board should be max 100 characters',
                                    },
                                })}
                            />
                        </InputSearch>
                        <ErrorMsg>{errors?.stateSchoolBoard?.message}</ErrorMsg>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-right py-3">
                        <Button
                            type="button"
                            className="mr-2 btn-border"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button type="submit">Update</Button>
                    </div>
                </div>
            </form>
        </ContactStyle>
    );
};

export default ContactDetailsEdit;
