import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { read, utils } from 'xlsx';

import PageNav from '../../common/NavLink';
import MapExcelFields from './MapExcelFields';
import CommonModal from '../../common/CommonModal';
import Loader from '../../common/Loader';
import { Button } from '../../common';
import { UndoFile } from '../../Theme/style/ContactStyle';
import { PATH_CONTACTS } from '../../constants';
import { ArrowRight, InfoImg, Undo, Upload } from '../../assets/images';
import { deleteLastUploadedFile, getLatestFileUploadLog } from '../../api';
import {
    Container,
    CrossButton,
    DashedBorder,
    File,
    FileError,
} from '../../Theme/style/UploadContactStyles';

const UploadContacts = () => {
    const navigate = useNavigate();
    const [fileName, setFileName] = useState<string>('');
    const [buttonFlag, setButtonFlag] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<any>([{}]);
    const [showMapping, setShowMapping] = useState(false);
    const [latestUploadLog, setLatestUploadLog] = useState('');
    const [deleteConfirmationModal, setDeleteConfirmationModal] =
        useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const formData = new FormData();

    const onDrop = (acceptedFiles: any) => {
        setButtonFlag(true);
        setFileName(acceptedFiles[0]?.name);
        setSelectedFile(
            acceptedFiles.map((file: any) =>
                Object.assign(file, { preview: URL.createObjectURL(file) })
            )
        );
    };

    const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
        onDrop,
        accept: {
            'text/plain': ['.xlsx'],
        },
        maxFiles: 1,
        noClick: true,
        noKeyboard: true,
    });

    formData.append('file', selectedFile[0]);
    if (fileRejections.length >= 1)
        toast.error('File type must be text/plain, .xlsx');

    const [fileHeader, setFileHeader] = useState<any>([]);
    const [fileRowsData, setFileRowsData] = useState<any>([]);
    const [fieldLoding, setFieldLoding] = useState(false);

    const handleUploadFile = () => {
        setFieldLoding(true);
        // Read uploaded file
        const reader = new FileReader();

        reader.onload = (e: any) => {
            const data = new Uint8Array(e.target.result);
            const headers: any = [];
            let isHeaderAvaible: boolean = false;
            let isDataAvaible: boolean = false;
            const rows: any = [];
            const workbook = read(data, { type: 'array', cellDates: true });
            const worksheet: any = workbook.Sheets[workbook.SheetNames[0]];
            // Assuming the headers are in the first row
            const range = utils.decode_range(worksheet['!ref']);
            for (let col = 0; col <= range.e.c; col += 1) {
                const cellAddress = utils.encode_cell({ r: 0, c: col });
                const cell = worksheet[cellAddress];
                let header = cell && cell?.v ? cell.v : ''; // Get the header value
                // if cells has value then convert it into lowercase and compare to general header arr.
                if (cell && cell?.v && typeof cell?.v === 'string') {
                    if (
                        cell?.v?.match(/first name/i) ||
                        cell?.v?.match(/last name/i) ||
                        cell?.v?.match(/firstname/i) ||
                        cell?.v?.match(/lastname/i)
                    ) {
                        isHeaderAvaible = true;
                    }
                }
                header += `_col${col}`;
                headers.push(header.trim());
            }
            // remaining are data rows
            for (let r = range.s.r + 1; r <= range.e.r; r += 1) {
                const row: any = {};
                for (let col = range.s.c; col <= range.e.c; col += 1) {
                    const cellAddress = utils.encode_cell({ r, c: col });
                    const cellValue = worksheet[cellAddress]
                        ? worksheet[cellAddress].v
                        : '';

                    // if data cells has value.
                    if (worksheet[cellAddress] && worksheet[cellAddress].v) {
                        isDataAvaible = true;
                    }
                    row[headers[col]] = cellValue;
                }
                rows.push(row);
            }

            // the file contains only headers
            if (isHeaderAvaible && isDataAvaible) {
                setFileHeader(headers);
                setFileRowsData(rows);
                setShowMapping(true);
                setFieldLoding(false);
            } else {
                setFileHeader([]);
                setFileRowsData([]);
                setShowMapping(false);
                toast.error(
                    'Please ensure that the file is not an empty, must have header and data'
                );
                setFieldLoding(false);
            }
        };

        if (selectedFile?.[0]?.path?.length) {
            reader?.readAsArrayBuffer(selectedFile?.[0]);
        }
    };

    const handleDelete = async () => {
        setIsDeleteLoading(true);

        const result = await deleteLastUploadedFile();
        if (result?.status === 200) {
            setIsDeleteLoading(false);
            toast.success(result?.data);
        } else {
            setIsDeleteLoading(false);
            toast.error(result?.data);
        }
        setDeleteConfirmationModal(false);
    };
    useEffect(() => {
        getLatestFileUploadLog().then((res) => {
            if (res.status === 200) {
                setLatestUploadLog(res.data);
            }
        });
    }, []);

    const ThemeData =
        localStorage.getItem('theme') &&
        JSON.parse(localStorage.getItem('theme') || '');

    return showMapping ? (
        <div>
            {fieldLoding ? (
                <Loader />
            ) : (
                <MapExcelFields
                    setSelectedFile={setSelectedFile}
                    fileHeaders={fileHeader}
                    fileRowData={fileRowsData}
                    fieldLoding={fieldLoding}
                />
            )}
        </div>
    ) : (
        <div className="body-container">
            <PageNav className="mb-3">
                <ul className="nav-list">
                    <li
                        className="active-nav"
                        onClick={() => navigate(PATH_CONTACTS)}
                        role="presentation"
                    >
                        Contacts{' '}
                        <img className="ml-1" src={ArrowRight} alt="arrow" />
                    </li>
                    <li>Upload</li>
                </ul>
            </PageNav>
            <div className="row">
                <div className="col-12 text-bold mt-4">
                    Upload your own contact file. {ThemeData?.type} will
                    identify any duplicates.
                </div>
                <div className="col-md-9 col-12 mt-2">
                    <Container {...getRootProps()}>
                        <input {...getInputProps({ className: 'dropzone' })} />
                        <ul className="import-upload">
                            <li className="import-file">
                                <div className="upload-file">
                                    <img src={Upload} alt="" />
                                    <p>Upload .xlsx</p>
                                </div>
                            </li>

                            <li className="import-file">
                                <div className="pt-2">
                                    <Button
                                        className="px-4 cross-import-btn"
                                        type="button"
                                        onClick={open}
                                        disabled={selectedFile?.[0]?.name}
                                    >
                                        Choose file
                                    </Button>
                                </div>
                            </li>
                            <li className="import-file">
                                {selectedFile[0]?.name && (
                                    <DashedBorder>
                                        <p
                                            style={{
                                                borderBottom: '1px solid grey',
                                            }}
                                        >
                                            {fileName}
                                        </p>
                                        {!fieldLoding && (
                                            <CrossButton
                                                className="btn-transparent"
                                                type="button"
                                                onClick={() => {
                                                    setSelectedFile([{}]);
                                                    setButtonFlag(false);
                                                }}
                                            >
                                                x
                                            </CrossButton>
                                        )}
                                    </DashedBorder>
                                )}
                            </li>
                        </ul>
                    </Container>
                </div>

                <div className="col-md-3 col-12 mt-3">
                    <File>
                        <Button
                            className={buttonFlag ? '' : 'disable-btn'}
                            type="button"
                            style={{ float: 'right', marginTop: 30 }}
                            onClick={handleUploadFile}
                            disabled={fieldLoding}
                        >
                            {fieldLoding ? <Loader type="button" /> : 'Upload'}
                        </Button>
                        <div className="clear-fix" />
                    </File>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mt-2">
                    <FileError className="d-flex">
                        <img src={InfoImg} alt="" />
                        <span className="text-grey pl-2">
                            <b> First name</b> and <b>Last name</b> is mandatory
                            for each entry.
                            <br />
                            Please ensure your files have header titles in row 1
                            to be able to properly link each contact field to
                            system fields.
                        </span>
                    </FileError>
                </div>
            </div>
            {latestUploadLog && (
                <div className="row mt-2">
                    <div className="col">
                        <UndoFile
                            onClick={() => {
                                setDeleteConfirmationModal(true);
                            }}
                        >
                            <img src={Undo} alt="undo" />
                            <span className="error-align">
                                Undo the last uploaded file.
                            </span>
                            <span className="grey-text">{latestUploadLog}</span>
                        </UndoFile>
                    </div>
                </div>
            )}
            {fieldLoding && <Loader />}
            {deleteConfirmationModal && (
                <CommonModal
                    modalBody={
                        <>
                            <div className="modal-heading text-center">
                                Are you sure you want to delete the last
                                uploaded file?
                            </div>
                            <div className="modal-footer text-center mt-4">
                                <Button
                                    className="btn-transparent"
                                    onClick={() =>
                                        setDeleteConfirmationModal(false)
                                    }
                                >
                                    Cancel
                                </Button>

                                {isDeleteLoading ? (
                                    <Button className="ml-2 pt-0">
                                        <Loader type="button" />
                                    </Button>
                                ) : (
                                    <Button
                                        className="ml-2 "
                                        onClick={handleDelete}
                                    >
                                        Delete
                                    </Button>
                                )}
                            </div>
                        </>
                    }
                    isModalOpen={deleteConfirmationModal}
                    handleCloseOperation={() => {
                        setDeleteConfirmationModal(false);
                    }}
                />
            )}
        </div>
    );
};

export default UploadContacts;
