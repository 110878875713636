import { styled } from 'styled-components';

const NavTab = styled.div`
    overflow: auto;
    .tabs {
        list-style-type: none;
        position: relative;
        text-align: left;
        margin: 0px;
        padding: 0px;
        border-bottom: 1px solid ${(props: any) => props.theme.colors.grey2};

        li {
            display: inline-block;
            color: ${(props: any) => props.theme.colors.secondaryColor};
            font-size: ${(props: any) => props.theme.fontSizes.normal};
            font-family: ${(props: any) => props.theme.fonts.FontFamily};
            cursor: pointer;
            padding: 0px 7px 7px;
            margin-right: 20px;
            &:first-child {
                margin-left: 10px;
            }

            &.active {
                border-bottom: 3px solid
                    ${(props: any) => props.theme.colors.dropdownActiveBg};
                color: ${(props: any) => props.theme.colors.dropdownActiveBg};
            }
        }
    }
`;

export default NavTab;
