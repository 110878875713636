import { toast } from 'react-toastify';

type ErrorWithMessage = {
    message: string;
    response?: { data: string };
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
    return (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        typeof (error as Record<string, unknown>).message === 'string'
    );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
    if (isErrorWithMessage(maybeError)) {
        return {
            ...maybeError,
            message: maybeError?.response?.data || maybeError.message,
        };
    }

    try {
        return new Error(JSON.stringify(maybeError));
    } catch {
        return new Error(String(maybeError));
    }
}

export default function handleError(error: unknown) {
    if (!error) return '';
    return toast.error(toErrorWithMessage(error).message);
}
