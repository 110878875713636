import { FC, useState } from 'react';

import Modal from 'react-modal';
import dayjs from 'dayjs';

import { toast } from 'react-toastify';

import ConfirmModal from './ConfirmModal';
import ContactDetailsEdit from './ContactDetailsEdit';
import InputWithoutAdd from './InputWithoutAdd';
import ContactStyle from '../../Theme/style/ContactStyle';
import UserAvatar from '../../common/UserAvatar';
import Loader from '../../common/Loader';
import NoRecords from '../../common/NoRecords';
import InputWithAdd from './InputWithAdd';
import { useAppDispatch } from '../../store/hook';
import { setIsUpdateMainComponent } from '../../features/contactsSlice';
import {
    getContactDetailsAPI,
    deleteContactAPI,
    getAffiliationDetails,
} from '../../api';
import { TableBody, phoneNoformat } from '../../common';
import {
    ContactDetailsListType,
    ContactDetailsRowType,
    ContactDetailsAPEType,
    VotingMethodsType,
    SelectOption,
} from '../../types/type';
import {
    RemoveCross,
    EditBackgroundImg,
    DeleteBackgroungImg,
} from '../../assets/images';
import {
    genaralContactDetails,
    firstRowArr,
    secondRowArr,
    voterInfoArr,
} from '../../constants';

const customStyles = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.6)',
        zIndex: 99,
    },
    content: {
        top: '50%',
        left: '50%',
        width: '70vw',
        right: 'auto',
        bottom: 'auto',
        backgroundColor: 'white',
        zIndex: '99',
        inset: '0% 0% 0% auto',
    },
};

interface Modaltype {
    contactID: number;
    isModalOpen: boolean;
    handleCloseModal: () => void;
}

Modal.setAppElement('#root');

const ContactDetails: FC<Modaltype> = ({
    contactID,
    isModalOpen,
    handleCloseModal,
}) => {
    const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
    const [note, setNote] = useState<string>('');
    const [firstRow, setFirstRow] = useState<any>();
    const [secondRow, setSecondRow] = useState<any>();
    const [voterInfo, setVoterInfo] = useState<any>();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [initial, setInitial] = useState<string>('');
    const [editInfo, setEditInfo] = useState<any>({});
    const [isData, setIsData] = useState<boolean>(false);
    const [isFirstTime, setIsFirsTime] = useState<boolean>(true);
    const [votingMethods, setVotingMethods] = useState<VotingMethodsType[]>([]);
    const [affiliactionDropdown, setAffiliactionDropdown] = useState<
        SelectOption[] | undefined
    >();
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const dispatch = useAppDispatch();

    const getContactDetails = async () => {
        if (isFirstTime) {
            setIsModalLoading(true);
        }

        try {
            if (contactID) {
                const response = await getContactDetailsAPI(contactID);
                if (response.status === 200) {
                    const details = {
                        ...response?.data?.contactDetails_List[0],
                        primaryPhone: phoneNoformat(
                            response?.data?.contactDetails_List[0].primaryPhone
                        ),
                        registrationDate: response?.data?.contactDetails_List[0]
                            .registrationDate
                            ? dayjs(
                                  new Date(
                                      response?.data?.contactDetails_List[0].registrationDate
                                          ?.toString()
                                          .replace(/-/g, '/')
                                  )
                              ).format('MMM D, YYYY')
                            : '',
                    };

                    setInitial(
                        `${
                            response?.data?.contactDetails_List[0].firstName
                                ?.charAt(0)
                                .toUpperCase() || ''
                        }${
                            response?.data?.contactDetails_List[0].lastName
                                ?.charAt(0)
                                .toUpperCase() || ''
                        }`
                    );

                    const address =
                        response?.data?.contactAddressModel_List?.length &&
                        response?.data?.contactAddressModel_List[0];

                    setNote(details?.note);

                    const getListVal = (access: string) => {
                        switch (access) {
                            case 'primaryPhone':
                                return response?.data?.otherPhone_List.filter(
                                    (item: ContactDetailsAPEType) =>
                                        !item.isPrimary
                                );
                                break;
                            case 'primaryAddress':
                                return response?.data?.otherAddress_List.filter(
                                    (item: ContactDetailsAPEType) =>
                                        !item.isPrimary
                                );
                                break;
                            case 'primaryEmail':
                            default:
                                return response?.data?.otherMails_List.filter(
                                    (item: ContactDetailsAPEType) =>
                                        !item.isPrimary
                                );
                        }
                    };

                    const getRealValues = (arr: string[], isList: boolean) => {
                        const data = genaralContactDetails
                            .map((item: ContactDetailsRowType) => {
                                if (arr.includes(item.accessor)) {
                                    return {
                                        ...item,
                                        value: details?.[
                                            item.accessor as keyof ContactDetailsListType
                                        ],
                                        list: isList
                                            ? getListVal(item.accessor)
                                            : null,
                                    };
                                }
                                return null;
                            })
                            .filter((item) => item !== null);
                        return data;
                    };

                    const editData = {
                        addressLine1: address?.addressLine1,
                        addressLine2: address?.addressLine2,
                        city: address?.city,
                        state: address?.state,
                        zipCode: address?.zip,
                        gpsCoordinates: address?.gpscoordinates,
                        addressId: address?.addressId,

                        primaryEmail: details.primaryEmail,
                        primaryPhone: details.primaryPhone,
                        employer: details.employer,
                        occupation: details.occupation,
                        affiliation: details.affiliation,
                        note: details.note,
                        voterStatus: details.voterStatus,
                        ballotStatus: details.ballotStatus,
                        registrationDate:
                            response?.data?.contactDetails_List[0]
                                .registrationDate &&
                            new Date(
                                response?.data?.contactDetails_List[0].registrationDate
                                    ?.toString()
                                    .replace(/-/g, '/')
                            ),
                        voterId: details.voterId,
                        preCinct: details.preCinct,
                        county: details.county,
                        congressionalHouseDistrict:
                            details.congressionalHouseDistrict,
                        localSchoolBoard: details.localSchoolBoard,
                        stateHouseDistrict: details.stateHouseDistrict,
                        stateSenateDistrict: details.stateSenateDistrict,
                        stateSchoolBoard: details.stateSchoolBoard,
                        contactId: details.contactId,
                        firstName: details?.firstName,
                        middleName: details?.middleName,
                        lastName: details?.lastName,
                    };

                    setEditInfo(editData);
                    setVotingMethods(response?.data?.otherVoterHistory_List);
                    const firstRowData = getRealValues(firstRowArr, true);
                    const secondRowData = getRealValues(secondRowArr, false);
                    const voterInfoData = getRealValues(voterInfoArr, false);

                    setFirstRow(firstRowData);
                    setSecondRow(secondRowData);
                    setVoterInfo(voterInfoData);
                    setIsData(true);
                } else {
                    setIsData(false);
                    toast.error(response?.data);
                }
            }
        } catch (error) {
            let message = 'Unknown Error';
            if (error instanceof Error) message = error.message;
            toast.error(message);
        }
        if (isFirstTime) {
            setIsModalLoading(false);
            setIsFirsTime(false);
        }
    };

    const closeDetailModal = () => {
        setIsFirsTime(true);
        handleCloseModal();
    };

    const handleEdit = () => {
        setIsEdit(!isEdit);
    };

    const handleDetailAPICall = () => {
        dispatch(setIsUpdateMainComponent(true));
        getContactDetails();
    };

    const handleDeletecontact = async () => {
        setIsConfirmModalOpen(true);
    };

    const getAffiliationDropdown = async () => {
        try {
            const response = await getAffiliationDetails();
            if (response?.status === 200) {
                const sortedData = [...response.data].sort(
                    (a, b) => a.id - b.id
                );
                const updatedData = sortedData?.map((item: any) => {
                    return {
                        label: item?.affiliationName,
                        value: item?.affiliationName,
                    };
                });

                setAffiliactionDropdown(updatedData);
            } else {
                toast.error(response?.data);
            }
        } catch (error) {
            let message = 'Unknown Error';
            if (error instanceof Error) message = error.message;
            toast.error(message);
        }
    };

    const afterOpenModal = () => {
        getContactDetails();
        getAffiliationDropdown();
    };

    const sendDecision = async (val: boolean) => {
        if (val) {
            const response = await deleteContactAPI(contactID);
            if (response.status === 200) {
                toast.success(response?.data);
                dispatch(setIsUpdateMainComponent(true));
                // setIsDetailScreenUpdated(true);
                closeDetailModal();
            } else if (response.status === 400) {
                toast.error(response?.data);
            }
        }
        setIsConfirmModalOpen(false);
    };

    return (
        <>
            <Modal
                isOpen={isModalOpen}
                onAfterOpen={afterOpenModal}
                onAfterClose={closeDetailModal}
                onRequestClose={closeDetailModal}
                shouldCloseOnOverlayClick
                shouldCloseOnEsc
                style={customStyles}
                contentLabel="Contact Details Modal"
            >
                {isModalLoading ? (
                    <Loader
                        type="sideModal"
                        text="Hang on...contact details are loaded"
                    />
                ) : (
                    <ContactStyle>
                        {isData ? (
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-10 py-2">
                                        <div className="contact-details-user">
                                            <UserAvatar>
                                                <div className="user-profile">
                                                    {initial}
                                                </div>
                                                <div className="contac-details ml-2 pl-1 ">
                                                    {`${editInfo?.firstName} ${editInfo?.middleName} ${editInfo?.lastName}`}
                                                </div>
                                            </UserAvatar>
                                            <div className="edit-icon">
                                                <img
                                                    src={EditBackgroundImg}
                                                    alt="Edit"
                                                    className="mr-2 cursor"
                                                    role="presentation"
                                                    onClick={handleEdit}
                                                />
                                                <img
                                                    src={DeleteBackgroungImg}
                                                    alt="Delete"
                                                    role="presentation"
                                                    className="cursor"
                                                    onClick={
                                                        handleDeletecontact
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2 py-2">
                                        <div className="cross-icon-black">
                                            <img
                                                role="presentation"
                                                src={RemoveCross}
                                                alt="times"
                                                className="cursor"
                                                onClick={closeDetailModal}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {isEdit ? (
                                    <ContactDetailsEdit
                                        {...editInfo}
                                        affiliactionDropdown={
                                            affiliactionDropdown
                                        }
                                        handleEdit={handleEdit}
                                        handleDetailAPICall={
                                            handleDetailAPICall
                                        }
                                        closeDetailModal={closeDetailModal}
                                    />
                                ) : (
                                    <>
                                        <div className="row">
                                            <div className="col-12 py-3">
                                                <p className="contact-details-header">
                                                    Details
                                                </p>
                                                <div className="primary-horizontal-line my-2" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            {firstRow?.length &&
                                                firstRow?.map(
                                                    (
                                                        item: ContactDetailsRowType
                                                    ) => (
                                                        <InputWithAdd
                                                            key={item.id}
                                                            label={item.label}
                                                            value={item.value}
                                                            isAdd
                                                            list={item.list}
                                                            contactID={
                                                                contactID
                                                            }
                                                            handleDetailAPICall={
                                                                handleDetailAPICall
                                                            }
                                                        />
                                                    )
                                                )}
                                        </div>

                                        <div className="row">
                                            {secondRow?.length &&
                                                secondRow?.map(
                                                    (
                                                        item: ContactDetailsRowType
                                                    ) => (
                                                        <InputWithoutAdd
                                                            key={item.id}
                                                            label={item.label}
                                                            value={item.value}
                                                        />
                                                    )
                                                )}
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 py-2">
                                                <p className="details-title">
                                                    Note
                                                </p>
                                                <p className="details-info">
                                                    {note}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 py-3">
                                                <p className="contact-details-header">
                                                    Voter Info
                                                </p>
                                                <div className="primary-horizontal-line my-2" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            {voterInfo?.length &&
                                                voterInfo?.map((item: any) => (
                                                    <InputWithoutAdd
                                                        key={item.id}
                                                        label={item.label}
                                                        value={item.value}
                                                    />
                                                ))}
                                        </div>

                                        <div className="row">
                                            <div className="col-4 py-2">
                                                <div className="table-responsive show-voter-table">
                                                    <TableBody>
                                                        <thead className="grey-header">
                                                            <tr>
                                                                <th>
                                                                    Voter
                                                                    History &
                                                                    Method
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {votingMethods.length ? (
                                                                votingMethods?.map(
                                                                    (
                                                                        item: VotingMethodsType
                                                                    ) => (
                                                                        <tr
                                                                            key={
                                                                                item.id
                                                                            }
                                                                        >
                                                                            <td>
                                                                                {
                                                                                    item.voterhistory
                                                                                }
                                                                                &nbsp;
                                                                                {
                                                                                    item.method
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )
                                                            ) : (
                                                                <tr>
                                                                    <td>
                                                                        <NoRecords />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </TableBody>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : (
                            <NoRecords />
                        )}
                    </ContactStyle>
                )}
            </Modal>
            {isConfirmModalOpen && (
                <ConfirmModal
                    isConfirmModalOpen={isConfirmModalOpen}
                    sendDecision={sendDecision}
                />
            )}
        </>
    );
};

export default ContactDetails;
