import ReactDOM from 'react-dom/client';

import App from './App';
import './index.css';
import './Theme/style/bootstrap-grid.min.css';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<App />);
