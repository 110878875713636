import styled from 'styled-components';

export const Container = styled.div`
    padding: 11px;
    border-radius: 8px;
    border-style: dashed;
    border-color: ${(props: any) => props.theme.colors.grey6};
    border-width: 2px;
    outline: none;
    transition: border 0.24s ease-in-out;
    width: fit-content;
    max-width: 700px;

    .import-upload {
        .import-file {
            list-style: none;
            display: inline-block;
            vertical-align: middle;
            padding: 10px 20px;

            @media screen and (max-width: 548px) {
                display: block;
                padding: 20px 20px;
            }
        }
    }

    p {
        font-size: ${(props: any) => props.theme.fontSizes.normal};
        color: ${(props: any) => props.theme.colors.grey6};
        font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
    }

    .upload-file {
        text-align: center;
    }
`;

export const DashedBorder = styled.div`
    border: 1px dashed ${(props: any) => props.theme.colors.grey8};
    padding: 5px;
    position: relative;
    word-break: break-all;
`;

export const CrossButton = styled.button`
    background-color: transparent;
    outline: none;
    border: 1px solid ${(props: any) => props.theme.colors.grey8};
    padding: 1px 3px;
    position: absolute;
    top: -21px;
    right: -15px;
`;

export const File = styled.div``;

export const FileError = styled.div`
    margin-top: 10px;
    span {
        vertical-align: top;
        font-size: ${(props: any) => props.theme.fontSizes.normal};
        color: ${(props: any) => props.theme.colors.grey3};
    }
`;
