import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Loader from '../../common/Loader';
import SearchInput from '../../common/SearchInput';
import NoRecords from '../../common/NoRecords';
import PlanManagementStyle from '../../Theme/style/PlanManagementStyle';
import { ArrowBtn } from '../../assets/images';
import { PlanHeaderType } from '../../types/type';
import { getPlanManagementList } from '../../api';
import {
    PATH_CREATE_PLAN_MANAGEMENT,
    PATH_EDIT_PLAN_MANAGEMENT,
    planTableHeaders,
} from '../../constants';
import {
    Button,
    FilterTag,
    InputSearch,
    TableBody,
    getIntWithTwoDecimal,
} from '../../common';

const PlanManagementList = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [searchInput, setSearchInput] = useState('');
    const [showEnterpriseList, setShowEnterpriseList] = useState(false);
    const [listData, setListData] = useState({
        planList: [],
        enterpriseList: [],
    });

    useEffect(() => {
        getPlanManagementList(searchInput).then((res: any) => {
            if (res?.status === 200) {
                setListData({
                    planList: res?.data?.planManagementModelLists,
                    enterpriseList:
                        res?.data?.enterprisePlanManagementModelList,
                });
            } else toast.error(res?.data);
            setIsLoading(false);
        });
    }, [searchInput]);

    const handleInputchange = (searchText: string) => {
        setSearchInput(searchText);
    };
    const getBulletPointClassname = (planName: string, isChild: boolean) => {
        switch (planName) {
            case 'Starter':
                return 'orange-dot';
            case 'Advanced':
                return '';
            case 'Enterprise':
                return 'teal-dot';
            default:
                return isChild ? 'teal-dot' : '';
        }
    };

    const getButtonCTA = (planName: string) => {
        switch (planName) {
            case 'Enterprise':
                return showEnterpriseList ? 'Hide' : 'View';
            default:
                return 'View';
        }
    };

    const getChildPlanPaymentDetails = (planDetails: any) => {
        if (planDetails.monthly) {
            return `Monthly - ${getIntWithTwoDecimal(planDetails.monthly)}/mo`;
        }
        if (planDetails.quarterly) {
            return `Monthly - ${getIntWithTwoDecimal(
                planDetails.quarterly
            )}/mo`;
        }
        if (planDetails.yearly) {
            return `Yearly - ${getIntWithTwoDecimal(planDetails.yearly)}/yr`;
        }
        return '';
    };

    const handleViewButtonClick = (planDetails: any) => {
        if (planDetails.planName === 'Enterprise') {
            setShowEnterpriseList(!showEnterpriseList);
        } else {
            navigate(
                `${PATH_EDIT_PLAN_MANAGEMENT}/${
                    planDetails.id
                }/${encodeURIComponent(planDetails.planName)}`
            );
        }
    };
    // Below method is used to display plan and enterprise table's rows.
    const renderTableItem = (planDetails: any, isChild: boolean = false) => {
        return planTableHeaders.map((keys: any) => {
            // the switch cases are tables 'td'.
            switch (keys.key) {
                case 'plan':
                    return (
                        <td key={`plan${planDetails?.id}`}>
                            <div className="bullet-text ">
                                <div
                                    className={`bullet-points ${getBulletPointClassname(
                                        planDetails.planName,
                                        isChild
                                    )}`}
                                />
                                <div>{planDetails.planName}</div>
                            </div>
                            {planDetails?.status &&
                            planDetails?.status === 'Draft' ? (
                                <div>
                                    <FilterTag className="list-status">
                                        Draft
                                    </FilterTag>
                                </div>
                            ) : null}
                        </td>
                    );
                case 'subscribers':
                    return (
                        <td key={`subscribers${planDetails?.id}`}>
                            {!isChild ? (
                                <ul className="active-total">
                                    <li className="border-right">
                                        <div className="bullet-text plan-text">
                                            <div className="bullet-points teal-dot" />
                                            Active - {planDetails?.activeCount}
                                        </div>
                                        <div className="bullet-text plan-text">
                                            <div className="bullet-points grey-dot" />
                                            Datahold -{' '}
                                            {planDetails?.inActiveCount}
                                        </div>
                                    </li>
                                    <li className="total">
                                        <div className="plan-total">Total</div>
                                        <div className="plan-total">
                                            {planDetails?.totalCount}
                                        </div>
                                    </li>
                                </ul>
                            ) : (
                                `${planDetails.firstName} ${planDetails.lastName}`
                            )}
                        </td>
                    );
                case 'pricing':
                    if (planDetails.planName !== 'Enterprise' && !isChild) {
                        return (
                            <td key={`pricing${planDetails?.id}`}>
                                <div className="bullet-text plan-text">
                                    <div
                                        className={`bullet-points ${getBulletPointClassname(
                                            planDetails.planName,
                                            isChild
                                        )}`}
                                    />
                                    Quarterly -
                                    <span className="dollar-amt">
                                        {getIntWithTwoDecimal(
                                            planDetails?.quarterly
                                        )}
                                    </span>
                                    / mo
                                </div>
                                <div className="bullet-text plan-text">
                                    <div
                                        className={`bullet-points ${getBulletPointClassname(
                                            planDetails.planName,
                                            isChild
                                        )}`}
                                    />
                                    Yearly -
                                    <span className="dollar-amt">
                                        {getIntWithTwoDecimal(
                                            planDetails.yearly
                                        )}
                                    </span>
                                    / mo
                                </div>
                            </td>
                        );
                    }
                    if (isChild) {
                        return (
                            <td>
                                <div>
                                    {getChildPlanPaymentDetails(planDetails)}
                                </div>
                            </td>
                        );
                    }
                    return <td>-</td>;

                case 'lastUpdated':
                    return (
                        <td key={`lastUpdated${planDetails?.id}`}>
                            {dayjs(planDetails.updatedDate).format(
                                'MMM DD, YYYY'
                            )}
                        </td>
                    );
                case 'details':
                    return (
                        <td key={`details${planDetails?.id}`}>
                            <Button
                                className="btn-border"
                                onClick={() => {
                                    handleViewButtonClick(planDetails);
                                }}
                            >
                                {getButtonCTA(planDetails.planName)}
                                {planDetails.planName === 'Enterprise' ? (
                                    <img
                                        className="ml-2"
                                        style={{
                                            transform: showEnterpriseList
                                                ? 'rotate(90deg)'
                                                : 'rotate(-90deg)',
                                        }}
                                        src={ArrowBtn}
                                        alt="arrow"
                                    />
                                ) : null}
                            </Button>
                        </td>
                    );
                default:
                    return <td key={`default${planDetails?.id}`}>-</td>;
            }
        });
    };
    return (
        <div className="body-container">
            <PlanManagementStyle>
                <div className="row">
                    <div className="col-6 my-2">
                        <div className="page-title">Plan Management</div>
                    </div>
                    <div className="col-6 my-2 text-right">
                        <Button
                            onClick={() =>
                                navigate(PATH_CREATE_PLAN_MANAGEMENT)
                            }
                        >
                            Create new
                        </Button>
                    </div>
                    <div className="col-md-4 py-3">
                        <InputSearch>
                            <SearchInput
                                setDebouncedInputValue={handleInputchange}
                            />
                        </InputSearch>
                    </div>
                </div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <TableBody className="plan-management-table">
                                    <thead className="grey-header">
                                        <tr>
                                            {planTableHeaders.map(
                                                (header: PlanHeaderType) => (
                                                    <th key={header?.label}>
                                                        {header?.label}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listData.planList.map(
                                            (planDetails: any) => {
                                                return (
                                                    <tr key={planDetails.id}>
                                                        {renderTableItem(
                                                            planDetails
                                                        )}
                                                    </tr>
                                                );
                                            }
                                        )}
                                        {showEnterpriseList &&
                                            (listData.enterpriseList.length ? (
                                                listData.enterpriseList.map(
                                                    (planDetails: any) => {
                                                        return (
                                                            <tr
                                                                className="plan-table-hover"
                                                                key={
                                                                    planDetails.id
                                                                }
                                                            >
                                                                <td
                                                                    colSpan={
                                                                        planTableHeaders?.length
                                                                    }
                                                                    className="view-table"
                                                                >
                                                                    <div className="drop-down-table">
                                                                        <TableBody>
                                                                            <tbody>
                                                                                <tr>
                                                                                    {renderTableItem(
                                                                                        planDetails,
                                                                                        true
                                                                                    )}
                                                                                </tr>
                                                                            </tbody>
                                                                        </TableBody>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <tr>
                                                    <td colSpan={5}>
                                                        {showEnterpriseList && (
                                                            <NoRecords />
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </TableBody>
                            </div>
                        </div>
                    </div>
                )}
            </PlanManagementStyle>
        </div>
    );
};

export default PlanManagementList;
