import { useEffect, useState } from 'react';

import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Loader from '../../common/Loader';
import ErrorMsg from '../../common/ErrorMessage';
import { Button, handleError } from '../../common';
import { resetPassword } from '../../api';
import { PATH_LOGIN } from '../../constants';
import { InputPassword } from '../../types/type';
import { ForgotPasswordLink, LoginStyle, SignUpSuccessMSG } from './AuthStyles';
import {
    CheckTeal,
    ForgetLockImg,
    LoginLeftImg,
    LoginLock,
    RestpasswordBackground,
} from '../../assets/images';

const resetPasswordValidation = {
    required: {
        value: true,
        message: 'This field is required',
    },
    pattern: {
        value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g,
        message:
            'The password must have a minimum length of 8 characters and include at least 1 uppercase letter, 1 numeric character, and 1 special character.',
    },
};

const ResetPassword = () => {
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<InputPassword>({ mode: 'onChange' });
    const navigate = useNavigate();

    const [queryParameters] = useSearchParams();
    const [token, setToken] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [counter, setCounter] = useState<number>(10);

    const handleResetPassword: SubmitHandler<InputPassword> = async (
        data: InputPassword
    ) => {
        try {
            setIsLoading(true);
            await resetPassword(
                {
                    password: data.password,
                },
                token
            );
            setIsSuccess(true);
            setTimeout(() => {
                navigate(PATH_LOGIN);
            }, 10000);
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const ThemeData =
        localStorage.getItem('theme') &&
        JSON.parse(localStorage.getItem('theme') || '');

    const renderResetPasswordHeader = () => {
        return (
            <div className="col-lg-6 login-left">
                <div className="logo cursor">
                    <img src={ThemeData?.adminLogo} alt="logo" />
                </div>
                <div className="login-left-style">
                    {isSuccess ? (
                        <img src={RestpasswordBackground} alt="logo" />
                    ) : (
                        <img width="310px" src={LoginLeftImg} alt="logo" />
                    )}
                </div>
            </div>
        );
    };

    const renderSuccess = () => {
        if (counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000);
        }
        return (
            <div className="col-lg-6 d-flex align-items-center success-password-reset">
                <SignUpSuccessMSG>
                    <div className="success-image">
                        <img src={CheckTeal} alt="" />
                    </div>
                    <h2 className="text-center">
                        Your password has been changed successfully!
                    </h2>
                    <p className="text-center">
                        <span>
                            Within {counter} seconds you will be redirected to
                            the &nbsp;
                        </span>
                        <ForgotPasswordLink
                            to={PATH_LOGIN}
                            className="text-underline"
                        >
                            sign in
                        </ForgotPasswordLink>
                        <span>&nbsp; page</span>
                    </p>
                </SignUpSuccessMSG>
            </div>
        );
    };

    useEffect(() => {
        if (queryParameters.get('code')) {
            setToken(queryParameters.get('code'));
        }
    }, [queryParameters]);

    return (
        <LoginStyle>
            {' '}
            <div className="container-fluid">
                <div className="row h-100">
                    <div className="col-12">
                        <div className="row">
                            {renderResetPasswordHeader()}
                            {isSuccess ? (
                                renderSuccess()
                            ) : (
                                <div className="col-lg-6">
                                    <div className="login-right-style">
                                        <div className="logo-rigt-img">
                                            <img
                                                src={ThemeData?.adminLogo}
                                                alt="Logo"
                                            />
                                        </div>

                                        <h1 className="heading text-center mb-4">
                                            Reset Password
                                        </h1>
                                        <div className="admin-login">
                                            <div className="adminlogin-img">
                                                <img
                                                    src={ForgetLockImg}
                                                    alt=""
                                                />
                                            </div>

                                            <form
                                                className="pt-3"
                                                onSubmit={handleSubmit(
                                                    handleResetPassword
                                                )}
                                            >
                                                <div className="fromfield py-2">
                                                    <input
                                                        type="password"
                                                        placeholder="Enter new password"
                                                        id="password"
                                                        {...register(
                                                            'password',
                                                            {
                                                                ...resetPasswordValidation,
                                                            }
                                                        )}
                                                    />
                                                    <div className="login-icon">
                                                        <img
                                                            src={LoginLock}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                                <ErrorMsg>
                                                    {errors?.password?.message}
                                                </ErrorMsg>

                                                <div className="fromfield py-2">
                                                    <input
                                                        type="password"
                                                        placeholder="Re-enter new password"
                                                        id="resetPassword"
                                                        {...register(
                                                            'resetPassword',
                                                            {
                                                                ...resetPasswordValidation,
                                                                validate: (
                                                                    value
                                                                ) =>
                                                                    value ===
                                                                        watch(
                                                                            'password'
                                                                        ) ||
                                                                    "The password doesn't match!",
                                                            }
                                                        )}
                                                    />
                                                    <div className="login-icon">
                                                        <img
                                                            src={LoginLock}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                                <ErrorMsg>
                                                    {
                                                        errors?.resetPassword
                                                            ?.message
                                                    }
                                                </ErrorMsg>
                                                <div className="row">
                                                    <div className="col-12 text-center pt-1">
                                                        <Button
                                                            type="submit"
                                                            disabled={isLoading}
                                                        >
                                                            {isLoading ? (
                                                                <Loader type="button" />
                                                            ) : (
                                                                'Reset password'
                                                            )}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </LoginStyle>
    );
};

export default ResetPassword;
