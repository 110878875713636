import { styled } from 'styled-components';

const PageNav = styled.div`
    .nav-list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            color: ${(props: any) => props.theme.colors.secondaryColor};
            font-size: ${(props: any) => props.theme.fontSizes.small};
            margin-right: 8px;

            img {
                width: 5px;
            }

            &.active-nav {
                color: ${(props: any) => props.theme.colors.primaryColor};
                cursor: pointer;
            }
        }
    }
`;

export default PageNav;
