import { FC, ChangeEvent, useState } from 'react';

import Select from 'react-select';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

import { updateNotApplicable } from '../../api';
import {
    FEATURE_FIELDS_UNLIMITED,
    planDetailsDropdownOptions,
    subLabelArr,
} from '../../constants';
import { FeaturChildlistType } from '../../types/type';
import { DropDownStyle, InputSearch, ToggleSwitch } from '../../common';

const tempArr = ['Contacts', 'Contact Lists', 'Emails', 'Multiusers'];

const AllFeatureDetails: FC<FeaturChildlistType> = ({
    lable: mainHeading,
    value1,
    value2,
    value3,
    inputTypeId,
    isEdit,
    handleFeatureDetails,
    featuresComponentId,
    isShowToggleSwitch,
    planName,
    isApplicable,
    handleFeaturesAPICall,
}) => {
    const [isToggleDisabled, setIsToggleDisabled] = useState<boolean>(false);
    const handleDropDownChange = (val: any, access: string) => {
        handleFeatureDetails?.({
            componentId: featuresComponentId,
            componentValue: mainHeading,
            inputId: inputTypeId,
            [access]: val,
        });
    };

    const getOtherValues = (id: number) => {
        switch (id) {
            case 1:
            case 3:
                return { value1 };
            case 4:
            case 6:
                return { value1, value2 };
            default:
                return { value1, value2, value3 };
        }
    };

    const handleInputchange = (
        val: any,
        access: string,
        title: string,
        type: string
    ) => {
        const regex = /^\d*\.?\d*$/;

        if (type === 'number' && !tempArr.includes(title)) {
            if (val === '' || regex.test(val)) {
                handleFeatureDetails?.({
                    componentId: featuresComponentId,
                    componentValue: mainHeading,
                    inputId: inputTypeId,
                    ...getOtherValues(inputTypeId),
                    [access]: val,
                });
            }
        } else {
            handleFeatureDetails?.({
                componentId: featuresComponentId,
                componentValue: mainHeading,
                inputId: inputTypeId,
                ...getOtherValues(inputTypeId),
                [access]: val?.toLowerCase() === 'unlimited' ? '-1' : val,
            });
        }
    };

    // we combine 2 and 3 input boxes into 1 as per design.
    const getBorderValue = (len: number, indexNum: number, heading: string) => {
        switch (len) {
            case 1:
                return {};
                break;
            case 2:
                if (indexNum === 0) {
                    return {
                        borderRight: 'none',
                        width: '35%',
                    };
                }
                if (indexNum === 1) {
                    return {
                        borderLeft: 'none',
                    };
                }
                break;

            default:
                if (indexNum === 0) {
                    return {
                        borderRight: 'none',
                        width:
                            heading === 'Donation Processing' ? '40%' : '35%',
                    };
                }
                if (indexNum === 1) {
                    return {
                        borderLeft: 'none',
                        borderRight: 'none',
                        width:
                            heading === 'Donation Processing' ? '40%' : '35%',
                    };
                }
                if (indexNum === 2) {
                    return {
                        borderLeft: 'none',
                    };
                }
        }
        return '';
    };

    const getPlace = (val1: any, head: string, indexNumber: number) => {
        if (head === 'Additional Contacts') {
            if (indexNumber === 1) {
                return `/${val1}`;
            }
            if (indexNumber === 0) {
                return `$${val1}`;
            }
        }
        if (head === 'Donation Processing') {
            if (indexNumber === 0) {
                return `${val1}%`;
            }
            if (indexNumber === 1) {
                return `+ $${val1}`;
            }
            if (indexNumber === 2) {
                return `/${val1}`;
            }
        }
        if (head === 'Website Add-on price') {
            if (indexNumber === 1) {
                return `/${val1}`;
            }
        }

        if (head === 'Data Transfer') {
            if (indexNumber === 1) {
                return `/${val1}`;
            }
        }
        if (FEATURE_FIELDS_UNLIMITED.includes(head) && val1 === '-1') {
            return 'Unlimited';
        }
        return val1;
    };

    const handleReadOnlyFields = (val: string, name: string) => {
        const tempNameArr = ['Additional Contacts', 'Donation Processing'];
        return (
            (val === 'value2' &&
                name === 'Campaign Logistics AI Manager (CLAIM)') ||
            (val === 'value3' && tempNameArr.includes(name)) ||
            (val === 'value2' && name === 'Emails')
        );
    };

    const getInputField = (nameArr: any) => {
        return (
            <li
                style={{
                    listStyleType: 'none',
                }}
                className="mx-1"
            >
                <InputSearch className="d-flex flex-row">
                    {nameArr.map(
                        (
                            { type, accessor, value, headingVal }: any,
                            index: number
                        ) => {
                            return (
                                <input
                                    key={accessor}
                                    className="form-control"
                                    type={
                                        tempArr.includes(headingVal)
                                            ? 'text'
                                            : type
                                    }
                                    name={accessor}
                                    style={{
                                        borderRadius: '0px',
                                        paddingLeft: '5px',
                                        paddingRight: '0px',
                                        ...getBorderValue(
                                            nameArr.length,
                                            index,
                                            headingVal
                                        ),
                                    }}
                                    placeholder={getPlace(
                                        value,
                                        headingVal,
                                        index
                                    )}
                                    onChange={(e) =>
                                        handleInputchange(
                                            e.target.value,
                                            accessor,
                                            headingVal,
                                            tempArr.includes(headingVal)
                                                ? 'text'
                                                : type
                                        )
                                    }
                                    onKeyDownCapture={(e) => {
                                        if (
                                            type === 'number' &&
                                            !tempArr.includes(headingVal) &&
                                            (e.code === 'Minus' ||
                                                e.code === 'NumpadSubtract')
                                        ) {
                                            e.preventDefault();
                                        }
                                    }}
                                    disabled={
                                        (isShowToggleSwitch && !isApplicable) ||
                                        (headingVal === 'Emails' &&
                                            accessor === 'value2')
                                    }
                                    min={0}
                                    readOnly={handleReadOnlyFields(
                                        accessor,
                                        headingVal
                                    )}
                                />
                            );
                        }
                    )}
                </InputSearch>
            </li>
        );
    };

    const getFeatureValueField = (
        idToGetHTML: number,
        val1: any,
        val2: any,
        val3: any,
        headingVal: string
    ) => {
        switch (idToGetHTML) {
            case 1:
                return getInputField([
                    {
                        accessor: 'value1',
                        value: val1,
                        headingVal,
                        idToGetHTML,
                        type: 'text',
                    },
                ]);
            case 2:
                return (
                    <DropDownStyle>
                        <Select
                            value={planDetailsDropdownOptions.find(
                                (item: any) => item.label === val1
                            )}
                            isClearable={false}
                            isSearchable={false}
                            options={planDetailsDropdownOptions}
                            onChange={(e) =>
                                handleDropDownChange(e?.value, 'value1')
                            }
                        />
                    </DropDownStyle>
                );
            case 3:
                if (headingVal === 'Emails') {
                    return getInputField([
                        {
                            accessor: 'value1',
                            value: parseInt(val1, 10),
                            headingVal,
                            idToGetHTML,
                            type: 'number',
                        },
                        {
                            accessor: 'value2',
                            value: '/mo',
                            headingVal,
                            idToGetHTML,
                            type: 'text',
                        },
                    ]);
                }
                return getInputField([
                    {
                        accessor: 'value1',
                        value: val1,
                        headingVal,
                        idToGetHTML,
                        type: 'number',
                    },
                ]);
            case 4:
                return getInputField([
                    {
                        accessor: 'value1',
                        value: val1,
                        headingVal,
                        idToGetHTML,
                        type: 'number',
                    },
                    {
                        accessor: 'value2',
                        value: val2,
                        headingVal,
                        idToGetHTML,
                        type: 'text',
                    },
                ]);
            case 5:
                return getInputField([
                    {
                        accessor: 'value1',
                        value: val1,
                        headingVal,
                        idToGetHTML,
                        type: 'number',
                    },
                    {
                        accessor: 'value2',
                        value: val2,
                        headingVal,
                        idToGetHTML,
                        type: 'number',
                    },
                    {
                        accessor: 'value3',
                        value: val3,
                        headingVal,
                        idToGetHTML,
                        type: 'text',
                    },
                ]);
            case 6:
                return getInputField([
                    {
                        accessor: 'value1',
                        value: val1,
                        headingVal,
                        idToGetHTML,
                        type: 'number',
                    },
                    {
                        accessor: 'value2',
                        value: val2,
                        headingVal,
                        idToGetHTML,
                        type: 'text',
                    },
                ]);
            default:
                return getInputField([
                    {
                        accessor: 'fcLabelName',
                        value: val1,
                        headingVal,
                        idToGetHTML,
                        type: 'text',
                    },
                ]);
        }
    };

    const getFirstVal = () => {
        let temp = value1 || '';
        if (mainHeading === 'Additional Contacts') {
            temp = `$${value1}`;
        } else if (value1 === '-1') {
            temp = 'Unlimited';
        }
        return temp;
    };

    const handleToggleChange = async (isChecked: boolean) => {
        setIsToggleDisabled(true);
        const response = await updateNotApplicable(
            false,
            featuresComponentId,
            planName!,
            isChecked
        );
        if (response?.status === 200) {
            toast.success(response?.data);
            handleFeaturesAPICall();
        } else {
            toast.error(response?.data);
        }
        setIsToggleDisabled(false);
    };

    return (
        <div className="col-lg-3 py-2 my-2">
            <div
                className="plan-title mb-2"
                style={{
                    color: isShowToggleSwitch && !isApplicable ? '#635E71' : '',
                }}
            >
                {mainHeading}
                {!isEdit && isShowToggleSwitch && (
                    <>
                        <ToggleSwitch
                            className="ml-2"
                            data-tooltip-id="app"
                            data-tooltip-content="Applicable/Not applicable"
                        >
                            <input
                                type="checkbox"
                                checked={isApplicable}
                                disabled={isToggleDisabled}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleToggleChange(e.target.checked)
                                }
                            />
                            <span className="slider round" />
                        </ToggleSwitch>
                        <Tooltip id="app" />
                    </>
                )}
            </div>
            {isEdit ? (
                <div style={{ width: '100%' }}>
                    {getFeatureValueField(
                        Number(inputTypeId),
                        Number(inputTypeId) === 2 && value1 === 'NA'
                            ? 'Not Applicable(NA)'
                            : value1,
                        value2,
                        value3,
                        mainHeading
                    )}
                </div>
            ) : (
                <div className="page-title mt-2">
                    <span className="pre-pay cf">
                        {mainHeading === 'Donation Processing'
                            ? value1 &&
                              value2 &&
                              `${value1}% + $${Number(value2).toFixed(
                                  2
                              )}/${value3}`
                            : `${getFirstVal()}${
                                  subLabelArr.includes(inputTypeId) &&
                                  value1 &&
                                  (value2 || value3)
                                      ? '/'
                                      : ' '
                              }${value2 || ''} ${value3 || ''}`}
                    </span>
                </div>
            )}
        </div>
    );
};

export default AllFeatureDetails;
