import { FC, useState, useRef, ChangeEvent } from 'react';

import { toast } from 'react-toastify';

import AddressComp from '../Address/AddressComp';
import AddressField from '../Address/AddressField';
import EmailField from './EmailField';
import PhoneField from './PhoneField';
import { ToggleSwitch } from '../../common';
import { AddressFieldConstant } from '../../constants';
import { useAppSelector } from '../../store/hook';
import {
    ContactDeleteImg,
    RemoveCross,
    TickMarkImg,
} from '../../assets/images';
import {
    ContactDetailsRowProp,
    AddressFilteredComps,
    AddressFieldsType,
} from '../../types/type';
import {
    setEmailAPI,
    setPhoneAPI,
    setAddressAPI,
    setPrimaryEmailAPI,
    setPrimaryPhoneAPI,
    setPrimaryAddressAPI,
    deleteEmailAPI,
    deletePhoneAPI,
    deleteAddressAPI,
} from '../../api';

const InputWithAdd: FC<ContactDetailsRowProp> = ({
    label,
    value,
    isAdd,
    list,
    contactID,
    handleDetailAPICall,
}) => {
    const [isShowInput, setIsShowInput] = useState<boolean>(false);
    const [isShowMulti, setIsShowMulti] = useState<boolean>(false);
    const [completeAddress, setCompleteAddress] =
        useState<AddressFieldsType[]>();
    const { userId } = useAppSelector((state) => state.auth);
    const timeoutId = useRef<any>();
    const [addToApi, setAddToApi] = useState<any>();

    const handleAddClick = () => {
        setIsShowInput(!isShowInput);
    };

    const handleShowClick = () => {
        setIsShowMulti(!isShowMulti);
    };

    const handleCompleteAddress = (obj: AddressFilteredComps) => {
        setAddToApi(obj);
        const completeAdd = AddressFieldConstant.map(
            (item: AddressFieldsType) => {
                return {
                    ...item,
                    value: obj[item.accessor as keyof AddressFilteredComps]!,
                };
            }
        );
        setCompleteAddress(completeAdd);
    };

    const handleAddressChange = (type: string, val: string) => {
        clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(() => {
            if (type === 'suit' && val) {
                const allAdd = { ...addToApi, suit: val };
                setAddToApi(allAdd);
            }
        }, 2000);
    };

    const handleResponse = (res: any, str: string) => {
        if (res.status === 200) {
            toast.success(res.data);
            if (str === 'show') {
                setIsShowInput(!isShowInput);
            } else if (str === 'primary') {
                setIsShowMulti(!isShowMulti);
            }

            handleDetailAPICall();
        } else if (res.status === 400) {
            toast.error('Something went wrong');
        }
    };

    const handleChange = async (type: string, val: string) => {
        if (type === 'email') {
            const response = await setEmailAPI({
                contactid: contactID,
                email: val,
                userid: userId,
            });

            handleResponse(response, 'show');
        }

        if (type === 'phone') {
            const response = await setPhoneAPI({
                contactid: contactID,
                phonenumber: val,
                userid: userId,
            });

            handleResponse(response, 'show');
        }
    };

    const sendAddress = async () => {
        const response = await setAddressAPI({
            contactid: contactID,
            addressline1: addToApi?.address,
            addressline2: addToApi?.suit,
            city: addToApi?.city,
            state: addToApi?.state,
            zip: addToApi?.zip,
            gpdcoordinates: addToApi?.gpdcoordinates,
            userid: userId,
        });

        handleResponse(response, 'show');
    };

    const handleToggleChange = async (
        val: boolean,
        txt: string,
        id: number
    ) => {
        if (val && label === 'Email') {
            const response = await setPrimaryEmailAPI({
                contactid: contactID,
                email: txt,
                userid: userId,
            });
            handleResponse(response, 'primary');
        }
        if (val && label === 'Phone') {
            const response = await setPrimaryPhoneAPI({
                contactid: contactID,
                phone: txt,
                userid: userId,
            });
            handleResponse(response, 'primary');
        }
        if (val && label === 'Address') {
            const response = await setPrimaryAddressAPI({
                contactid: contactID,
                addressId: id,
                userid: userId,
            });
            handleResponse(response, 'primary');
        }
    };

    const handleDelete = async (id: number, val: string) => {
        if (label === 'Email') {
            const response = await deleteEmailAPI({
                contactId: contactID,
                emailid: id,
                email: val,
                userId,
            });
            handleResponse(response, 'primary');
        }
        if (label === 'Phone') {
            const response = await deletePhoneAPI({
                contactid: contactID,
                phoneid: id,
                phone: val,
                userId,
            });
            handleResponse(response, 'primary');
        }
        if (label === 'Address') {
            const response = await deleteAddressAPI({
                contactid: contactID,
                addressId: id,
                userid: userId,
            });
            handleResponse(response, 'primary');
        }
    };

    return (
        <div className="col-lg-4 py-2">
            <p className="details-title">{label}</p>
            <p className="details-info">{value}</p>
            {list && list.length ? (
                <p
                    className="show-more py-2"
                    role="presentation"
                    onClick={handleShowClick}
                >
                    Show {isShowMulti ? 'less' : 'more'}
                </p>
            ) : (
                ''
            )}
            {isShowMulti && (
                <div>
                    {list &&
                        list.length &&
                        list.map((item: any) => (
                            <div className="row" key={item.id}>
                                <div className="col-10">
                                    <div className="show-more-info">
                                        <ToggleSwitch>
                                            <input
                                                type="checkbox"
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>
                                                ) =>
                                                    handleToggleChange(
                                                        e.target.checked,
                                                        item.value,
                                                        item.id
                                                    )
                                                }
                                            />
                                            <span className="slider round" />
                                        </ToggleSwitch>
                                        <p className="details-info pl-2">
                                            {item.value}
                                        </p>
                                    </div>
                                </div>

                                <div className="col-2 text-right">
                                    <div>
                                        <img
                                            src={ContactDeleteImg}
                                            alt="Delete"
                                            role="presentation"
                                            className="cursor"
                                            onClick={() =>
                                                handleDelete(
                                                    item.id,
                                                    item.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            )}
            {isAdd && !isShowInput && (
                <div>
                    <p
                        className="add py-2"
                        role="presentation"
                        onClick={handleAddClick}
                    >
                        + Add {label.toLowerCase()}
                    </p>
                </div>
            )}
            {isShowInput && (
                <div className="p-4 box-shadow-small position-relative mt-3">
                    <div className="txt-right">
                        <img
                            role="presentation"
                            src={RemoveCross}
                            style={{ height: '20px', width: '20px' }}
                            alt="times"
                            className="cross-icon-filter cursor"
                            onClick={handleAddClick}
                        />
                    </div>
                    {label === 'Address' && (
                        <div className="position-relative">
                            <AddressComp
                                setCompleteAddress={handleCompleteAddress}
                            />

                            <div className="row align-items-end">
                                {completeAddress &&
                                    completeAddress.length &&
                                    completeAddress.map(
                                        (item: AddressFieldsType) =>
                                            item.accessor !==
                                                'gpdcoordinates' && (
                                                <AddressField
                                                    key={item.id}
                                                    handleChange={
                                                        handleAddressChange
                                                    }
                                                    {...item}
                                                />
                                            )
                                    )}
                            </div>
                            <div>
                                <img
                                    role="presentation"
                                    src={TickMarkImg}
                                    style={{
                                        width: '15px',
                                        top: '-20px',
                                        right: '30px',
                                    }}
                                    alt="submit"
                                    className="cross-icon-filter cursor position-absolute"
                                    onClick={sendAddress}
                                />
                            </div>
                        </div>
                    )}
                    {label === 'Email' && (
                        <EmailField handleChange={handleChange} />
                    )}
                    {label === 'Phone' && (
                        <PhoneField handleChange={handleChange} />
                    )}
                </div>
            )}
        </div>
    );
};

export default InputWithAdd;
