import { FC, useState, useEffect, useCallback } from 'react';

import dayjs from 'dayjs';

import { getPlanActivityAPI } from '../../api';
import { WhiteCard } from '../../common';
import PlanManagementStyle from '../../Theme/style/PlanManagementStyle';
import Loader from '../../common/Loader';
import NoRecords from '../../common/NoRecords';

const TDValue = ({ type, name, oldOfferVal, oldRegularVal }: any) => {
    return (
        <>
            <div className="old-new-amount">
                {type === 'Pricing' ? `${name} Offer amount` : name}{' '}
                {oldOfferVal || 'NA'}
            </div>
            {type === 'Pricing' && (
                <div className="old-new-amount">
                    {`${name} Regular amount`} {oldRegularVal || 'NA'}
                </div>
            )}
        </>
    );
};

const PlanManagementActivity: FC<{ planName: string }> = ({ planName }) => {
    const [planActivity, setPlanActivity] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getPlanActivity = useCallback(async () => {
        setIsLoading(true);
        const response = await getPlanActivityAPI(planName);
        let tempPlanActivity = [];
        if (
            response.status === 200 &&
            response?.data &&
            response?.data?.length
        ) {
            tempPlanActivity = response?.data;
        }
        setPlanActivity(tempPlanActivity);
        setIsLoading(false);
    }, [planName]);

    const arrele: any = [];
    const getUniqueDate = (date: string, id: number) => {
        const check = arrele.find(
            (item: any) => item.id === id && item.date === date
        );
        let val = '';

        if (!check) {
            arrele.push({ id, date });
            val = dayjs(date).format('MMM DD YYYY');
        }
        return val;
    };

    useEffect(() => {
        getPlanActivity();
    }, [getPlanActivity]);

    return (
        <PlanManagementStyle>
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    {' '}
                    {planActivity && planActivity?.length ? (
                        planActivity.map(
                            ({
                                id,
                                activityType,
                                planActivityDetailsList,
                            }: any) => {
                                return (
                                    <WhiteCard className="mt-4" key={id}>
                                        <div className="card-header d-flex justify-content-between">
                                            {activityType} Activity
                                        </div>
                                        <div className="horizontal-line mt-3" />

                                        <div className="table-responsive">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>&nbsp;</th>
                                                        <th className="dash-line-width">
                                                            &nbsp;
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: '250px',
                                                            }}
                                                        >
                                                            Old
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: '250px',
                                                            }}
                                                        >
                                                            New
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {planActivityDetailsList &&
                                                    planActivityDetailsList.length
                                                        ? planActivityDetailsList.map(
                                                              ({
                                                                  activityDate,
                                                                  componentName,
                                                                  oldValue,
                                                                  newValue,
                                                                  oldRegular,
                                                                  newRegular,
                                                              }: any) => {
                                                                  return (
                                                                      <tr
                                                                          key={`${activityDate}${Math.random()}`}
                                                                          className="mb-2"
                                                                      >
                                                                          <td className="date">
                                                                              {activityDate &&
                                                                                  getUniqueDate(
                                                                                      activityDate,
                                                                                      id
                                                                                  )}
                                                                          </td>
                                                                          <td>
                                                                              <div className="dash-line" />
                                                                          </td>
                                                                          <td>
                                                                              <TDValue
                                                                                  type={
                                                                                      activityType
                                                                                  }
                                                                                  name={
                                                                                      componentName
                                                                                  }
                                                                                  oldOfferVal={
                                                                                      oldValue
                                                                                  }
                                                                                  oldRegularVal={
                                                                                      oldRegular
                                                                                  }
                                                                              />
                                                                          </td>
                                                                          <td>
                                                                              <TDValue
                                                                                  type={
                                                                                      activityType
                                                                                  }
                                                                                  name={
                                                                                      componentName
                                                                                  }
                                                                                  oldOfferVal={
                                                                                      newValue
                                                                                  }
                                                                                  oldRegularVal={
                                                                                      newRegular
                                                                                  }
                                                                              />
                                                                          </td>
                                                                      </tr>
                                                                  );
                                                              }
                                                          )
                                                        : 'No Records Found'}
                                                </tbody>
                                            </table>
                                        </div>
                                    </WhiteCard>
                                );
                            }
                        )
                    ) : (
                        <NoRecords />
                    )}
                </div>
            )}
        </PlanManagementStyle>
    );
};

export default PlanManagementActivity;
