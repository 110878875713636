import styled from 'styled-components';

const FilterStyle = styled.div`
    width: 100%;
    background-color: ${(props: any) => props.theme.colors.white};
    border-radius: 5px;
    padding: 0 18px;
    position: absolute;
    border: 1px solid ${(props: any) => props.theme.colors.grey10};
    border-radius: 5px;
    box-shadow: 0px 3px 6px ${(props: any) => props.theme.shadows.commonShadow};
    z-index: 2;
    top: 0px;

    @media screen and (max-width: 1190px) {
        top: -84px;
    }

    @media screen and (max-width: 572px) {
        top: -181px;
    }

    @media screen and (max-width: 436px) {
        top: -169px;
    }

    &::before {
        content: '';
        position: absolute;
        left: 343px;
        top: -41px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-bottom: 26px solid ${(props: any) => props.theme.colors.white};
        border-top: 17px solid transparent;
        border-right: 15px solid transparent;
        filter: drop-shadow(
            0 -2px 1px ${(props: any) => props.theme.colors.grey10}
        );

        @media screen and (max-width: 1272px) {
            left: 241px;
        }

        @media screen and (max-width: 1190px) {
            left: 341px;
        }

        @media screen and (max-width: 437px) {
            left: 12px;
        }
    }

    .form-style {
        padding: 10px 0px 10px 0px;

        @media screen and (max-width: 545px) {
            padding: 10px 44px;
        }

        @media screen and (max-width: 545px) {
            padding: 10px 20px;
        }

        @media screen and (max-width: 340px) {
            padding: 10px 0px;
        }

        ul {
            .filter-popup {
                width: 100%;
                max-width: 218px;
                display: inline-block;
                padding: 5px 10px;
                vertical-align: middle;
                margin: 7px 0px;
                list-style: none;

                input {
                    width: 100%;
                    max-width: 200px;
                }
            }
        }
        .form-group {
            margin-bottom: 5px;
        }

        .filter-btn {
            margin-top: 68px;
            padding-right: 15px;
            list-style: none;
            @media screen and (max-width: 1034px) {
                margin-right: 22px;
            }

            @media screen and (max-width: 545px) {
                margin: 0 auto;
            }

            @media screen and (max-width: 412px) {
                margin-top: 20px;
                padding-left: 42px;
            }

            .outlinebtn {
                padding: 10px 22px;
            }

            .btn {
                padding: 10px 22px;
                @media screen and (max-width: 545px) {
                    margin-top: 15px;
                    padding: 10px 26px !important;
                }
            }
        }
        .title {
            font-family: ${(props: any) => props.theme.fonts.interregular};
            font-size: ${(props: any) => props.theme.fontSizes.medium};
            padding-bottom: 4px;
        }
    }

    .cross-icon-filter {
        width: 20px;
        cursor: pointer;
    }
`;

export default FilterStyle;
