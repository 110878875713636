import styled from 'styled-components';

const TableBody = styled.table`
    width: 100%;
    border-spacing: 0 8px !important;
    table-layout: fixed !important;
    &.plan-management-table {
        border-collapse: separate;
    }
    &.duplicate-table {
        thead {
            tr {
                th {
                    width: 200px;
                }
            }
        }
    }

    thead {
        &.grey-header {
            tr {
                box-shadow: 0px 3px 6px
                    ${(props: any) => props.theme.shadows.commonShadow};
                th {
                    background-color: ${(props: any) =>
                        props.theme.colors.appleWhite};
                    color: ${(props: any) => props.theme.colors.secondaryColor};
                    font-size: ${(props: any) => props.theme.fontSizes.normal};
                    width: 190px;
                }

                &.contact-table {
                    th:first-child {
                        width: 60px;
                    }
                }
            }
        }
        tr {
            th {
                padding: 20px 12px;
                text-align: left;
                position: sticky;
                top: 0px;
                z-index: 1;
            }
        }
    }
    tbody {
        &.data-border {
            box-shadow: 0px 3px 6px
                ${(props: any) => props.theme.shadows.commonShadow};
        }
        tr {
            margin-top: 12px;
            background-color: ${(props: any) => props.theme.colors.white};
            margin-bottom: 5px;
            &:first-child {
                .show-on-info {
                    top: 180px;
                    &::before {
                        right: 51px;
                        top: -32px;
                        border-left: 10px solid transparent;
                        border-bottom: 17px solid
                            ${(props: any) => props.theme.colors.white};
                        border-top: 17px solid transparent;
                        border-right: 10px solid transparent;
                        filter: drop-shadow(
                            0 -2px 1px ${(props: any) => props.theme.colors.grey2}
                        );
                    }
                }
            }

            &.normal-table {
                box-shadow: none;

                td:first-child {
                    vertical-align: top;
                }
            }

            &:hover {
                background-color: ${(props: any) => props.theme.colors.grey8};
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
            }

            &.plan-table-hover {
                &:hover {
                    background-color: ${(props: any) =>
                        props.theme.colors.white};
                    box-shadow: none;
                }
            }
            &.duplicate-table-hover {
                &:hover {
                    background-color: ${(props: any) =>
                        props.theme.colors.white};
                    box-shadow: none;
                    transform: unset;
                }
            }
            &.link-table-row {
                box-shadow: none;
                margin-top: 5px;

                &:hover {
                    background-color: ${(props: any) =>
                        props.theme.colors.white};
                    box-shadow: none;
                    transform: unset;
                }
            }

            td {
                font-size: ${(props: any) => props.theme.fontSizes.medium};
                font-family: ${(props: any) =>
                    props.theme.fonts.regularFontFamily};
                padding: 18px 12px;
                box-shadow: 0px 5px 0px 0px
                    ${(props: any) => props.theme.shadows.commonShadow};

                &.table-link {
                    position: sticky;
                    left: 0;
                    box-shadow: none;
                }

                &.contact-log-table {
                    padding: 12px 12px;
                }

                &.view-table {
                    background-color: ${(props: any) =>
                        props.theme.colors.appleWhite};
                    padding: 1px 12px;
                }

                .bullet-text {
                    display: flex;
                    align-items: center;
                    font-size: ${(props: any) => props.theme.fontSizes.medium};
                    font-family: ${(props: any) =>
                        props.theme.fonts.interregular};

                    &.plan-text {
                        font-size: ${(props: any) =>
                            props.theme.fontSizes.smallText};
                        color: ${(props: any) => props.theme.colors.darkGrey};
                    }

                    .bullet-points {
                        width: 8px;
                        height: 8px;
                        border-radius: 100%;
                        background-color: ${(props: any) =>
                            props.theme.colors.ButtonColor};
                        margin-right: 8px;

                        &.orange-dot {
                            background-color: ${(props: any) =>
                                props.theme.colors.dropdownActiveBg};
                        }
                        &.teal-dot {
                            background-color: ${(props: any) =>
                                props.theme.colors.primaryColor};
                        }
                        &.grey-dot {
                            background-color: ${(props: any) =>
                                props.theme.colors.grey4};
                        }
                    }

                    .dollar-amt {
                        font-size: ${(props: any) =>
                            props.theme.fontSizes.medium};
                        font-family: ${(props: any) =>
                            props.theme.fonts.intersemibold};
                        padding: 0px 2px;
                    }
                }

                .active-total {
                    li {
                        display: inline-block;
                        list-style: none;
                        padding-right: 13px;

                        &.border-right {
                            border-right: 2px solid
                                ${(props: any) => props.theme.colors.grey4};
                        }

                        &.total {
                            padding-left: 13px;
                        }

                        .plan-total {
                            color: ${(props: any) =>
                                props.theme.colors.darkGrey};
                            font-size: ${(props: any) =>
                                props.theme.fontSizes.medium};
                            font-family: ${(props: any) =>
                                props.theme.fonts.intersemibold};
                            text-align: center;
                        }
                    }
                }

                .capsule-status {
                    width: fit-content;
                    border-radius: 11px;
                    font-size: ${(props: any) =>
                        props.theme.fontSizes.extrasmall};
                    color: ${(props: any) => props.theme.colors.white};
                    padding: 3px 15px;
                    &.data {
                        background-color: ${(props: any) =>
                            props.theme.colors.dataHolderColor};
                    }
                    &.active {
                        background-color: ${(props: any) =>
                            props.theme.colors.activeBg};
                    }
                    &.inactive {
                        background-color: ${(props: any) =>
                            props.theme.colors.grey4};
                    }
                    &.terminated {
                        background-color: ${(props: any) =>
                            props.theme.colors.userTextColor};
                    }

                    &.subscription {
                        background-color: ${(props: any) =>
                            props.theme.colors.userTextColor};
                    }
                }

                &.normal-data {
                    border-bottom: 1px solid
                        ${(props: any) => props.theme.colors.grey2};
                    font-size: ${(props: any) => props.theme.fontSizes.normal};
                    font-family: ${(props: any) =>
                        props.theme.fonts.interregular};
                    padding: 14px 8px;
                    color: ${(props: any) => props.theme.colors.secondaryColor};
                    vertical-align: text-top;
                    border-radius: 0%;
                }

                &.plan-table {
                    border-bottom: 1px solid
                        ${(props: any) => props.theme.colors.grey2};
                }

                &.link-text {
                    color: ${(props: any) => props.theme.colors.primaryColor};
                    text-decoration: underline;
                }

                .table-link-opt {
                    text-decoration: underline;
                    cursor: pointer;
                    padding-right: 16px;
                    color: ${(props: any) => props.theme.colors.ButtonColor};
                    font-family: ${(props: any) =>
                        props.theme.fonts.regularFontFamily};
                    font-size: ${(props: any) => props.theme.fontSizes.medium};
                }
                .report-img {
                    &:hover .show-on-info {
                        display: block;
                    }
                }
                .show-on-info {
                    position: absolute;
                    right: 25px;
                    top: -99px;

                    width: 330px;
                    z-index: 1;
                    text-align: left;
                    padding: 10px 10px;
                    background-color: ${(props: any) =>
                        props.theme.colors.white};
                    border-radius: 5px;
                    border: 1px solid
                        ${(props: any) => props.theme.colors.grey10};
                    box-shadow: 0px 1px 1px
                        ${(props: any) => props.theme.colors.grey2};
                    display: none;

                    &:hover {
                        display: block;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        right: 49px;
                        bottom: -20px;
                        width: 0;
                        height: 0;
                        border-left: 15px solid transparent;
                        border-top: 21px solid
                            ${(props: any) => props.theme.colors.white};
                        border-right: 15px solid transparent;
                        filter: drop-shadow(
                            0 0px 1px
                                ${(props: any) => props.theme.colors.grey10}
                        );
                    }
                    .transaction-date {
                        font-size: ${(props: any) =>
                            props.theme.fontSizes.medium};
                        font-family: ${(props: any) =>
                            props.theme.fonts.intersemibold};
                        color: ${(props: any) => props.theme.colors.darkGrey};
                        padding: 5px 0px;
                    }
                    .transaction-date-rightside {
                        font-size: ${(props: any) =>
                            props.theme.fontSizes.smallText};
                        font-family: ${(props: any) =>
                            props.theme.fonts.interregular};
                        color: ${(props: any) => props.theme.colors.darkGrey};
                        padding: 5px 0px;
                    }
                }
                .report-img + .show-on-info:hover {
                    display: block;
                }
            }
        }
    }
    .table-link {
        position: sticky;
        left: 0;
    }

    .link-table-row {
        border-bottom: 3px solid ${(props: any) => props.theme.colors.grey10};
        box-shadow: none !important;

        td {
            box-shadow: none !important;
        }
    }
    .contactChangeLog {
        position: fixed;
        z-index: 999;
        overflow: visible;
        margin: auto;
        inset: 170px 0px 0px 250px;
    }
`;

export default TableBody;
