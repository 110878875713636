import { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import useLogout from '../hooks/useLogout';
import { HeaderStyle } from '.';
import { PATH_ROOT } from '../constants';
import { useAppSelector } from '../store/hook';
import { getInitialName } from './CommonMethods';
import { LogoutImg, UPArrow } from '../assets/images';

const Header = () => {
    const userDetails = useAppSelector((state) => state.auth);
    const logout = useLogout();
    const navigate = useNavigate();

    const [showLogout, setShowLogout] = useState(false);
    const dropdownRef = useRef<any>(null);

    const handleLogout = async () => {
        await logout();
        navigate(PATH_ROOT, { replace: true });
    };

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setShowLogout(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);
    const ThemeData =
        localStorage.getItem('theme') &&
        JSON.parse(localStorage.getItem('theme') || '');

    return (
        <HeaderStyle>
            <div className="row">
                <div className="col-12">
                    <div className="header-style">
                        <div className="proximity-logo">
                            <img
                                src={ThemeData?.headerLogo}
                                alt="logo"
                                width="100px "
                            />
                        </div>
                        <div className="header-right">
                            <span className="user-avatar">
                                {getInitialName(
                                    userDetails?.givenName,
                                    userDetails?.surName
                                )}
                            </span>
                            <div ref={dropdownRef}>
                                <p className="user-name">
                                    {`${userDetails.givenName} ${userDetails.surName}`}{' '}
                                    <img
                                        width="9px"
                                        className={`ml-1 img-arrow ${
                                            showLogout ? 'img-rotate' : ''
                                        }`}
                                        src={UPArrow}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        alt=""
                                        role="presentation"
                                        onClick={() => {
                                            setShowLogout(!showLogout);
                                        }}
                                    />
                                </p>
                                {showLogout && (
                                    <div className="logout show-on-hover">
                                        <ul className="cursor">
                                            <li
                                                role="presentation"
                                                onClick={handleLogout}
                                            >
                                                <img
                                                    src={LogoutImg}
                                                    alt=""
                                                    className="logout-img"
                                                />
                                                Logout
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                <div className="sub-admin">
                                    {userDetails.role}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HeaderStyle>
    );
};

export default Header;
