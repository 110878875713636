import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { tableHeader } from '../constants';
import { TableHeaderType } from '../types/type';

const initialState = {
    columns: tableHeader,
    isUpdateMainComponent: false,
};

export const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        setColumns: (state, action: PayloadAction<TableHeaderType[]>) => {
            return {
                ...state,
                columns: action.payload,
            };
        },

        setIsUpdateMainComponent: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isUpdateMainComponent: action.payload,
            };
        },
    },
});

export const { setColumns, setIsUpdateMainComponent } = contactsSlice.actions;

export default contactsSlice.reducer;
