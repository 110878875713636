import Modal from 'react-modal';

import {
    ModalOverlay,
    ModalContainer,
    customStyle,
} from '../Theme/style/ModalStyle';
import { CommonModalProps } from '../types/type';

const CommonModal = ({
    modalBody,
    isModalOpen,
    handleCloseOperation,
    styles = {},
}: CommonModalProps) => {
    return (
        <Modal
            isOpen={isModalOpen}
            style={{
                ...customStyle,
                content: {
                    ...customStyle.content,
                    ...styles,
                },
            }}
            onRequestClose={handleCloseOperation}
        >
            <ModalOverlay>
                <ModalContainer>{modalBody}</ModalContainer>
            </ModalOverlay>
        </Modal>
    );
};

export default CommonModal;
