import { FC, useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DebounceInput } from 'react-debounce-input';

import ConfirmModal from './ConfirmModal';
import ContactStyle from '../../Theme/style/ContactStyle';
import SelectDropdown from '../../common/SelectDropdown';
import ContactsTable from './ContactsTable';
import ContactDetails from './ContactDetails';
import NoRecords from '../../common/NoRecords';
import Loader from '../../common/Loader';
import PrimaryHeader from '../../common/PrimaryHeader';
import RcPagination from '../../common/RcPagination';
import MultiSelect from '../../common/MultiSelect';
import { useAppSelector, useAppDispatch } from '../../store/hook';
import { setIsUpdateMainComponent } from '../../features/contactsSlice';
import { Button, InputSearch, FilterTag2 } from '../../common';
import { SearchIconImg, TealCross } from '../../assets/images';
import { getContactsRecordsAPI, deleteBulkContactAPI } from '../../api';
import { PATH_UPLOAD_CONTACTS, viewAs } from '../../constants';
import {
    ContactRecordsType,
    SelectOption,
    SearchOptionsType,
    SortOptionsType,
    SortType,
} from '../../types/type';
import ContactFilter from './ContactFilter';

const Contacts: FC = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalNoOfRecords, setTotalNoOfRecords] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [inputSearch, setInputSearch] = useState<string>('');
    const [contactID, setContactID] = useState<number>(0);
    const [checked, setChecked] = useState<string[]>([]);
    const [contactRecords, setContactRecords] = useState<ContactRecordsType[]>(
        []
    );
    const [recordsPerPage, setRecordsPerPage] = useState<SelectOption>({
        value: '20',
        label: '20',
    });
    const [isAscending, setIsAscending] = useState<SortType>({
        value: false,
        name: '',
    });

    const [searchOptions, setSearchOptions] = useState<SearchOptionsType>({
        PageNumber: 1,
        PageSize: 20,
        sorttype: 'ASC',
        sort: 'FirstName',
    });

    const [showFilterUI, setShowFilterUI] = useState(false);
    const [filterTagNames, setFilterTagNames] = useState<any>({});
    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [selectedMultiFilters, setSelectedMultiFilters] = useState<any>([]);
    const dispatch = useAppDispatch();
    const { isUpdateMainComponent } = useAppSelector((state) => state.contacts);

    const handlePagination = (currentPageNo: number) => {
        setSearchOptions({
            ...searchOptions,
            PageNumber: currentPageNo,
        });
    };

    const handlePageChange = (option: SelectOption) => {
        const opt = {
            ...searchOptions,
            PageNumber: 1,
            PageSize: Number(option.value),
        };
        setRecordsPerPage(option);
        setSearchOptions(opt);
    };

    const handleTableCellClick = (id: number) => {
        setIsModalOpen(true);
        setContactID(id);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        if (isUpdateMainComponent) {
            getContactRecords(searchOptions);
            dispatch(setIsUpdateMainComponent(false));
        }
    };

    const handleSorting = (obj: SortOptionsType) => {
        setSearchOptions({ ...searchOptions, ...obj });
    };

    const handleInputchange = (searchtext: string) => {
        const opt = { ...searchOptions, searchtext };
        setSearchOptions(opt);
        setInputSearch(searchtext);
    };

    const handleFilterClick = () => {
        if (!isLoading) {
            setShowFilterUI(!showFilterUI);
        }
    };

    const getContactRecords = useCallback(async (searchOptio: any) => {
        setIsLoading(true);
        try {
            const searchParams = { ...searchOptio };
            const response = await getContactsRecordsAPI(searchParams);

            const records = response?.data?.canvassing_List?.map(
                (item: ContactRecordsType) => {
                    return { ...item, checkbox: false };
                }
            );
            setContactRecords(records);
            setTotalNoOfRecords(Number(response?.data?.count));
        } catch (error) {
            let message = 'Unknown Error';
            if (error instanceof Error) message = error.message;
            toast.error(message);
            setContactRecords([]);
            setTotalNoOfRecords(0);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleBulkDelete = async () => {
        setIsConfirmModalOpen(true);
    };

    const removeFilter = (key: string) => {
        const initialFilters = { ...selectedFilters, ...searchOptions };
        const initialTags = { ...filterTagNames };
        delete initialFilters[key];
        delete initialTags[key];

        const initialKeys = Object.keys(initialTags);
        const tempMultiSelectOptions: any = {};
        initialKeys.forEach((ele) => {
            if (selectedMultiFilters[ele])
                tempMultiSelectOptions[ele] = selectedMultiFilters[ele];
        });

        setFilterTagNames(initialTags);
        setSelectedMultiFilters(tempMultiSelectOptions);
        setSelectedFilters(initialFilters);
        setSearchOptions(initialFilters);
    };

    const getFilterValues = (value: string) => {
        if (value.includes('All')) return 'All';
        return value;
    };

    const getFilterTags = () => {
        const fields: any[] = [];
        if (filterTagNames) {
            Object.keys(filterTagNames).forEach((key: any) => {
                fields.push(
                    <div className="mb-3 mr-2">
                        <FilterTag2 className="capitalize">
                            {key.includes('bollotStatus')
                                ? 'Ballot Status'
                                : key.split(/(?=[A-Z])/).join(' ')}{' '}
                            - {getFilterValues(filterTagNames[key])}
                            <img
                                className="cross-icon"
                                src={TealCross}
                                alt="cross"
                                role="presentation"
                                onClick={() => removeFilter(key)}
                            />
                        </FilterTag2>
                    </div>
                );
            });
            return fields;
        }
        return null;
    };

    const sendDecision = async (val: boolean) => {
        try {
            if (val && checked && checked.length) {
                const str = checked.join(',');
                const response = await deleteBulkContactAPI(str);

                if (response?.status === 200) {
                    toast.success(response?.data);
                    getContactRecords(searchOptions);
                    setChecked([]);
                }
            }
        } catch (error) {
            let message = 'Unknown Error';
            if (error instanceof Error) message = error.message;
            toast.error(message);
        } finally {
            setIsConfirmModalOpen(false);
        }
    };

    const handleContactFilterOpt = (convObj: any) => {
        setFilterTagNames(convObj);
        setSelectedFilters({ ...selectedFilters, ...convObj });
        const opt = {
            ...searchOptions,
            ...selectedFilters,
            ...convObj,
        };
        setSearchOptions(opt);
        setShowFilterUI(false);
    };

    const handleClearAll = () => {
        setShowFilterUI(false);
        setSelectedMultiFilters([]);
        setFilterTagNames({});
        setSearchOptions({
            PageNumber: 1,
            PageSize: 20,
            sorttype: 'ASC',
            sort: 'FirstName',
        });
    };

    useEffect(() => {
        getContactRecords(searchOptions);
    }, [getContactRecords, searchOptions]);

    return (
        <>
            <div className="body-container">
                <ContactStyle>
                    <div className="row my-2">
                        <div className="col">
                            <PrimaryHeader label="Contacts" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 py-4">
                            <div className="contact-filter-left">
                                <ul className="contact-filter">
                                    <li className="input-search">
                                        <InputSearch>
                                            <DebounceInput
                                                placeholder="Search"
                                                className="form-control"
                                                aria-label="Search"
                                                minLength={2}
                                                debounceTimeout={1500}
                                                value={inputSearch}
                                                onChange={(event) =>
                                                    handleInputchange(
                                                        event.target.value
                                                    )
                                                }
                                                disabled={isLoading}
                                            />
                                            <img
                                                src={SearchIconImg}
                                                alt=""
                                                className="search-icon"
                                            />
                                        </InputSearch>
                                    </li>

                                    <li>
                                        <div className="pt-2">Or</div>
                                    </li>

                                    <li>
                                        <div
                                            role="presentation"
                                            className="filter-link pt-2"
                                            onClick={handleFilterClick}
                                        >
                                            Filter
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="contact-btn-right">
                                <Button
                                    onClick={handleBulkDelete}
                                    disabled={!checked.length}
                                >
                                    Delete
                                </Button>
                                <Button
                                    className="mx-2"
                                    onClick={() => {
                                        navigate(PATH_UPLOAD_CONTACTS);
                                    }}
                                    disabled={isLoading}
                                >
                                    Upload
                                </Button>

                                <div className="multiple-select">
                                    <MultiSelect isDisabled={isLoading} />
                                </div>

                                <div className="d-flex">
                                    <p className="mt-2 mx-2 display-inline-block">
                                        View As
                                    </p>
                                    <SelectDropdown
                                        options={viewAs}
                                        selected={recordsPerPage}
                                        handlePageChange={handlePageChange}
                                        isDisabled={isLoading}
                                    />
                                </div>
                            </div>
                            <div className="clear-fix " />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12 d-flex flex-row flex-wrap">
                            {getFilterTags()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ContactFilter
                                showFilterUI={showFilterUI}
                                selectedMultiFilters={selectedMultiFilters}
                                setContactFilterOpt={handleContactFilterOpt}
                                setShowFilterUI={setShowFilterUI}
                                setSelectedMultiFilters={
                                    setSelectedMultiFilters
                                }
                                handleClearAll={handleClearAll}
                            />
                        </div>
                    </div>

                    {isLoading ? (
                        <div>
                            <Loader
                                type="pageWithTopContent"
                                text="Hang on...We are working on it."
                            />
                        </div>
                    ) : (
                        <div>
                            {contactRecords && contactRecords.length ? (
                                <>
                                    <div>
                                        <ContactsTable
                                            records={contactRecords}
                                            isAscending={isAscending}
                                            checked={checked}
                                            handleClick={handleTableCellClick}
                                            setSortingOpt={handleSorting}
                                            setIsAscending={setIsAscending}
                                            setChecked={setChecked}
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <RcPagination
                                                totalRecords={totalNoOfRecords}
                                                currentPage={
                                                    searchOptions.PageNumber
                                                }
                                                recordsPerPage={Number(
                                                    recordsPerPage.value
                                                )}
                                                handlePageChange={
                                                    handlePagination
                                                }
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="position-relative">
                                    <NoRecords />
                                </div>
                            )}
                        </div>
                    )}
                </ContactStyle>
            </div>
            {contactID && isModalOpen && (
                <ContactDetails
                    contactID={contactID}
                    isModalOpen={isModalOpen}
                    handleCloseModal={handleCloseModal}
                />
            )}
            {isConfirmModalOpen && (
                <ConfirmModal
                    isConfirmModalOpen={isConfirmModalOpen}
                    sendDecision={sendDecision}
                />
            )}
        </>
    );
};

export default Contacts;
