import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import CreatePlan from './CreatePlan';
import Loader from '../../common/Loader';
import CommonModal from '../../common/CommonModal';
import PlanManagementStyle from '../../Theme/style/PlanManagementStyle';
import { sendSignupEmail } from '../../api';
import { PlanTabsType } from '../../types/type';
import { validateEmail } from '../../common/CommonMethods';
import { ArrowBtn, ArrowRight } from '../../assets/images';
import {
    PATH_PLAN_MANAGEMENT,
    enterprisePlanType,
    planTabs,
} from '../../constants';
import {
    PageNav,
    NavTab,
    Button,
    InputSearch,
    DropDownStyle,
    ErrorMsg,
} from '../../common';

const CreatePlanContainer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState('feature');
    const [subTitle, setSubTitle] = useState('');
    const [isPlanCreated, setIsPlanCreated] = useState(false);
    const [isSignupModal, setIsSignupModal] = useState(false);
    const [singupEmail, setSingupEmail] = useState('');
    const [enterprisePlan, setEnterprisePlan] = useState<any>({
        value: '',
        label: '',
    });
    const [createdPlanId, setCreatedPlanId] = useState(0);
    const [loader, setLoader] = useState({ buttonLoader: false });
    const [isPlanChanged, setIsPlanChanged] = useState(false);

    const mode: any = location.pathname
        .split('/')
        .find((element: any) => element === 'create' || element === 'clone');

    const getTabHTML = () => {
        return (
            <CreatePlan
                mode={mode}
                subTitle={subTitle}
                isPlanCreated={isPlanCreated}
                setIsPlanCreated={setIsPlanCreated}
                planType={enterprisePlan}
                createdPlanId={createdPlanId}
                setCreatedPlanId={setCreatedPlanId}
                isPlanChanged={isPlanChanged}
                setIsPlanChanged={setIsPlanChanged}
            />
        );
    };

    const getNavBarPath = (navLoc: string) => {
        switch (navLoc) {
            case 'create':
                return 'Create new';
            case 'clone':
                return 'Clone plan';
            default:
                return '';
        }
    };

    const handleSendEmail = async () => {
        const payload = {
            eentrpriseplanId: Number(createdPlanId),
            email: singupEmail,
        };
        try {
            const response = await sendSignupEmail(payload);
            if (response.status === 200) {
                toast.success(response.data);
                navigate(PATH_PLAN_MANAGEMENT);
            }
        } catch (error: any) {
            toast.error(error?.data);
        } finally {
            setLoader({ buttonLoader: false });
        }
    };

    const handleSendEmailValidation = () => {
        if (!singupEmail || (singupEmail && !validateEmail(singupEmail))) {
            return true;
        }
        return false;
    };

    const handleModalClose = () => {
        setSingupEmail('');
        setIsSignupModal(false);
    };

    return (
        <div className="body-container">
            <PlanManagementStyle>
                <PageNav className="mb-4">
                    <ul className="nav-list">
                        <li
                            className="active-nav"
                            role="presentation"
                            onClick={() => navigate(PATH_PLAN_MANAGEMENT)}
                        >
                            Plan Management{' '}
                            <img
                                className="ml-1"
                                src={ArrowRight}
                                alt="arrow"
                            />{' '}
                        </li>
                        <li>{getNavBarPath(mode)}</li>
                    </ul>
                </PageNav>
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="capitalize">{getNavBarPath(mode)}</h2>
                    </div>
                    <div className="col-md-6">
                        <div className="toggle-btn">
                            {isPlanCreated ? (
                                <Button
                                    className="mr-2"
                                    onClick={() => {
                                        setIsSignupModal(true);
                                    }}
                                >
                                    Send sign up link
                                </Button>
                            ) : null}
                            <Button
                                className="btn-border"
                                onClick={() => {
                                    navigate(PATH_PLAN_MANAGEMENT);
                                }}
                            >
                                <img src={ArrowBtn} alt="back" /> Back
                            </Button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="bullet-text">
                            <div
                                className={`bullet-points ${
                                    mode === 'create'
                                        ? 'teal-dot'
                                        : 'orange-dot'
                                }`}
                            />

                            <span className="pl-1">
                                {mode === 'create' || mode === 'clone'
                                    ? 'Enterprise'
                                    : 'Starter'}
                            </span>
                        </div>
                        {(mode === 'create' && !isPlanCreated) ||
                        (mode === 'clone' && !isPlanCreated) ? (
                            <div className="row">
                                <div className="col-md-6">
                                    <InputSearch className="mt-3">
                                        <p className="amt-title mb-1">
                                            Subtitle
                                        </p>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={subTitle}
                                            onChange={(e) => {
                                                setSubTitle(e.target.value);
                                            }}
                                        />
                                    </InputSearch>
                                </div>
                                {mode === 'create' && (
                                    <div className="col-md-5">
                                        <DropDownStyle className="mt-3">
                                            <div
                                                style={{ paddingBottom: '3px' }}
                                            >
                                                Plan Type
                                            </div>
                                            <Select
                                                options={enterprisePlanType}
                                                value={enterprisePlan}
                                                onChange={(e) => {
                                                    setIsPlanChanged(true);
                                                    setEnterprisePlan(e);
                                                }}
                                            />
                                        </DropDownStyle>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="mt-3">Subtitle: {subTitle}</div>
                        )}
                    </div>
                </div>
                {mode === 'edit' ? (
                    <NavTab className="mt-4">
                        <ul className="tabs">
                            {planTabs.map((item: PlanTabsType) => (
                                <li
                                    key={item.key}
                                    onClick={() => setSelectedTab(item.key)}
                                    role="presentation"
                                    className={
                                        selectedTab === item.key ? 'active' : ''
                                    }
                                >
                                    {item.label}
                                </li>
                            ))}
                        </ul>
                    </NavTab>
                ) : null}
                {getTabHTML()}
                {isSignupModal && (
                    <CommonModal
                        isModalOpen={isSignupModal}
                        modalBody={
                            <>
                                <h3 className="text-center mb-3">
                                    Please enter candidate&apos;s email below to
                                    send the registration link for the
                                    application
                                </h3>
                                <div className="row my-2">
                                    <div className="col-12 text-center">
                                        <InputSearch>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={singupEmail}
                                                onChange={(e) => {
                                                    setSingupEmail(
                                                        e.target.value
                                                    );
                                                }}
                                                placeholder="Enter email"
                                            />
                                            {singupEmail &&
                                            handleSendEmailValidation() ? (
                                                <ErrorMsg className="text-left email-error">
                                                    Invalid email
                                                </ErrorMsg>
                                            ) : (
                                                ''
                                            )}
                                        </InputSearch>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 text-center">
                                        <Button
                                            className="btn-border"
                                            onClick={() => {
                                                handleModalClose();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="ml-2"
                                            disabled={
                                                loader.buttonLoader ||
                                                handleSendEmailValidation()
                                            }
                                            onClick={() => {
                                                setLoader({
                                                    buttonLoader: true,
                                                });
                                                handleSendEmail();
                                            }}
                                        >
                                            {loader.buttonLoader ? (
                                                <Loader type="button" />
                                            ) : (
                                                'Send email'
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </>
                        }
                        handleCloseOperation={() => {
                            handleModalClose();
                        }}
                    />
                )}
            </PlanManagementStyle>
        </div>
    );
};

export default CreatePlanContainer;
