import { styled } from 'styled-components';

export const InputSearch = styled.div`
    position: relative;

    .form-control {
        padding: 8px 10px;
        min-height: 40px;
        font-size: ${(props: any) => props.theme.fontSizes.medium};
        background-color: ${(props: any) => props.theme.colors.white};
        color: ${(props: any) => props.theme.colors.secondaryColor};
        border: 1px solid ${(props: any) => props.theme.colors.grey2};
        border-radius: 4px;
        font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
        width: 100%;
        position: relative;

        &.dollar-input {
            padding-left: 42px;
        }

        &::placeholder {
            font-family: ${(props: any) => props.theme.fonts.regularFontFamily};
            font-size: ${(props: any) => props.theme.fontSizes.normal};
            color: ${(props: any) => props.theme.colors.grey3};
        }

        &:focus {
            background: none !important;
            outline: none !important;
            padding-right: 36px;
            box-shadow: none;
        }
    }

    input[type='text'] {
        border: 1px solid ${(props: any) => props.theme.colors.grey2};
        outline: none;
    }

    .search-icon {
        position: absolute;
        top: 13px;
        right: 8px;
        width: 14px;
    }

    .up-arrow {
        position: absolute;
        right: 11px;
        top: 19px;
    }

    .dollar-img {
        position: absolute;
        top: 0px;
        height: 39px;
        width: 39px;
        left: 0px;
    }
    .inputSearchID {
        border-radius: 0;
        padding-left: 5px;
        padding-right: 0;
    }
`;

export const ListElement = styled.li`
    list-style-type: none;
`;
