import { FC, useState, useEffect, useCallback, memo } from 'react';

import Select from 'react-select';
import { toast } from 'react-toastify';

import { getDataTypeAPI, setAddOnFeatureAPI, addNewTypeAPI } from '../../api';
import {
    planDetailsDropdownOptions,
    subLabelArr,
    onlyNumbers,
} from '../../constants';
import { Button, InputSearch, DropDownStyle, handleError } from '../../common';
import { DropDownOptionType, AddOnFeaturePropsType } from '../../types/type';
import { convertEnterpriseData } from '../../common/CommonMethods';

const AddOnFeature: FC<AddOnFeaturePropsType> = ({
    fmTitleId,
    fmTitleName,
    planName,
    isAddOn,
    setAddFeature,
    setIsAddNewType,
    planId,
    flag = '',
    handleAddFeatureClose,
    getPlanDetail,
}) => {
    const initialAddOnValues = {
        planName: ['Starter', 'Advanced'].includes(planName)
            ? planName
            : 'Enterprise',
        planId: Number(planId),
        fmTitleId,
        fmTitleName,
        fcLabelId: 0,
        fcLabelName: '',
        inputTypeId: 1,
        value1: '',
        value2: '',
        value3: '',
    };
    const initialTempAddNewType = {
        planId: 0,
        planName: '',
        fmTitleName: '',
        featureslist: [],
    };
    const [dataType, setDataType] = useState<DropDownOptionType[]>([
        { label: '', value: '' },
    ]);
    const [selectedApplicable, setSelectedApplicable] =
        useState<DropDownOptionType>(planDetailsDropdownOptions[0]);
    const [addOnValues, setAddOnValues] = useState<any>(initialAddOnValues);
    const [tempAddNewtype, setTempAddNewType] = useState<any>(
        initialTempAddNewType
    );
    const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState<boolean>(false);

    const handleDropDownChange = (e: any, dataTypeVal: string) => {
        const temp = { ...addOnValues };
        if (dataTypeVal === 'inputTypeId' && e?.value === 2) {
            setAddOnValues({
                ...temp,
                value1: planDetailsDropdownOptions[0]?.value,
                inputTypeId: e?.value,
            });
        } else if (dataTypeVal === 'applicableType') {
            setAddOnValues({
                ...temp,
                value1: e?.value === 'applicable' ? '1' : '2',
            });
            setSelectedApplicable(e);
        } else {
            setAddOnValues({
                ...temp,
                [dataTypeVal]: e?.value,
                value1: '',
                value2: '',
                value3: '',
            });
        }
    };

    const handleInputChange = (
        val: string,
        accessorProps: string,
        isValid: boolean
    ) => {
        const temp = { ...addOnValues };
        // isValid  is to check difference between number and text.
        if (isValid) {
            if (val === '' || onlyNumbers.test(val)) {
                setAddOnValues({ ...temp, [accessorProps]: val });
            }
        } else {
            setAddOnValues({ ...temp, [accessorProps]: val });
        }
    };

    const getDropdown = (
        lable: string,
        options: DropDownOptionType[],
        selectedType: DropDownOptionType,
        accessorProp: string
    ) => {
        return (
            <li>
                <p className="mb-1">{lable}</p>
                <DropDownStyle>
                    <Select
                        value={selectedType}
                        isClearable={false}
                        isSearchable={false}
                        options={options}
                        onChange={(e) => handleDropDownChange(e, accessorProp)}
                    />
                </DropDownStyle>
            </li>
        );
    };

    const getInputField = (nameArr: any) => {
        return nameArr.map(({ lable, accessor, isNum }: any) => (
            <li key={accessor}>
                <p className="mb-1">{lable}</p>
                <InputSearch>
                    <input
                        type="text"
                        name={accessor}
                        className="form-control"
                        value={addOnValues?.[accessor]}
                        onChange={(e) =>
                            handleInputChange(e.target.value, accessor, isNum)
                        }
                    />
                </InputSearch>
            </li>
        ));
    };

    const getFeatureValueField = (val: number) => {
        switch (val) {
            case 1:
                return getInputField([
                    { lable: 'Text', accessor: 'value1', isNum: false },
                ]);
            case 2:
                return getDropdown(
                    'Applicable/Not Applicable',
                    planDetailsDropdownOptions,
                    selectedApplicable,
                    'applicableType'
                );
            case 3:
                return getInputField([
                    { lable: 'Numeric', accessor: 'value1', isNum: true },
                ]);
            case 4:
                return getInputField([
                    { lable: 'Numeric', accessor: 'value1', isNum: true },
                    { lable: 'Prefix', accessor: 'value2', isNum: false },
                ]);
            case 5:
                return getInputField([
                    { lable: 'Numeric', accessor: 'value1', isNum: true },
                    { lable: 'Numeric', accessor: 'value2', isNum: true },
                    { lable: 'Prefix', accessor: 'value3', isNum: false },
                ]);
            case 6:
                return getInputField([
                    { lable: 'Numeric', accessor: 'value1', isNum: true },
                    { lable: 'Frequency', accessor: 'value2', isNum: false },
                ]);
            default:
                return getInputField([
                    { lable: 'Label', accessor: 'fcLabelName', isNum: false },
                ]);
        }
    };

    const getDataType = useCallback(async () => {
        try {
            const response = await getDataTypeAPI();
            if (response?.data?.length) {
                const temp = response?.data.map((item: any) => {
                    return { label: item?.value, value: item?.id };
                });
                setDataType(temp);
            }
        } catch (error) {
            handleError(error);
            setDataType([{ label: '', value: '' }]);
        }
    }, []);

    const handleAddButton = async () => {
        if (!isAddOn) {
            setTempAddNewType({
                ...tempAddNewtype,
                planId: addOnValues?.planId,
                planName: addOnValues?.planName,
                fmTitleName: addOnValues?.fmTitleName,
                featureslist: [
                    ...tempAddNewtype.featureslist,
                    {
                        fcLabelName: addOnValues?.fcLabelName,
                        inputTypeId: addOnValues?.inputTypeId,
                        value1: addOnValues?.value1,
                        value2: addOnValues?.value2,
                        value3: addOnValues?.value3,
                    },
                ],
            });
            setAddOnValues({
                ...initialAddOnValues,
                fmTitleName: addOnValues?.fmTitleName || '',
            });
        } else {
            try {
                const response = await setAddOnFeatureAPI(addOnValues);
                toast.success(response?.data);
                setAddFeature(
                    addOnValues?.fmTitleName,
                    addOnValues?.fcLabelName,
                    {
                        value1: addOnValues?.value1,
                        value2: addOnValues?.value2,
                        value3: addOnValues?.value3,
                    },
                    isAddOn
                );
            } catch (error) {
                handleError(error);
            }
        }
    };

    const getSelectedValue = (id: number) => {
        return dataType.find((item: any) => Number(item.value) === Number(id));
    };

    const handleCancleBtn = () => {
        setIsAddNewType?.(false);
        setTempAddNewType(initialTempAddNewType);
    };

    const handleAddNewTypeSave = async () => {
        try {
            setIsSaveBtnDisabled(true);
            const response = await addNewTypeAPI(tempAddNewtype);
            toast.success(response?.data);
            setIsAddNewType?.(false);
            setTempAddNewType(initialTempAddNewType);
            if (flag === 'create' || flag === 'clone') {
                const featureObj = convertEnterpriseData([
                    {
                        featuresName: tempAddNewtype.fmTitleName,
                        featurChildlist: tempAddNewtype.featureslist.map(
                            (featureListDetails: any) => {
                                return {
                                    ...featureListDetails,
                                    lable: featureListDetails.fcLabelName,
                                    inputTypeId: featureListDetails.inputTypeId,
                                };
                            }
                        ),
                    },
                ]);
                setAddFeature(
                    tempAddNewtype?.fmTitleName,
                    '',
                    featureObj,
                    isAddOn
                );
            }
            getPlanDetail?.(false);
        } catch (error) {
            handleError(error);
        } finally {
            setIsSaveBtnDisabled(false);
        }
    };

    const getDisableVal = (lblId: number) => {
        if (
            lblId === addOnValues?.fmTitleId &&
            (addOnValues?.fmTitleName !== '' ||
                tempAddNewtype?.fmTitleName !== '')
        ) {
            switch (addOnValues?.inputTypeId) {
                case 1:
                case 2:
                case 3:
                    if (
                        addOnValues?.value1 !== '' &&
                        addOnValues?.fcLabelName !== ''
                    ) {
                        return false;
                    }
                    break;
                case 4:
                case 6:
                    if (
                        addOnValues?.value1 !== '' &&
                        addOnValues?.value2 !== '' &&
                        addOnValues?.fcLabelName !== ''
                    ) {
                        return false;
                    }
                    break;
                case 5:
                    if (
                        addOnValues?.value1 !== '' &&
                        addOnValues?.value2 !== '' &&
                        addOnValues?.value3 !== '' &&
                        addOnValues?.fcLabelName !== ''
                    ) {
                        return false;
                    }
                    break;
                default:
                    return true;
            }
        }
        return true;
    };

    useEffect(() => {
        getDataType();
    }, [getDataType]);

    const handleChangeOfInput = (val: string) => {
        handleInputChange(val, 'fmTitleName', false);
    };

    return (
        <>
            {!isAddOn && !tempAddNewtype?.featureslist.length ? (
                <div className="col-12 py-2">
                    <p className="mb-1">Title</p>
                    <InputSearch className="title-input">
                        <input
                            type="text"
                            className="form-control"
                            value={addOnValues?.fmTitleName}
                            onChange={(e) =>
                                handleChangeOfInput(e.target.value)
                            }
                        />
                    </InputSearch>
                </div>
            ) : (
                ''
            )}
            {tempAddNewtype?.featureslist &&
            tempAddNewtype?.featureslist.length ? (
                <>
                    <div className="card-header col-12">
                        {tempAddNewtype?.fmTitleName}
                    </div>
                    <div className="horizontal-line mt-3" />
                    <div className="row">
                        {tempAddNewtype?.featureslist.map((item: any) => {
                            return (
                                <div
                                    className="col-lg-3 py-2 my-2"
                                    key={item?.fcLabelName}
                                >
                                    <div className="plan-title mb-2">
                                        {item?.fcLabelName}
                                    </div>
                                    <div className="page-title mt-2">
                                        <span className="pre-pay cf">
                                            {`${item?.value1 || ''}${
                                                subLabelArr.includes(
                                                    item?.inputTypeId
                                                ) &&
                                                item?.value1 &&
                                                (item?.value2 || item?.value3)
                                                    ? '/'
                                                    : ' '
                                            }${item?.value2 || ''} ${
                                                item?.value3 || ''
                                            }`}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : (
                ''
            )}
            <div className="col-12">
                <ul className="plan-management-list">
                    {getFeatureValueField(0)}
                    {getDropdown(
                        'Data Type',
                        dataType,
                        getSelectedValue(addOnValues?.inputTypeId)!,
                        'inputTypeId'
                    )}

                    {getFeatureValueField(addOnValues?.inputTypeId)}
                    <li style={{ maxWidth: '245px' }}>
                        <Button
                            className="btn-border mr-2 vertical-align-middle"
                            onClick={() => {
                                setAddOnValues(initialAddOnValues);
                                handleAddFeatureClose?.(
                                    addOnValues?.fmTitleName
                                );
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleAddButton}
                            className="vertical-align-middle"
                            disabled={getDisableVal(addOnValues?.fmTitleId)}
                        >
                            Add
                        </Button>
                    </li>
                </ul>
            </div>
            {!isAddOn ? (
                <div className="col-12 text-right mt-4">
                    <Button
                        className="btn-border mr-3"
                        onClick={handleCancleBtn}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        onClick={handleAddNewTypeSave}
                        disabled={
                            !tempAddNewtype?.featureslist.length ||
                            isSaveBtnDisabled
                        }
                    >
                        Save
                    </Button>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default memo(AddOnFeature);
