import { FC, ReactElement, ChangeEvent } from 'react';

import TableHeader from '../planManagement/TableHeader';
import { useAppSelector } from '../../store/hook';
import { TableBody, phoneNoformat, CheckBox } from '../../common';
import {
    ContactRecordsType,
    TableHeaderType,
    ContactTableProp,
} from '../../types/type';

const ContactsTable: FC<ContactTableProp> = ({
    records,
    isAscending,
    checked,
    handleClick,
    setSortingOpt,
    setIsAscending,
    setChecked,
}) => {
    const { columns } = useAppSelector((state) => state.contacts);
    const columnsWithCheckbox = [
        { label: '', value: 'checkbox', isChecked: true },
        ...columns,
    ];

    const handleTdClick = (id: number) => {
        handleClick(id);
    };

    const handleSorting = (order: string, field: string) => {
        setIsAscending({ value: order === 'ASC', name: field });
        setSortingOpt({ sorttype: order, sort: field });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let updatedList = [...checked];
        if (e.target.checked) {
            updatedList = [...checked, e.target.value];
        } else {
            updatedList.splice(checked.indexOf(e.target.value), 1);
        }

        setChecked(updatedList);
    };

    const getFirstName = (fName: string, id: number) => {
        return (
            <span
                className="cursor link-name"
                role="presentation"
                onClick={() => handleTdClick(id)}
            >
                <span>{fName}</span>
            </span>
        );
    };

    const getAddress = (record: ContactRecordsType) => {
        let updatedAddress = `${record?.addressLine1 || ''} ${
            record?.addressLine2 || ''
        } ${record?.city || ''} ${record?.state || ''}`;
        if (!updatedAddress.trim()) {
            updatedAddress = '-';
        }

        return updatedAddress;
    };

    const getTDStyle = (val: string) => {
        const txtCenterArr = [
            'ballotStatus',
            'voterStatus',
            'votemethod',
            'contacttype',
            'supporter',
            'tags',
        ];
        let txtStyle: 'left' | 'center' | 'right' | undefined = 'left';
        if (val === 'phoneNumber') {
            txtStyle = 'right';
        } else if (txtCenterArr.includes(val)) {
            txtStyle = 'center';
        }
        return txtStyle;
    };

    const getSortColor = (isVal: boolean, name: string, cName: string) => {
        return isVal && name === cName ? '#414042' : '#bcbec0';
    };

    const getCheckbox = (id: number) => {
        return (
            <CheckBox>
                <label
                    className="check-container customer-pannel ml-0 pl-0"
                    htmlFor={id.toString()}
                >
                    <input
                        className="checkboxes"
                        type="checkbox"
                        id={id.toString()}
                        value={id}
                        onChange={handleChange}
                    />
                    <span className="checkmark" />
                </label>
            </CheckBox>
        );
    };

    return (
        <div className="table-responsive">
            <TableBody>
                <thead className="grey-header">
                    <tr className="contact-table">
                        {columnsWithCheckbox?.length &&
                            columnsWithCheckbox.map(
                                ({
                                    value,
                                    label,
                                    isChecked,
                                }: TableHeaderType) => {
                                    return (
                                        isChecked && (
                                            <TableHeader
                                                key={value}
                                                label={label}
                                                keyVal={value}
                                                noSort={[
                                                    'addressLine1',
                                                    'phoneNumber',
                                                    'checkbox',
                                                ]}
                                                accessor={value}
                                                upColor={getSortColor(
                                                    isAscending?.value,
                                                    isAscending?.name,
                                                    value
                                                )}
                                                downColor={getSortColor(
                                                    !isAscending?.value,
                                                    isAscending?.name,
                                                    value
                                                )}
                                                handleSorting={handleSorting}
                                                isAscending={isAscending}
                                                cssStyle={{
                                                    textAlign:
                                                        getTDStyle(value),
                                                }}
                                            />
                                        )
                                    );
                                }
                            )}
                    </tr>
                </thead>
                <tbody>
                    {records?.map((record: ContactRecordsType) => (
                        <tr key={record.id} className="normal-table">
                            {columnsWithCheckbox.map(
                                ({ value, isChecked }: TableHeaderType) => {
                                    let tdData: string | ReactElement | any =
                                        '';
                                    if (isChecked) {
                                        switch (value) {
                                            case 'checkbox':
                                                tdData = getCheckbox(record.id);
                                                break;
                                            case 'firstName':
                                                tdData = getFirstName(
                                                    record?.firstName,
                                                    record?.contactId
                                                );
                                                break;
                                            case 'addressLine1':
                                                tdData = getAddress(record);
                                                break;
                                            case 'phoneNumber':
                                                tdData =
                                                    phoneNoformat(
                                                        record?.phoneNumber
                                                    ) || '-';
                                                break;
                                            default:
                                                tdData = record[
                                                    value as keyof ContactRecordsType
                                                ]
                                                    ? record[
                                                          value as keyof ContactRecordsType
                                                      ]
                                                    : '-';
                                        }
                                    }
                                    return (
                                        tdData && (
                                            <td
                                                key={value}
                                                className="normal-data"
                                                style={{
                                                    textAlign:
                                                        getTDStyle(value),
                                                }}
                                            >
                                                {tdData}
                                            </td>
                                        )
                                    );
                                }
                            )}
                        </tr>
                    ))}
                </tbody>
            </TableBody>
        </div>
    );
};

export default ContactsTable;
