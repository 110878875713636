import { styled } from 'styled-components';

const CapsuleStatus = styled.div`
    width: fit-content;
    background-color: ${(props: any) => props.theme.colors.activeBg};
    border-radius: 11px;
    font-size: ${(props: any) => props.theme.fontSizes.extrasmall};
    color: ${(props: any) => props.theme.colors.white};
    padding: 3px 15px;
`;

export default CapsuleStatus;
