import styled from 'styled-components';

const ErrorMsg = styled.div`
    color: ${(props: any) => props.theme.colors.red};
    font-size: ${(props: any) => props.theme.fontSizes.normal};
    padding: 0;
    margin: 0 0 0 0;
    transform: translate(0, 6px);
    &.email-error {
        font-size: ${(props: any) => props.theme.fontSizes.small};
    }
    a {
        text-transform: lowercase;
        text-decoration: underline;
        display: block;
        color: ${(props: any) => props.theme.colors.red};
        font-size: ${(props: any) => props.theme.fontSizes.extrasmall};
    }
`;
export default ErrorMsg;
